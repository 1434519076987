export class PriceHistory {
  id: string;
  businessId: string;
  storeId: string;
  storeName: string;
  storeLocal: string;
  productId: string;
  productSku: string;
  productName: string;
  productImage: string;
  productBarcode: string;
  productLocation: string;
  before: number;
  price: number;
  userId: string;
  userName: string;
  changeType: string;
  supplierId: string;
  supplierName: string;
  isPrinted: boolean;
  version: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  constructor(init?: Partial<PriceHistory>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      business_id: this.businessId,
      store_id: this.storeId,
      store_name: this.storeName,
      store_local: this.storeLocal,
      product_id: this.productId,
      product_sku: this.productSku,
      product_name: this.productName,
      product_image: this.productImage,
      product_barcode: this.productBarcode,
      product_location: this.productLocation,
      before: this.before,
      price: this.price,
      user_id: this.userId,
      user_name: this.userName,
      change_type: this.changeType,
      supplier_id: this.supplierId,
      supplier_name: this.supplierName,
      is_printed: this.isPrinted,
      version: this.version,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      deleted_at: this.deletedAt,
    };
  }
}
