import { ClassificationType } from '../enums/classification-type';
import { ProductCodeType } from '../enums/product-code-type';
import { ProductSaleType } from '../enums/product-sale-type';
import { hasValue } from '../utils/array_util';
import { Brand } from './brand';
import { Category } from './category';
import { Inventory } from './inventory';
import { PriceBookProduct } from './price-book';
import { ProductSupplier } from './product';
import { ProductCode } from './product-code';
import { ProductPackage } from './product-package';

export class Item {
  public id: string;
  public sku: string;
  public codes?: ProductCode[];
  public name: string;
  public description: string;
  public image: string;
  public externalImage: string;
  public ageRestriction: boolean;
  public alcoholicBeverage: boolean;
  public keywords: string;
  public saleType: ProductSaleType;
  public presentation: string;
  public unitType: string;
  public quantity: number;
  public stepQuantity: number;
  public minQuantity: number;
  public maxQuantity: number;
  public category?: Category;
  public brand?: Brand;
  public tagIds: string[];
  public classification: ClassificationType;
  public compositeItems: CompositeItem[];
  public trackInventory: boolean;
  public packing: string;
  public madeFrom: ProductPackage[];
  public breakInto: ProductPackage[];
  public suppliers: ProductSupplier[];
  public prices: PriceBookProduct[];
  public inventories?: Inventory[];
  public isActive: boolean;
  public createdAt: Date;
  public updatedAt: Date;

  isLoading = false;
  isActiveLoading = false;
  selectedTab: 'inventory' | 'pricing' | 'details' = 'inventory';

  get price(): number {
    if (this.prices.length > 0) {
      return this.prices[0].priceIncludingTax;
    } else {
      return 0;
    }
  }

  get inStock(): number {
    return this.inventories?.reduce((acc, inv) => acc + (inv.inStock ? inv.inStock : 0), 0) ?? 0;
  }

  canBreakdown(): boolean {
    return false; // Implement this...
  }

  public hasPackagesTree(): boolean {
    return false; // (this.madeFrom && this.madeFrom.length > 0) || (this.breakInto && this.breakInto.length > 0);
  }

  constructor(init?: Partial<Item>) {
    Object.assign(this, init);
    this.codes = hasValue(init?.codes) ? init!.codes!.map((s) => new ProductCode(s)) : undefined;
    this.category = init?.category ? new Category(init.category) : undefined;
    this.brand = init?.brand ? new Brand(init.brand) : undefined;
    this.madeFrom = init?.madeFrom ? init.madeFrom.map((p) => new ProductPackage(p)) : [];
    this.breakInto = init?.breakInto ? init.breakInto.map((p) => new ProductPackage(p)) : [];
    this.compositeItems = init?.compositeItems ? init.compositeItems.map((ci) => new CompositeItem(ci)) : [];
    this.suppliers = init?.suppliers ? init.suppliers.map((s) => new ProductSupplier(s)) : [];
    this.prices = init?.prices ? init.prices.map((p) => new PriceBookProduct(p)) : [];
    this.inventories = init?.inventories ? init.inventories.map((i) => new Inventory(i)) : [];
  }

  static initialize(): Item {
    const product = new Item();
    product.codes = [new ProductCode({ type: ProductCodeType.CUSTOM })];
    product.saleType = ProductSaleType.UNIT;
    product.isActive = true;
    product.trackInventory = true;
    product.quantity = 1;
    product.unitType = 'und';
    product.presentation = '1 und';
    product.minQuantity = 1;
    product.stepQuantity = 1;
    product.maxQuantity = 100;
    product.classification = ClassificationType.STANDARD;
    product.suppliers = [new ProductSupplier()];
    return product;
  }

  static clone(source: Item): Item {
    const product = Item.initialize();
    //product.productId = source.productId;
    //product.sku = source.sku;
    //product.codes = source.codes;
    product.name = `${source.name} (Copy)`;
    product.description = source.description;
    product.classification = source.classification;
    //product.location = source.location;
    // ** product.tax = source.tax;
    // ** product.price = source.price;
    // ** product.markup = source.markup;
    // ** product.supplierId = source.supplierId;
    product.suppliers = source.suppliers;
    //product.imageUrl = source.imageUrl;
    //product.image = source.image;
    //product.images = source.images;
    product.keywords = source.keywords;
    //product.media = source.media;
    // ** product.brandId = source.brandId;
    // ** product.brandName = source.brandName;
    // ** product.categoryId = source.categoryId;
    // ** product.categoryName = source.categoryName;
    //product.tagIds = source.tagIds;
    //product.index = source.index;
    product.packing = source.packing;
    product.quantity = source.quantity;
    product.unitType = source.unitType;
    product.presentation = source.presentation;
    product.saleType = source.saleType;
    product.stepQuantity = source.stepQuantity;
    product.minQuantity = source.minQuantity;
    product.maxQuantity = source.maxQuantity;
    //product.discounts = source.discounts;
    //product.discount = source.discount;
    // ** product.disabledSrc = source.disabledSrc;
    product.prices =
      source.prices?.map(
        (p) =>
          new PriceBookProduct({
            priceBookId: p.priceBookId,
            priceBookName: p.priceBookName,
            supplyPrice: p.supplyPrice,
            markup: p.markup,
            tax: p.tax,
            price: p.price,
            priceIncludingTax: p.priceIncludingTax,
            priceExcludingTax: p.priceExcludingTax,
          })
      ) ?? [];
    product.trackInventory = source.trackInventory;
    product.inventories =
      source.inventories?.map(
        (i) =>
          new Inventory({
            storeId: i.storeId,
            storeName: i.storeName,
            inStock: 0,
            safetyStock: i.safetyStock,
            reorderPoint: i.reorderPoint,
            reorderQuantity: i.reorderQuantity,
            isAvailable: i.isAvailable,
            isActive: i.isActive,
          })
      ) ?? [];
    //product.inStock = source.inStock;
    // ** product.safetyStock = source.safetyStock;
    // ** product.reorderPoint = source.reorderPoint;
    // ** product.reorderQuantity = source.reorderQuantity;
    //product.madeFrom = source.madeFrom;
    //product.breakInto = source.breakInto;
    //product.packages = source.packages;
    //product.stockoutAt = source.stockoutAt;
    //product.lots = source.lots;
    // ** product.type = source.type; // @deprecated
    //product.expiresAt = source.expiresAt;
    //product.expirationParentId = source.expirationParentId;
    // ** product.isAvailable = source.isAvailable;
    product.isActive = source.isActive;
    // ** product.isPublic = source.isPublic;
    //product.isFeatured = source.isFeatured;
    //product.featuredIndex = source.featuredIndex;
    //product.createdAt = source.createdAt;
    //product.updatedAt = source.updatedAt;
    //product.deletedAt = source.deletedAt;
    return product;
  }

  public toJSON(): any {
    return {
      id: this.id,
      sku: this.sku,
      codes: hasValue(this.codes) ? this.codes!.map((c) => c.toJSON()) : undefined,
      name: this.name,
      description: this.description,
      image: this.image,
      external_image: this.externalImage,
      age_restriction: this.ageRestriction,
      alcoholic_beverage: this.alcoholicBeverage,
      keywords: this.keywords,
      sale_type: this.saleType,
      presentation: this.presentation,
      unit_type: this.unitType,
      quantity: this.quantity,
      step_quantity: this.stepQuantity,
      min_quantity: this.minQuantity,
      max_quantity: this.maxQuantity,
      category_id: this.category?.id,
      brand_id: this.brand?.id,
      tag_ids: this.tagIds,
      classification: this.classification,
      composite_items: this.compositeItems ? this.compositeItems.map((ci) => ci.toJSON()) : [],
      track_inventory: this.trackInventory,
      packing: this.packing,
      suppliers: this.suppliers ? this.suppliers.map((s) => s.toJSON()) : [],
      prices: this.prices ? this.prices.map((p) => p.toJSON()) : [],
      inventories: this.inventories ? this.inventories.map((i) => i.toJSON()) : [],
      is_active: this.isActive,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}

export class CompositeItem {
  productId: string;
  quantity: number;

  constructor(init?: Partial<CompositeItem>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      product_id: this.productId,
      quantity: this.quantity,
    };
  }
}
