import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '@app/core';

@Component({
  selector: 'ns-discount-label-print',
  templateUrl: './discount-label-print.component.html',
  styleUrls: ['./discount-label-print.component.scss'],
})
export class DiscountLabelPrintComponent implements OnInit {
  items: number[] = [];
  barcode: string = '';
  quantity: number = 1;

  data: { barcode: string; quantity: number };
  invoiceDetails: Promise<any>[];

  //@ViewChild('canvas', { static: true })
  //canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;

  constructor(route: ActivatedRoute, private printService: PrintService) {
    this.barcode = route.snapshot.queryParams['barcode'];
    this.quantity = route.snapshot.queryParams['quantity'] as number;

    for (var i = 1; i <= this.quantity; i++) {
      this.items.push(i);
    }
  }

  ngOnInit() {
    this.printService.onDataReady();
    //this.ctx = this.canvas.nativeElement.getContext('2d')!!;
  }
}
