import { RegisterLiteEntity } from '../data/entities/store.entity';

export class RegisterLite {
  id: string;
  name: string;
  sequenceNumber: number;
  isOpen: boolean;
  registerClosureId: string;
  openingTime: Date;
  closingTime: Date;

  constructor(init?: Partial<RegisterLite>) {
    Object.assign(this, init);
  }

  public entity(): RegisterLiteEntity {
    return new RegisterLiteEntity({
      id: this.id,
      name: this.name,
      sequenceNumber: this.sequenceNumber,
      isOpen: this.isOpen,
      registerClosureId: this.registerClosureId,
      openingTime: this.openingTime,
      closingTime: this.closingTime,
    });
  }
}
