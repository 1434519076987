import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  OnDestroy,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Chat, DEFAULT_USER_ID, Message, MessageType, Messaging } from '@app/core';
import { FileService } from '@app/core/services/file.service';
import Utils from '@app/core/utils/utils';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ns-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SharedModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class MessageComponent implements OnInit, AfterViewInit, OnDestroy {
  // Chat input
  @ViewChild('input') input: ElementRef;

  private _chat: Chat | undefined;

  defaultUserId = DEFAULT_USER_ID;

  @Input()
  set chat(value: Chat | undefined) {
    this._chat = value;
    if (value) {
      this.roomId = value.id;
      this.subscribeToRoom(this.roomId);
    }
  }

  get chat(): Chat | undefined {
    return this._chat;
  }

  // Scroll
  @ViewChild('scrollable', { static: false }) scroll: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;
  private isNearBottom = true;

  // Message list
  public messages: Message[] = [];

  isLoading = false;

  audioPlayerURL: string;
  fileWAV: File;

  roomId: string;
  lastRoomId: string;

  actionLayout: 'text' | 'audio' = 'text';

  isRecording: boolean;
  isPaused: boolean;

  subscription = new Subscription();

  constructor(private fileService: FileService, private messaging: Messaging) {}

  ngOnInit(): void {
    this.messages = [];

    this.subscription.add(
      // listen messages
      this.messaging.data$.subscribe((data) => {
        this.isLoading = false;

        if (!data) {
          return;
        }

        if (data.id !== this.roomId) {
          this.messages = data.messages;
        } else {
          switch (data.type) {
            case 'modified':
              for (let i = 0; i < data.messages.length; i++) {
                const updatedMessage = data.messages[i];
                const index = this.messages.findIndex((j) => j.id == updatedMessage.id);
                if (index != -1) {
                  this.messages[index] = updatedMessage;
                }
              }
              break;
            case 'added':
              this.messages = this.messages.concat(data.messages);
              if (this.isNearBottom) {
                this.scrollToBottom(false);
              }
              break;
          }
        }

        // set mesage as seen
        const lastMessage = this.messages._last();
        if (this.chat && lastMessage && lastMessage.uid != DEFAULT_USER_ID && lastMessage.seen != true) {
          this.messaging.seenMessage(this.chat?.id, lastMessage.id);
        }
      })
    );
  }

  onRecord() {
    this.actionLayout = 'audio';
  }

  onFinishAudio(): void {
    this.actionLayout = 'text';
  }

  onAudioFile(file: File): void {
    if (file) {
      this.fileService.uploadAudioToFirebase(file).then((url) => {
        if (url) {
          this.sendAudio(url);
        }
      });
    }

    this.onFinishAudio();
  }

  /**
   * Suscribir a canal
   * @param roomId id
   */
  subscribeToRoom(roomId: string) {
    // Evitar suscribirse al mismo canal
    if (this.lastRoomId === roomId) {
      return;
    }

    this.isLoading = true;

    this.messages = [];
    this.lastRoomId = roomId;

    this.messaging.addListener(roomId);
  }

  ngAfterViewInit() {
    this.itemElements.changes.subscribe((_) => this.onItemElementsChanged());
    this.scrollToBottom(false);
  }

  onScrollPosition(value: string) {
    switch (value) {
      case 'near_bottom':
        this.isNearBottom = true;
        break;
      case 'none':
        this.isNearBottom = false;
        break;
    }
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(isSmooth: boolean = true): void {
    if (this.scroll) {
      // @todo verificar la razon de nulo
      this.scroll.nativeElement.scroll({
        top: this.scroll.nativeElement.scrollHeight,
        left: 0,
        behavior: isSmooth ? 'smooth' : 'auto',
      });
    } else {
      console.log('scrool is null');
    }
  }

  download(url: string) {
    // console.log('url', url);
    // const fileURL = window.URL.createObjectURL(url);
    // window.open(fileURL, '_blank');
  }

  /**
   * Enviar mensaje
   */
  sendMessage(input: string) {
    if (Utils.isEmpty(this.roomId)) {
      console.log('roomId is empty');
      return;
    }

    if (Utils.isEmpty(input)) {
      console.log('input is empty');
      return;
    }

    this.messaging.sendText(input, (ok) => {
      console.log('send', ok);
    });

    this.input.nativeElement.value = '';
    this.input.nativeElement.focus();
  }

  /**
   * Send audio
   * @param url audio
   */
  private sendAudio(url: string): void {
    this.messaging.sendAudio(url, (response) => {
      console.log('send audio success', response);
    });
  }

  /**
   * Anular evento keydown.enter
   * @param event Event
   */
  onKeydownEnter(event: any) {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.messaging.removeListener();
  }

  getMessage(): Message[] {
    const messages: Message[] = [];
    for (let i = 0; i < 20; i++) {
      messages.push(
        new Message({
          id: 'default_63dd7e352efd0ef313bbcc83',
          name: `John doe ${i}`,
          photo: i % 2 == 0 ? undefined : 'https://thumbs.dreamstime.com/b/selfie-teen-girl-taking-beach-44978938.jpg',
          text: 'Hola, necesito ayuda con mis pedidos. Aún no llega y a pasado más de una hora. Donde esta!!',
          type: MessageType.TEXT,
          date: new Date()._addDays(-i),
        })
      );
    }
    return messages;
  }
}
