import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private auth: AngularFireAuth, private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  /**
   * @todo move to isLogginIn
   * @param url string
   */
  checkLogin(url: string): Observable<boolean> {
    return this.auth.authState.pipe(
      take(1),
      map((authState) => !!authState),
      tap((authenticated) => {
        if (!authenticated) {
          this.authService.redirectUrl = url;
          this.router.navigate(['/sign-in']);
        }
      })
    );
  }
}
