import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Cart } from '../models/cart';
import { CartItem, QuickSale } from '../models/cart-item';
import { Product } from '../models/product';
import { Store } from '../models/store';
import { PaymentMethod } from '../models/payment-method';
import { CartPayment } from '../models/cart-payment';
import { Customer } from '../models/customer';
import { SaleCreateRequest, SaleItemRequest } from '../api/requests/sale-create.request';
import { Sale } from '../models/sale';
import { UserConfig } from './user-config.service';
import { CartSerie, SerieType } from '../enums/serie-type';
import { Document } from '../data/document';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _cart = new BehaviorSubject<Cart | undefined>(undefined);
  public cart$ = this._cart.asObservable();
  public cart: Cart = new Cart();

  local: Store;

  constructor(private config: UserConfig) {}

  saleCompleted(sale: Sale) {
    this.cart.id = sale.id;
    this.cart.saleCompleted = true;
    this.cart.sale = sale;
    this.notifyChanges();
  }

  clear() {
    this.cart = new Cart();
    this.cart.setStore(this.local);
    this.notifyChanges();
  }

  newReturn(store: Store, sale: Sale) {
    // Save original sale id for reference in return sale
    sale.returnFor = sale.id;

    // Set new sale id
    sale.id = Document.newId();

    // Set items quantity to negative
    sale.items = sale.items.map((i) => {
      i.quantity = -i.quantity;
      return i;
    });

    // Clear payments
    sale.payments = [];

    this.loadSale(store, sale);
  }

  loadSale(store: Store, sale: Sale) {
    this.cart = new Cart();
    this.cart.setStore(store);
    this.cart.loadSale(sale);
    this.notifyChanges();
  }

  /**
   * Set local properties to cart
   * @param local Store
   */
  setStore(local: Store) {
    this.local = local;
    this.cart.setStore(local);
    this.notifyChanges();
  }

  setCustomer(customer: Customer | undefined) {
    this.cart.customer = customer;
    this.notifyChanges();
  }

  /**
   * Set default serie
   * @param serie Serie
   */
  setSerie(serie: CartSerie | undefined) {
    this.cart.selectedSerie = serie;
  }

  /**
   * Add product
   * @param product Product
   */
  addProduct(product: Product) {
    let item = this.cart.getItemByProductId(product.productId);
    if (item) {
      item.quantity += 1;
    } else {
      item = CartItem.fromProduct(this.cart, product);
      this.cart.addItem(item);
    }
    this.notifyChanges();
  }

  /**
   * Add product
   * @param product Product
   */
  addQuickSale(quickSale: QuickSale) {
    const item = CartItem.fromQuickSale(this.cart, quickSale);
    this.cart.addItem(item);
    this.notifyChanges();
  }

  /**
   * Set item quantity
   * @param cartItemId cartItemId
   * @param quantity quantity
   */
  setQuantity(cartItemId: string, quantity: number) {
    const item = this.cart.getItemById(cartItemId);
    if (item) {
      item.quantity = quantity;
      this.notifyChanges();
    }
  }

  /**
   * Set item price
   * @param cartItemId cartItemId
   * @param price price
   */
  setPrice(cartItemId: string, price: number) {
    const item = this.cart.getItemById(cartItemId);
    if (item) {
      item.priceInput = price;
      const percentage = ((item.basePrice - price) / item.basePrice) * 100; // ((5-1) / 5) * 100
      item.discountPercentageInput = percentage._round();
      //item.discountRateFmt = rate._round();
      this.notifyChanges();
    }
  }

  /**
   * Set item discount percentage
   * @param cartItemId cartItemId
   * @param percentage percentage
   */
  setDiscountRate(cartItemId: string, percentage: number) {
    const item = this.cart.getItemById(cartItemId);
    if (item) {
      item.discountPercentageInput = percentage;
      const price = item.basePrice - (item.basePrice * percentage) / 100;
      item.priceInput = price._round();
      //item.priceFmt = price._round();
      this.notifyChanges();
    }
  }

  /**
   * Set item note
   * @param cartItemId cartItemId
   * @param note note
   */
  setNote(cartItemId: string, note: string) {
    const item = this.cart.getItemById(cartItemId);
    if (item) {
      item.note = note;
      this.notifyChanges();
    }
  }

  /**
   * Remove cart item
   * @param cartItem CartItem
   */
  removeItem(cartItem: CartItem) {
    this.cart.removeItem(cartItem);
    this.notifyChanges();
  }

  /**
   * Add payment to cart
   * @param payment Payment
   * @param amount amount
   */
  addPayment(payment: PaymentMethod, amount: number) {
    const item = new CartPayment(CartPayment.from(payment, amount));
    this.cart.addPayment(item);
    this.notifyChanges();
  }

  /**
   * Remove cart payment
   * @param payment Payment
   * @param amount amount
   */
  removePayment(payment: CartPayment) {
    this.cart.removePayment(payment);
    this.notifyChanges();
  }

  /**
   * Notify changes to subscribers
   */
  notifyChanges() {
    this.cart.build();
    this._cart.next(this.cart);
  }

  validate(): string | null {
    if (!this.cart.local) {
      return 'Local no seleccionado';
    }

    // if (!this.cart.selectedSerie) {
    //   return 'Serie no seleccionada';
    // }

    if (this.cart.items.length === 0) {
      return 'No hay productos en el carrito';
    }

    if (!this.cart.isReturning() && this.cart.totalSale <= 0) {
      return 'El monto total debe ser mayor a 0';
    }

    if (this.cart.isReturning() && this.cart.totalSale >= 0) {
      return 'El monto total debe ser menor a 0';
    }

    // check if total is greater than 700 for boletas
    if (this.cart.selectedSerie?.type === SerieType.BOLETA && this.cart.totalSale > 700) {
      return 'El monto total no puede ser mayor a 700 para boletas';
    }

    // check if customer has RUC for facturas
    if (this.cart.selectedSerie?.type === SerieType.FACTURA && this.cart.customer?.documentType !== 'ruc') {
      return 'El cliente debe tener RUC para facturas';
    }

    return null;
  }

  toSaleRequest(): SaleCreateRequest {
    const sale = new SaleCreateRequest();

    // set sale details
    sale.store_id = this.cart.local?.id;
    sale.register_id = this.config.register?.id;
    sale.user_id = this.config.currentUserId;
    sale.customer = this.cart.customer
      ? {
          id: this.cart.customer.id,
          name: this.cart.customer.name,
          id_number: this.cart.customer.documentNumber,
          document_type: this.cart.customer.documentType,
        }
      : undefined;
    sale.invoice_type = this.cart.selectedSerie?.type;
    sale.global_discount = this.cart.globalDiscount;
    sale.global_charge_exempt = this.cart.globalChargeExempt;
    sale.global_charge = this.cart.globalCharge;
    sale.global_discount_exempt = this.cart.globalDiscountExempt;
    sale.total = this.cart.totalSale;
    //sale.total_tax = this.cart.totalTax;
    sale.note = this.cart.note;
    sale.return_for = this.cart.returnFor;
    sale.type = 'pos';

    // set items
    sale.items = this.cart.items.map((i) => {
      return {
        product_id: i.productId,
        name: i.name,
        tax_id: i.tax?.id,
        tax_code: i.tax?.code,
        tax_rate: i.tax?.rate,
        discount_id: i.discountId,
        quantity: i.quantity,
        unit_value: i.unitValue,
        unit_discount: i.unitDiscount,
        unit_charge: i.unitCharge,
        sale_type: i.saleType,
        unit_type: i.unitType ?? 'und',
        excise_type: i.exciseType,
        excise_value: i.exciseValue,
        bag_unit_tax: i.bagUnitTax,
        note: i.note,
        created_at: i.createdAt,
        updated_at: i.updatedAt,
      } as SaleItemRequest;
    });

    // set payments
    sale.payments = this.cart.payments.map((i) => {
      return {
        payment_id: i.paymentMethodId,
        amount: i.amount,
        payment_date: i.createdAt,
        created_at: i.createdAt,
      };
    });

    return sale;
  }
}
