import { StockAdjustment } from '../enums/stock-adjustment';

export class ProductStockAdjusment {
  private _storeId: string;
  private _productId: string;
  private _quantity: number;
  private _reason: StockAdjustment;

  public get storeId(): string {
    return this._storeId;
  }

  public set storeId(storeId: string) {
    this._storeId = storeId;
  }

  public get productId(): string {
    return this._productId;
  }

  public set productId(productId: string) {
    this._productId = productId;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(quantity: number) {
    this._quantity = quantity;
  }

  public get reason(): StockAdjustment {
    return this._reason;
  }

  public set reason(reason: StockAdjustment) {
    this._reason = reason;
  }

  constructor(init?: Partial<ProductStockAdjusment>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      store_id: this.storeId,
      // product_id: this.productId,
      quantity: this.quantity,
      reason: this.reason,
    };
  }
}
