import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { QuickKey } from '../models/quick-key';
import { Response } from '../interfaces/response';
import { QuickKeyFilter } from '../filters/quick-key.filter';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class QuickKeyService {
  private readonly index = environment.api + '/v1/quick_keys';

  constructor(protected api: ApiService, private http: HttpClient) {}

  get(id: string): Observable<Response<QuickKey | null>> {
    return this.api.get<Response<QuickKey | null>>(QuickKey, this.index + '/' + id);
  }

  all(p: Partial<QuickKeyFilter>): Observable<Response<QuickKey[]>> {
    return this.api.get<Response<QuickKey[]>>(QuickKey, this.index + (p ? new QuickKeyFilter(p).getQuery() : ''));
  }

  create(quickKey: QuickKey): Observable<Response<QuickKey>> {
    return this.api.post<Response<QuickKey>>(QuickKey, this.index, quickKey);
  }

  update(quickKey: QuickKey): Observable<Response<QuickKey>> {
    return this.api.put<Response<QuickKey>>(QuickKey, this.index + '/' + quickKey.id, quickKey);
  }

  delete(quickKey: QuickKey): Observable<Response<QuickKey>> {
    return this.api.delete<Response<QuickKey>>(QuickKey, this.index + '/' + quickKey.id);
  }
}
