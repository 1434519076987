export class ReportResponse {
  totalRevenue: ReportItemResponse;
  salesCount: ReportItemResponse;
  customersCount: ReportItemResponse;
  totalDiscount: ReportItemResponse;
  discountPercent: ReportItemResponse;
  basketValue: ReportItemResponse;
  basketSize: ReportItemResponse;
  grossProfit: ReportItemResponse;

  constructor(init?: Partial<ReportResponse>) {
    Object.assign(this, init);
  }
}

export class ReportItemResponse {
  total: number;
  value: number;
  movement: number | null;
  stores: { id: string; name: string; value: number }[];
  items: { date: string; value: number }[];
}
