import { ConsignmentProduct } from '..';
import { CONSINGMENT_STATUS_LABELS, ConsignmentStatus } from '../enums/consignment-status';
import { ConsignmentType } from '../enums/consignment-type';

export class Consignment {
  public id: string;
  public number: string;
  public sourceStoreId: string;
  public storeId: string;
  public storeName: string;
  public supplierId: string;
  public supplierName: string;
  public supplierInvoice: string;
  public dueAt: Date;
  public sentAt: Date;
  public receivedAt: Date;
  public completedAt: Date;
  public note: string;
  public quantity: number;
  public cost: number;
  public received: number;
  public createdBy: string;
  public cancelledBy: string;
  public type: ConsignmentType;
  public isDirectReception: boolean;
  public status: ConsignmentStatus;
  public products: ConsignmentProduct[];
  public createdAt: Date;
  public updatedAt: Date;

  public get statusLabel(): string {
    let label = CONSINGMENT_STATUS_LABELS[this.status];
    if (label) {
      return label;
    } else {
      return this.status;
    }
  }

  get isTypeOrder(): boolean {
    return this.type === ConsignmentType.ORDER;
  }

  // get isTypeReception(): boolean {
  //   return this.type === ConsignmentType.RECEPTION
  // }

  // get isOrderReception(): boolean {
  //   return this.type === ConsignmentType.ORDER && this.receivedAt !== undefined;
  // }

  get isStatusOpen(): boolean {
    return this.status === ConsignmentStatus.OPEN;
  }

  constructor(init?: Partial<Consignment>) {
    Object.assign(this, init);
    this.products = init?.products?.map((i) => new ConsignmentProduct(i)) ?? [];
  }

  /**
   * Consignment completed
   * @returns boolean
   */
  public isFinished(): boolean {
    switch (this.type) {
      case ConsignmentType.ORDER:
        return this.status === ConsignmentStatus.RECEIVED || this.status === ConsignmentStatus.CANCELED;
      case ConsignmentType.RETURN:
        return this.status === ConsignmentStatus.SENT || this.status === ConsignmentStatus.CANCELED;
      case ConsignmentType.TRANSFER:
        return this.status === ConsignmentStatus.RECEIVED || this.status === ConsignmentStatus.CANCELED;
    }
  }

  public canOpen(): boolean {
    if (this.status === ConsignmentStatus.SENT || this.status === ConsignmentStatus.DISPATCHED) {
      return true;
    }
    return false;
  }

  public canSent(): boolean {
    if (this.status === ConsignmentStatus.OPEN || this.status === ConsignmentStatus.DISPATCHED) {
      return true;
    }
    return false;
  }

  public canDispatched(): boolean {
    if (this.status === ConsignmentStatus.OPEN || this.status === ConsignmentStatus.SENT) {
      return true;
    }
    return false;
  }

  public canReceive(): boolean {
    return !this.isFinished();
  }

  public canCancel(): boolean {
    return !this.isFinished();
  }

  public canEdit(): boolean {
    if (!this.isFinished() && (this.status === ConsignmentStatus.OPEN || this.status === ConsignmentStatus.SENT)) {
      return true;
    }
    return false;
  }

  public items(): number {
    switch (this.type) {
      case ConsignmentType.ORDER:
        if (this.isDirectReception || this.status == ConsignmentStatus.RECEIVED) {
          return this.receptionCount();
        } else {
          return this.quantityCount();
        }
      case ConsignmentType.RETURN:
        return this.quantityCount();

      case ConsignmentType.TRANSFER:
        return this.quantityCount();
    }
  }

  private quantityCount(): number {
    let value = 0;
    for (let i of this.products) {
      value += i.quantity ?? 0;
    }
    return value;
  }

  private receptionCount(): number {
    let value = 0;
    for (let i of this.products) {
      value += i.received ?? 0;
    }
    return value;
  }

  public total(): number {
    let total = 0;
    for (let i of this.products) {
      total += this.subtotal(i);
    }
    return total;
  }

  public subtotal(item: ConsignmentProduct): number {
    let quantity = 0;

    switch (this.type) {
      case ConsignmentType.ORDER:
        if (this.isDirectReception || this.status == ConsignmentStatus.RECEIVED) {
          quantity = item.received ?? 0;
        } else {
          quantity = item.quantity ?? 0;
        }
        break;

      case ConsignmentType.RETURN:
        quantity = item.quantity ?? 0;
        break;

      case ConsignmentType.TRANSFER:
        quantity = item.quantity ?? 0;
        break;
    }

    return quantity * (item.cost ?? 0);
  }

  // private receptionCost(): number {
  //   let cost = 0;
  //   for (let i of this.products) {
  //     cost += i.received * (i.cost ?? 0);
  //   }
  //   return cost;
  // }

  // private quantityCost(): number {
  //   let cost = 0;
  //   for (let i of this.products) {
  //     cost += i.quantity * (i.cost ?? 0);
  //   }
  //   return cost;
  // }

  statusClass(): string {
    switch (this.status) {
      case ConsignmentStatus.OPEN:
        return 'open';
      case ConsignmentStatus.SENT:
        return 'sent';
      case ConsignmentStatus.DISPATCHED:
        return 'dispatched';
      case ConsignmentStatus.RECEIVED:
        return 'received';
      case ConsignmentStatus.CANCELED:
        return 'cancelled';
      default:
        return '';
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      number: this.number,
      source_store_id: this.sourceStoreId,
      store_id: this.storeId,
      store_name: this.storeName,
      supplier_id: this.supplierId,
      supplier_name: this.supplierName,
      supplier_invoice: this.supplierInvoice,
      due_at: this.dueAt,
      sent_at: this.sentAt,
      received_at: this.receivedAt,
      completed_at: this.completedAt,
      note: this.note,
      quantity: this.quantity,
      cost: this.cost,
      received: this.received,
      created_by: this.createdBy,
      cancelled_by: this.cancelledBy,
      type: this.type,
      status: this.status,
      products: this.products?.map((i) => new ConsignmentProduct(i).toJSON()) ?? null,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
