import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NotifyService, AuthService, AuthApi } from '@app/core';
import { CoreService } from '@app/core/services/core.service';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: true,
  imports: [RouterModule, MaterialModule, FormsModule, ReactiveFormsModule, NgIf, SharedModule],
})
export class SignInComponent implements OnInit {
  options = this.settings.getOptions();

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.minLength(6)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  get f() {
    return this.form.controls;
  }

  submit() {
    // console.log(this.form.value);
    this.router.navigate(['/dashboards/dashboard1']);
  }

  isLoading: boolean = false;

  constructor(
    private router: Router,
    private notify: NotifyService,
    private auth: AuthService,
    private authApi: AuthApi,
    private settings: CoreService
  ) {}

  ngOnInit(): void {
    this.checkLoging();
  }

  private checkLoging(): void {
    if (this.auth.isLoggedIn()) {
      const redirect = this.auth.redirectUrl ? this.auth.redirectUrl : '/';
      this.router.navigateByUrl(redirect);
    }
  }

  signInWithEmail(): void {
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;

    const email = this.form.get('email')?.value!;
    const password = this.form.get('password')?.value!;

    this.auth
      .signInWithPassword(email, password)
      .then(
        (response) => {
          // console.log('response', response);
          // this.isLoading = false;
          this.router.navigateByUrl(this.auth.ROUTE_DASHBOARD);
        },
        (error) => {
          this.isLoading = false;
          const errorCode = error.code;
          const errorMessage = error.message;

          switch (errorCode) {
            case 'auth/invalid-credential':
              this.notify.error('Correo o contraseña incorrectos.');
              break;
            case 'auth/user-disabled':
              this.notify.error('Usuario deshabilitado.');
              break;
            default:
              this.notify.error('No se pudo iniciar sesión ' + errorCode);
              break;
          }

          console.log('auth error', errorCode, errorMessage);
        }
      )
      .catch((error) => {
        this.isLoading = false;

        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('catch: auth error', errorCode, errorMessage);

        this.notify.error(errorCode);
      });

    //   this.isLoading = false;
    //   if (!error) {
    //     this.router.navigateByUrl(this.auth.ROUTE_DASHBOARD);
    //   } else {
    //     if (error) {
    //       switch (error.code) {
    //         case 'auth/cancelled-popup-request':
    //         case 'auth/popup-closed-by-user':
    //         case 'auth/user-cancelled':
    //           break;
    //         default:
    //           this.notify.error(error);
    //           break;
    //       }
    //     }
    //   }
    // });
  }

  singInWithGoogle(): void {
    this.isLoading = true;

    this.auth.signInAndGetFirebaseToken((token: string | null, error: any) => {
      if (!token) {
        this.isLoading = false;
        if (error) {
          switch (error.code) {
            case 'auth/cancelled-popup-request':
            case 'auth/popup-closed-by-user':
            case 'auth/user-cancelled':
              break;
            default:
              this.notify.error(error);
              break;
          }
        }
        return;
      }

      // Redireccionar
      this.router.navigateByUrl(this.auth.ROUTE_DASHBOARD);

      // this.authApi.signIn(token).subscribe((response: Response<SignIn>) => {
      //   console.log('response', response);

      //   // Refresh token
      //   // this.auth.getToken(true).subscribe(
      //   //   (tokenRefresh) => {
      //   if (response?.ok) {
      //     console.log('success signIn');
      //     this.auth.setUserConfig(response.data);

      //     // Redireccionar
      //     this.router.navigateByUrl(this.auth.ROUTE_DASHBOARD);

      //     // const redirect = this.auth.redirectUrl ? this.auth.redirectUrl : '/';
      //     // this.router.navigateByUrl(redirect);
      //   } else {
      //     this.isLoading = false;
      //     this.auth.logoutWithoutRedirect();

      //     switch (response?.error) {
      //       case Error.ACCOUNT_NOT_EXIST:
      //         this.notify.show('No tienes permiso para acceder.', 7);
      //         break;
      //       default:
      //         this.notify.error(response);
      //         break;
      //     }
      //   }
      //   //   },
      //   //   (error) => {
      //   //     console.log(error);
      //   //     this.notify.errorMessage('Error al refrescar token.');
      //   //   }
      //   // );
      // });
    });
  }
}
