import { TaxLite } from '@app/core/models/tax-lite';
import { Product, ProductSupplier } from '@app/core/models/product';
import { ProductPackage } from '@app/core/models/product-package';

export class ProductEntity {
  id?: string;
  storeId?: string;
  productId?: string;
  sku?: string;
  // codes?: string;
  name?: string;
  description?: string;
  location?: string;
  tax?: TaxLite;
  price?: number;
  supplierId?: string;
  suppliers?: ProductSupplier[];
  image?: string;
  keywords?: string;
  //media?: Media;
  brandId?: string;
  brandName?: string;
  categoryId?: string;
  categoryName?: string;
  tagIds?: string[];
  index?: number;
  quantity?: number;
  packing?: string;
  unitType?: string;
  presentation?: string;
  //saleType?: SaleType;
  stepQuantity?: number;
  minQuantity?: number;
  maxQuantity?: number;
  //discounts?: Discount[];
  disabledSrc?: string;
  trackInventory?: boolean;
  inStock?: number | undefined;
  safetyStock?: number | undefined;
  reorderPoint?: number;
  reorderQuantity?: number;
  madeFrom?: ProductPackage[];
  breakInto?: ProductPackage[];
  stockoutAt?: Date;
  //lots?: Lot[];
  //type?: ProductType[];
  expiresAt?: Date;
  expirationParentId?: String;
  isAvailable?: boolean;
  isActive?: boolean;
  isPublic?: boolean;
  isFeatured?: boolean;
  featuredIndex?: number;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(init?: Partial<ProductEntity>) {
    Object.assign(this, init);
  }

  model(): Product {
    const product = new Product();
    //product.id = `${this.storeId}${this.productId}`;
    product.storeId = this.storeId!;
    product.productId = this.productId!;
    product.sku = this.sku!;
    // product.codes = this.codes;
    product.name = this.name!;
    product.description = this.description!;
    product.location = this.location!;
    product.tax = this.tax!;
    product.price = this.price!;
    product.supplierId = this.supplierId!;
    product.image = this.image!;
    product.suppliers = this.suppliers ?? [];
    product.keywords = this.keywords!;
    product.brandId = this.brandId!;
    //media= this.media
    product.brandName = this.brandName!;
    product.categoryId = this.categoryId!;
    product.categoryName = this.categoryName!;
    product.tagIds = this.tagIds!;
    product.index = this.index!;
    product.quantity = this.quantity!;
    product.packing = this.packing!;
    product.unitType = this.unitType!;
    product.presentation = this.presentation!;
    product.stepQuantity = this.stepQuantity!;
    //saleType= this.saleType
    product.minQuantity = this.minQuantity!;
    product.maxQuantity = this.maxQuantity!;
    product.disabledSrc = this.disabledSrc!;
    //discounts= this.discounts
    product.inStock = this.inStock!;
    // trackInventory= this.trackInventory
    product.safetyStock = this.safetyStock!;
    product.reorderPoint = this.reorderPoint!;
    product.reorderQuantity = this.reorderQuantity!;
    product.stockoutAt = this.stockoutAt!;
    product.madeFrom = this.madeFrom ?? [];
    product.breakInto = this.breakInto ?? [];
    product.expiresAt = this.expiresAt!;
    //lots= this.lots
    //type= this.type
    product.expirationParentId = this.expirationParentId!;
    product.isAvailable = this.isAvailable!;
    product.isActive = this.isActive!;
    product.isPublic = this.isPublic!;
    product.isFeatured = this.isFeatured!;
    product.featuredIndex = this.featuredIndex!;
    product.createdAt = this.createdAt!;
    product.updatedAt = this.updatedAt!;
    return product;
  }
}
