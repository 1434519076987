export enum StocktakeStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export enum StocktakeType {
  PARTIAL = 'partial',
  FULL = 'full',
}
