import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { errorMessage, Response } from '../interfaces/response';
import { SnackbarComponent } from '@app/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private _snackBar = inject(MatSnackBar);

  durationInSeconds = 5;

  constructor(private snackbar: MatSnackBar) {}

  public warning(message: string, seconds = 4): void {
    this.snackbar.open(message, undefined, {
      duration: seconds * 1000,
      verticalPosition: 'top',
    });
  }

  public show(message: string, seconds = 4): void {
    this.snackbar.open(message, undefined, {
      duration: seconds * 1000,
      verticalPosition: 'top',
    });
  }

  public success(message: string = 'La solicitud se realizó correctamente.', seconds = 4): void {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      duration: seconds * 1000,
      verticalPosition: 'top',
      panelClass: ['root-snackbar-container', 'success-snackbar'],
    });

    // this.snackbar.open(message, undefined, {
    //   duration: seconds * 1000,
    //   verticalPosition: 'top',
    // });
  }

  public error<T>(response: Response<T> | string): void {
    // check if response is an string
    if (typeof response === 'string') {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: response,
        duration: 4 * 1000,
        verticalPosition: 'top',
        panelClass: ['root-snackbar-container', 'error-snackbar'],
      });
      return;
    }

    const message = errorMessage(response);
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      duration: 4 * 1000,
      verticalPosition: 'top',
      panelClass: ['root-snackbar-container', 'error-snackbar'],
    });
    // this.snackbar.open(message, undefined, {
    //   duration: 4000,
    //   verticalPosition: 'top',
    // });
  }

  public errorMessage(message: string, seconds = 4): void {
    this.snackbar.open(message, undefined, {
      duration: seconds * 1000,
      verticalPosition: 'top',
    });
  }

  public failed(message: string = 'Se ha producido un problema con tu solicitud.', seconds = 4): void {
    this.snackbar.open(message, undefined, {
      duration: seconds * 1000,
      verticalPosition: 'top',
    });
  }
}
