import { OrderBy, SortBy } from '../enums/sorting';

export class ReportFilter {
  storeId?: string | null;
  categoryId?: string | null;
  productId?: string | null;

  storeIds?: string[];
  productIds?: string[];
  brandIds?: string[];
  categoryIds?: string[];
  customerIds?: string[];
  userIds?: string[];

  type: 'day' | 'week' | 'month';
  from?: string | null;
  to?: string | null;
  sort?: SortBy | null;
  order?: OrderBy | null;
  start?: number | null;
  limit?: number | null;

  constructor(init?: Partial<ReportFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let q = '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q += this.categoryId ? '&category_id=' + this.categoryId : '';
    q += this.productId ? '&product_id=' + this.productId : '';

    q += this.storeIds ? '&store_ids=' + this.storeIds.join(',') : '';
    q += this.productIds ? '&product_ids=' + this.productIds.join(',') : '';
    q += this.brandIds ? '&brand_ids=' + this.brandIds.join(',') : '';
    q += this.categoryIds ? '&category_ids=' + this.categoryIds.join(',') : '';
    q += this.customerIds ? '&customer_ids=' + this.customerIds.join(',') : '';
    q += this.userIds ? '&user_ids=' + this.userIds.join(',') : '';

    q += this.type ? '&type=' + this.type : '';
    q += this.from ? '&from=' + this.from : '';
    q += this.to ? '&to=' + this.to : '';
    q += this.sort ? '&sort=' + this.sort : '';
    q += this.order ? '&order=' + this.order : '';
    q += this.start ? '&start=' + this.start : '';
    q += this.limit ? '&limit=' + this.limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
