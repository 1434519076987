import { DEFAULT_CURRENCY_CODE, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { provideLottieOptions } from 'ngx-lottie';
// import { provideLottieOptions, AnimationLoader, provideCacheableAnimationLoader } from 'ngx-lottie';
// import player from 'lottie-web';

import { HttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import { CoreModule } from './core/core.module';
// import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { JwtModule, JwtInterceptor } from '@auth0/angular-jwt';
import { DataInterceptor } from './core/interceptors/data.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/error.interceptor';
import { Injector } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PrintLayoutComponent } from './components/print-layout/print-layout.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';

// Lottie
// import { LottieModule, LottieCacheModule } from 'ngx-lottie';
// import player from 'lottie-web';

// Note we need a separate function as it's required
// by the AOT compiler.
// export function playerFactory() {
//   return player;
// }

// Import extensions
import './core/extensions';

import { AppService } from './core';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePe from '@angular/common/locales/es-PE';
import { PrintConsignmentComponent } from './components/print-consignment/print-consignment.component';
import { DiscountLabelPrintComponent } from './components/print/discount-label-print/discount-label-print.component';
import { ChatComponent } from './modules/messaging/components/chat/chat.component';
import { BlankComponent } from './layouts/blank/blank.component';
registerLocaleData(localePe, 'pe');

export let AppInjector: Injector;
export function getToken(): Promise<any | undefined> {
  const auth = AppInjector.get(AuthService);
  return auth.getToken().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    // SignInComponent,
    ToolbarComponent,
    SidebarComponent,
    PrintLayoutComponent,
    InvoiceComponent,
    GiftCardComponent,
    DiscountLabelPrintComponent,
    PrintConsignmentComponent,
    BlankComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    ChatComponent,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        allowedDomains: environment.allowedDomains,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // LottieModule.forRoot({ player: playerFactory }),
    // LottieCacheModule.forRoot(),
  ],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    {
      provide: LOCALE_ID,
      useValue: 'pe',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'PEN',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    JwtInterceptor, // Providing JwtInterceptor allow to inject JwtInterceptor manually into RefreshTokenInterceptor
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  // AppService is required to initialization
  constructor(private injector: Injector, private app: AppService) {
    AppInjector = this.injector;
  }
}
