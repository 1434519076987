import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'sl-dashboard-c05.pages.dev',
    'https://api.nishi.app',
    'https://api.nishi.pe',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    // https://firebase.google.com/docs/reference/js/auth#autherrorcodes
    'auth/admin-restricted-operation',
    'auth/argument-error',
    'auth/app-not-authorized',
    'auth/app-not-installed',
    'auth/captcha-check-failed',
    'auth/code-expired',
    'auth/cordova-not-ready',
    'auth/cors-unsupported',
    'auth/credential-already-in-use',
    'auth/custom-token-mismatch',
    'auth/requires-recent-login',
    'auth/dependent-sdk-initialized-before-auth',
    'auth/dynamic-link-not-activated',
    'auth/email-change-needs-verification',
    'auth/email-already-in-use',
    'auth/emulator-config-failed',
    'auth/expired-action-code',
    'auth/cancelled-popup-request',
    'auth/internal-error',
    'auth/invalid-api-key',
    'auth/invalid-app-credential',
    'auth/invalid-app-id',
    'auth/invalid-user-token',
    'auth/invalid-auth-event',
    'auth/invalid-cert-hash',
    'auth/invalid-verification-code',
    'auth/invalid-continue-uri',
    'auth/invalid-cordova-configuration',
    'auth/invalid-custom-token',
    'auth/invalid-dynamic-link-domain',
    'auth/invalid-email',
    'auth/invalid-emulator-scheme',
    'auth/invalid-credential',
    'auth/invalid-credential',
    'auth/invalid-message-payload',
    'auth/invalid-multi-factor-session',
    'auth/invalid-oauth-client-id',
    'auth/invalid-oauth-provider',
    'auth/invalid-action-code',
    'auth/unauthorized-domain',
    'auth/wrong-password',
    'auth/invalid-persistence-type',
    'auth/invalid-phone-number',
    'auth/invalid-provider-id',
    'auth/invalid-recipient-email',
    'auth/invalid-sender',
    'auth/invalid-verification-id',
    'auth/invalid-tenant-id',
    'auth/multi-factor-info-not-found',
    'auth/multi-factor-auth-required',
    'auth/missing-android-pkg-name',
    'auth/missing-app-credential',
    'auth/auth-domain-config-required',
    'auth/missing-verification-code',
    'auth/missing-continue-uri',
    'auth/missing-iframe-start',
    'auth/missing-ios-bundle-id',
    'auth/missing-or-invalid-nonce',
    'auth/missing-multi-factor-info',
    'auth/missing-multi-factor-session',
    'auth/missing-phone-number',
    'auth/missing-verification-id',
    'auth/app-deleted',
    'auth/account-exists-with-different-credential',
    'auth/network-request-failed',
    'auth/null-user',
    'auth/no-auth-event',
    'auth/no-such-provider',
    'auth/operation-not-allowed',
    'auth/operation-not-supported-in-this-environment',
    'auth/popup-blocked',
    'auth/popup-closed-by-user',
    'auth/provider-already-linked',
    'auth/quota-exceeded',
    'auth/redirect-cancelled-by-user',
    'auth/redirect-operation-pending',
    'auth/rejected-credential',
    'auth/second-factor-already-in-use',
    'auth/maximum-second-factor-count-exceeded',
    'auth/tenant-id-mismatch',
    'auth/timeout',
    'auth/user-token-expired',
    'auth/too-many-requests',
    'auth/unauthorized-continue-uri',
    'auth/unsupported-first-factor',
    'auth/unsupported-persistence-type',
    'auth/unsupported-tenant-operation',
    'auth/unverified-email',
    'auth/user-cancelled',
    'auth/user-not-found',
    'auth/user-disabled',
    'auth/user-mismatch',
    'auth/user-signed-out',
    'auth/weak-password',
    'auth/web-storage-unsupported',
    'auth/already-initialized',
    'auth/recaptcha-not-enabled',
    'auth/missing-recaptcha-token',
    'auth/invalid-recaptcha-token',
    'auth/invalid-recaptcha-action',
    'auth/missing-client-type',
    'auth/missing-recaptcha-version',
    'auth/invalid-recaptcha-version',
    'auth/invalid-req-type',

    // https://firebase.google.com/docs/reference/node/firebase.User#error-codes_1
    'auth/requires-recent-login',
    'auth/provider-already-linked',
    'auth/invalid-credential',
    'auth/credential-already-in-use',
    'auth/email-already-in-use',
    'auth/operation-not-allowed',
    'auth/invalid-email',
    'auth/wrong-password',
    'auth/invalid-verification-code',
    'auth/invalid-verification-id',
    'auth/captcha-check-failed',
    'auth/invalid-phone-number',
    'auth/missing-phone-number',
    'auth/quota-exceeded',
    'auth/user-disabled',
    'auth/auth-domain-config-required',
    'auth/operation-not-supported-in-this-environment',
    'auth/unauthorized-domain',
    'auth/user-mismatch',
    'auth/user-not-found',
    'auth/no-such-provider',
    'auth/weak-password',
    'auth/missing-android-pkg-name',
    'auth/missing-continue-uri',
    'auth/missing-ios-bundle-id',
    'auth/invalid-continue-uri',
    'auth/unauthorized-continue-uri',

    // https://firebase.google.com/docs/auth/admin/errors
    'auth/claims-too-large',
    'auth/email-already-exists',
    'auth/id-token-expired',
    'auth/id-token-revoked',
    'auth/insufficient-permission',
    'auth/internal-error',
    'auth/invalid-argument',
    'auth/invalid-claims',
    'auth/invalid-continue-uri',
    'auth/invalid-creation-time',
    'auth/invalid-credential',
    'auth/invalid-disabled-field',
    'auth/invalid-display-name',
    'auth/invalid-dynamic-link-domain',
    'auth/invalid-email',
    'auth/invalid-email-verified',
    'auth/invalid-hash-algorithm',
    'auth/invalid-hash-block-size',
    'auth/invalid-hash-derived-key-length',
    'auth/invalid-hash-key',
    'auth/invalid-hash-memory-cost',
    'auth/invalid-hash-parallelization',
    'auth/invalid-hash-rounds',
    'auth/invalid-hash-salt-separator',
    'auth/invalid-id-token',
    'auth/invalid-last-sign-in-time',
    'auth/invalid-page-token',
    'auth/invalid-password',
    'auth/invalid-password-hash',
    'auth/invalid-password-salt',
    'auth/invalid-phone-number',
    'auth/invalid-photo-url',
    'auth/invalid-provider-data',
    'auth/invalid-provider-id',
    'auth/invalid-oauth-responsetype',
    'auth/invalid-session-cookie-duration',
    'auth/invalid-uid',
    'auth/invalid-user-import',
    'auth/maximum-user-count-exceeded',
    'auth/missing-android-pkg-name',
    'auth/missing-continue-uri',
    'auth/missing-hash-algorithm',
    'auth/missing-ios-bundle-id',
    'auth/missing-uid',
    'auth/missing-oauth-client-secret',
    'auth/operation-not-allowed',
    'auth/phone-number-already-exists',
    'auth/project-not-found',
    'auth/reserved-claims',
    'auth/session-cookie-expired',
    'auth/session-cookie-revoked',
    'auth/too-many-requests',
    'auth/uid-already-exists',
    'auth/unauthorized-continue-uri',
    'auth/user-not-found',
  ],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
