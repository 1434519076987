import { Item } from './item';

export class ProductSupplierRequest {
  public product: Item;
  public productId: string;
  public supplierId: string;
  public supplyPrice: number;
  public supplierCode: string;

  // UI
  isLoading: boolean;

  constructor(init?: Partial<ProductSupplierRequest>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      product_id: this.productId,
      supply_price: +this.supplyPrice,
      supplier_code: this.supplierCode,
    };
  }
}
