import { LotFilter2 } from '../models/lot-filter';

export class Preference {
  id: string; // model id
  lotFilters: LotFilter2[];

  constructor(init?: Partial<Preference>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      lot_filters: this.lotFilters,
    };
  }
}
