export class Tag {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init?: Partial<Tag>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
