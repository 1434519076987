import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ns-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BadgeComponent implements OnInit {
  @Input() label: string = '';
  @Input() color:
    | string
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warn'
    | 'error'
    | 'info'
    | 'light'
    | 'dark' = 'primary';

  constructor() {}

  ngOnInit(): void {}
}
