import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';
import { DataService } from '../services/data.service';
import { BusinessEntity } from './entities/business.entity';
import { StoreEntity } from './entities/store.entity';
import { CategoryEntity } from './entities/category.entity';
import { ProductEntity } from './entities/product.entity';
import { PaymentMethodEntity } from './entities/payment-method.entity';

@Injectable({
  providedIn: 'root',
})
export class AppDatabase extends Dexie {
  public businesses!: Table<BusinessEntity, string>; // id is string in this case
  public locals!: Table<StoreEntity, string>; // id is string in this case
  public paymentMethods!: Table<PaymentMethodEntity, string>; // id is string in this case
  public categories!: Table<CategoryEntity, string>; // id is string in this case
  public products!: Table<ProductEntity, string>;

  constructor(private localstorage: DataService) {
    super('AppDatabase');
    this.version(2).stores({
      businesses: 'id,name,version',
      locals: 'id,name,version',
      paymentMethods: 'id,name,version',
      categories: 'id,name,index,version',
      products: 'id,storeId,productId,sku,name',
    });
  }

  removeData() {
    this.businesses.clear();
    this.locals.clear();
    this.paymentMethods.clear();
    this.categories.clear();
    this.products.clear();
  }
}
