export enum AdvertisementType {
  TAG = 'tag',
  PRODUCT = 'product',
  CATEGORY = 'category',
  DISCOUNT = 'discount',
}

export const ADVERTISEMENT_TYPES = [
  AdvertisementType.TAG,
  // AdvertisementType.PRODUCT,
  AdvertisementType.CATEGORY,
  AdvertisementType.DISCOUNT,
];
