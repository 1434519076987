import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Business,
  NotifyService,
  BusinessService,
  Tax,
  TaxService,
  CATEGORIES,
  UserConfig,
  AuthService,
  rucValidator,
  LocationInfoService,
} from '@app/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialModule } from '@app/material.module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserProfilePictureComponent } from '@app/components/shared/user-profile-picture/user-profile-picture.component';
import { CoreService } from '@app/core/services/core.service';

@Component({
  selector: 'app-business-register',
  templateUrl: './business-register.component.html',
  styleUrls: ['./business-register.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UserProfilePictureComponent,
  ],
})
export class BusinessRegisterComponent implements OnInit {
  options = this.settings.getOptions();

  model = new Business();

  taxes: Tax[] = [];
  categories = CATEGORIES;

  isLoading: boolean = false;

  timeZone: string;
  countryCode: string;
  currencyCode: string;

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.min(1)]),
    idNumber: new FormControl('', [Validators.required, rucValidator, Validators.min(1)]),
    category: new FormControl(null, [Validators.required, Validators.min(1)]),
  });

  compareObjectId(opcion1: any, opcion2: any): boolean {
    return opcion1 && opcion2 ? opcion1.id === opcion2.id : opcion1 === opcion2;
  }

  currentStep: number = 1;

  nextStep(): void {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }

  prevStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  constructor(
    private settings: CoreService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notify: NotifyService,
    private businessService: BusinessService,
    private taxService: TaxService,
    private config: UserConfig,
    private authService: AuthService,
    private locationInfoService: LocationInfoService
  ) {}

  ngOnInit(): void {
    this.locationInfoService.getLocationInfo().subscribe((locationInfo) => {
      this.timeZone = locationInfo.time_zone;
      this.countryCode = locationInfo.country_code;
      this.currencyCode = locationInfo.currency_code;
    });
    // this.load(this.config.currentBusinessId);
    // this.loadTaxes();
  }

  onUpdate(): void {
    this.isLoading = true;
    this.businessService.update(this.model).subscribe((response) => {
      this.isLoading = false;
      if (response.ok) {
        this.notify.show('Guardado.');
        // this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      } else {
        this.notify.error(response);
      }
    });
  }

  onSubmit() {
    const request = {
      name: this.formGroup.value.name!,
      id_number: this.formGroup.value.idNumber!,
      category: this.formGroup.value.category!,
      country_code: this.countryCode ?? 'PE',
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? this.timeZone ?? 'America/Lima',
      currency_code: this.currencyCode ?? 'PEN',
      locale: Intl.DateTimeFormat().resolvedOptions().locale ?? 'es-PE',
    };

    this.isLoading = true;
    this.businessService.create(request).subscribe((response) => {
      if (response.ok) {
        // this.notify.show('Guardado.');
        this.config.clear();
        this.config.setBusiness(response.data, false);
        // this.config.setStore(null);
        this.router.navigate(['/']).then(() => {
          window.location.reload(); // @todo: Fix clear all data. remove config values.
        });
      } else {
        this.isLoading = false;
        this.notify.error(response);
      }
    });
  }

  logout(): void {
    // this.businessService.list().subscribe((resp) => {
    //   this.config.clear();
    //   this.config.setBusiness(resp.data[1], false);
    //   this.router.navigate(['/']).then(() => {
    //     window.location.reload();
    //   });
    // });
    // return;
    this.authService.logout();
  }

  // delete(): void {
  //   const dialogRef = this.dialog.open(DialogComponent, {
  //     width: '480px',
  //     data: {
  //       title: 'Eliminar método de entrega',
  //       message: '¿Estás seguro que deseas eliminar este registro de forma permanente?',
  //       style: 'danger',
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((ok: boolean) => {
  //     if (ok) {
  //       this.onDelete();
  //     }
  //   });
  // }

  // private onDelete(): void {
  //   this.isLoading = true;
  //   this.issueService.delete(this.model).subscribe((response) => {
  //     this.isLoading = false;
  //     if (response.ok) {
  //       this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  //     } else {
  //       this.notify.error(response);
  //     }
  //   });
  // }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}
