import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { InventoryMovement } from '../models/inventory-movement';
import { Response } from '../interfaces/response';
import { InventoryMovementFilter } from '../filters/inventory-movement.filter';

@Injectable({
  providedIn: 'root',
})
export class InventoryMovementService {
  private readonly index = environment.api + '/v1/inventory_movements'; // inventory_movements

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<InventoryMovement | null>> {
    return this.api.get<Response<InventoryMovement | null>>(InventoryMovement, this.index + '/' + id);
  }

  all(p: InventoryMovementFilter | null = null): Observable<Response<InventoryMovement[]>> {
    return this.api.get<Response<InventoryMovement[]>>(InventoryMovement, this.index + (p ? p!.getQuery() : ''));
  }

  create(inventoryMovement: InventoryMovement): Observable<Response<InventoryMovement>> {
    return this.api.post<Response<InventoryMovement>>(InventoryMovement, this.index, inventoryMovement);
  }

  update(inventoryMovement: InventoryMovement): Observable<Response<InventoryMovement>> {
    return this.api.put<Response<InventoryMovement>>(
      InventoryMovement,
      this.index + '/' + inventoryMovement.id,
      inventoryMovement
    );
  }

  delete(inventoryMovement: InventoryMovement): Observable<Response<InventoryMovement>> {
    return this.api.delete<Response<InventoryMovement>>(InventoryMovement, this.index + '/' + inventoryMovement.id);
  }
}
