import { OrderBy, SortBy } from '../enums/sorting';

export class PriceHistoryFilter {
  storeId?: string | null;
  productId?: string | null;
  query?: string | null;
  sort?: SortBy | null;
  order?: OrderBy | null;
  start?: number | null;
  limit?: number | null;

  constructor(init?: Partial<PriceHistoryFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let q = '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q += this.productId ? '&product_id=' + this.productId : '';
    q += this.query ? '&q=' + this.query : '';
    q += this.sort ? '&sort=' + this.sort : '';
    q += this.order ? '&order=' + this.order : '';
    q += this.start ? '&start=' + this.start : '';
    q += this.limit ? '&limit=' + this.limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
