export enum IssuePriority {
  OPEN = 'low',
  IN_PROGRESS = 'medium',
  CLOSED = 'high',
  REJECTED = 'critical',
}

export const ISSUE_PRIORITIES = [
  { id: 'low', name: 'Baja' },
  { id: 'medium', name: 'Medio' },
  { id: 'high', name: 'Alto' },
  { id: 'critical', name: 'Crítico' },
];
