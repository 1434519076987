import { Attachment } from './attachment';

export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
  FILE = 'file',
}

export class Message {
  private _id: string;
  private _uid: string;
  private _name: string;
  private _photo: string;
  private _type: MessageType;
  private _text: string;
  private _url: string;
  private _seen: boolean;
  private _atts: Attachment[];
  private _ts: any;

  date: Date;

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get uid(): string {
    return this._uid;
  }

  public set uid(value: string) {
    this._uid = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get photo(): string {
    return this._photo;
  }

  public set photo(value: string) {
    this._photo = value;
  }

  public get type(): MessageType {
    return this._type;
  }

  public set type(value: MessageType) {
    this._type = value;
  }

  public get text(): string {
    return this._text;
  }

  public set text(value: string) {
    this._text = value;
  }

  public get url(): string {
    return this._url;
  }

  public set url(value: string) {
    this._url = value;
  }

  public get seen(): boolean {
    return this._seen;
  }

  public set seen(value: boolean) {
    this._seen = value;
  }

  public get ts(): any {
    return this._ts;
  }

  public set ts(value: any) {
    this._ts = value;
    if (value && typeof value.toDate === 'function') {
      this.date = value.toDate();
    }
  }

  public get atts(): Attachment[] {
    return this._atts;
  }

  public set atts(value: Attachment[]) {
    this._atts = value;
  }

  constructor(init?: Partial<Message>) {
    Object.assign(this, init);
  }

  public toJSON() {
    return {
      uid: this.uid ?? null,
      name: this.name ?? null,
      photo: this.photo ?? null,
      type: this.type ?? null,
      text: this.text ?? null,
      url: this.url ?? null,
      seen: this.seen ?? null,
      ts: this.ts ?? null,
      atts: this.atts ? this.atts.map((i) => i.toJSON()) : null,
    };
  }
}
