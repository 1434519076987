import { TaxType } from '../enums/tax-type';

export class TaxLite {
  id: string;
  name: string;
  type: TaxType;
  rate: number;
  code: string;

  constructor(init?: Partial<TaxLite>) {
    Object.assign(this, init);
  }

  public get fullName(): string {
    if (this.type === TaxType.TAXABLE) {
      return `${this.name}, ${this.rate}%`;
    }
    return `${this.name}`;
  }

  public get displayValue(): string {
    if (this.type === TaxType.TAXABLE) {
      return `${this.name} ${this.rate}%`;
    }
    return `${this.name}`;
  }

  valueFormatted(): string {
    return `${this.rate}%`;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      rate: this.rate,
      code: this.code,
    };
  }
}
