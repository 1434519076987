import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReportFilter } from '../filters/report.filter';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private readonly productSaslesResource = environment.import + '/v1/orders/export';

  constructor(private http: HttpClient, protected api: ApiService) {}

  productSales(filter: ReportFilter): Observable<ArrayBuffer> {
    const url = `${this.productSaslesResource + filter.getQuery()}`;
    return this.http
      .get(url, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }
}
