import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var JsBarcode: any;

@Component({
  selector: 'ns-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit, AfterViewInit {
  @Input() value: string;
  @Input() size: number = 200;

  @ViewChild('barcode') container: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.generateQrCode();
  }

  generateQrCode() {
    JsBarcode(this.container.nativeElement, this.value, {
      format: 'CODE128',
      lineColor: '#000000',
      width: 4,
      height: 260,
      fontSize: 42,
      displayValue: true,
    });
  }
}
