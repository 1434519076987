<div class="barcode-list">
  <ng-container *ngFor="let i of items; let idx = index">
    <div class="barcode-item">
      <!-- barcode image -->
      <div class="barcode-img">
        <ns-barcode [size]="200" [value]="barcode"></ns-barcode>
      </div>
      <!-- barcode text -->
      <!-- <span class="bctext">{{ barcode }}</span> -->
    </div>
    <!-- <div class="break"></div> -->
  </ng-container>
</div>
