import { Document } from '../data/document';
import { PaymentType } from '../enums/payment-type';
import { SalePayment } from './sale';
import { PaymentMethod } from './payment-method';

export class CartPayment {
  id: string;
  name: string;
  type: string;
  amount: number;
  paymentMethodId: string;
  createdAt: Date;
  isChange: boolean = false;

  public get isRemovable(): boolean {
    return !this.isChange && this.type != PaymentType.CASH_ROUNDING;
  }

  constructor(init?: Partial<CartPayment>) {
    Object.assign(this, init);
  }

  static from(payment: PaymentMethod, amount: number): CartPayment {
    return new CartPayment({
      id: Document.newId(),
      name: payment.name,
      type: payment.type,
      paymentMethodId: payment.id,
      amount: amount,
      createdAt: new Date(),
    });
  }

  static fromOrderPayment(payment: SalePayment): CartPayment {
    return new CartPayment({
      id: Document.newId(),
      name: payment.name,
      type: payment.type,
      paymentMethodId: payment.paymentId,
      amount: payment.amount,
      createdAt: new Date(),
    });
  }
}
