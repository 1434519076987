import { RegisterClosure } from './register-closure';
import { RegisterLite } from './register-lite';

export class Register {
  id: string;
  storeId: string;
  name: string;
  email: string;
  sequenceNumber: number;
  serieId: string;
  serieNumber: string;
  isOpen: boolean;
  isLogged: boolean;
  registerClosureId: string;
  printerId: string;
  cashPaymentId: string;
  openingTime: Date;
  closingTime: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  // Helpers
  registerClosure: RegisterClosure;

  // only for create or udpate
  password: string | undefined;

  constructor(init?: Partial<Register>) {
    Object.assign(this, init);
    this.registerClosure = init?.registerClosure ? new RegisterClosure(init?.registerClosure) : undefined!;
  }

  lite(): RegisterLite {
    return new RegisterLite({
      id: this.id,
      name: this.name,
      sequenceNumber: this.sequenceNumber,
      isOpen: this.isOpen,
      registerClosureId: this.registerClosureId,
      openingTime: this.openingTime,
      closingTime: this.closingTime,
    });
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      name: this.name,
      email: this.email,
      password: this.password,
      sequence_number: this.sequenceNumber,
      serie_id: this.serieId,
      serie_number: this.serieNumber,
      is_open: this.isOpen,
      is_logged: this.isLogged,
      register_closure_id: this.registerClosureId,
      printer_id: this.printerId,
      cash_payment_id: this.cashPaymentId,
      opening_time: this.openingTime,
      closing_time: this.closingTime,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      deleted_at: this.deletedAt,
    };
  }
}
