import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, skip } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AppService } from './app.service';
import { DataService } from './data.service';

const EVENTS_COLLECTION = 'events';

class EventData {
  id: string;
  type: string;
  data: any;
  created_at: Date;

  constructor(init?: Partial<EventData>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private unsubscribe: any;

  private _data = new BehaviorSubject<EventData | null>(null);
  public data$ = this._data.asObservable().pipe(skip(1));
  public data: EventData | null = null;

  private channelId: string | undefined;

  constructor(
    private app: AppService,
    private auth: AuthService,
    private afs: AngularFirestore,
    private dataa: DataService
  ) {}

  /**
   * Subscribe to channel
   * @param channel id
   */
  addListener(channel: string): void {
    this.removeListener();

    this.channelId = channel;

    this.unsubscribe = this.afs.firestore
      .collection(EVENTS_COLLECTION)
      .doc(this.channelId)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        console.log('event', data);

        const lastEventId = this.dataa.getString('last_event_id');
        const eventId = data?.['id'];

        if (lastEventId === undefined) {
          this.dataa.set('last_event_id', eventId);
          console.log('first event');
          return;
        }

        if (eventId === lastEventId) {
          console.log('already processed');
          return;
        }

        this.dataa.set('last_event_id', eventId);

        console.log('notify', lastEventId, eventId);

        this._data.next(new EventData(data));
        if (data?.['type'] === 'order.create') {
          this.notifyMe();
        }
      });
  }

  play() {
    var audio = new Audio('/assets/sounds/order.wav');
    audio.play();
  }

  notifyMe() {
    this.play();

    const options = {
      icon: '/assets/images/logo.svg',
    };

    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      alert('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification('Nuevo pedido', options);
      // …
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          const notification = new Notification('Nuevo pedido', options);
          // …
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }

  /**
   * Remover listener
   */
  removeListener(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.channelId = undefined;

    this._data.next(null);
    this.data = null;
  }
}
