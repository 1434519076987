export enum TaxCode {
  TaxCodeUnknown = '',
  TaxCodeGravadoOperacionOnerosa_10 = '10',
  TaxCodeGravadoRetiroPorPremio_11 = '11',
  TaxCodeGravadoRetiroPorDonacion_12 = '12',
  TaxCodeGravadoRetiro_13 = '13',
  TaxCodeGravadoRetiroPorPublicidad_14 = '14',
  TaxCodeGravadoBonificaciones_15 = '15',
  TaxCodeGravadoRetiroPorEntregaATrabajadores_16 = '16',
  TaxCodeGravadoIvap_17 = '17',
  TaxCodeExoneradoOperacionOnerosa_20 = '20',
  TaxCodeExoneradoTransferenciaGratuita_21 = '21',
  TaxCodeInafectoOperacionOnerosa_30 = '30',
  TaxCodeInafectoRetiroPorBonificacion_31 = '31',
  TaxCodeInafectoRetiro_32 = '32',
  TaxCodeInafectoRetiroPorMuestrasMedicas_33 = '33',
  TaxCodeInafectoRetiroPorConvenioColectivo_34 = '34',
  TaxCodeInafectoRetiroPorPremio_35 = '35',
  TaxCodeInafectoRetiroPorPublicidad_36 = '36',
  TaxCodeInafectoTransferenciaGratuita_37 = '37',
  TaxCodeExportacion_40 = '40',
}

// IsFree returns true if the tax code is free.
export function isTaxCodeFree(code: string): boolean {
  switch (code) {
    case // TaxCode.TaxCodeGravadoOperacionOnerosa_10,
    (TaxCode.TaxCodeGravadoRetiroPorPremio_11,
    TaxCode.TaxCodeGravadoRetiroPorDonacion_12,
    TaxCode.TaxCodeGravadoRetiro_13,
    TaxCode.TaxCodeGravadoRetiroPorPublicidad_14,
    TaxCode.TaxCodeGravadoBonificaciones_15,
    TaxCode.TaxCodeGravadoRetiroPorEntregaATrabajadores_16,
    // TaxCode.TaxCodeGravadoIvap_17,
    // TaxCode.TaxCodeExoneradoOperacionOnerosa_20,
    TaxCode.TaxCodeExoneradoTransferenciaGratuita_21,
    // TaxCode.TaxCodeInafectoOperacionOnerosa_30,
    TaxCode.TaxCodeInafectoRetiroPorBonificacion_31,
    TaxCode.TaxCodeInafectoRetiro_32,
    TaxCode.TaxCodeInafectoRetiroPorMuestrasMedicas_33,
    TaxCode.TaxCodeInafectoRetiroPorConvenioColectivo_34,
    TaxCode.TaxCodeInafectoRetiroPorPremio_35,
    TaxCode.TaxCodeInafectoRetiroPorPublicidad_36,
    TaxCode.TaxCodeInafectoTransferenciaGratuita_37):
      // TaxCode.TaxCodeExportacion_40
      return true;
  }

  return false;
}
