import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map, Observable, from, of, tap } from 'rxjs';
import { Product } from '../models/product';
import { Response, responseError, responseSuccess } from '../interfaces/response';
import { ProductFilter } from '../interfaces/product-filter';
import { DataImport } from '../models/data-import';
import { HttpClient } from '@angular/common/http';
import { ProductStockAdjusment } from '../models/product-stock-adjustment';
import { ProductBreakdown } from '../models/product-breakdown';
import { AppDatabase } from '../data/app-database';

import Fuse from 'fuse.js';
import { DataService, PreferenceVars } from '../services/data.service';
import { AppService } from '../services/app.service';
import { ProductEntity } from '../data/entities/product.entity';
import { ProductType } from '../enums/product-type';
import Utils from '../utils/utils';

const PRODUCT_VERSION_KEY = PreferenceVars.SYNC_PRODUCT_VERSION;

const fuseOptions = {
  keys: ['name', 'brandName', 'categoryName', 'barcode'],
};

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private readonly sync = environment.api + '/v1/sync/products';
  private readonly index = environment.api + '/v1/inventories';
  private readonly exportResource = environment.import + '/v1/products/export';
  private readonly importResource = environment.import + '/v1/products/import';

  fuse: Fuse<Product>;

  constructor(
    private app: AppService,
    private http: HttpClient,
    protected api: ApiService,
    private appDatabase: AppDatabase,
    private localStorage: DataService
  ) {}

  download(): Observable<Response<Product[]>> {
    return this.api.get<Response<Product[]>>(Product, this.sync);
  }

  export(storeId: string): Observable<ArrayBuffer> {
    const url = `${this.exportResource}?store_id=${storeId}`;
    return this.http
      .get(url, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  import(storeId: string, file: File): Observable<Response<Product | null>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const url = `${this.importResource}?store_id=${storeId}`;
    return this.http.post<Response<Product | null>>(url, formData);
  }

  import0(data: { store_id: string; products: DataImport[] }): Observable<Response<Product>> {
    return this.api.post<Response<Product>>(Product, this.importResource, data);
  }

  get(storeId: string, productId: string): Observable<Response<Product | null>> {
    const params = new ProductFilter({ storeId: storeId, productIds: [productId] }).getQuery();
    return this.api.get<Response<Product[]>>(Product, `${this.index}${params}`).pipe(
      map((response) => {
        if (response.ok) {
          return responseSuccess(response.data.length > 0 ? response.data[0] : null);
        }
        return responseError(response.error);
      })
    );
  }

  all(p: ProductFilter): Observable<Response<Product[]>> {
    // console.trace('all', p);
    return this.api.get<Response<Product[]>>(Product, this.index + p.getQuery());
  }

  // @deprecated
  getByProductId(storeId: string, productId: string): Observable<Product | undefined> {
    const promise = from(
      this.appDatabase.products
        .where('id')
        .equals(storeId + productId)
        .toArray()
    );
    return promise.pipe(map((items) => (items.length > 0 ? new ProductEntity(items[0]).model() : undefined)));
  }

  getByBarcode(barcode: string): Observable<Product[]> {
    const promise = from(
      this.appDatabase.products.where('barcode').equals(barcode).or('barcodes').equals(barcode).toArray()
    );
    return promise.pipe(map((items) => items.map((i) => new ProductEntity(i).model())));
  }

  /**
   *
   * @param query string
   * @returns Product[]
   */
  search(query: string): Observable<Product[]> {
    // const result = new BehaviorSubject<Product[]>([]);
    if (this.fuse) {
      if (!query) {
        const promise = from(this.appDatabase.products.limit(100).toArray());
        return promise.pipe(map((items) => items.map((i) => new ProductEntity(i).model())));
      }
      return of(this.fuse.search(query, { limit: 40 }).map((i) => i.item));
    }
    return of([]);
    // return result.asObservable();
  }

  /**
   *
   * @param query string
   * @returns Product[]
   */
  products(storeId: string, query: string = ''): Observable<Product[]> {
    if (query) {
      if (Utils.isBarcode(query)) {
        return this.getByBarcode(query);
      } else {
        return this.search(query);
      }
    } else {
      return from(this.appDatabase.products.where('storeId').equals(storeId).sortBy('name')).pipe(
        map((items) => items.map((i) => new ProductEntity(i).model()))
      );
    }
  }

  /**
   * Index Search
   * @param storeId string
   */
  reindexFuse(callback: Function) {
    console.log('reindexing fuse');
    this.loadFuse(this.app.currentStoreId, callback);
  }

  /**
   * Index Search
   * @param storeId string
   */
  indexSearch(storeId: string | undefined = undefined, callback: Function | undefined = undefined) {
    // console.log('indexing search');
    // if (!storeId) {
    //   storeId = this.app.currentStoreId;
    // }
    // this.loadFuse(storeId);
    // // Get latest version
    // const version = this.localStorage.getDate(PRODUCT_VERSION_KEY);
    // // Request to server
    // this.requestUpdatedProducts(storeId, version, callback);
  }

  /**
   * Request updated products
   * @param version Date
   */
  requestUpdatedProducts(storeId: string, version: Date | undefined, callback: Function | undefined) {
    const versionQuery = `?version=${version?.toISOString() ?? null}`;

    this.api.get<Response<Product[]>>(Product, this.sync + versionQuery).subscribe((response) => {
      if (response.ok) {
        const updatedOrNewProducts = response.data.filter((i) => !i.deletedAt && i.type == ProductType.DEFAULT);
        console.log(`new products: (${updatedOrNewProducts.length})`);

        // Remove all products
        if (version === undefined) {
          this.appDatabase.products.clear();
        } else {
          // Get removed products
          const deletedProducts = response.data.filter((i) => i.deletedAt);
          if (deletedProducts.length > 0) {
            console.log(`deleted products: (${deletedProducts.length})`);

            // Clear removed products
            this.appDatabase.products.bulkDelete(deletedProducts.map((i) => i.id));
          }
        }

        // Update or create products
        this.appDatabase.products.bulkPut(updatedOrNewProducts.map((i) => i.entity()));

        // Set new latest version
        const latest = updatedOrNewProducts
          .sort((a: Product, b: Product) => {
            return a.updatedAt?.getTime() ?? 0 - b.updatedAt?.getTime() ?? 0;
          })
          ._last()?.updatedAt;

        // Save new lastest version
        if (latest) {
          this.localStorage.setDate(PRODUCT_VERSION_KEY, latest);
        }

        // reload fuse
        if (updatedOrNewProducts.length > 0) {
          this.loadFuse(storeId, callback);
        } else if (callback !== undefined) {
          callback();
        }
      } else {
        console.log('error in product sync', response);
      }
    });
  }

  /**
   * Initialize fuse
   * @param storeId string
   */
  private loadFuse(storeId: string, callback: Function | undefined = undefined) {
    // const promise =
    this.appDatabase.products
      .where('storeId')
      .equals(storeId)
      .toArray()
      .then((response) => {
        // Convert to models
        const models = response.map((i) => new ProductEntity(i).model());

        // Add full text search
        this.fuse = new Fuse(models, fuseOptions);
        console.log(`load fuse: ${models.length}`);

        if (callback !== undefined) {
          callback();
        }
      });

    // from(promise).subscribe((response) => {
    // });
  }

  download0(version: Date | undefined): Observable<Response<Product[]>> {
    // this.fuse = new Fuse(response.data, options);

    const versionQuery = version ? `?version=${version.getTime()}` : '';
    return this.api.get<Response<Product[]>>(Product, this.index + versionQuery);
  }

  /**
   * @deprecated user package tree service
   * @param storeId
   * @param productId
   * @returns
   */
  packageTree(storeId: string, productId: string): Observable<Response<Product[]>> {
    const endpont = `${this.index}/${productId}/package_tree?store_id=${storeId}`;
    return this.api.get<Response<Product[]>>(Product, endpont);
  }

  breakdown(body: ProductBreakdown): Observable<Response<Product>> {
    return this.api.post<Response<Product>>(Product, `${this.index}/breakdown`, body);
  }

  adjustment(body: ProductStockAdjusment): Observable<Response<Product>> {
    return this.api.post<Response<Product>>(Product, `${this.index}/${body.productId}/stock_adjustment`, body);
  }

  create(model: Product): Observable<Response<Product>> {
    return this.api.post<Response<Product>>(Product, this.index, model).pipe(
      tap((response) => {
        if (response.ok) {
          const entity = response.data;
          this.appDatabase.products.put(entity.entity(), entity.id);
        }
      })
    );
  }

  update(model: Product): Observable<Response<Product>> {
    return this.api.put<Response<Product>>(Product, this.index + '/' + model.productId, model).pipe(
      tap((response) => {
        if (response.ok) {
          const entity = response.data;
          this.appDatabase.products.put(entity.entity(), entity.id);
        }
      })
    );
  }

  partialUpdate(model: {
    id: string;
    is_active?: boolean | undefined;
    is_public?: boolean | undefined;
    is_available?: boolean | undefined;
  }): Observable<Response<Product>> {
    return this.api.put<Response<Product>>(Product, this.index + '/' + model.id, model);
  }

  delete(model: Product): Observable<Response<Product>> {
    return this.api.delete<Response<Product>>(Product, this.index + '/' + model.productId).pipe(
      tap((response) => {
        if (response.ok) {
          this.appDatabase.products.delete(model.id);
        }
      })
    );
  }
}
