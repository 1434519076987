import { LotReviewType } from '../enums/lot-review-type';
import { LotReview } from './lot-review';

export const lotReviewsMockup: LotReview[] = [
  new LotReview({
    id: '01',
    storeId: '123',
    productId: '1',
    productName: 'Galletas SALADITAS 46g',
    productSku: '396454544',
    lotExpiresAt: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
    userId: 'userId',
    userName: 'userName',
    type: LotReviewType.DONATION,
    quantity: 5,
    isReviewed: true,
    isActive: false,
    isReversed: false,
    reviewedAt: undefined,
    createdAt: new Date(new Date().getTime() - 0 * 24 * 60 * 60 * 1000),
  }),
  new LotReview({
    id: '02',
    storeId: '123',
    productId: 'p2',
    productName: 'Margarina MANTY Clasica pote 300g',
    productSku: '45686232128',
    lotExpiresAt: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
    userId: 'userId',
    userName: 'userName',
    type: LotReviewType.INTERNAL_USE,
    quantity: 3,
    isReviewed: true,
    isActive: false,
    isReversed: true,
    reviewedAt: undefined,
    createdAt: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
  }),
  new LotReview({
    id: '03',
    storeId: '123',
    productId: 'p3',
    productName: 'Galletas SALADITAS 46g',
    productSku: '545141867856',
    lotExpiresAt: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
    userId: 'userId',
    userName: 'userName',
    type: undefined,
    quantity: undefined,
    isReviewed: false,
    isActive: true,
    isReversed: false,
    reviewedAt: undefined,
    createdAt: new Date(),
  }),
  new LotReview({
    id: '04',
    storeId: '123',
    productId: 'p4',
    productName: 'Margarina MANTY Clasica pote 300g',
    productSku: 'A89423487984',
    lotExpiresAt: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
    userId: 'userId',
    userName: 'userName',
    type: undefined,
    quantity: undefined,
    isReviewed: false,
    isActive: true,
    isReversed: false,
    reviewedAt: undefined,
    createdAt: new Date(),
  }),
];
