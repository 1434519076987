import { Discount } from './discount';
import { ProductSaleType } from '../enums/product-sale-type';
import { Media } from './media';
import { ConsignmentProduct } from './consignment-product';
import { ProductPackage } from './product-package';
import { Lot } from './lot';
import { ProductType } from '../enums/product-type';
import { ProductEntity } from '../data/entities/product.entity';
import { DiscountType } from '../enums/discount-type';
import { TaxLite } from './tax-lite';
import { ProductCode } from './product-code';

export const autoGenerateBarcodeKey = '-auto-';

export enum Operation {
  CREATE = 'create',
  EDIT = 'edit',
  CLONE = 'clone',
}

export class Product {
  public storeId: string;
  public productId: string;
  public sku: string;
  public codes?: ProductCode[];
  public name: string;
  public description: string;
  public location: string;
  public tax: TaxLite;
  public price: number;
  public markup: number;
  public supplierId: string;
  public suppliers: ProductSupplier[];
  public imageUrl: string;
  public image: string;
  public images: string[];
  public keywords: string;
  public media: Media;
  public brandId: string;
  public brandName: string;
  public categoryId: string;
  public categoryName: string;
  public tagIds: string[];
  public index: number;
  public packing: string;
  public quantity: number;
  public unitType: string;
  public presentation: string;
  public saleType: ProductSaleType;
  public stepQuantity: number;
  public minQuantity: number;
  public maxQuantity: number;
  public discounts: Discount[];
  public discount: Discount;
  public disabledSrc: string;
  public trackInventory: boolean;
  public inStock: number | undefined;
  public safetyStock: number | undefined;
  public reorderPoint: number;
  public reorderQuantity: number;
  public madeFrom: ProductPackage[];
  public breakInto: ProductPackage[];
  public packages: Product[];
  public stockoutAt: Date;
  public lots: Lot[];
  public type: ProductType;
  public expiresAt: Date;
  public expirationParentId: String;
  public isAvailable: boolean;
  public isActive: boolean;
  public isPublic: boolean;
  public isFeatured: boolean;
  public featuredIndex: number;
  public createdAt: Date;
  public updatedAt: Date;
  public deletedAt: Date;

  tagNames: string[];
  priceExcludingTax: number;
  priceIncludingTax: number;

  getImageUrl(size: string = 'small'): string {
    if (this.image) {
      if (this.image.startsWith('http')) {
        return this.image;
      }
      return `https://imagedelivery.net/GM5ihU5VGkNFGQUwiS1u2w/${this.image}/${size}`;
    }
    return '/assets/images/placeholder.svg';
  }

  /**
   * product id for local storage
   */
  public get id(): string {
    return `${this.storeId}${this.productId}`;
  }

  public isConfigPackage(): boolean {
    return (this.madeFrom && this.madeFrom.length > 0) || (this.breakInto && this.breakInto.length > 0);
  }

  public get discountFormatted(): string {
    if (this.discount.type == DiscountType.PERCENTAGE) {
      return `${this.discount.name} (${this.discount.value}%)`;
    } else if (this.discount.type == DiscountType.CURRENCY) {
      return `${this.discount.name} (S/${this.discount.value.toFixed(2)})`;
    }
    return `-`;
  }

  public get packageQuantity(): number {
    return this.breakInto?._first()?.quantity;
  }

  public set packageQuantity(quantity: number) {
    const breakInto = this.breakInto?._first();
    if (breakInto) {
      breakInto.quantity = quantity;
    } else {
      this.breakInto = [new ProductPackage({ quantity: quantity })];
    }
  }

  // Helper UI
  //@deprecated use loading
  isLoading: boolean = false;
  loading: boolean = false;
  isActiveLoading: boolean = false;
  isPublicLoading: boolean = false;
  isAvailableLoading: boolean = false;

  public get supplyPrice(): number {
    if (Array.isArray(this.suppliers) && this.suppliers.length) {
      const found = this.suppliers.find((i) => i.id == this.supplierId);
      return found ? found.price : this.suppliers[0].price;
    } else {
      return 0;
    }
  }

  // NOTE: This is a hack to avoid the error "Cannot assign to read only property 'supplyPrice' of object"
  public set supplyPrice(price: number) {}

  public getSupplyPrice(supplierId?: string): number {
    // No suppliers
    if (!Array.isArray(this.suppliers) || this.suppliers.length == 0) {
      return 0;
    }

    // Get from supplierId requested
    if (supplierId) {
      const found = this.suppliers.find((i) => i.id == supplierId);
      return found ? found.price : 0;
    }

    // Get from last last supplier by default
    const found = this.suppliers.find((i) => i.id == this.supplierId);
    return found ? found.price : 0;
  }

  priceWithDiscount(): number {
    if (this.hasDiscount()) {
      return this.discount.newPrice(this.price);
    }
    return this.price;
  }

  hasDiscount(): boolean {
    return this.discount != null;
  }

  public get inStockOrZero(): number {
    return this.inStock == undefined ? 0 : this.inStock;
  }

  getStockClass(): string {
    const stock = this.inStockOrZero;
    if (stock <= 0) {
      return 'stock-danger';
    }
    if (this.reorderPoint && stock <= this.reorderPoint) {
      return 'stock-warning';
    }
    return 'stock-success';
  }

  constructor(init?: Partial<Product>) {
    Object.assign(this, init);
    this.discount = init?.discount ? new Discount(init?.discount) : init?.discount!!;
    this.discounts = init?.discounts?.map((i) => new Discount(i)) ?? [];
    this.madeFrom = init?.madeFrom?.map((i) => new ProductPackage(i)) ?? [];
    this.breakInto = init?.breakInto?.map((i) => new ProductPackage(i)) ?? [];
    this.packages = init?.packages?.map((i) => new Product(i)) ?? [];
  }

  static initialize(): Product {
    const product = new Product();
    product.saleType = ProductSaleType.UNIT;
    product.isAvailable = true;
    product.isActive = true;
    product.trackInventory = true;
    product.quantity = 1;
    product.unitType = 'und';
    product.presentation = '1 und';
    product.minQuantity = 1;
    product.stepQuantity = 1;
    product.maxQuantity = 100;
    product.suppliers = [new ProductSupplier()];
    return product;
  }

  static clone(source: Product): Product {
    const product = new Product();
    //product.productId = source.productId;
    //product.sku = source.sku;
    //product.barcode = source.barcode;
    //product.barcodes = source.barcodes;
    product.name = `${source.name} (Copy)`;
    product.description = source.description;
    //product.location = source.location;
    product.tax = source.tax;
    product.price = source.price;
    product.markup = source.markup;
    product.supplierId = source.supplierId;
    product.suppliers = source.suppliers;
    //product.imageUrl = source.imageUrl;
    //product.image = source.image;
    //product.images = source.images;
    product.keywords = source.keywords;
    //product.media = source.media;
    product.brandId = source.brandId;
    product.brandName = source.brandName;
    product.categoryId = source.categoryId;
    product.categoryName = source.categoryName;
    //product.tagIds = source.tagIds;
    //product.index = source.index;
    product.packing = source.packing;
    product.quantity = source.quantity;
    product.unitType = source.unitType;
    product.presentation = source.presentation;
    product.saleType = source.saleType;
    product.stepQuantity = source.stepQuantity;
    product.minQuantity = source.minQuantity;
    product.maxQuantity = source.maxQuantity;
    //product.discounts = source.discounts;
    //product.discount = source.discount;
    product.disabledSrc = source.disabledSrc;
    product.trackInventory = source.trackInventory;
    //product.inStock = source.inStock;
    product.safetyStock = source.safetyStock;
    product.reorderPoint = source.reorderPoint;
    product.reorderQuantity = source.reorderQuantity;
    //product.madeFrom = source.madeFrom;
    //product.breakInto = source.breakInto;
    //product.packages = source.packages;
    //product.stockoutAt = source.stockoutAt;
    //product.lots = source.lots;
    product.type = source.type;
    //product.expiresAt = source.expiresAt;
    //product.expirationParentId = source.expirationParentId;
    product.isAvailable = source.isAvailable;
    product.isActive = source.isActive;
    product.isPublic = source.isPublic;
    //product.isFeatured = source.isFeatured;
    //product.featuredIndex = source.featuredIndex;
    //product.createdAt = source.createdAt;
    //product.updatedAt = source.updatedAt;
    //product.deletedAt = source.deletedAt;
    return product;
  }

  public madeFromIds(): string {
    return this.madeFrom?.map((i) => i.id).join(', ');
  }

  public canEdit(): boolean {
    if (this.type == ProductType.EXPIRATION) {
      return false;
    }
    return true;
  }

  public canDelete(): boolean {
    if (this.type == ProductType.EXPIRATION) {
      return false;
    }
    return true;
  }

  public canBreakdown(): boolean {
    if (this.type == ProductType.EXPIRATION) {
      return false;
    }
    return true;
  }

  public hasPackagesTree(): boolean {
    return (this.madeFrom && this.madeFrom.length > 0) || (this.breakInto && this.breakInto.length > 0);
  }

  public toConsignmentProduct(supplierId: string): ConsignmentProduct {
    return new ConsignmentProduct({
      productId: this.productId,
      name: this.name,
      sku: this.sku,
      location: this.location,
      quantity: this.reorderQuantity,
      cost: this.getSupplyPrice(supplierId),
      product: this,
    });
  }

  public toJSON(): any {
    return {
      store_id: this.storeId,
      product_id: this.productId,
      sku: this.sku,
      codes: this.codes,
      name: this.name,
      description: this.description,
      location: this.location,
      tax_id: this.tax?.id,
      price: this.price,
      markup: this.markup,
      supplier_id: this.supplierId,
      suppliers: this.suppliers?.map((i) => new ProductSupplier(i).toJSON()),
      image_url: this.imageUrl,
      image: this.image,
      images: this.images,
      keywords: this.keywords,
      media: this.media,
      brand_id: this.brandId,
      brand_name: this.brandName,
      category_id: this.categoryId,
      category_name: this.categoryName,
      tag_ids: this.tagIds,
      index: this.index,
      quantity: this.quantity,
      packing: this.packing,
      unit_type: this.unitType,
      presentation: this.presentation,
      sale_type: this.saleType,
      step_quantity: this.stepQuantity,
      min_quantity: this.minQuantity,
      max_quantity: this.maxQuantity,
      discounts: this.discounts,
      disabled_src: this.disabledSrc,
      track_inventory: this.trackInventory,
      in_stock: this.inStock,
      safety_stock: this.safetyStock,
      reorder_point: this.reorderPoint,
      reorder_quantity: this.reorderQuantity,
      made_from: this.madeFrom?.map((i) => new ProductPackage(i).toJSON()),
      break_into: this.breakInto?.map((i) => new ProductPackage(i).toJSON()),
      packages: this.packages?.map((i) => new Product(i).toJSON()),
      stockout_at: this.stockoutAt,
      is_available: this.isAvailable,
      is_active: this.isActive,
      is_public: this.isPublic,
      is_featured: this.isFeatured,
      featured_index: this.featuredIndex,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      tag_names: this.tagNames,
    };
  }

  public entity(): ProductEntity {
    const entity = new ProductEntity();

    entity.id = this.id;
    entity.storeId = this.storeId;
    entity.productId = this.productId;
    entity.sku = this.sku;
    // entity.codes = this.codes;
    entity.name = this.name;
    entity.description = this.description;
    entity.location = this.location;
    entity.tax = this.tax;
    entity.price = this.price;
    entity.supplierId = this.supplierId;
    entity.image = this.image;
    entity.suppliers = this.suppliers;
    entity.keywords = this.keywords;
    entity.brandId = this.brandId;
    //media= this.media
    entity.brandName = this.brandName;
    entity.categoryId = this.categoryId;
    entity.categoryName = this.categoryName;
    entity.tagIds = this.tagIds;
    entity.index = this.index;
    entity.quantity = this.quantity;
    entity.packing = this.packing;
    entity.unitType = this.unitType;
    entity.presentation = this.presentation;
    entity.stepQuantity = this.stepQuantity;
    //saleType= this.saleType
    entity.minQuantity = this.minQuantity;
    entity.maxQuantity = this.maxQuantity;
    entity.disabledSrc = this.disabledSrc;
    //discounts= this.discounts
    entity.inStock = this.inStock;
    // trackInventory= this.trackInventory
    entity.safetyStock = this.safetyStock;
    entity.reorderPoint = this.reorderPoint;
    entity.reorderQuantity = this.reorderQuantity;
    entity.stockoutAt = this.stockoutAt;
    entity.madeFrom = this.madeFrom;
    entity.breakInto = this.breakInto;
    entity.expiresAt = this.expiresAt;
    //lots = this.lots
    //type= this.type
    entity.expirationParentId = this.expirationParentId;
    entity.isAvailable = this.isAvailable;
    entity.isActive = this.isActive;
    entity.isPublic = this.isPublic;
    entity.isFeatured = this.isFeatured;
    entity.featuredIndex = this.featuredIndex;
    entity.createdAt = this.createdAt;
    entity.updatedAt = this.updatedAt;
    return entity;
  }
}

export class ProductSupplier {
  public id: string;
  public name: string;
  public code: string;
  public price: number;
  public markup: number;

  constructor(init?: Partial<ProductSupplier>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      price: this.price,
      markup: this.markup,
    };
  }
}
