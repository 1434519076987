import { NgModule } from '@angular/core';

import { TablerIconsModule } from 'angular-tabler-icons';
// import * as TablerIcons from 'angular-tabler-icons/icons';

// IconInnerShadowBottomFilled
// IconBell
// IconHelp
// IconFlame
// IconShoppingCart
// IconShoppingBag
// IconCube
// IconSquareRounded
// IconUserHeart
// IconLayout_2
// IconFileAnalytics
// IconMessage_2
// IconSettings
// IconCircle

import {
  // Sidebar Menu

  // IconInnerShadowBottomFilled, // Already Added
  // IconBell, // Already Added
  IconHelp,
  IconFlame,
  IconShoppingCart,
  IconShoppingBag,
  IconCube,
  IconSquareRounded,
  IconUserHeart,
  IconLayout2, // Already Added
  IconFileAnalytics,
  // IconMessage_2, // Already Added
  IconSettings,
  IconCircle,

  // Other Icons
  IconArrowUpRight,
  IconArrowDownRight,
  IconArrowRight,
  IconArrowBackUp,
  IconTrash,
  IconUserPlus,
  IconCirclePlus,
  // Icon{{ i.icon }},
  IconMapPin,
  IconChevronDown,
  IconChevronRight,
  IconCheck,
  IconSearch,
  IconBell,
  IconMessage,
  IconInnerShadowBottomFilled,
  IconHelpHexagon,
  IconCircleCheck,
  IconPlus,
  IconPower,
  IconWalk,
  IconTruck,
  IconQrcode,
  IconHome,
  IconHandMove,
  IconPackageExport,
  IconScissors,
  IconTag,
  IconCopy,
  IconArrowRampRight,
  IconArrowLeft,
  IconBolt,
  IconPrinter,
  IconWand,
  IconSparkles,
  IconDotsVertical,
  IconSquareRoundedPlus,
  IconX,
  IconTableShortcut,
  IconMenu2,
  IconSelector,
  IconBox,
  IconChartPie,
  IconCategory,
  IconCreditCard,
  IconUser,
  IconInvoice,
  IconFileInvoice,
  IconHistory,
  IconCash,
  IconCoins,
  IconLock,
  IconLockOpen,
  IconNotes,
  IconHash,
  IconArrowsRightDown,
  IconArrowRightCircle,
  IconZoomScan,
  IconClockHour3,
  IconUserHexagon,
} from 'angular-tabler-icons/icons';

// Select some icons (use an object, not an array)
const icons = {
  // Sidebar Menu
  // IconInnerShadowBottomFilled, // Already Added
  // IconBell, // Already Added
  IconHelp,
  IconFlame,
  IconShoppingCart,
  IconShoppingBag,
  IconCube,
  IconSquareRounded,
  IconUserHeart,
  IconLayout2, // Already Added
  IconFileAnalytics,
  // IconMessage2, // Already Added
  IconSettings,
  IconCircle,

  // Other Icons
  IconArrowUpRight,
  IconArrowDownRight,
  IconArrowRight,
  IconArrowBackUp,
  IconTrash,
  IconUserPlus,
  IconCirclePlus,
  // Icon{{ i.icon }},
  IconMapPin,
  IconChevronDown,
  IconChevronRight,
  IconCheck,
  IconSearch,
  IconBell,
  IconMessage,
  IconInnerShadowBottomFilled,
  IconHelpHexagon,
  IconCircleCheck,
  IconPlus,
  IconPower,
  IconWalk,
  IconTruck,
  IconQrcode,
  IconHome,
  IconHandMove,
  IconPackageExport,
  IconScissors,
  IconTag,
  IconCopy,
  IconArrowRampRight,
  IconArrowLeft,
  IconBolt,
  IconPrinter,
  IconWand,
  IconSparkles,
  IconDotsVertical,
  IconSquareRoundedPlus,
  IconX,
  IconTableShortcut,
  IconMenu2,
  IconSelector,
  IconBox,
  IconChartPie,
  IconCategory,
  IconCreditCard,
  IconUser,
  IconInvoice,
  IconFileInvoice,
  IconHistory,
  IconCash,
  IconCoins,
  IconLock,
  IconLockOpen,
  IconNotes,
  IconHash,
  IconArrowRightCircle,
  IconZoomScan,
  IconClockHour3,
  IconUserHexagon,
};

@NgModule({
  imports: [TablerIconsModule.pick(icons)],
  exports: [TablerIconsModule],
})
export class IconsModule {}

// NOTES:
// 1. We add TablerIconsModule to the 'exports', since the <i-tabler> component will be used in templates of parent module
// 2. Don't forget to pick some icons using TablerIconsModule.pick({ ... })
