export default class Utils {
  static hasValue(value: any): boolean {
    return value != undefined && value != null;
  }

  static isBarcode(value: string): boolean {
    const regexProductBarcode = new RegExp(/^[0-9-. NS]+$/);
    return regexProductBarcode.test(value);
  }

  static round(value: number) {
    return Math.round(value * 100) / 100;
  }

  static isObjectId(id: string): boolean {
    if (!this.isEmpty(id) && id.match(/^[0-9a-fA-F]{24}$/)) {
      return true;
    }
    return false;
  }

  static normalize(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  static blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return theBlob as File;
  }

  static isEmpty(value: any): boolean {
    if (value === null || value === undefined) {
      return true;
    } else if (typeof value === 'boolean') {
      return value === false;
    } else if (typeof value === 'number') {
      return value === 0;
    } else if (typeof value === 'string') {
      return value.trim().length === 0;
    } else if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === 'object') {
      return Object.keys(value).length === 0;
    } else {
      throw new Error('Unsuported type');
    }
  }

  static deepEqual(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  static random(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  static humanFileSize(bytes: any, si: boolean = true): string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    let u = -1;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  static getParameterByName(name: string, url: string): string | null {
    if (!url) {
      url = window.location.href;
      console.log('url', url);
    }

    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
