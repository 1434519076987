import { Lot } from '../models/lot';
import { Product } from './product';

export class ConsignmentProduct {
  public id: string;
  public productId: string;
  public name: string;
  public sku: string;
  public location: string;
  public quantity: number;
  public received: number;
  public cost: number;
  public lots: Lot[];
  public createdAt: Date;
  public updatedAt: Date;

  // get first expiration lot
  public get expiresAt(): Date {
    return this.lots._first()?.expiresAt;
  }

  // set first expiration lot
  public set expiresAt(value: Date) {
    if (!value) {
      this.lots = [];
      return;
    }

    const lot = this.lots._first();
    if (lot) {
      lot.expiresAt = value;
    } else {
      this.lots = [new Lot({ expiresAt: value })];
    }
  }

  // Helper UI
  product: Product;
  removable: boolean;

  constructor(init?: Partial<ConsignmentProduct>) {
    Object.assign(this, init);
    this.lots = init?.lots?.map((i) => new Lot(i)) ?? [];
  }

  setLotQuantity(value: number) {
    const firstLot = this.lots?._first();
    if (firstLot) {
      firstLot.quantity = value;
    } else {
      this.lots = [new Lot({ quantity: value })];
    }
  }

  setLotExpiration(value: Date) {
    const firstLot = this.lots._first();
    if (firstLot) {
      firstLot.expiresAt = value;
    } else {
      this.lots = [new Lot({ expiresAt: value })];
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      product_id: this.productId,
      name: this.name,
      sku: this.sku,
      quantity: this.quantity,
      received: this.received,
      cost: this.cost,
      lots: this.lots?.map((i) => new Lot(i).toJSON()),
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
