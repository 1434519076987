import { Product } from './product';

export class QuickKey {
  id: string;
  businessId: string;
  storeId: string;
  userId: string;
  rootId: string;
  parentId: string;
  name: string;
  items: QuickKeyItem[];
  version: Date;
  deletedBy: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  constructor(init?: Partial<QuickKey>) {
    Object.assign(this, init);
    this.items = init?.items ? init.items.map((item) => new QuickKeyItem(item)) : [];
  }

  public toJSON(): any {
    return {
      id: this.id,
      business_id: this.businessId,
      store_id: this.storeId,
      user_id: this.userId,
      root_id: this.rootId,
      parent_id: this.parentId,
      name: this.name,
      items: this.items,
      version: this.version,
      deleted_by: this.deletedBy,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      deleted_at: this.deletedAt,
    };
  }
}

export class QuickKeyItem {
  id: string;
  layoutId: string;
  productId: string;
  name: string;
  color: string;
  imageUrl: string;
  product?: Product;

  isLoading: boolean = false;

  constructor(init?: Partial<QuickKeyItem>) {
    Object.assign(this, init);
    this.product = init?.product ? new Product(init.product) : undefined;
  }

  public toJSON(): any {
    return {
      id: this.id,
      layout_id: this.layoutId,
      product_id: this.productId,
      name: this.name,
      color: this.color,
      image_url: this.imageUrl,
    };
  }
}
