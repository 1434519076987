export const printerConnectionTypes = [
  { id: 'TCP', name: 'TCP' },
  { id: 'USB', name: 'USB' },
];

export const printerModelTypes = [
  { id: 'TM_M10', name: 'TM-M10' },
  { id: 'TM_M30', name: 'TM-M30' },
  { id: 'TM_P20', name: 'TM-P20' },
  { id: 'TM_P60', name: 'TM-P60' },
  { id: 'TM_P60II', name: 'TM-P60II' },
  { id: 'TM_P80', name: 'TM-P80' },
  { id: 'TM_T20', name: 'TM-T20' },
  { id: 'TM_T60', name: 'TM-T60' },
  { id: 'TM_T70', name: 'TM-T70' },
  { id: 'TM_T81', name: 'TM-T81' },
  { id: 'TM_T82', name: 'TM-T82' },
  { id: 'TM_T83', name: 'TM-T83' },
  { id: 'TM_T88', name: 'TM-T88' },
  { id: 'TM_T90', name: 'TM-T90' },
  { id: 'TM_T90KP', name: 'TM-T90KP' },
  { id: 'TM_U220', name: 'TM-U220' },
  { id: 'TM_U330', name: 'TM-U330' },
  { id: 'TM_L90', name: 'TM-L90' },
  { id: 'TM_H6000', name: 'TM-H6000' },
  { id: 'TM_T83III', name: 'TM-T83III' },
  { id: 'TM_T100', name: 'TM-T100' },
  { id: 'TM_M30II', name: 'TM-M30II' },
  { id: 'TS_100', name: 'TS-100' },
  { id: 'TM_M50', name: 'TM-M50' },
  { id: 'TM_T88VII', name: 'TM-T88VII' },
  { id: 'TM_L90LFC', name: 'TM-L90LFC' },
  { id: 'EU_M30', name: 'EU-M30' },
  { id: 'TM_L100', name: 'TM-L100' },
  { id: 'TM_P20II', name: 'TM-P20II' },
  { id: 'TM_P80II', name: 'TM-P80II' },
];
