import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export function serieNumberValidatorFactory(type: string | null) {
  return function (control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    // Ejemplo de lógica de validación basada en el tipo
    let validPattern;
    if (type === 'boleta') {
      validPattern = /^(B)[A-Z0-9]{3}$/; // Lógica específica para boleta
    } else if (type === 'factura') {
      validPattern = /^(F)[A-Z0-9]{3}$/; // Lógica específica para factura
    } else {
      validPattern = /^(B|F)[A-Z0-9]{3}$/;
      // return null; // Tipo desconocido, no hay validación
    }

    if (!validPattern.test(value)) {
      return {
        number: {
          message: 'Número de serie no válido para ' + type + '.',
        },
      };
    }

    return null;
  };
}

// export function serieNumberValidator(control: AbstractControl): ValidationErrors | null {
//   const value = control.value;

//   const validPattern = /^(B|F)[A-Z0-9]{3}$/; // Example: B001, F001
//   if (!validPattern.test(value)) {
//     return {
//       number: {
//         message: 'Número de serie no válido.',
//       },
//     };
//   }

//   return null;
// }
