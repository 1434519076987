export class Issue {
  id: string;
  title: string;
  description: string;
  status: string;
  priority: string;
  createdBy: string;
  assigneeId: string;
  version: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  constructor(init?: Partial<Issue>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      status: this.status,
      priority: this.priority,
      created_by: this.createdBy,
      assignee_id: this.assigneeId,
      version: this.version,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      deleted_at: this.deletedAt,
    };
  }
}
