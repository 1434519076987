<!-- <mat-sidenav-container>
  <mat-sidenav-content> -->
<router-outlet></router-outlet>
<!-- <dl-loader></dl-loader> -->

<div *ngIf="loadingService.loading$ | async" class="loader-wrapper">
  <mat-progress-spinner diameter="30" mode="indeterminate"></mat-progress-spinner>
</div>

<!-- </mat-sidenav-content>
  <mat-sidenav #sidenav mode="over" position="end" style="min-width: 600px">
    <ng-container #dynamicContent></ng-container>
  </mat-sidenav>
</mat-sidenav-container> -->

<!-- <app-loader [visible]="loading"></app-loader> -->
