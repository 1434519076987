import { StocktakeStatus, StocktakeType } from '../enums/stocktake-status';

export class Stocktake {
  public id: string;
  public storeId: string;
  public storeName: string;
  public name: string;
  public showInactive: boolean;
  public status: StocktakeStatus;
  public type: StocktakeType;
  public totalCostGain: number;
  public totalCostLoss: number;
  public totalCountGain: number;
  public totalCountLoss: number;
  public filters: StocktakeFilter[];
  public startAt: Date;
  public countedAt: Date;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(init?: Partial<Stocktake>) {
    Object.assign(this, init);
    this.filters = init?.filters?.map((i) => new StocktakeFilter(i)) ?? [];
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      store_name: this.storeName,
      name: this.name,
      show_inactive: this.showInactive,
      status: this.status,
      type: this.type,
      total_cost_gain: this.totalCostGain,
      total_cost_loss: this.totalCostLoss,
      total_count_gain: this.totalCountGain,
      total_count_loss: this.totalCountLoss,
      filters: this.filters?.map((i) => i.toJSON()),
      start_at: this.startAt,
      counted_at: this.countedAt,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}

export class StocktakeFilter {
  type: string;
  value: string;
  name: string;

  constructor(init?: Partial<StocktakeFilter>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      type: this.type,
      value: this.value,
      name: this.name,
    };
  }
}
