import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { StocktakeCount } from '../models/stocktake-count';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class StocktakeCountService {
  private readonly index = environment.api + '/v1/stocktake_counts';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<StocktakeCount | null>> {
    return this.api.get<Response<StocktakeCount | null>>(StocktakeCount, this.index + '/' + id);
  }

  all(stocktakeId: string, version: number = 0): Observable<Response<StocktakeCount[]>> {
    return this.api.get<Response<StocktakeCount[]>>(
      StocktakeCount,
      `${this.index}?stocktake_id=${stocktakeId}&version=${version}`
    );
  }

  create(stocktakeCount: StocktakeCount): Observable<Response<StocktakeCount>> {
    return this.api.post<Response<StocktakeCount>>(StocktakeCount, this.index, stocktakeCount);
  }

  update(stocktakeCount: StocktakeCount): Observable<Response<StocktakeCount>> {
    return this.api.put<Response<StocktakeCount>>(StocktakeCount, this.index + '/' + stocktakeCount.id, stocktakeCount);
  }

  delete(stocktakeCount: StocktakeCount): Observable<Response<StocktakeCount>> {
    return this.api.delete<Response<StocktakeCount>>(StocktakeCount, this.index + '/' + stocktakeCount.id);
  }
}
