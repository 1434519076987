import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ViewContainerRef, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { environment } from 'environments/environment';
import { AppSettings } from './app.config';
import { menu } from './components/sidebar/menu';
import { AppService, AuthService, Barcode, BarcodeService, Chat, EventService, SidenavService } from './core';
import { CoreService } from './core/services/core.service';
import { NavigationService } from './core/services/navigation.service';
import { GiftCardViewComponent } from './modules/product/pages/gift-card/gift-card-view/gift-card-view.component';
import { Observable } from 'rxjs';
import { UserConfig } from './core/services/user-config.service';
import { ChatDialogComponent } from './components/chat-dialog/chat-dialog.component';
import { LoadingService } from './core/services/loading.service';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'main',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  private htmlElement!: HTMLHtmlElement;

  //get options from service
  options = this.settings.getOptions();

  items = menu;
  buildVersion = environment.buildVersion;
  buildDate = environment.buildDate;
  account: Observable<any>;

  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  // @ViewChild('sidenav') sidenavContainer0: MatSidenav;
  // @ViewChild('dynamicContent', { read: ViewContainerRef }) dynamicContentContainer: ViewContainerRef;

  // barcode scan
  // barcode: string = '';
  // maxDelayConcatenation: number = 100;
  // lastTimeKeyPressed: number = 0;

  // @HostListener('document:keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent) {
  //   console.log(event.key, event.code);

  //   if (performance.now() - this.lastTimeKeyPressed > this.maxDelayConcatenation) {
  //     this.barcode = '';
  //   }

  //   this.lastTimeKeyPressed = performance.now();

  //   const key = event.key;

  //   if (event.key === 'Enter') {
  //     //this.barcodeSerivce.scan(this.barcode);
  //     console.log('barcode', this.barcode);
  //     this.barcode = '';
  //   } else {
  //     this.barcode += key;
  //   }
  // }

  constructor(
    private settings: CoreService,
    private navigation: NavigationService,
    private observer: BreakpointObserver,
    private barcodeSerivce: BarcodeService,
    private dialog: MatDialog,
    private authService: AuthService,
    private app: AppService,
    private eventService: EventService,
    private sidenavService: SidenavService,
    public loadingService: LoadingService,
  ) {
    this.account = authService.account$;

    this.htmlElement = document.querySelector('html')!;

    // Initialize project theme with options
    this.receiveOptions(this.options);

    // listen barcode scan
    this.barcodeSerivce.barcode$.subscribe((barcode: Barcode) => {
      switch (barcode.type) {
        case 'giftcard':
          this.showGiftCard(barcode.giftCardId);
          break;

        case 'order':
          this.navigation.toOrderView(barcode.invoiceNumber);
          break;
      }
    });
  }

  // showChat(chat: Chat) {
  //   const dialogRef = this.dialog.open(ChatDialogComponent, {
  //     width: '700px',
  //     data: chat,
  //   });

  //   dialogRef.afterClosed().subscribe((url: string | undefined) => {
  //     if (url) {
  //     }
  //   });
  // }

  logout(): void {
    this.authService.logout();
  }

  ngAfterViewInit(): void {
    // this.sidenavService.setSidenav(this.sidenavContainer0);
    // this.sidenavService.setViewContainerRef(this.dynamicContentContainer);
    // this.sidenavContainer.scrollable.elementScrolled().subscribe((e: Event) => {
    //   // Obtener la posición de desplazamiento actual
    //   const scrollTop = this.sidenavContainer.scrollable.getElementRef().nativeElement.scrollTop || 0;
    //   // Verificar si la posición de desplazamiento es mayor o igual a 10px
    //   if (scrollTop >= 10) {
    //     $('.page .header').addClass('sticky');
    //   } else {
    //     $('.page .header').removeClass('sticky');
    //   }
    // });
    // this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
    //   if (res.matches) {
    //     this.sidenav.mode = 'over';
    //     this.sidenav.close();
    //   } else {
    //     this.sidenav.mode = 'side';
    //     this.sidenav.open();
    //   }
    // });
  }

  ngOnInit(): void {
    moment().locale('es');

    // this.app.store$.subscribe((store) => {
    //   if (store != null) {
    //     this.eventService.addListener(store?.id);
    //   }
    // });
  }

  ngOnDestroy(): void {
    // this.eventService.removeListener();
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }

  showGiftCard(giftcardId: string): void {
    this.dialog.open(GiftCardViewComponent, {
      width: '940px',
      data: giftcardId,
    });
  }
}
