export class StocktakeItem {
  private _id: string;
  private _storeId: string;
  private _stocktakeId: string;
  private _name: string;
  private _sku: string;
  private _productId: string;
  private _cost: number;
  private _counted: number;
  private _planned: boolean;
  private _isExcluded: boolean;
  private _isIncremental: boolean;
  private _expected: number;
  private _version: Date;
  private _createdAt: Date;
  private _updatedAt: Date;

  public get difUnit(): number {
    return (this.counted ?? 0) - (this.expected ?? 0);
  }

  public get difCost(): number {
    return ((this.counted ?? 0) - (this.expected ?? 0)) * (this.cost ?? 0);
  }

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get storeId(): string {
    return this._storeId;
  }

  public set storeId(storeId: string) {
    this._storeId = storeId;
  }

  public get stocktakeId(): string {
    return this._stocktakeId;
  }

  public set stocktakeId(stocktakeId: string) {
    this._stocktakeId = stocktakeId;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get sku(): string {
    return this._sku;
  }

  public set sku(sku: string) {
    this._sku = sku;
  }

  public get productId(): string {
    return this._productId;
  }

  public set productId(productId: string) {
    this._productId = productId;
  }

  public get cost(): number {
    return this._cost;
  }

  public set cost(cost: number) {
    this._cost = cost;
  }

  public get counted(): number {
    return this._counted;
  }

  public set counted(counted: number) {
    this._counted = counted;
  }

  public get planned(): boolean {
    return this._planned;
  }

  public set planned(value: boolean) {
    this._planned = value;
  }

  public get isExcluded(): boolean {
    return this._isExcluded;
  }

  public set isExcluded(isExcluded: boolean) {
    this._isExcluded = isExcluded;
  }

  public get isIncremental(): boolean {
    return this._isIncremental;
  }

  public set isIncremental(isIncremental: boolean) {
    this._isIncremental = isIncremental;
  }

  public get expected(): number {
    return this._expected;
  }

  public set expected(expected: number) {
    this._expected = expected;
  }

  public get version(): Date {
    return this._version;
  }

  public set version(version: Date) {
    this._version = version;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  constructor(init?: Partial<StocktakeItem>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      stocktake_id: this.stocktakeId,
      name: this.name,
      sku: this.sku,
      product_id: this.productId,
      cost: this.cost,
      counted: this.counted,
      is_excluded: this.isExcluded,
      is_incremental: this.isIncremental,
      planned: this.planned,
      expected: this.expected,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
