import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'void' })
export class VoidPipe implements PipeTransform {
  transform(value: string | number | null | undefined, param: string | null = null): any {
    if (value === null || value === undefined || value === '') {
      return param ? param : '—';
    }
    return value;
  }
}
