import { Business } from '@app/core/models/business';

export class BusinessEntity {
  id: string;
  name: string;
  logoURL: string;
  receiptLogoUrl: string;
  denomination: string;
  idNumber: string;
  phone: string;
  mobile: string;
  email: string;
  address: string;
  currencyCode: string;
  countryCode: string;
  city: string;
  department: string;
  district: string;
  province: string;
  locale: string;
  timeZone: string;
  isTaxInclusive: boolean;
  version?: Date;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(init?: Partial<BusinessEntity>) {
    Object.assign(this, init);
  }

  public model(): Business {
    return new Business({
      id: this.id,
      name: this.name,
      logoURL: this.logoURL,
      receiptLogoUrl: this.receiptLogoUrl,
      denomination: this.denomination,
      idNumber: this.idNumber,
      phone: this.phone,
      mobile: this.mobile,
      email: this.email,
      address: this.address,
      countryCode: this.countryCode,
      currencyCode: this.currencyCode,
      locale: this.locale,
      timeZone: this.timeZone,
      isTaxInclusive: this.isTaxInclusive,
      updatedAt: this.updatedAt,
    });
  }
}
