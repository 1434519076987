export enum IssueStatus {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
  REJECTED = 'rejected',
}

export const ISSUE_STATUSES = [
  { id: 'open', name: 'Abierto' },
  { id: 'in_progress', name: 'En progreso' },
  { id: 'closed', name: 'Cerrado' },
  { id: 'rejected', name: 'Cancelado' },
];
