import { Injectable } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import Utils from '../utils/utils';

const GIFT_CARD = 'gc';

export class Barcode {
  type: 'barcode' | 'giftcard' | 'order';
  barcode: string;
  giftCardId: string;
  invoiceNumber: string;
}

@Injectable({
  providedIn: 'root',
})
export class BarcodeService {
  private _barcode = new BehaviorSubject<Barcode>(new Barcode());
  public barcode$ = this._barcode.asObservable().pipe(skip(1));
  public barcode: string = '';

  setBarcode(barcode: string) {
    this.barcode = barcode;

    // Detect barcode
    if (Utils.isBarcode(barcode)) {
      const data = new Barcode();
      data.type = 'barcode';
      data.barcode = barcode;
      this._barcode.next(data);
      return;
    }

    // Detect order QR
    if (this.isQRCode(barcode)) {
      return;
    }

    // Decode base64
    try {
      var decoded = atob(barcode);
      const sections = decoded.split(':');

      if (sections.length == 1) {
        const data = new Barcode();
        data.type = 'barcode';
        data.barcode = barcode;
        this._barcode.next(data);
      } else {
        const type = sections[0];
        const value = sections[1];

        switch (type) {
          case GIFT_CARD:
            const data = new Barcode();
            data.type = 'giftcard';
            data.giftCardId = value.split('.')[0];
            this._barcode.next(data);
            break;
        }
      }
    } catch (e) {}
  }

  /**
   * Check order qr code
   * formats:
   *   20607942898|03|BBB1|000048|1.14|7.50|01'12'2022|1|00000000||
   *   }00002620607942898]03]BBB1]000048]1.14]7.50]01'12'2022]1]00000000]]
   * @param barcode string
   * @returns
   */
  isQRCode(barcode: string): boolean {
    let separator = '|';

    if (barcode.includes('Ç')) {
      separator = 'Ç';
    } else if (barcode.includes(']')) {
      separator = ']';
    }

    const dividers = [...barcode].filter((l) => l === separator).length;
    if (dividers >= 9) {
      const sections = barcode.split(separator);
      // support malformed start "}000026"
      if (sections[0].length >= 11) {
        // Extract invoice number from qr code
        const serie = sections[2];
        const number = sections[3];
        const invoiceNumber = `${serie}-${number}`;

        const data = new Barcode();
        data.type = 'order';
        data.invoiceNumber = invoiceNumber;
        this._barcode.next(data);

        return true;
      }
    }
    return false;
  }
}
