import { PaymentMethodEntity } from '../data/entities/payment-method.entity';
import { PaymentType } from '../enums/payment-type';
import { RoundingMode } from '../enums/rounding-mode';

export class PaymentMethod {
  public id: string;
  public name: string;
  public description: string;
  public type: PaymentType;
  public supported: string[];
  public rounding: number;
  public roundingMode: RoundingMode;
  public link: string;
  public storeIds: string[];
  public isInternal: boolean;
  public isEnabled: boolean;
  public createdAt: Date;
  public updatedAt: Date;

  isLoading = false;

  constructor(init?: Partial<PaymentMethod>) {
    Object.assign(this, init);

    if (this.supported == undefined) {
      this.supported = [];
    }

    if (this.storeIds == undefined) {
      this.storeIds = [];
    }
  }

  image(): string {
    switch (this.type) {
      case PaymentType.CASH:
        return 'assets/images/payment-cash.svg';
      case PaymentType.CREDIT_CARD:
        return 'assets/images/payment-credit-card.svg';
      case PaymentType.GIFT_CARD:
        return 'assets/images/payment-gift-card.svg';
      case PaymentType.POS:
        return 'assets/images/payment-pos.svg';
      case PaymentType.WALLET:
        return 'assets/images/payment-wallet.svg';
      case PaymentType.QR:
        return 'assets/images/payment-qr.svg';
      case PaymentType.BANK_TRANSFER:
        return 'assets/images/payment-bank-transfer.svg';
    }
    return 'assets/images/payment-default.svg';
  }

  entity(): PaymentMethodEntity {
    return new PaymentMethodEntity({
      id: this.id,
      name: this.name,
      description: this.description,
      type: this.type,
      supported: this.supported,
      rounding: this.rounding,
      roundingMode: this.roundingMode,
      link: this.link,
      storeIds: this.storeIds,
      isInternal: this.isInternal,
      isEnabled: this.isEnabled,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    });
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      type: this.type,
      supported: this.supported,
      rounding: this.rounding,
      rounding_mode: this.roundingMode,
      link: this.link,
      store_ids: this.storeIds,
      is_internal: this.isInternal,
      is_enabled: this.isEnabled,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
