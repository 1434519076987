export class ImageResult {
  name: string;
  url: string;
  mime: string;
  width: number;
  height: number;

  constructor(init?: Partial<ImageResult>) {
    Object.assign(this, init);
  }
}

export class ImageDirectResult {
  id: string;
  uploadUrl: string;

  constructor(init?: Partial<ImageDirectResult>) {
    Object.assign(this, init);
  }
}

// export class ImageUploadResult {
//   id: string;
//   uploadUrl: string;

//   constructor(init?: Partial<ImageUploadResult>) {
//     Object.assign(this, init);
//   }
// }

export function defaultImageVariant(urls: string[]): string | undefined {
  // Ordena el array en orden descendente
  urls.sort((a: string, b: string): number => {
    const sizeOrder: { [key: string]: number } = { small: 1, medium: 2, large: 3 };
    const sizeA: string = a.split('/').pop()!;
    const sizeB: string = b.split('/').pop()!;
    return sizeOrder[sizeB] - sizeOrder[sizeA];
  });

  // Encuentra el primer elemento que contiene "large", "medium" o "small"
  return urls.find((url: string) => url.includes('large') || url.includes('medium') || url.includes('small'));
}

export interface ImageUploadResult {
  id: string;
  metadata: { [key: string]: string };
  uploaded: Date;
  requireSignedURLs: boolean;
  variants: string[];
  draft: boolean;
}

export interface ImageUploadResponse {
  result: ImageUploadResult;
  success: boolean;
  errors: any[];
  messages: any[];

  // helder
  status: 'progress' | 'complete';
  progress: number;
}
