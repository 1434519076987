import { CashMovementType } from '../enums/cash-movement-type';

export class RegisterClosure {
  id: string;
  storeId: string;
  storeName: string;
  storeLocal: string;
  registerId: string;
  registerName: string;
  openById: string;
  openByName: string;
  closedById: string;
  closedByName: string;
  sequenceNumber: number;
  note: string;
  cashMovements: CashMovement[];
  payments: RegisterClosurePayment[];
  totalSale: number;
  onAccount: number;
  totalTax: number;
  totalDiscount: number;
  totalDifference: number;
  openingTime: Date;
  closingTime: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  constructor(init?: Partial<RegisterClosure>) {
    Object.assign(this, init);
    this.cashMovements = init?.cashMovements?.map((i) => new CashMovement(i)) ?? [];
    this.payments = init?.payments?.map((i) => new RegisterClosurePayment(i)) ?? [];
    this.payments.sort((a, b) => {
      const dateA = a.createdAt ? a.createdAt.getTime() : Infinity;
      const dateB = b.createdAt ? b.createdAt.getTime() : Infinity;
      return dateA - dateB;
    });
  }
}

export class CashMovement {
  id: string;
  userId: string;
  userName: string;
  userImageUrl: string;
  amount: number;
  type: CashMovementType;
  note: string;
  createdAt: Date;

  constructor(init?: Partial<CashMovement>) {
    Object.assign(this, init);
  }

  // Return type name
  getTypeName(): string {
    switch (this.type) {
      case CashMovementType.OPENING_FLOAT:
        return 'Fondo de Apertura';
      case CashMovementType.CASH_IN:
        return 'Entrada de Efectivo';
      case CashMovementType.CASH_OUT:
        return 'Salida de Efectivo';
      case CashMovementType.PETTY_CASH_IN:
        return 'Entrada de Caja Chica';
      case CashMovementType.PETTY_CASH_OUT:
        return 'Salida de Caja Chica';
      default:
        return '';
    }
  }

  // Get is opening float
  get isOpeningFloat(): boolean {
    return this.type === CashMovementType.OPENING_FLOAT;
  }

  // Get is in or out
  get isCashIn(): boolean {
    return this.type === CashMovementType.CASH_IN || this.type === CashMovementType.PETTY_CASH_IN;
  }

  get isCashOut(): boolean {
    return this.type === CashMovementType.CASH_OUT || this.type === CashMovementType.PETTY_CASH_OUT;
  }
}

export class RegisterClosurePayment {
  id: string;
  paymentMethodId: string;
  paymentMethodType: string;
  paymentMethodName: string;
  expected: number;
  counted: number;
  difference: number;
  isReconcilable: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(init?: Partial<RegisterClosurePayment>) {
    Object.assign(this, init);
  }

  getDifference(): number {
    return (this.counted ?? 0) - (this.expected ?? 0);
  }
}
