<!-- <div mat-dialog-title>
  <h2></h2>
</div> -->

<mat-dialog-content>
  <ns-message [chat]="chat"></ns-message>
</mat-dialog-content>

<!-- <mat-dialog-actions>
  <button mat-button (click)="close()">Cerrar</button>
</mat-dialog-actions> -->
