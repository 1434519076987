import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/compat/app';
import { BehaviorSubject } from 'rxjs';
import { Message, MessageType } from '../firestore/models/message';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AppService } from './app.service';
import { Chat } from '../firestore/models/chat';
import { DataService } from './data.service';

const CHATS_COLLECTION = 'chats';
const MESSAGES_COLLECTION = 'messages';
const CHATS_LIMIT = 100;
const MESSAGES_LIMIT = 50;

class MessageData {
  id: string;
  type: 'added' | 'modified' | 'removed';
  messages: Message[] = [];
}

@Injectable({
  providedIn: 'root',
})
export class Messaging {
  private unsubscribe: any;
  private chatSubscription: any;

  private _chats = new BehaviorSubject<Chat[]>([]);
  public chats$ = this._chats.asObservable();
  public chats: Chat[] = [];

  private _data = new BehaviorSubject<MessageData | null>(null);
  public data$ = this._data.asObservable();
  public data: MessageData | null = null;

  private _loading = new BehaviorSubject<Boolean>(false);
  public loading$ = this._loading.asObservable();

  private roomId: string | undefined;

  constructor(
    private app: AppService,
    private auth: AuthService,
    private afs: AngularFirestore,
    private dataService: DataService
  ) {}

  /**
   * Subscribe to messaging
   */
  chatSubscribe(userId: string): void {
    this.removeChatListener();

    this.chatSubscription = this.afs.firestore
      .collection(CHATS_COLLECTION)
      .doc(userId)
      .collection(CHATS_COLLECTION)
      .orderBy('ts', 'desc')
      .limit(CHATS_LIMIT)
      .onSnapshot((snapshot) => {
        const newChats: Chat[] = [];

        // notify loaded
        this._loading.next(true);

        for (const change of snapshot.docChanges()) {
          if (change.type === 'added') {
            const chat = new Chat(change.doc.data());
            chat.id = change.doc.id;
            newChats.push(chat);
            console.log('added: ', chat);
          }

          if (change.type === 'modified') {
            const chat = new Chat(change.doc.data());
            chat.id = change.doc.id;
            const index = this.chats.findIndex((i) => i.id == chat.id);
            if (index != -1) {
              this.chats[index] = chat;
            }
            console.log('modified: ', change.doc.data());
          }

          if (change.type === 'removed') {
            const chat = new Chat(change.doc.data());
            chat.id = change.doc.id;
            const index = this.chats.findIndex((i) => i.id == chat.id);
            if (index != -1) {
              this.chats._removeByIndex(index);
            }
            console.log('removed: ', change.doc.data());
          }
        }

        this.chats.push(...newChats);
        this.chats.sort((a, b) => (b.date?.getTime() ?? 0) - (a.date?.getTime() ?? 0));

        this._chats.next(this.chats);

        // play sound
        if (this.chats.length > 0) {
          const lastConversationtime = this.dataService.getString('last_conversation');
          const conversationTime = this.chats.reduce((a, b) => (a.date > b.date ? a : b), this.chats[0]).date.getTime();

          if (lastConversationtime === undefined) {
            this.dataService.set('last_conversation', conversationTime);
            return;
          }

          if (lastConversationtime !== conversationTime + '') {
            this.dataService.set('last_conversation', conversationTime);
            this.play();
          }

          console.log('last', lastConversationtime, conversationTime);
        }
      });
  }

  play() {
    var audio = new Audio('/assets/sounds/message.wav');
    audio.play();
  }

  /**
   * Subscribe to messaging
   * @param businessId id
   * @param group id
   * @param roomId id
   */
  addListener(roomId: string): void {
    this.removeListener();

    this.roomId = roomId;

    this.unsubscribe = this.afs.firestore
      .collection(MESSAGES_COLLECTION)
      .doc(this.roomId)
      .collection(MESSAGES_COLLECTION)
      .orderBy('ts', 'desc')
      .limit(MESSAGES_LIMIT)
      .onSnapshot((snapshot) => {
        const newMessages: Message[] = [];
        const updatedMessages: Message[] = [];

        for (const change of snapshot.docChanges()) {
          if (change.type === 'added') {
            const message = new Message(change.doc.data());
            message.id = change.doc.id;
            newMessages.push(message);
          }

          if (change.type === 'modified') {
            const message = new Message(change.doc.data());
            message.id = change.doc.id;
            updatedMessages.push(message);
            console.log('modified: ', change.doc.data());
          }

          if (change.type === 'removed') {
            console.log('Removed: ', change.doc.data());
          }
        }

        if (updatedMessages.length > 0) {
          const data = new MessageData();
          data.id = roomId;
          data.type = 'modified';
          data.messages = updatedMessages;
          this._data.next(data);
        }

        if (newMessages.length > 0) {
          const data = new MessageData();
          data.id = roomId;
          data.type = 'added';
          data.messages = newMessages.reverse();
          this._data.next(data);
        }
      });
  }

  initMessage(): Message {
    const message = new Message();
    message.uid = 'default'; // this.app.store?.id!;
    message.name = 'Nishi'; // this.app.store?.name!;
    // message.photo = this.app.store?.logoUrl!;
    return message;
  }

  sendText(text: string, callback: (value: boolean) => void) {
    if (this.app.store == null) {
      callback(false);
      return;
    }

    const message = this.initMessage();
    message.text = text;
    message.type = MessageType.TEXT;

    this.send(message, callback);
  }

  sendAudio(url: string, callback: (value: boolean) => void) {
    if (this.auth.account == null) {
      callback(false);
      return;
    }

    const message = this.initMessage();
    message.url = url;
    message.type = MessageType.AUDIO;

    this.send(message, callback);
  }

  private send(message: Message, callback: (value: boolean) => void): void {
    const newId = this.afs.createId();
    message.ts = firebase.default.firestore.FieldValue.serverTimestamp();
    this.afs
      .collection(MESSAGES_COLLECTION)
      .doc(this.roomId)
      .collection(MESSAGES_COLLECTION)
      .doc(newId)
      .set(message.toJSON())
      .then((_) => {
        callback(true);
      })
      .catch((_) => {
        callback(false);
      });
  }

  /**
   * Set conversation as seen
   * @param userId user id
   */
  seenConversation(userId: string, chatId: string) {
    this.afs
      .collection(CHATS_COLLECTION)
      .doc(userId)
      .collection(CHATS_COLLECTION)
      .doc(chatId)
      .set({ seen: true, unread: 0 }, { merge: true })
      .then((_) => {})
      .catch((_) => {});
  }

  /**
   * Set message as seen
   * @param chatId chat id
   * @param messageId message id
   */
  seenMessage(chatId: string, messageId: string) {
    this.afs
      .collection(MESSAGES_COLLECTION)
      .doc(chatId)
      .collection(MESSAGES_COLLECTION)
      .doc(messageId)
      .set({ seen: true }, { merge: true })
      .then((_) => {})
      .catch((_) => {});
  }

  /**
   * Remover chat listener
   */
  removeChatListener(): void {
    if (this.chatSubscription) {
      this.chatSubscription();
    }

    this.chats = [];
    this._chats.next(this.chats);
  }

  /**
   * Remover classroom listener
   */
  removeListener(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.roomId = undefined;

    this._data.next(null);
    this.data = null;
  }
}
