import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  // List of common locales (you can add more)
  private locales = [
    // Latin American locales
    'es-AR', // Español (Argentina)
    'es-BO', // Español (Bolivia)
    'es-BR', // Español (Brasil, uso común)
    'es-CL', // Español (Chile)
    'es-CO', // Español (Colombia)
    'es-CR', // Español (Costa Rica)
    'es-CU', // Español (Cuba)
    'es-DO', // Español (República Dominicana)
    'es-GT', // Español (Guatemala)
    'es-HN', // Español (Honduras)
    'es-MX', // Español (México)
    'es-NI', // Español (Nicaragua)
    'es-PY', // Español (Paraguay)
    'es-PE', // Español (Perú)
    'es-UY', // Español (Uruguay)
    'es-VE', // Español (Venezuela)
    'es-SV', // Español (El Salvador)

    // Locales for other important languages
    'en-US', // Inglés (Estados Unidos)
    'fr-FR', // Francés (Francia)
    'de-DE', // Alemán (Alemania)
    'pt-BR', // Portugués (Brasil)
    'ja-JP', // Japonés (Japón)
    'zh-CN', // Chino (Simplificado, China)
    'ar-SA', // Árabe (Arabia Saudita)
    'ru-RU', // Ruso (Rusia)
  ];

  constructor() {}

  // Method to get the list of locales in { label, value } format
  getLocales(): Array<{ label: string; value: string }> {
    const localesList: Array<{ label: string; value: string }> = [];

    this.locales.forEach((locale) => {
      const label = this.getLocaleDisplayName(locale);
      if (label) {
        localesList.push({ label, value: locale });
      }
    });

    return localesList;
  }

  // Get the language and country name for the label
  private getLocaleDisplayName(locale: string): string | null {
    try {
      // Use Intl.DisplayNames to get the language and country names
      const languageName = new Intl.DisplayNames([locale], { type: 'language' }).of(locale.split('-')[0]);
      const regionName = new Intl.DisplayNames([locale], { type: 'region' }).of(locale.split('-')[1]);
      return this.capitalizeWords(`${languageName} ${regionName}`); // Format: "Language Country"
    } catch (error) {
      console.error(`Error getting names for locale ${locale}: `, error);
      return null;
    }
  }

  // Helper function to capitalize the currency name
  private capitalizeWords(text: string): string {
    return text
      .split(' ')
      .map((word) => {
        // Capitalize the first letter and keep the rest as it is
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }
}
