import { Injectable, ViewContainerRef, ComponentRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private sidenav: MatSidenav;
  private viewContainerRef: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  constructor() {}

  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  open(component: any) {
    this.sidenav.open();
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
      this.componentRef = this.viewContainerRef.createComponent(component);
    }
  }

  close() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.sidenav.close();
  }
}
