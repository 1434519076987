import { Product } from './product';

export class ProductBreakdown {
  private _storeId: string;
  private _productId: string;
  private _packaging: ProductBreakdownPackage[];

  public get storeId(): string {
    return this._storeId;
  }

  public set storeId(storeId: string) {
    this._storeId = storeId;
  }

  public get productId(): string {
    return this._productId;
  }

  public set productId(productId: string) {
    this._productId = productId;
  }

  public get packaging(): ProductBreakdownPackage[] {
    return this._packaging;
  }

  public set packaging(packaging: ProductBreakdownPackage[]) {
    this._packaging = packaging;
  }

  constructor(init?: Partial<ProductBreakdown>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      store_id: this.storeId,
      packaging: this.packaging?.map((i) => new ProductBreakdownPackage(i).toJSON()),
    };
  }
}

export class ProductBreakdownPackage {
  private _productId: string;
  private _product: Product;
  private _quantity: number;
  private _newStock: number;
  private _lotId: string;

  public get productId(): string {
    return this._productId;
  }

  public set productId(productId: string) {
    this._productId = productId;
  }

  public get product(): Product {
    return this._product;
  }

  public set product(product: Product) {
    this._product = product;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(quantity: number) {
    this._quantity = quantity;
  }

  public get newStock(): number {
    return this._newStock;
  }

  public set newStock(newStock: number) {
    this._newStock = newStock;
  }

  constructor(init?: Partial<ProductBreakdownPackage>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      product_id: this.productId,
      quantity: this.quantity,
    };
  }
}
