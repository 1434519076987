import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { Response } from '../interfaces/response';
import { CustomerFilter } from '../filters/customer.filter';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private readonly index = environment.api + '/v1/customers';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Customer | null>> {
    return this.api.get<Response<Customer | null>>(Customer, this.index + '/' + id);
  }

  all(f: Partial<CustomerFilter> | null = null): Observable<Response<Customer[]>> {
    return this.api.get<Response<Customer[]>>(Customer, this.index + (f ? new CustomerFilter(f)?.getQuery() : ''));
  }

  create(customer: Customer): Observable<Response<Customer>> {
    return this.api.post<Response<Customer>>(Customer, this.index, customer);
  }

  update(customer: Customer): Observable<Response<Customer>> {
    return this.api.put<Response<Customer>>(Customer, this.index + '/' + customer.id, customer);
  }

  delete(customer: Customer): Observable<Response<Customer>> {
    return this.api.delete<Response<Customer>>(Customer, this.index + '/' + customer.id);
  }
}
