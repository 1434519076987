import { Store, User, Business } from '..';

export class SignIn {
  private _user: User;
  private _store: Store;
  private _business: Business;

  public get user(): User {
    return this._user;
  }

  public set user(user: User) {
    this._user = user;
  }

  public get store(): Store {
    return this._store;
  }

  public set store(store: Store) {
    this._store = store;
  }

  public get business(): Business {
    return this._business;
  }

  public set business(value: Business) {
    this._business = value;
  }

  constructor(init?: Partial<SignIn>) {
    Object.assign(this, init);
  }
}
