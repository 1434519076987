import { CategoryEntity } from '../data/entities/category.entity';

export class Category {
  private _id: string;
  private _name: string;
  private _imageUrl: string;
  private _index: string;
  private _type: number;
  private _createdAt: Date;
  private _updatedAt: Date;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get imageUrl(): string {
    return this._imageUrl;
  }

  public set imageUrl(imageUrl: string) {
    this._imageUrl = imageUrl;
  }

  public get index(): string {
    return this._index;
  }

  public set index(index: string) {
    this._index = index;
  }

  public get type(): number {
    return this._type;
  }

  public set type(type: number) {
    this._type = type;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(cretedAt: Date) {
    this._createdAt = cretedAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  constructor(init?: Partial<Category>) {
    Object.assign(this, init);
  }

  entity(): CategoryEntity {
    return {};
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      image_url: this.imageUrl,
      index: this.index,
      type: this.type,
      creted_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
