export class Supplier {
  private _id: string;
  private _name: string;
  private _idNumber: string;
  private _phone: string;
  private _mobile: string;
  private _email: string;
  private _address: string;
  private _note: string;
  private _createdAt: Date;
  private _updatedAt: Date;

  parentId: string;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get idNumber(): string {
    return this._idNumber;
  }

  public set idNumber(idNumber: string) {
    this._idNumber = idNumber;
  }

  public get phone(): string {
    return this._phone;
  }

  public set phone(phone: string) {
    this._phone = phone;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(mobile: string) {
    this._mobile = mobile;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get address(): string {
    return this._address;
  }

  public set address(address: string) {
    this._address = address;
  }

  public get note(): string {
    return this._note;
  }

  public set note(note: string) {
    this._note = note;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  constructor(init?: Partial<Supplier>) {
    Object.assign(this, init);
  }

  public toExport(): any {
    return {
      id: this.id,
      name: this.name,
      id_number: this.idNumber,
      phone: this.phone,
      mobile: this.mobile,
      email: this.email,
      address: this.address,
      note: this.note,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      id_number: this.idNumber,
      phone: this.phone,
      mobile: this.mobile,
      email: this.email,
      address: this.address,
      note: this.note,
      parent_id: this.parentId,
    };
  }
}
