import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { Lot } from '../models/lot';
import { Response } from '../interfaces/response';
import { LotFilter } from '../filters/lot-filter';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LotService {
  private readonly index = environment.api + '/v1/lots';
  private readonly exportResource = environment.import + '/v1/lots/export';
  private readonly importResource = environment.import + '/v1/lots/import';

  constructor(protected api: ApiService, private http: HttpClient) {}

  get(id: string): Observable<Response<Lot | null>> {
    return this.api.get<Response<Lot | null>>(Lot, this.index + '/' + id);
  }

  all(p: LotFilter | null = null): Observable<Response<Lot[]>> {
    return this.api.get<Response<Lot[]>>(Lot, this.index + (p ? p!.getQuery() : ''));
  }

  create(lot: Lot): Observable<Response<Lot>> {
    return this.api.post<Response<Lot>>(Lot, this.index, lot);
  }

  update(lot: Lot): Observable<Response<Lot>> {
    return this.api.put<Response<Lot>>(Lot, this.index + '/' + lot.id, lot);
  }

  delete(lot: Lot): Observable<Response<Lot>> {
    return this.api.delete<Response<Lot>>(Lot, this.index + '/' + lot.id);
  }

  adjustStockByIds(lotIds: string[]): Observable<Response<Lot[]>> {
    return this.api.post<Response<Lot[]>>(Lot, `${this.index}/adjust_stock`, lotIds);
  }

  import(storeId: string, file: File): Observable<Response<Lot | null>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const url = `${this.importResource}?store_id=${storeId}`;
    return this.http.post<Response<Lot | null>>(url, formData);
  }

  export(storeId: string): Observable<ArrayBuffer> {
    const url = `${this.exportResource}?store_id=${storeId}`;
    return this.http
      .post(url, null, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }
}
