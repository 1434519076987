import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Response } from '../interfaces/response';
import { SignIn } from '../models/sign-in';
import { AuthData } from '../models/auth_data';
import { UserConfig } from '../services/user-config.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  private readonly dataURL = environment.api + '/v1/auth/data';
  private readonly singInURL = environment.api + '/v1/auth/sign_in';

  constructor(protected api: ApiService, private config: UserConfig) {}

  data(): Observable<Response<AuthData>> {
    const businessId = this.config.businessIdPreference;
    const query = businessId ? `?business_id=${businessId}` : '';

    return this.api.post<Response<AuthData>>(AuthData, `${this.dataURL}${query}`, null);
  }

  signIn(token: string): Observable<Response<SignIn>> {
    return this.api.post<Response<SignIn>>(SignIn, this.singInURL, { access_token: token });
  }
}
