import { GiftCard } from './gift-card';

export class GiftCardGroup {
  private _id: string;
  private _name: string;
  private _mobile: string;
  private _email: string;
  private _idNumber: string;
  private _quantity: number;
  private _amount?: number;
  private _note: string;
  private _expiresAt?: Date;
  private _notifiedAt: Date;
  private _giftCards: GiftCard[];
  private _createdAt: Date;
  private _updatedAt: Date;
  private _deletedAt: Date;

  // UI Helpers
  isLoading: boolean = false;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(mobile: string) {
    this._mobile = mobile;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get idNumber(): string {
    return this._idNumber;
  }

  public set idNumber(number: string) {
    this._idNumber = number;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(value: number) {
    this._quantity = value;
  }

  public get amount(): number | undefined {
    return this._amount;
  }

  public set amount(balance: number | undefined) {
    this._amount = balance;
  }

  public get note(): string {
    return this._note;
  }

  public set note(note: string) {
    this._note = note;
  }

  public get giftCards(): GiftCard[] {
    return this._giftCards;
  }

  public set giftCards(giftCards: GiftCard[]) {
    this._giftCards = giftCards;
  }

  public get expiresAt(): Date | undefined {
    return this._expiresAt;
  }

  public set expiresAt(expiresAt: Date | undefined) {
    this._expiresAt = expiresAt;
  }

  public get notifiedAt(): Date {
    return this._notifiedAt;
  }

  public set notifiedAt(value: Date) {
    this._notifiedAt = value;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  public get deletedAt(): Date {
    return this._deletedAt;
  }

  public set deletedAt(deletedAt: Date) {
    this._deletedAt = deletedAt;
  }

  constructor(init?: Partial<GiftCardGroup>) {
    Object.assign(this, init);
    this.giftCards = init?.giftCards?.map((i) => new GiftCard(i)) ?? [];
  }

  public toJSON(): any {
    return {
      id_number: this.idNumber,
      name: this.name,
      mobile: this.mobile,
      email: this.email,
      amount: this.amount,
      quantity: this.quantity,
      expires_at: this.expiresAt,
      note: this.note,
    };
  }
}
