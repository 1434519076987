import { Business, User, Store } from '..';

export class AuthData {
  userHash: string;
  business: Business;
  stores: Store[];
  user: User;

  constructor(init?: Partial<AuthData>) {
    Object.assign(this, init);
    this.business = new Business(init?.business);
    this.stores = init?.stores?.map((store) => new Store(store)) ?? [];
    this.user = new User(init?.user);
  }
}
