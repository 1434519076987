import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Response } from '../interfaces/response';
import { ReportFilter } from '../filters/report.filter';
import { BestSellerReport, SearchTermReport } from '../models/report';
import { ReportResponse } from '../interfaces/report-response';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly index = environment.api + '/v1/reports';

  constructor(protected api: ApiService) {}

  dashboard(filter: Partial<ReportFilter>): Observable<Response<ReportResponse>> {
    return this.api.get<Response<ReportResponse>>(
      ReportResponse,
      `${this.index}/dashboard${filter ? new ReportFilter(filter)?.getQuery() : ''}`,
      30
    );
  }

  bestSellers(p: ReportFilter | null = null): Observable<Response<BestSellerReport[]>> {
    return this.api.get<Response<BestSellerReport[]>>(
      BestSellerReport,
      this.index + '/best_seller_products' + (p ? p!.getQuery() : '')
    );
  }

  searchTerms(p: ReportFilter | null = null): Observable<Response<SearchTermReport[]>> {
    return this.api.get<Response<SearchTermReport[]>>(
      SearchTermReport,
      this.index + '/search_terms' + (p ? p!.getQuery() : '')
    );
  }
}
