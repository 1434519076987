export class Chat {
  id: string;
  name: string;
  photo: string;
  seen: boolean;
  unread: number;
  m_id: string;
  m_text: string;
  m_type: string;
  m_uid: string;
  private _ts: Date;

  date: Date;

  public get ts(): any {
    return this._ts;
  }

  public set ts(value: any) {
    this._ts = value;
    if (value && typeof value.toDate === 'function') {
      this.date = value.toDate();
    }
  }

  constructor(init?: Partial<Chat>) {
    Object.assign(this, init);
  }
}
