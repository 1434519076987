export enum DeliveryType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
  FAST_DELIVERY = 'fast_delivery',
  SCHEDULED_DELIVERY = 'scheduled_delivery',
}

export const DELIVERY_TYPES = [
  DeliveryType.PICKUP,
  DeliveryType.DELIVERY,
  DeliveryType.FAST_DELIVERY,
  DeliveryType.SCHEDULED_DELIVERY,
];
