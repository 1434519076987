import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private languages = [
    { label: 'Español', value: 'es' },
    { label: 'Inglés', value: 'en' },
    { label: 'Portugués', value: 'pt' },
  ];

  private countries = [
    { label: 'Argentina', value: 'AR' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Brasil', value: 'BR' },
    { label: 'Chile', value: 'CL' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'República Dominicana', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Honduras', value: 'HN' },
    { label: 'México', value: 'MX' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Panamá', value: 'PA' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Perú', value: 'PE' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Venezuela', value: 'VE' },
  ];

  constructor() {}

  getLanguages(): Array<{ label: string; value: string }> {
    return this.languages;
  }

  getCountries(): Array<{ label: string; value: string }> {
    return this.countries;
  }
}
