import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '.header',
})
export class PageHeaderDirective {
  constructor(public elementRef: ElementRef) {}

  // @HostBinding('class')
  // elementClass = 'transition-in';
}
