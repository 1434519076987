import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { Response } from '../interfaces/response';
import { UserFilter } from '../filters/user.filter';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly index = environment.api + '/v1/users';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<User | null>> {
    return this.api.get<Response<User | null>>(User, this.index + '/' + id);
  }

  all(p: UserFilter | null = null): Observable<Response<User[]>> {
    let q = '';
    q += p?.query ? '&q=' + p.query : '';
    q += p?.start ? '&start=' + p.start : '';
    q += p?.limit ? '&limit=' + p.limit : '';
    q = q ? '?' + q.substring(1) : '';
    return this.api.get<Response<User[]>>(User, this.index + q);
  }

  create(model: User): Observable<Response<User>> {
    return this.api.post<Response<User>>(User, this.index, model);
  }

  update(model: User): Observable<Response<User>> {
    return this.api.put<Response<User>>(User, this.index + '/' + model.id, model);
  }

  delete(model: User): Observable<Response<User>> {
    return this.api.delete<Response<User>>(User, this.index + '/' + model.id);
  }
}
