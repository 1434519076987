import { SerieType } from '../enums/serie-type';

export enum SerieEntity {
  STORE = 'store',
  REGISTER = 'register',
}

export class Serie {
  id: string;
  storeId: string;
  name: string;
  entity: SerieEntity;
  entityId: string;
  type: SerieType;
  serie: string;
  number: number;
  description: number;
  externalUse: boolean;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  constructor(init?: Partial<Serie>) {
    Object.assign(this, init);
  }

  displayName() {
    switch (this.type) {
      case SerieType.SALE_NOTE:
        return `Nota de Venta`;
      case SerieType.FACTURA:
        return `Factura`;
      case SerieType.BOLETA:
        return `Boleta`;
      default:
        return 'No definido';
    }
  }

  displayNumber() {
    switch (this.type) {
      case SerieType.SALE_NOTE:
        return `NV-${this.serie}`;
      case SerieType.FACTURA:
        return `FAC-${this.serie}`;
      case SerieType.BOLETA:
        return `BOL-${this.serie}`;
      default:
        return this.serie;
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      name: this.name,
      entity: this.entity,
      entity_id: this.entityId,
      type: this.type,
      number: this.serie,
      sequence_number: this.number,
      description: this.description,
      external_use: this.externalUse,
      is_active: this.isActive,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      deleted_at: this.deletedAt,
    };
  }
}
