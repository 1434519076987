import { TaxType } from '../enums/tax-type';
import { TaxLite } from './tax-lite';

export class Tax {
  id: string;
  name: string;
  type: TaxType;
  rate: number;
  code: string;
  isTaxed: boolean;
  createdAt: Date;
  updatedAt: Date;

  // public get isTaxed(): boolean {
  //   return this._type === TaxType.TAXED;
  // }

  // get full name: ex: Gravado (18%)
  get fullName(): string {
    return `${this.name} (${this.rate}%)`;
  }

  valueFormatted(): string {
    return `${this.rate}%`;
  }

  toLite(): TaxLite {
    return new TaxLite({
      id: this.id,
      name: this.name,
      type: this.type,
      rate: this.rate,
      code: this.code,
    });
  }

  constructor(init?: Partial<Tax>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      rate: this.rate,
      code: this.code,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
