import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AuthService } from './auth.service';
import { finalize } from 'rxjs/operators';
import Utils from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private storage: AngularFireStorage, private auth: AuthService) {}

  download(url: string) {
    const headers = {
      responseType: 'blob',
    };

    return this.http.get(url, { headers });
  }

  uploadImage(file: File): Promise<any> {
    return new Promise((resolutionFunc: Function, rejectionFunc) => {
      resolutionFunc();
    });
  }

  private getStorageFilePath(file: File): string {
    const ext = file.name.split('.').pop();
    const name = Utils.random(36);

    return `messaging/files/${new Date().getFullYear()}/${name}.${ext}`;
  }

  uploadAudioToFirebase(file: File): Promise<any> {
    return new Promise((resolutionFunc, rejectionFunc) => {
      if (!file) {
        resolutionFunc(null);
      }

      const filePath = this.getStorageFilePath(file);
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(
              (url) => {
                resolutionFunc(url);
              },
              (err) => {
                resolutionFunc(null);
              }
            );
          })
        )
        .subscribe();
    });
  }

  uploadToFirebase(file: File): Promise<any> {
    return new Promise((resolutionFunc, rejectionFunc) => {
      if (!file) {
        resolutionFunc(null);
      }

      const filePath = this.getStorageFilePath(file);
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(
              (url) => {
                resolutionFunc(url);
              },
              (err) => {
                resolutionFunc(null);
              }
            );
          })
        )
        .subscribe();
    });
  }
}
