export class ItemFilter {
  query?: string | null;
  hasImage?: boolean | null;
  barcode?: string | null;
  start?: number | null;
  limit?: number | null;

  // helper
  currentPage = 1;
  itemsPerPage = 20;

  constructor(init?: Partial<ItemFilter>) {
    Object.assign(this, init);
  }

  public get params(): string {
    let start = this.start;
    let limit = this.limit;

    if (start == undefined && limit == undefined) {
      start = (this.currentPage - 1) * this.itemsPerPage;
      limit = this.itemsPerPage;
    }

    let q = '';
    q += this.query ? '&search=' + this.query : '';
    q += this.barcode ? '&barcode=' + this.barcode : '';
    q += this.hasImage != null ? '&has_image=' + this.hasImage : '';
    q += start ? '&start=' + start : '';
    q += limit ? '&limit=' + limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
