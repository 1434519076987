<div class="blank-layout-container transition-in justify-content-center align-items-center">
  <div class="position-relative row w-100 h-100 _bg-gredient justify-content-center">
    <div class="col-lg-4 d-flex align-items-center">
      <mat-card class="p-24 w-100 cardWithShadow">
        <mat-card-content class="p-24">
          <div class="sp__1"></div>

          <div class="text-center">
            <a [routerLink]="['/dashboards/dashboard1']" *ngIf="options.theme === 'light'">
              <img src="./assets/images/logos/logo.svg" class="img-logo align-middle m-2" alt="logo" />
            </a>
            <a [routerLink]="['/dashboards/dashboard1']" *ngIf="options.theme === 'dark'">
              <img src="./assets/images/logos/logo.svg" class="img-logo align-middle m-2" alt="logo" />
            </a>
          </div>

          <div class="sp__1"></div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%">
            <h1 style="font-size: 24px">Registra tu negocio</h1>
            <p style="text-align: center">Ingresa los datos de tu negocio para iniciar.</p>
          </div>

          <div class="sp__2"></div>

          <div class="row">
            <div class="col">
              <form [formGroup]="formGroup">
                <!-- name -->
                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block">Nombre del negocio</mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary">
                  <input matInput type="text" formControlName="name" />
                </mat-form-field>

                <!-- id_number -->
                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block">Número de documento (RUC)</mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary">
                  <input matInput type="text" formControlName="idNumber" />
                  <mat-hint
                    *ngIf="formGroup.get('idNumber')!.invalid && formGroup.get('idNumber')!.touched"
                    class="m-b-16 error-msg"
                  >
                    <div class="text-error">{{formGroup.get('idNumber')!.errors?.['invalidRUC'].message}}</div>
                  </mat-hint>
                </mat-form-field>

                <!-- category -->
                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block">Categoría</mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select formControlName="category">
                    <mat-option *ngFor="let item of categories" [value]="item.id">{{ item.name }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="sp__1"></div>
                <div class="d-flex align-items-center">
                  <div class="fill-remaining-space">
                    <button
                      class="w-100"
                      mat-flat-button
                      style="min-height: 45px"
                      color="primary"
                      [disabled]="formGroup.invalid"
                      (click)="onSubmit()"
                    >
                      Registrar <mat-icon iconPositionEnd>arrow_forward</mat-icon>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="sp__3"></div>

          <div class="row">
            <div class="d-flex w-100 align-items-center justify-content-center">
              <user-profile-picture></user-profile-picture>
              <a mat-button (click)="logout()" class="m-l-8">Salir</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<!-- <mat-accordion>
            <mat-expansion-panel
              [expanded]="currentStep === 1"
              class="mat-expansion-panel mat-elevation-z0 document-item _cardWithShadow"
            >
              <mat-expansion-panel-header class="mat-expansion-panel-header">
                Paso 1: Información del Negocio
              </mat-expansion-panel-header>
              Hello step 1
              <div>
                <button mat-stroked-button (click)="nextStep()">Siguiente</button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel
              [expanded]="currentStep === 2"
              class="mat-expansion-panel mat-elevation-z0 document-item _cardWithShadow"
            >
              <mat-expansion-panel-header class="mat-expansion-panel-header">
                Paso 2: Información de Contacto
              </mat-expansion-panel-header>
              Hello step 2
              <div>
                <button mat-stroked-button (click)="prevStep()">Anterior</button>
                <button mat-stroked-button (click)="nextStep()">Siguiente</button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel
              [expanded]="currentStep === 3"
              class="mat-expansion-panel mat-elevation-z0 document-item _cardWithShadow"
            >
              <mat-expansion-panel-header class="mat-expansion-panel-header">
                Paso 3: Configuración de Cuenta
              </mat-expansion-panel-header>
              Hello step 3
              <div>
                <button mat-stroked-button (click)="prevStep()">Anterior</button>
                <button mat-stroked-button (click)="onSubmit()">Enviar</button>
              </div>
            </mat-expansion-panel>
          </mat-accordion> -->

<dl-loader [visible]="isLoading"></dl-loader>
