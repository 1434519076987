export enum AppEvent {
  MANUAL_ADJUSTMENT = 'manual_adjustment',
  PACKAGE_BREAKDOWN = 'package_breakdown',
  RETURN = 'return',
  SALE = 'sale',
  STOCK_ADJUSTMENT = 'stock_adjustment',
  STOCKTAKE = 'stocktake',
  SUPPLIER_ORDER = 'supplier_order',
  SUPPLIER_RETURN = 'supplier_return',
  TRANSFER = 'transfer',
}

export const EVENTS = [
  { id: AppEvent.MANUAL_ADJUSTMENT, name: 'Ajuste manual' },
  { id: AppEvent.PACKAGE_BREAKDOWN, name: 'Desglose de paquete' },
  { id: AppEvent.RETURN, name: 'Retorno' },
  { id: AppEvent.SALE, name: 'Venta' },
  { id: AppEvent.STOCK_ADJUSTMENT, name: 'Ajuste de stock' },
  { id: AppEvent.STOCKTAKE, name: 'Conteo de inventario' },
  { id: AppEvent.SUPPLIER_ORDER, name: 'Orden de proveedor' },
  { id: AppEvent.SUPPLIER_RETURN, name: 'Retorno de proveedor' },
  { id: AppEvent.TRANSFER, name: 'Transferencia' },
];
