export class Subscription {
  id: string;
  productId: string;
  variantId: string;
  status: string;
  cardBrand: string;
  cardLastFour: string;
  cancelled: boolean;
  trialEndsAt: Date;
  billingAnchor: number;
  urls: Urls;
  renewsAt: Date;
  endsAt: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(init?: Partial<Subscription>) {
    Object.assign(this, init);
    this.urls = init?.urls ? new Urls(init?.urls) : undefined!;
  }
}

class Urls {
  updatePaymentMethod: string;
  customerPortal: string;
  customerPortalUpdateSubscription: string; // TODO: Unused

  constructor(init?: Partial<Urls>) {
    Object.assign(this, init);
  }
}
