export class BestSellerReport {
  productId: string;
  sku: string;
  name: string;
  barcode: string;
  image: string;
  totalRevenue: number;
  totalDiscount: number;
  totalQuantity: number;
  totalCount: number;
  lastSaleDate: Date;

  constructor(init?: Partial<BestSellerReport>) {
    Object.assign(this, init);
  }
}

export class SearchTermReport {
  term: string;
  score: number;
  searchCount: number;
  resultsCount: number;

  constructor(init?: Partial<SearchTermReport>) {
    Object.assign(this, init);
  }
}
