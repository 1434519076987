import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable, from, map, of, tap } from 'rxjs';
import { Store } from '../models/store';
import { Response } from '../interfaces/response';
import { StoreFilter } from '../interfaces/store-filter';
import { liveQuery } from 'dexie';
import { AppDatabase } from '../data/app-database';
import { StoreEntity } from '../data/entities/store.entity';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly index = environment.api + '/v1/stores';

  locals$ = from(liveQuery(() => this.database.locals.toArray())).pipe(
    map((items) => items.map((i) => new StoreEntity(i).model()))
  );

  constructor(protected api: ApiService, private database: AppDatabase) {}

  /**
   * Sync data from server to local database
   */
  sync(): void {
    this.api.get<Response<Store[]>>(Store, this.index).subscribe((response) => {
      if (response.ok) {
        const entities = response.data.map((i) => i.entity());
        this.database.locals.clear();
        this.database.locals.bulkPut(entities);
      }
    });
  }

  get(id: string): Observable<Response<Store | null>> {
    return this.api.get<Response<Store | null>>(Store, this.index + '/' + id).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.locals.put(response.data!.entity());
        }
      })
    );
  }

  all(p: StoreFilter | null = null): Observable<Response<Store[]>> {
    let q = '';
    q += p?.query ? '&q=' + p.query : '';
    q += p?.start ? '&start=' + p.start : '';
    q += p?.limit ? '&limit=' + p.limit : '';
    q = q ? '?' + q.substring(1) : '';
    return this.api.get<Response<Store[]>>(Store, this.index + q).pipe(
      tap((response) => {
        if (response.ok) {
          const entities = response.data.map((i) => i.entity());
          this.database.locals.clear();
          this.database.locals.bulkPut(entities);
        }
      })
    );
  }

  create(model: Store): Observable<Response<Store>> {
    return this.api.post<Response<Store>>(Store, this.index, model).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.locals.put(response.data.entity());
        }
      })
    );
  }

  update(model: Store): Observable<Response<Store>> {
    return this.api.put<Response<Store>>(Store, this.index + '/' + model.id, model).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.locals.put(response.data.entity());
        }
      })
    );
  }

  delete(model: Store): Observable<Response<Store>> {
    return this.api.delete<Response<Store>>(Store, this.index + '/' + model.id).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.locals.delete(model.id);
        }
      })
    );
  }
}
