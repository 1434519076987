export class DataImport {
  id: string;
  ref_id: string;
  sku: string;
  location: string;
  name: string;
  description: string;
  image_url: string;
  keywords: string;
  price: string;
  quantity: number;
  unit_type: string;
  presentation: string;
  sale_type: string;
  min_quantity: number;
  step_quantity: number;
  max_quantity: number;
  has_stock: number;
  in_stock: number;
  safety_stock: number;
  reorder_point: number;
  reorder_quantity: number;
  reorder_unit_type: string;
  category_name: string;
  supplier_name: string;
  brand_name: string;
  is_available: number;
  is_active: number;

  constructor(init?: Partial<DataImport>) {
    Object.assign(this, init);
  }

  generateRefId(value: any): string | undefined {
    if (typeof value === 'number' || value instanceof Number) {
      return value + '';
    }

    return value;
  }

  generateSku(): string | undefined {
    const value: any = this.sku;

    if (!value) {
      // console.log('new sku', this.ref_id);
      // return 'ns:' + this.ref_id ?? this.id;
      return undefined;
    }

    if (typeof value === 'number' || value instanceof Number) {
      return value + '';
    }

    return value;
  }

  getBoolean(value: any): boolean | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (value === 0) {
      return false;
    }
    return true;
  }

  getCurrency(value: any | undefined): number | undefined {
    if (!value) {
      return 0;
    }

    if (typeof value === 'string' || value instanceof String) {
      return Number(value?.replace('S/ ', '') ?? 0);
    }

    return value;
  }

  getCategoryName(value: string): string {
    if (value) {
      return value;
    } else {
      return 'Otros';
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      ref_id: this.generateRefId(this.ref_id),
      sku: this.generateSku(),
      location: this.location,
      name: this.name,
      description: this.description,
      image_url: this.image_url,
      keywords: this.keywords,
      price: this.getCurrency(this.price),
      quantity: this.quantity,
      unit_type: this.unit_type,
      presentation: this.presentation,
      sale_type: this.sale_type,
      min_quantity: this.min_quantity,
      step_quantity: this.step_quantity,
      max_quantity: this.max_quantity,
      has_stock: this.getBoolean(this.has_stock),
      in_stock: this.in_stock,
      safety_stock: this.safety_stock,
      reorder_point: this.reorder_point,
      reorder_quantity: this.reorder_quantity,
      reorder_unit_type: this.reorder_unit_type,
      category_name: this.getCategoryName(this.category_name) + '',
      supplier_name: this.supplier_name ? this.supplier_name + '' : undefined,
      brand_name: this.brand_name ? this.brand_name + '' : undefined,
      is_available: this.getBoolean(this.is_available),
      is_active: this.getBoolean(this.is_active),
    };
  }
}
