export class Brand {
  private _id: string;
  private _name: string;
  private _imageUrl: string;
  private _createdAt: Date;
  private _updatedAt: Date;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get imageUrl(): string {
    return this._imageUrl;
  }

  public set imageUrl(imageUrl: string) {
    this._imageUrl = imageUrl;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(cretedAt: Date) {
    this._createdAt = cretedAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  constructor(init?: Partial<Brand>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      image_url: this.imageUrl,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
