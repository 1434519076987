import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  constructor() {}

  // Obtener lista de timezones formateados y ordenados
  getTimezones(): { label: string; value: string }[] {
    const timezones = moment.tz.names(); // Obtener nombres de zonas horarias

    return timezones
      .map((timezone) => {
        const offset = moment.tz(timezone).utcOffset() / 60; // Obtener el offset en horas
        const formattedOffset = this.formatOffset(offset);

        return {
          label: `(GMT${formattedOffset}) ${timezone}`,
          value: timezone,
          offset: offset, // Agregar el offset a los datos para poder ordenar
        };
      })
      .sort((a, b) => a.offset - b.offset) // Ordenar por el offset (de negativo a positivo)
      .map(({ label, value }) => ({ label, value })); // Retornar solo el nombre y label (sin el offset)
  }

  // Formatear el offset en formato ±XX:XX
  private formatOffset(offset: number): string {
    const sign = offset >= 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(offset)).toString().padStart(2, '0');
    const minutes = Math.abs((offset % 1) * 60)
      .toString()
      .padStart(2, '0');
    return `${sign}${hours}:${minutes}`;
  }
}
