import { Injectable } from '@angular/core';
import { Business } from '../models/business';
import { User } from '../models/user';
import { Store } from '../models/store';

export enum PreferenceVars {
  SYNC_PRODUCT_VERSION = 'SYNC_PRODUCT_VERSION',
  LAST_EXPIRES_AT = 'LAST_EXPIRES_AT',
  LAST_GIFT_CARD_AMOUNT = 'LAST_GIFT_CARD_AMOUNT',
  USER_PREF = 'user.preferences',
}

const DEFAULT_BUSINESS_ID = 'default_business_id';
const DEFAULT_LOCAL_ID = 'default_local_id';
const DEFAULT_REGISTER_ID = 'default_register_id';
const DEFAULT_USER_ID = 'default_user_id';

const USER_PREF = 'user';
const STORE_PREF = 'store';
const BUSINESS_PREF = 'business';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public get businessIdPreference(): string {
    return this.getString(DEFAULT_BUSINESS_ID) ?? '';
  }

  public set businessIdPreference(value: string) {
    this.set(DEFAULT_BUSINESS_ID, value);
  }

  public get localIdPreference(): string {
    return this.getString(DEFAULT_LOCAL_ID) ?? '';
  }

  public set localIdPreference(value: string) {
    this.set(DEFAULT_LOCAL_ID, value);
  }

  public get registerIdPreference(): string {
    return this.getString(DEFAULT_REGISTER_ID) ?? '';
  }

  public set registerIdPreference(value: string | null) {
    this.set(DEFAULT_REGISTER_ID, value);
  }

  public get userIdPreference(): string {
    return this.getString(DEFAULT_USER_ID) ?? '';
  }

  public set userIdPreference(value: string) {
    this.set(DEFAULT_USER_ID, value);
  }

  getString(key: string): string | undefined {
    return localStorage.getItem(key) ?? undefined;
  }

  getNumber(key: string): number | undefined {
    const v = localStorage.getItem(key);
    return v ? +v : undefined;
  }

  getDate(key: string): Date | undefined {
    const time = localStorage.getItem(key);
    return time ? new Date(+time) : undefined;
  }

  setDate(key: string, value: Date) {
    localStorage.setItem(key, value.getTime().toString());
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  set(key: string, value: any) {
    return localStorage.setItem(key, value);
  }

  setUser(user: User): void {
    localStorage.setItem(USER_PREF, JSON.stringify(user.toLocalStorage()));
  }

  setStore(store: Store): void {
    localStorage.setItem(STORE_PREF, JSON.stringify(store.toLocalStorage()));
  }

  setBusiness(business: Business): void {
    localStorage.setItem(BUSINESS_PREF, JSON.stringify(business.toLocalStorage()));
  }

  getUser(): User | null {
    const value = localStorage.getItem(USER_PREF);
    if (!value) {
      return null;
    }

    try {
      const user = new User(JSON.parse(value));
      if (user.id) {
        return user;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  getStore(): Store | null {
    const value = localStorage.getItem(STORE_PREF);
    if (!value) {
      return null;
    }

    try {
      const store = new Store(JSON.parse(value));
      if (store.id) {
        return store;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  getBusiness(): Business | null {
    const value = localStorage.getItem(BUSINESS_PREF);
    if (!value) {
      return null;
    }

    try {
      const business = new Business(JSON.parse(value));
      if (business.id) {
        return business;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  cleanData(): void {
    localStorage.removeItem(PreferenceVars.SYNC_PRODUCT_VERSION);
    localStorage.removeItem(USER_PREF);
    localStorage.removeItem(STORE_PREF);
    localStorage.removeItem(BUSINESS_PREF);
    localStorage.removeItem(DEFAULT_BUSINESS_ID);
    localStorage.removeItem(DEFAULT_LOCAL_ID);
    localStorage.removeItem(DEFAULT_REGISTER_ID);
    localStorage.removeItem(DEFAULT_USER_ID);
    localStorage.removeItem(PreferenceVars.USER_PREF);
  }
}
