import { BusinessEntity } from '../data/entities/business.entity';
import { TaxLite } from './tax-lite';
import { Subscription } from './subscription';

export class Business {
  id: string;
  name: string;
  idNumber: string;
  denomination: string;
  logoURL: string;
  category: string;
  logoUrl: string;
  receiptLogoUrl: string;
  mobile: string;
  phone: string;
  email: string;
  address: string;
  invoicingEnabled: boolean;
  tax: TaxLite;
  isTaxInclusive: boolean;
  isVerified: boolean;
  countryCode: string;
  currencyCode: string;
  locale: string;
  timeZone: string;
  subscription: Subscription | null = null;
  updatedAt: Date;

  constructor(init?: Partial<Business>) {
    Object.assign(this, init);
    this.tax = init?.tax ? new TaxLite(init?.tax) : undefined!;
    this.subscription = init?.subscription ? new Subscription(init?.subscription) : undefined!;
  }

  public entity(): BusinessEntity {
    return new BusinessEntity({
      id: this.id,
      name: this.name,
      logoURL: this.logoURL,
      receiptLogoUrl: this.receiptLogoUrl,
      denomination: this.denomination,
      idNumber: this.idNumber,
      phone: this.phone,
      mobile: this.mobile,
      email: this.email,
      address: this.address,
      isTaxInclusive: this.isTaxInclusive,
      countryCode: this.countryCode,
      currencyCode: this.currencyCode,
      locale: this.locale,
      timeZone: this.timeZone,
      updatedAt: this.updatedAt,
    });
  }

  public toLocalStorage(): any {
    return {
      id: this.id,
      name: this.name,
      idNumber: this.idNumber,
      denomination: this.denomination,
      category: this.category,
      logoURL: this.logoUrl,
      receiptLogoUrl: this.receiptLogoUrl,
      mobile: this.mobile,
      phone: this.phone,
      email: this.email,
      address: this.address,
      tax: this.tax,
      isVerified: this.isVerified,
      isTaxInclusive: this.isTaxInclusive,
      countryCode: this.countryCode,
      currencyCode: this.currencyCode,
      locale: this.locale,
      timeZone: this.timeZone,
      updatedAt: this.updatedAt,
    };
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      id_number: this.idNumber,
      denomination: this.denomination,
      category: this.category,
      logo_url: this.logoUrl,
      receipt_logo_url: this.receiptLogoUrl,
      mobile: this.mobile,
      phone: this.phone,
      email: this.email,
      address: this.address,
      is_tax_inclusive: this.isTaxInclusive,
      country_code: this.countryCode,
      currency_code: this.currencyCode,
      locale: this.locale,
      time_zone: this.timeZone,
      tax_id: this.tax?.id,
    };
  }
}
