import { PaymentType } from '@app/core/enums/payment-type';
import { RoundingMode } from '@app/core/enums/rounding-mode';
import { PaymentMethod } from '@app/core/models/payment-method';

export class PaymentMethodEntity {
  id: string;
  name: string;
  description: string;
  type: PaymentType;
  supported: string[];
  rounding: number;
  roundingMode: RoundingMode;
  link: string;
  storeIds: string[];
  isInternal: boolean;
  isEnabled: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(init?: Partial<PaymentMethodEntity>) {
    Object.assign(this, init);
  }

  public model(): PaymentMethod {
    return new PaymentMethod({
      id: this.id,
      name: this.name,
      description: this.description,
      type: this.type,
      supported: this.supported,
      rounding: this.rounding,
      roundingMode: this.roundingMode,
      link: this.link,
      storeIds: this.storeIds,
      isInternal: this.isInternal,
      isEnabled: this.isEnabled,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    });
  }
}
