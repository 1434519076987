import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Response } from '../interfaces/response';
import { ProductGeneratedRequest } from './requests/product-generated.request';

@Injectable({
  providedIn: 'root',
})
export class AssistantService {
  private readonly index = environment.aiServicesURL + '/v1';

  constructor(protected api: ApiService) {}

  generateProduct(storeId: string, name: string): Observable<Response<ProductGeneratedRequest | null>> {
    return this.api.get<Response<ProductGeneratedRequest | null>>(
      ProductGeneratedRequest,
      `${this.index}/suggestions?type=product&query=${name}&store_id=${storeId}`
    );
  }
}
