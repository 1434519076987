export class LetterSoup {
  private _id: string;
  private _name: string;
  private _description: string;
  private _words: string;
  private _isEnabled: boolean;
  private _createdAt: Date;

  // UI
  loading: boolean = false;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get words(): string {
    return this._words;
  }

  public set words(words: string) {
    this._words = words;
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public set isEnabled(value: boolean) {
    this._isEnabled = value;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  constructor(init?: Partial<LetterSoup>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      words: this.words,
      is_enabled: this.isEnabled,
      created_at: this.createdAt,
    };
  }
}

export const itemsLetterSoup: LetterSoup[] = [
  new LetterSoup({
    id: '0',
    name: 'First words',
    createdAt: new Date(),
    isEnabled: true,
    words: 'abeja,canguro,cocodrilo,cuervo,delfin,gato,iguala,leon,mono,pantera,perico,perro,raton,tigre,zebra',
  }),
];
