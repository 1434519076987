import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Supplier } from '../models/supplier';
import { Response } from '../interfaces/response';
import { SupplierFilter } from '../interfaces/supplier-filter';
import { ProductSupplierRequest } from '../models/product_supplier';
import { OrderBy, SortBy } from '../enums/sorting';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  private readonly index = environment.api + '/v1/suppliers';

  constructor(protected api: ApiService) {}

  /**
   * Products
   */

  addProduct(supplierId: string, body: ProductSupplierRequest[]): Observable<Response<Supplier>> {
    return this.api.post<Response<Supplier>>(Supplier, `${this.index}/${supplierId}/products`, body);
  }

  updateProduct(supplierId: string, productId: string, model: ProductSupplierRequest): Observable<Response<Supplier>> {
    return this.api.put<Response<Supplier>>(Supplier, `${this.index}/${supplierId}/products/${productId}`, model);
  }

  removeProducts(supplierId: string, productIds: string[]): Observable<Response<Supplier>> {
    return this.api.delete<Response<Supplier>>(
      Supplier,
      `${this.index}/${supplierId}/products?product_ids=${productIds.join(',')}`
    );
  }

  products(storeId: string, supplierId: string): Observable<Response<ProductSupplierRequest[]>> {
    return this.api.get<Response<ProductSupplierRequest[]>>(
      ProductSupplierRequest,
      `${this.index}/${supplierId}/products?store_id=${storeId}&start=0&limit=1000`
    );
  }

  /**
   * Suppliers
   */

  get(id: string): Observable<Response<Supplier | null>> {
    return this.api.get<Response<Supplier | null>>(Supplier, this.index + '/' + id);
  }

  all(p: SupplierFilter | null = null): Observable<Response<Supplier[]>> {
    let filter = p;
    if (!filter) {
      filter = new SupplierFilter({ sort: SortBy.NAME, order: OrderBy.ASC, limit: 500 });
    }
    return this.api.get<Response<Supplier[]>>(Supplier, this.index + (filter?.getQuery() ?? ''));
  }

  create(model: Supplier): Observable<Response<Supplier>> {
    return this.api.post<Response<Supplier>>(Supplier, this.index, model);
  }

  update(model: Supplier): Observable<Response<Supplier>> {
    return this.api.put<Response<Supplier>>(Supplier, this.index + '/' + model.id, model);
  }

  delete(model: Supplier): Observable<Response<Supplier>> {
    return this.api.delete<Response<Supplier>>(Supplier, this.index + '/' + model.id);
  }
}
