import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { PriceBook } from '../models/price-book';
import { Response } from '../interfaces/response';
import { PriceBookFilter } from '../filters/price-book.filter';

@Injectable({
  providedIn: 'root',
})
export class PriceBookService {
  private readonly index = environment.api + '/v1/price_books';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<PriceBook | null>> {
    return this.api.get<Response<PriceBook | null>>(PriceBook, this.index + '/' + id);
  }

  all(p: Partial<PriceBookFilter> | null = null): Observable<Response<PriceBook[]>> {
    return this.api.get<Response<PriceBook[]>>(
      PriceBook,
      this.index + (p ? '?' + new PriceBookFilter(p).getQuery() : '')
    );
  }

  create(priceBook: PriceBook): Observable<Response<PriceBook>> {
    return this.api.post<Response<PriceBook>>(PriceBook, this.index, priceBook);
  }

  update(priceBook: PriceBook): Observable<Response<PriceBook>> {
    return this.api.put<Response<PriceBook>>(PriceBook, this.index + '/' + priceBook.id, priceBook);
  }

  delete(priceBook: PriceBook): Observable<Response<PriceBook>> {
    return this.api.delete<Response<PriceBook>>(PriceBook, this.index + '/' + priceBook.id);
  }
}
