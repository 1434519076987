<div class="_chat">
  <div class="chat-message-content">
    <!-- Tabs -->
    <!-- <ul class="tab-wrapper is-centered">
      <li class="tab is-active">Chat</li>
      <li class="tab">Archivos</li>
    </ul> -->

    <div class="message-title">
      <h2>{{ chat?.name ?? '' }}</h2>
    </div>

    <!-- <div id="scroll-messages" #scroll data-simplebar (scroll)="scrolled($event)" class="chat-messages"> -->
    <div class="messages-wrap">
      <!-- <div> -->
        <div scrollable (scrollPosition)="onScrollPosition($event)" #scrollable class="scrollable chat-messages">
          <!-- Chat item -->
          <div #item class="message" [class.right]="i.uid == defaultUserId" *ngFor="let i of messages">
            <div class="avatar">
              <img class="avatar" draggable="false" src="{{ i.photo ?? 'assets/images/unknown.jpg' }}" alt="photo" />
              <!-- <ed-letter-avatar [edNone]="true" [edName]="i.name"></ed-letter-avatar> -->
            </div>
            <div class="message-content">
              <div class="header">
                <span class="username">{{ i.name }}</span>
                <span class="date">{{ i.date | moment : 'hh:mm A' }}</span>
              </div>
              <div class="body">
                <!-- Message Type -->
                <div [ngSwitch]="i.type">
                  <!-- Type text -->
                  <ng-template ngSwitchCase="text">
                    <div class="flex">
                      <div class="text" [innerHTML]="i.text | linkify"></div>
                      <img
                        draggable="false"
                        *ngIf="i.uid == defaultUserId && i.seen"
                        style="width: 12px; margin-left: 1rem"
                        src="assets/icons/check.svg"
                        alt=""
                      />
                    </div>
                    <!-- Mostrar preview de sitios soportados -->
                    <!-- <div class="preview" *ngIf="i.msg | linkpreview as preview">
                        <div [ngSwitch]="preview.type">
                          <ng-template ngSwitchCase="youtube">
                            <youtube-player [width]="500" [height]="300" [videoId]="preview.id"></youtube-player>
                          </ng-template>
                        </div>
                      </div> -->
                  </ng-template>

                  <!-- image -->
                  <ng-template ngSwitchCase="image">
                    <div class="message-image">
                      <a target="_blank" rel="noopener noreferrer" href="{{ i.url }}">
                        <img src="{{ i.url }}" />
                      </a>
                    </div>
                  </ng-template>

                  <!-- audio -->
                  <!-- <ng-template ngSwitchCase="audio">
                    <div class="message-audio">
                      <ed-audio [url]="i.url"></ed-audio>
                    </div>
                  </ng-template> -->

                  <ng-template ngSwitchDefault>
                    <span>unsupported type</span>
                  </ng-template>

                  <!-- Audio -->
                  <!-- <ng-template ngSwitchCase="audio">
                      <div class="audio">
                        <ed-audio [url]="i.url"></ed-audio>
                      </div>
                    </ng-template> -->

                  <!-- Type image -->
                  <!-- <ng-template ngSwitchCase="file">
                      <div class="file">
                        <ed-file
                          [fileName]="i.atts[0].name"
                          [fileUrl]="i.atts[0].url"
                          [fileSize]="i.atts[0].size"
                        ></ed-file>
                      </div>
                    </ng-template> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->

      <!-- <ed-empty-state
          [visible]="!isLoading && messages.length === 0"
          [title]="'No hay mensajes'"
          [description]="'Inicia una conversación con tus compañeros'"
        ></ed-empty-state> -->

      <!-- <div *ngIf="!isLoading && messages.length === 0" class="empty-state"> -->
      <!-- <h2>No hay mensajes</h2> -->
      <!-- <p>Publica tu primer mensaje o comparte un archivo.</p> -->
      <!-- </div> -->
    </div>

    <!-- Input -->
    <div *ngIf="chat" class="chat-input">
      <div *ngIf="actionLayout === 'text'" class="layout1">
        <div class="message">
          <textarea
            autofocus
            #input
            (keydown.enter)="onKeydownEnter($event)"
            (keyup.enter)="sendMessage(input.value)"
            class="input"
            placeholder="Escribe un mensaje"
            maxlength="250"
          ></textarea>
        </div>

        <div class="actions">
          <!-- Attach -->
          <!-- <div class="action-item">
              <img src="assets/icons/attachment.svg" alt="" />
            </div> -->

          <!-- Voice -->
          <!-- <div (click)="onRecord()" class="action-item">
            <img src="assets/icons/microphone.svg" alt="" />
          </div> -->
        </div>
      </div>

      <!-- <div *ngIf="actionLayout === 'audio'" class="layout2">
          <ed-recorder (audioFile)="onAudioFile($event)" (finish)="onFinishAudio()"></ed-recorder>
        </div> -->
    </div>
  </div>
</div>

<dl-loader [visible]="isLoading"></dl-loader>
<!-- <ed-loader [visible]="isLoading"></ed-loader> -->
