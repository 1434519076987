import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var QRCode: any;

@Component({
  selector: 'ns-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit, AfterViewInit {
  @Input() value: string;
  @Input() size: number = 200;

  @ViewChild('qrcode') container: ElementRef;

  qrcode: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.generateQrCode();
  }

  generateQrCode() {
    this.qrcode = new QRCode(this.container.nativeElement, {
      text: this.value,
      width: this.size,
      height: this.size,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H,
    });
  }
}
