export class RegisterClosureFilter {
  storeId?: string | null;
  registerId?: string | null;
  start?: number | null;
  limit?: number | null;

  constructor(init?: Partial<RegisterClosureFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let q = '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q += this.registerId ? '&register_id=' + this.registerId : '';
    q += this.start ? '&start=' + this.start : '';
    q += this.limit ? '&limit=' + this.limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
