export class Rider {
  private _id: string;
  private _name: string;
  private _lastName: string;
  private _imageUrl: string;
  private _mobile: string;
  private _email: string;
  private _number: string;
  private _code: string;
  private _address: string;
  private _vehicle: string;
  private _storeIds: string[];
  private _assignedAt: string;
  private _lastDelivery: string;
  private _ordersCount: string;
  private _score: string;
  private _isEnabled: string;
  private _isAvailable: string;
  private _type: string;
  private _createdBy: string;
  private _createdAt: string;
  private _updatedAt: string;
  private _deletedAt: string;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public set lastName(lastName: string) {
    this._lastName = lastName;
  }

  public get imageUrl(): string {
    return this._imageUrl;
  }

  public set imageUrl(imageUrl: string) {
    this._imageUrl = imageUrl;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(mobile: string) {
    this._mobile = mobile;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get number(): string {
    return this._number;
  }

  public set number(number: string) {
    this._number = number;
  }

  public get code(): string {
    return this._code;
  }

  public set code(code: string) {
    this._code = code;
  }

  public get address(): string {
    return this._address;
  }

  public set address(address: string) {
    this._address = address;
  }

  public get vehicle(): string {
    return this._vehicle;
  }

  public set vehicle(vehicle: string) {
    this._vehicle = vehicle;
  }

  public get storeIds(): string[] {
    return this._storeIds;
  }

  public set storeIds(storeIds: string[]) {
    this._storeIds = storeIds;
  }

  public get assignedAt(): string {
    return this._assignedAt;
  }

  public set assignedAt(assignedAt: string) {
    this._assignedAt = assignedAt;
  }

  public get lastDelivery(): string {
    return this._lastDelivery;
  }

  public set lastDelivery(lastDelivery: string) {
    this._lastDelivery = lastDelivery;
  }

  public get ordersCount(): string {
    return this._ordersCount;
  }

  public set ordersCount(ordersCount: string) {
    this._ordersCount = ordersCount;
  }

  public get score(): string {
    return this._score;
  }

  public set score(score: string) {
    this._score = score;
  }

  public get isEnabled(): string {
    return this._isEnabled;
  }

  public set isEnabled(isEnabled: string) {
    this._isEnabled = isEnabled;
  }

  public get isAvailable(): string {
    return this._isAvailable;
  }

  public set isAvailable(isAvailable: string) {
    this._isAvailable = isAvailable;
  }

  public get type(): string {
    return this._type;
  }

  public set type(type: string) {
    this._type = type;
  }

  public get createdBy(): string {
    return this._createdBy;
  }

  public set createdBy(createdBy: string) {
    this._createdBy = createdBy;
  }

  public get createdAt(): string {
    return this._createdAt;
  }

  public set createdAt(createdAt: string) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): string {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: string) {
    this._updatedAt = updatedAt;
  }

  public get deletedAt(): string {
    return this._deletedAt;
  }

  public set deletedAt(deletedAt: string) {
    this._deletedAt = deletedAt;
  }

  constructor(init?: Partial<Rider>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      last_name: this.lastName,
      image_url: this.imageUrl,
      mobile: this.mobile,
      email: this.email,
      number: this.number,
      code: this.code,
      address: this.address,
      vehicle: this.vehicle,
      store_ids: this.storeIds,
      assigned_at: this.assignedAt,
      last_delivery: this.lastDelivery,
      orders_count: this.ordersCount,
      score: this.score,
      is_enabled: this.isEnabled,
      is_available: this.isAvailable,
      type: this.type,
      created_by: this.createdBy,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      deleted_at: this.deletedAt,
    };
  }
}
