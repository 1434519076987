export const CATEGORIES = [
  { id: 'restaurant', name: 'Restaurante' },
  { id: 'supermarket', name: 'Supermercado' },
  { id: 'health', name: 'Salud' },
  { id: 'clothing', name: 'Ropa' },
  { id: 'electronic', name: 'Electrónica' },
  { id: 'homeware', name: 'Artículos para el hogar' },
  { id: 'beauty', name: 'Belleza' },
  { id: 'coffee', name: 'Café' },
  { id: 'vehicle', name: 'Vehículo' },
  { id: 'tool', name: 'Herramienta y Ferretería' },
  { id: 'book', name: 'Librería' },
  { id: 'toy', name: 'Juguetería' },
  { id: 'gift', name: 'Regalo' },
  { id: 'pet', name: 'Mascota' },
  { id: 'liqueur', name: 'Licor' },
  { id: 'jewelry', name: 'Joyería' },
  { id: 'tourism', name: 'Turismo' },
  { id: 'grocery', name: 'Alimentos' },
  { id: 'furniture', name: 'Mueble' },
  { id: 'footwear', name: 'Calzado' },
  { id: 'florist', name: 'Flores' },
  { id: 'service', name: 'Servicio' },
  { id: 'second_hand', name: 'Segunda mano' },
  { id: 'sport', name: 'Deporte' },
  { id: 'others', name: 'Otros' },
];
