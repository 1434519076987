import { OrderBy, SortBy } from '../enums/sorting';

export class TagFilter {
  query?: string | null;
  sort?: SortBy | null;
  order?: OrderBy | null;
  start?: number | null;
  limit?: number | null;

  // helper
  currentPage = 1;
  itemsPerPage = 20;

  constructor(init?: Partial<TagFilter>) {
    Object.assign(this, init);
  }

  static default() {
    return new TagFilter({ sort: SortBy.NAME, order: OrderBy.ASC });
  }

  static defaultFull() {
    return new TagFilter({ sort: SortBy.NAME, order: OrderBy.ASC, limit: 500 });
  }

  getQuery(): string {
    let start = this.start;
    let limit = this.limit;

    if (start == undefined && limit == undefined) {
      start = (this.currentPage - 1) * this.itemsPerPage;
      limit = this.itemsPerPage;
    }

    let q = '';
    q += this.query ? '&q=' + this.query : '';
    q += this.sort ? '&sort=' + this.sort : '';
    q += this.order ? '&order=' + this.order : '';
    q += start ? '&start=' + start : '';
    q += limit ? '&limit=' + limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
