import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationInfoService {
  private apiUrl = 'https://cs-location-info.102480.workers.dev';

  constructor(private http: HttpClient) {}

  getLocationInfo(): Observable<LocationInfo> {
    return this.http.get<LocationInfo>(this.apiUrl);
  }
}

export interface LocationInfo {
  time_zone: string;
  country_code: string;
  currency_code: string;
  region: string; // unimplemented
  city: string; // unimplemented
}
