export class Lot {
  public id: string;
  public storeId: string;
  public productId?: string;
  public productName?: string;
  public productSku?: string;
  public productBarcode?: string;
  public productCategory?: string;
  public number: string;
  public quantity: number;
  public inStock: number;
  public note: string;
  public expiresAt: Date;
  public createdAt: Date;
  public updatedAt: Date;

  private _name: string; // @unused: remove

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  constructor(init?: Partial<Lot>) {
    Object.assign(this, init);
  }

  isExpired(): boolean {
    return this.expiresAt && this.expiresAt.getTime() - new Date().getTime() <= 0;
  }

  public toJSON(): any {
    // @note disabled reset
    // Reset expiration time
    // this.expiresAt = new Date(this.expiresAt.getTime() - this.expiresAt.getTimezoneOffset() * 60 * 1000);
    // this.expiresAt.setUTCHours(0, 0, 0, 0);

    return {
      id: this.id,
      store_id: this.storeId,
      product_id: this.productId,
      product_name: this.productName,
      product_sku: this.productSku,
      product_barcode: this.productBarcode,
      product_category: this.productCategory,
      number: this.number,
      quantity: this.quantity,
      note: this.note,
      expires_at: this.expiresAt,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
