import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Rider } from '../models/rider';
import { Response } from '../interfaces/response';
import { RiderFilter } from '../filters/rider.filter';

@Injectable({
  providedIn: 'root',
})
export class RiderService {
  private readonly index = environment.api + '/v1/riders';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Rider | null>> {
    return this.api.get<Response<Rider | null>>(Rider, this.index + '/' + id);
  }

  all(p: RiderFilter | null = null): Observable<Response<Rider[]>> {
    return this.api.get<Response<Rider[]>>(Rider, this.index + (p ? p!.getQuery() : ''));
  }

  create(rider: Rider): Observable<Response<Rider>> {
    return this.api.post<Response<Rider>>(Rider, this.index, rider);
  }

  update(rider: Rider): Observable<Response<Rider>> {
    return this.api.put<Response<Rider>>(Rider, this.index + '/' + rider.id, rider);
  }

  delete(rider: Rider): Observable<Response<Rider>> {
    return this.api.delete<Response<Rider>>(Rider, this.index + '/' + rider.id);
  }
}
