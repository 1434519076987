import { TaxLite } from '@app/core/models/tax-lite';
import { RegisterLite } from '@app/core/models/register-lite';
import { Store } from '@app/core/models/store';
import { PaymentMethodEntity } from './payment-method.entity';

export class StoreEntity {
  id: string;
  name: string;
  local: string;
  logoUrl: string;
  denomination: string;
  idNumber: string;
  phone: string;
  mobile: string;
  email: string;
  address: string;
  currencyCode: string;
  country: string;
  city: string;
  department: string;
  district: string;
  province: string;
  timeZone: string;
  tax?: TaxLite;
  isTaxInclusive: boolean;
  version?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  paymentMethods?: PaymentMethodEntity[];
  registers?: RegisterLiteEntity[];

  constructor(init?: Partial<StoreEntity>) {
    Object.assign(this, init);
    this.tax = init?.tax ? new TaxLite(init.tax) : undefined;
    this.paymentMethods = init?.paymentMethods?.map((i) => new PaymentMethodEntity(i)) ?? [];
    this.registers = init?.registers?.map((i) => new RegisterLiteEntity(i)) ?? [];
  }

  public model(): Store {
    return new Store({
      id: this.id,
      name: this.name,
      local: this.local,
      logoUrl: this.logoUrl,
      denomination: this.denomination,
      idNumber: this.idNumber,
      phone: this.phone,
      mobile: this.mobile,
      email: this.email,
      address: this.address,
      currencyCode: this.currencyCode,
      country: this.country,
      city: this.city,
      department: this.department,
      district: this.district,
      timeZone: this.timeZone,
      tax: this.tax ? new TaxLite(this.tax) : undefined,
      isTaxInclusive: this.isTaxInclusive,
      updatedAt: this.updatedAt,
      paymentMethods: this.paymentMethods?.map((i) => new PaymentMethodEntity(i).model()),
      registers: this.registers?.map((i) => new RegisterLiteEntity(i).model()),
    });
  }
}

export class RegisterLiteEntity {
  id: string;
  name: string;
  sequenceNumber: number;
  isOpen: boolean;
  registerClosureId: string;
  openingTime: Date;
  closingTime: Date;

  constructor(init?: Partial<RegisterLiteEntity>) {
    Object.assign(this, init);
  }

  public model(): RegisterLite {
    return new RegisterLite({
      id: this.id,
      name: this.name,
      sequenceNumber: this.sequenceNumber,
      isOpen: this.isOpen,
      registerClosureId: this.registerClosureId,
      openingTime: this.openingTime,
      closingTime: this.closingTime,
    });
  }
}
