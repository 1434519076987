export enum PaymentType {
  CASH = 'cash',
  OTHER = 'other',
  CASH_ROUNDING = 'cash_rounding',
  CREDIT_CARD = 'credit_card',
  BANK_TRANSFER = 'bank_transfer',
  QR = 'qr',
  WALLET = 'wallet',
  POS = 'pos',
  GIFT_CARD = 'gift_card',
}

export const PAYMENT_TYPES = [
  { value: PaymentType.CASH, label: 'Efectivo', isInternal: false },
  { value: PaymentType.OTHER, label: 'Otro Medio de Pago', isInternal: false },
  { value: PaymentType.CASH_ROUNDING, label: 'Redondeo de Efectivo', isInternal: true },
  // { value: PaymentType.CREDIT_CARD, label: 'Tarjeta de Crédito', isInternal: false },
  // { value: PaymentType.BANK_TRANSFER, label: 'Transferencia Bancaria', isInternal: false },
  // { value: PaymentType.QR, label: 'QR', isInternal: false },
  // { value: PaymentType.WALLET, label: 'Billetera', isInternal: false },
  { value: PaymentType.GIFT_CARD, label: 'Tarjeta de Regalo', isInternal: true },
  // { value: PaymentType.POS, label: 'POS', isInternal: false },
];

export const PAYMENT_TYPES_EXTERNAL = PAYMENT_TYPES.filter((i) => !i.isInternal);
