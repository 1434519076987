export enum StockOption {
  All = 'null',
  OUT = 'out',
  EXISTS = 'exists',
  LOW = 'low',
  FULL = 'full',
  SHORTAGE = 'shortage',
  SAFETY = 'safety',
}

export const STOCK_OPTIONS = [
  { id: StockOption.All, text: 'All' },
  { id: StockOption.OUT, text: 'Stockout' },
  { id: StockOption.EXISTS, text: 'Exists' },
  { id: StockOption.LOW, text: 'Low' },
  { id: StockOption.FULL, text: 'Full' },
  { id: StockOption.SHORTAGE, text: 'Shortage' },
  { id: StockOption.SAFETY, text: 'Safety' },
];
