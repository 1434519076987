export class Customer {
  public id: string;
  public documentType: string;
  public documentNumber: string;
  public userId: string;
  public name: string;
  public imageUrl: string;
  public mobile: string;
  public phone: string;
  public email: string;
  public address: string;
  public isEnabled: boolean;
  public isVerified: boolean;
  public note: string;
  public ordersCount: number;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(init?: Partial<Customer>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      document_type: this.documentType,
      document_number: this.documentNumber,
      user_id: this.userId,
      name: this.name,
      image_url: this.imageUrl,
      mobile: this.mobile,
      phone: this.phone,
      email: this.email,
      address: this.address,
      is_enabled: this.isEnabled,
      is_verified: this.isVerified,
      note: this.note,
      orders_count: this.ordersCount,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
