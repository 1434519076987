import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

const printUrlPattern = /\(print:/;

/**
 * @todo fix router listener not called
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        console.log('history', this.history);
      }
    });
  }

  toOrderView(orderId: string) {
    this.router.navigate([`/sales/orders/${orderId}/view`]);
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      const previousUrl = this.history[this.history.length - 1];
      const currentUrl = this.router.url;
      if (previousUrl == currentUrl || printUrlPattern.test(previousUrl)) {
        this.back();
      } else {
        this.router.navigateByUrl(previousUrl);
      }
    } else {
      this.location.back();
    }
  }
}
