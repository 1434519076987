import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Response, Error } from '../interfaces/response';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { RequestCache } from './request-cache';
import { environment } from 'environments/environment';

const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.\d{1,9})?Z$/;

// Interface que servirá para generar instancia de un generic.
type IEntity<T> = new (...args: any[]) => T;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private cache: RequestCache) {}

  procesing<T>(response: Response<T>, type: any): any {
    if (!response?.ok) {
      console.error(response);
    }

    response.data = this._unserialize<T>(response.data, type);

    return response;
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    const response = { ok: false, error: Error.CONNECTION_REFUSED } as any;

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      response.error = Error.CONNECTION_REFUSED;
      console.error('An error occurred:', error.error.message);
    } else {
      if (environment.env == 'local') {
        console.trace('An error occurred:', error);
      }

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      switch (error.status) {
        case 404:
          response.error = Error.HTTP_NOT_FOUND;
          break;

        default:
          response.error = Error.ERROR;
          break;
      }
    }
    return of(response);
  }

  /**
   *
   * @param type object
   * @param url string
   * @param expire cache
   */
  get<T>(type: any, url: string, expire = 0): Observable<T> {
    // 10000 // 10s
    const cachedResponse = this.cache.get(url);

    if (cachedResponse) {
      return of(cachedResponse);
    }

    return this.http.get<Response<T>>(url).pipe(
      switchMap((response) => {
        // return this._unserialize<T>(response.data, type);
        const result = this.procesing<T>(response, type);
        this.cache.put(url, result, expire);
        return of(result);
      }),
      catchError(this.handleError)
    );
  }

  post<T>(type: any, url: string, body: any | null, options: {} = {}): Observable<T> {
    return this.http.post<Response<T>>(url, body, options).pipe(
      map((response) => {
        return this.procesing<T>(response, type);
      }),
      catchError(this.handleError)
    );
  }

  put<T>(type: any, url: string, body: any | null): Observable<T> {
    return this.http.put<Response<T>>(url, body).pipe(
      map((response) => {
        return this.procesing<T>(response, type);
      }),
      catchError(this.handleError)
    );
  }

  delete<T>(type: any, url: string): Observable<T> {
    return this.http.delete<Response<T>>(url).pipe(
      map((response) => {
        return this.procesing<T>(response, type);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Transformar a camelCase y convertir a tipos
   * @param object Json object
   * @param type Type
   */
  private _unserialize<T>(object: any, type: IEntity<T>): any {
    const objectCamel = this._objectToCamel(object);

    if (Array.isArray(objectCamel)) {
      return objectCamel.map((i: any) => this._instantiate(type, i));
    } else {
      return this._instantiate(type, objectCamel);
    }
  }

  /**
   * Transformar a camelCase y convertir a tipos
   * @param object Json object
   * @param type Type
   */
  private _objectToCamel(object: any): any {
    let newObject: any;
    let origKey: any;
    let newKey: any;
    let value: any;

    if (Array.isArray(object)) {
      return object.map((item) => {
        if (typeof item === 'object') {
          item = this._objectToCamel(item);
        }
        return item;
      });
    } else {
      if (object === null) {
        return null;
      }

      newObject = {};

      for (origKey in object) {
        newKey = this.snakeToCamel(origKey);

        value = object[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = this._objectToCamel(value);
        }

        if (typeof value === 'string' && dateRegex.test(value)) {
          newObject[newKey] = new Date(value);
        } else {
          newObject[newKey] = value;
        }
      }
    }

    return newObject;
  }

  /**
   * @deprecated
   * Transformar a camelCase y convertir a tipos
   * @param object Json object
   * @param type Type
   */
  private _unserializeOld<T>(object: any, type?: IEntity<T>): any {
    let newObject: any;
    let origKey: any;
    let newKey: any;
    let value: any;

    if (Array.isArray(object)) {
      return object.map((_value) => {
        if (typeof _value === 'object') {
          _value = this._unserializeOld(_value, type);
        }
        return _value;
      });
    } else {
      if (object === null) {
        // todo: comprobar primitivos
        return null;
      }

      newObject = type ? this._instantiate(type) : {};
      for (origKey in object) {
        if (object.hasOwnProperty(origKey)) {
          newKey = this.snakeToCamel(origKey);
          value = object[origKey];
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            // Fix error: type not found in nested objects
            value = this._unserializeOld(value, this.getType(newObject, newKey));
          }

          if (typeof value === 'string' && dateRegex.test(value)) {
            newObject[newKey] = new Date(value);
          } else {
            newObject[newKey] = value;
          }
        }
      }
    }

    return newObject;
  }

  /**
   * Obtener el tipo de la propiedad de un objecto
   * @param entity Class
   * @param prop property
   */
  // @ts-ignore: Unreachable code error
  private getType<T>(entity, prop: string): IEntity<T> {
    let type = null;
    if (entity.constructor.prototype.hasOwnProperty('_jsonUnserializeMeta')) {
      // @ts-ignore: Unreachable code error
      entity.constructor.prototype._jsonUnserializeMeta.forEach((property) => {
        if (property.prop === prop) {
          type = property.type;
        }
      });
    }
    // @ts-ignore: Unreachable code error
    return type;
  }

  // Retorna la instacia del tipo
  private _instantiate<T>(type: IEntity<T>, param: any = null): T {
    return new type(param);
  }

  /**
   * Transformar snake a camelCase
   * @param value value
   */
  private snakeToCamel(value: string): string {
    return value.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '');
    });
  }
}
