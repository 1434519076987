export class Inventory {
  public id: string;
  public storeId: string;
  public storeName: string; // Redundance for UI
  public productId: string;
  public inStock?: number;
  public safetyStock: number;
  public reorderPoint: number;
  public reorderQuantity: number;
  public location: string;
  public isPublic: boolean;
  public isAvailable: boolean;
  public updatedAt: Date;
  public createdAt: Date;

  // Product info
  public isActive: boolean;
  public trackInventory: boolean;

  // PriceBookProduct info
  public price: number;
  public priceIncluingTax: number;
  public priceExcludingTax: number;

  constructor(init?: Partial<Inventory>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      product_id: this.productId,
      in_stock: this.inStock,
      safety_stock: this.safetyStock,
      reorder_point: this.reorderPoint,
      reorder_quantity: this.reorderQuantity,
      location: this.location,
      is_public: this.isPublic,
      is_available: this.isAvailable,
      updated_at: this.updatedAt,
      created_at: this.createdAt,
    };
  }
}
