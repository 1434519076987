export class RegisterFilter {
  storeId?: string | null;

  constructor(init?: Partial<RegisterFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let q = '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
