<mat-toolbar class="topbar">
  <!-- logo -->
  <a *ngIf="false" routerLink="/" class="app-logo">
    <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M95.3726 42.9333C111.628 26.6777 137.984 26.6777 154.239 42.9333C170.495 59.1889 170.495 85.5444 154.239 101.8L119.237 136.802C102.982 153.057 76.6264 153.057 60.3708 136.802C44.1152 120.546 44.1152 94.1907 60.3708 77.9351L95.3726 42.9333Z"
        fill="url(#paint0_linear_919_557)"
      />
      <path
        d="M25.4958 101.8C9.24024 85.5444 9.24025 59.189 25.4958 42.9334C41.7514 26.6778 68.1069 26.6778 84.3625 42.9334L140.843 99.4135C145.236 103.807 145.236 110.93 140.843 115.323L119.364 136.802C103.109 153.057 76.7532 153.057 60.4976 136.802L25.4958 101.8Z"
        fill="url(#paint1_linear_919_557)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_919_557"
          x1="154.239"
          y1="42.9333"
          x2="60.3708"
          y2="136.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3A7DFF" />
          <stop offset="1" stop-color="#5379FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_919_557"
          x1="25.4958"
          y1="42.9334"
          x2="119.364"
          y2="136.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#36FFF3" />
          <stop offset="1" stop-color="#366EFF" />
        </linearGradient>
      </defs>
    </svg>
    <!-- <div class="label">{{ i.name }}</div> -->
  </a>

  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  <!-- @if(showToggle) {
  <button mat-icon-button (click)="toggleCollapsed.emit()" class="d-flex justify-content-center">
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  } -->

  <!-- Mobile Menu -->
  <!-- @if(!showToggle) {
  <button mat-icon-button (click)="toggleMobileNav.emit()" class="d-flex justify-content-center">
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  } -->
  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!-- business Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-button [matMenuTriggerFor]="menu" class="d-flex justify-content-center">
    <!-- <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler> -->
    <div class="d-flex" style="align-items: center">
      <img
        style="border-radius: 50%; width: 26px"
        src="https://ui-avatars.com/api/?background=103b63&color=fff&length=1&name=Dotpem"
        alt=""
      />
      <span style="padding: 0 0.5rem 0 1rem">{{ selectedBusiness?.name }}</span>
      <i-tabler name="selector" style="opacity: 0.5" class="icon-16 m-l-4"></i-tabler>
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <!-- business name -->
    <div (click)="onBusinessSelect(i)" mat-menu-item *ngFor="let i of business$ | async">
      <mat-icon>
        <i-tabler name="circle-check" class="icon-20 d-flex flex-justify-center"></i-tabler>
      </mat-icon>
      <span>{{ i.name }}</span>
    </div>

    <!-- <mat-divider></mat-divider> -->

    <!-- create business -->
    <button routerLink="/business-register" mat-menu-item>
      <mat-icon>
        <i-tabler name="plus" class="icon-20 d-flex flex-justify-center"></i-tabler>
      </mat-icon>
      <span>Crear Negocio</span>
    </button>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- store Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-button [matMenuTriggerFor]="storeMenu" class="d-flex justify-content-center">
    <!-- <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler> -->
    <div class="d-flex" style="align-items: center">
      <div class="d-flex align-items-center">
        @if(!selectedRegister){
        <span class="f-w-600">{{ selectedLocal?.name }}</span>
        <i-tabler name="selector" style="opacity: 0.5; margin: 0 0.5rem" class="icon-16 m-l-4"></i-tabler>
        } @else{
        <span>{{ selectedLocal?.name }}</span>
        <i-tabler name="selector" style="opacity: 0.5; margin: 0 0.5rem" class="icon-16 m-l-4"></i-tabler>
        <!-- <i-tabler name="chevron-right" style="opacity: 0.5" class="icon-16 m-x-4"></i-tabler> -->
        <span class="f-w-600" style="padding: 0 0.5rem 0 0rem">{{ selectedRegister?.name }}</span>
        }
      </div>
    </div>
  </button>
  <mat-menu #storeMenu="matMenu">
    <!-- locals -->
    <ng-container *ngFor="let local of locals$ | async">
      <div (click)="onLocalSelect(local)" mat-menu-item [matMenuTriggerFor]="registerMenu">
        @if(selectedLocal?.id === local.id){
        <mat-icon>
          <i-tabler name="circle-check" class="icon-20 d-flex flex-justify-center"></i-tabler>
        </mat-icon>
        }
        <span>{{ local.name }}</span>
      </div>

      <!-- add subumenu registers -->
      <mat-menu #registerMenu="matMenu">
        <ng-container *ngFor="let register of local.registers">
          <div (click)="onRegisterSelect(local, register)" mat-menu-item>
            <!-- icon -->
            @if(selectedRegister?.id === register.id){
            <mat-icon>
              <i-tabler name="circle-check" class="icon-20 d-flex flex-justify-center"></i-tabler>
            </mat-icon>
            }

            <!-- name -->
            <span style="margin-right: 0.5rem">{{ register.name }}</span>

            <!-- status -->
            @if(register.isOpen) {
            <ns-badge color="success"> Abierto </ns-badge>
            } @else {
            <ns-badge color="danger"> Cerrado </ns-badge>
            }
            <!-- <span *ngIf="register.isOpen === true" class="mat-body-1 f-s-10">
                        Abierto el {{ register.openingTime | moment }}
                      </span>
                      <span *ngIf="register.isOpen === false && register.closingTime" class="mat-body-1 f-s-10">
                        Cerrado el {{ register.closingTime | moment }}
                      </span> -->
          </div>
        </ng-container>
      </mat-menu>
    </ng-container>

    <!-- <mat-divider></mat-divider> -->
    <!-- create local -->
    <button routerLink="/settings/stores" mat-menu-item>
      <mat-icon>
        <i-tabler name="settings" class="icon-20 d-flex flex-justify-center"></i-tabler>
      </mat-icon>
      <span>Gestionar Locales</span>
    </button>

    <!-- old store menu -->
    <div *ngIf="false" class="p-24">
      <div class="row">
        <!-- stores -->
        <div class="col col-12" *ngFor="let local of locals$ | async">
          <!-- store -->
          <div class="row text-hover-primary">
            <div class="col">
              <a
                (click)="onLocalSelect(local)"
                class="cursor-pointer d-flex justify-content-between align-items-center text-decoration-none"
              >
                <!-- <span
                  class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center"
                > -->
                <!-- <img [src]="local.img" width="20" /> -->
                <!-- </span> -->

                <div>
                  <h5 class="f-s-14 f-w-600 m-0 textprimary _mat-body-1 hover-text">
                    {{ local.name }}
                  </h5>
                  <span class="_mat-body-1 f-s-12">{{ local.address }}</span>
                </div>

                <i-tabler
                  *ngIf="selectedLocal?.id === local.id && !selectedRegister"
                  name="circle-check"
                  class="icon-20 m-r-8"
                ></i-tabler>
              </a>
            </div>
          </div>

          <!-- registers -->
          <div class="row">
            <div class="col">
              <div class="registers">
                <div
                  (click)="onRegisterSelect(local, register)"
                  class="register col cursor-pointer text-hover-primary"
                  [class.is-selected]="selectedRegister?.id === register.id"
                  *ngFor="let register of local.registers"
                >
                  <a
                    class="d-flex _align-items-center w-100 align-items-center justify-content-between text-decoration-none"
                  >
                    <div class="m-l-10 m-r-10">
                      <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                        {{ register.name }}
                      </h5>
                      <!-- <span *ngIf="register.isOpen === true" class="mat-body-1 f-s-10">
                        Abierto el {{ register.openingTime | moment }}
                      </span>
                      <span *ngIf="register.isOpen === false && register.closingTime" class="mat-body-1 f-s-10">
                        Cerrado el {{ register.closingTime | moment }}
                      </span> -->
                    </div>

                    <i-tabler
                      *ngIf="selectedRegister?.id === register.id"
                      name="circle-check"
                      class="icon-20 m-r-8"
                    ></i-tabler>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          class="b-t-1 p-24 d-none d-lg-flex align-items-center justify-content-between"
        >
          <span
            class="d-flex align-items-center mat-subtitle-1 f-s-16 f-w-500"
          >
            <i-tabler name="help" class="icon-20 m-r-8"></i-tabler>Frequently
            Asked Questions
          </span>
          <a
            [routerLink]="['/theme-pages/faq']"
            mat-flat-button
            color="primary"
            >Check</a
          >
        </div> -->
        <!-- <div class="col-sm-4">
        <div class="p-x-16 p-y-32">
          <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
          <div
            class="text-hover-primary"
            *ngFor="let quicklink of quicklinks"
          >
            <a
              [routerLink]="[quicklink.link]"
              class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8"
              >{{ quicklink.title }}</a
            >
          </div>
        </div>
      </div> -->
      </div>
      <!-- footer -->
      <div class="hidden row">
        <div class="col">
          <div class="b-t-1 p-t-20 d-none d-lg-flex align-items-center justify-content-between">
            <!-- <a
              [routerLink]="['/settings/stores']"
              class="d-flex align-items-center f-w-600 m-0 textprimary mat-body-1 hover-text"
            >
              <i-tabler name="edit" class="icon-20 m-r-8"></i-tabler>
              Editar locales
            </a> -->
            <!-- <a
            [routerLink]="['/settings/businesses']"
            mat-flat-button
            color="primary"
            >Check</a
          > -->
          </div>
        </div>
      </div>
    </div>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button mat-icon-button (click)="openDialog()" class="d-flex justify-content-center">
    <i-tabler name="home" class="icon-20 d-flex"></i-tabler>
  </button> -->

  <!-- open menu search -->
  <!-- <button mat-button (click)="openDialog()" class="d-flex justify-content-center">
    <div class="d-flex" style="align-items: center">
      <i-tabler name="layout-2" class="icon-20 d-flex"></i-tabler>

      <div style="display: flex; justify-content: center; align-items: center; padding: 0; opacity: 0.5">
        <i-tabler name="chevron-right" class="icon-18 d-flex flex-justify-center"></i-tabler>
      </div>
    </div>
  </button> -->

  <div class="d-none d-lg-flex" style="margin-left: 0rem">
    <!-- --------------------------------------------------------------- -->
    <!--  Links -->
    <!-- --------------------------------------------------------------- -->
    <!-- <button *ngIf="false" mat-button [matMenuTriggerFor]="appsmenu" aria-label="Notifications">
      <div class="d-flex align-items-center">Apps <i-tabler name="chevron-down" class="icon-16 m-l-4"></i-tabler></div>
    </button> -->
    <mat-menu *ngIf="false" #appsmenu="matMenu" class="apps-dd cardWithShadow">
      <div class="row">
        <div class="col-sm-8 b-r-1 p-r-0">
          <div class="p-32 p-b-0">
            <div class="row">
              @for(appdd of apps; track appdd.title) {
              <div class="col-sm-6 text-hover-primary">
                <a [routerLink]="[appdd.link]" class="d-flex align-items-center text-decoration-none m-b-24">
                  <span
                    class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center"
                  >
                    <img [src]="appdd.img" width="20" />
                  </span>

                  <div class="m-l-16">
                    <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                      {{ appdd.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                  </div>
                </a>
              </div>
              }
            </div>
          </div>
          <div class="b-t-1 p-24 d-none d-lg-flex align-items-center justify-content-between">
            <span class="d-flex align-items-center mat-subtitle-1 f-s-16 f-w-500">
              <i-tabler name="help" class="icon-20 m-r-8"></i-tabler>Frequently Asked Questions
            </span>
            <a [routerLink]="['/theme-pages/faq']" mat-flat-button color="primary">Check</a>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="p-x-16 p-y-32">
            <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
            @for(quicklink of quicklinks; track quicklink.title) {
            <div class="text-hover-primary">
              <a
                [routerLink]="[quicklink.link]"
                class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8"
                >{{ quicklink.title }}</a
              >
            </div>
            }
          </div>
        </div>
      </div>
    </mat-menu>
    <!-- <a mat-button color="primary" [routerLink]="['/apps/chat']">Vender</a> -->
    <!-- <a mat-button [routerLink]="['/apps/email/inbox']">Historial</a> -->
    <!-- <a mat-button [routerLink]="['/apps/calendar']">Abrir caja</a> -->
    <!-- <a mat-button [routerLink]="['/apps/email/inbox']">Efectivo</a> -->

    <!-- arrow right -->
    <!-- <div style="display: flex; justify-content: center; align-items: center; padding: 0; opacity: 0.5">
      <i-tabler name="chevron-right" class="icon-18 d-flex flex-justify-center"></i-tabler>
    </div> -->

    <!-- top menu -->
    <ng-container *ngFor="let i of items; let idx = index">
      <ng-container *ngIf="!i.hidden && activeItem?.route === i.route">
        <!-- label -->
        <a
          *ngIf="false"
          [matTooltip]="i.name"
          [routerLink]="i.route"
          class="section-link is-header"
          style="display: flex; justify-content: center; align-items: center; padding: 0rem"
        >
          <!-- <div>{{ i.name }}</div> -->
          <!-- <button mat-button class="d-flex align-items-center"> -->
          <mat-icon color="primary">
            <i-tabler name="{{ i.icon }}" class="icon-20 d-flex flex-justify-center"></i-tabler>
          </mat-icon>
          <!-- <span class="mat-subtitle-2 f-w-600 d-block">{{ i.name }}</span> -->
          <!-- </button> -->
        </a>

        <!-- search -->
        <button *ngIf="false" mat-button color="primary" (click)="openDialog()" class="d-flex justify-content-center">
          <div class="d-flex" style="align-items: center">
            <i-tabler name="{{ i.icon }}" class="icon-18 d-flex"></i-tabler>

            <div style="display: flex; justify-content: center; align-items: center; padding-left: 4px; opacity: 0.5">
              <i-tabler name="chevron-right" class="icon-16 d-flex flex-justify-center"></i-tabler>
              <!-- chevron-right -->
            </div>
          </div>
        </button>

        <!-- nested items -->
        <ng-container *ngFor="let j of i.children; let idx = index">
          <a
            mat-button
            [routerLink]="j.route"
            routerLinkActive="is-active"
            #rla="routerLinkActive"
            [color]="rla.isActive ? 'primary' : null"
            *ngIf="!j.hidden"
          >
            <div class="d-flex" style="align-items: center">
              @if(j.icon){
              <i-tabler name="{{ j.icon }}" class="icon-18 d-flex" style="margin-right: 4px"></i-tabler>
              }
              <span>
                {{ j.name }}
              </span>
            </div>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- <a mat-button [routerLink]="['/apps/email/inbox']">Cierres de caja</a> -->
  </div>

  <span class="flex-1-auto"></span>

  <!-- Mobile Menu -->
  <button mat-icon-button (click)="toggleMobileFilterNav.emit()" class="d-flex d-lg-none justify-content-center">
    <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
  </button>

  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <img [src]="selectedLanguage.icon" class="rounded-circle object-cover icon-20" />
  </button>
  <mat-menu #flags="matMenu" class="cardWithShadow">
    @for(lang of languages; track lang.icon) {
    <button mat-menu-item (click)="changeLanguage(lang)">
      <div class="d-flex align-items-center">
        <img [src]="lang.icon" class="rounded-circle object-cover m-r-8 icon-20" />
        <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
      </div>
    </button>
    }
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- <button mat-icon-button [matMenuTriggerFor]="notificationmenu" aria-label="Notifications">
    <i-tabler class="d-flex" name="bell" matBadge="1" matBadgeColor="primary"></i-tabler>
  </button>
  <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Notifications</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">5 new</span>
      </span>
    </div>

    @for(notification of notifications; track notification.title) {
    <button mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <img [src]="notification.img" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
            {{ notification.title }}
          </h5>
          <span>{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    }

    <div class="p-y-12 p-x-32">
      <button mat-stroked-button color="primary" class="w-100">See all notifications</button>
    </div>
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- mat-icon-button -->
  <a [matMenuTriggerFor]="profilemenu" aria-label="Notifications" class="d-flex justify-content-center m-x-10">
    <!-- <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler> -->
    <img
      src="https://ui-avatars.com/api/?background=d9d9e3&length=1&color=000&name=Yuri"
      class="rounded-circle _object-cover _icon-22 _profile-dd"
      style="border-radius: 50%; width: 28px; height: 28px"
    />
  </a>
  <mat-menu #profilemenu1="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="min-height: 300px">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Cuenta</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img src="/assets/images/profile/user-1.jpg" class="rounded-circle" width="95" />
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0 mat-subtitle-1">David</h6>
            <span class="f-s-14 d-block m-b-4">Admin</span>
            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              david&#64;gmail.com
            </span>
          </div>
        </div>
      </div>
      <div class="p-x-32">
        <!-- @for(profile of profiledd; track profile.title) {
        <a class="p-y-16 text-decoration-none d-block text-hover-primary" [routerLink]="[profile.link]">
          <div class="d-flex align-items-center">
            <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
              <img [src]="profile.img" width="20" />
            </button>

            <div class="m-l-16">
              <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                {{ profile.title }}
              </h5>
              <span class="mat-body-1">{{ profile.subtitle }}</span>
            </div>
          </div>
        </a>
        } -->

        <!-- upgrade -->
        <!-- <div class="p-24 overflow-hidden bg-light-primary rounded position-relative m-y-16">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="f-s-18 m-0 f-w-600 m-b-12 mat-subtitle-1">
                Unlimited <br />
                Access
              </h5>
              <button mat-flat-button color="primary">Upgrade</button>
            </div>
            <div class="m-l-auto">
              <img src="/assets/images/backgrounds/unlimited-bg.png" alt="upgrade-bg" class="upgrade-bg" />
            </div>
          </div>
        </div> -->
      </div>

      <div class="p-y-12 p-x-32">
        <a [routerLink]="['/authentication/login']" mat-stroked-button color="primary" class="w-100">Logout</a>
      </div>
    </ng-scrollbar>
  </mat-menu>

  <mat-menu #profilemenu="matMenu">
    <div class="d-flex p-24 flex-col align-items-center">
      <!-- <img
        class="image"
        style="width: 4.6rem; height: 4.6rem; border-radius: 50%"
        src="{{ accountPhotoURL }}"
        alt=""
      /> -->
      <user-profile-picture #user size="4rem"></user-profile-picture>
      <div class="p-t-12 f-w-600">{{ user.name() }}</div>
      <div class="email">{{ user.email() }}</div>
    </div>

    <button (click)="logout()" mat-menu-item>
      <mat-icon>
        <i-tabler name="power" class="icon-20 d-flex flex-justify-center"></i-tabler>
      </mat-icon>
      <span> Cerrar sesión </span>
    </button>

    <!-- language -->
    <!-- <div>
      {{ 'HELLO' | translate }}
      <mat-chip-set aria-label="Language selection">
        <mat-chip
          (click)="switchLanguage(language.value)"
          *ngFor="let language of languages"
          [value]="language.value"
        >
          {{ language.label }}
        </mat-chip>
      </mat-chip-set>
    </div> -->
  </mat-menu>
</mat-toolbar>
