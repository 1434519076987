<span
  [ngClass]="{
    'bg-light-success': color === 'success',
    'bg-light-error': color === 'error' || color === 'danger',
    'bg-light-warn': color === 'warn',
    'bg-light-info': color === 'info'
  }"
  class="mat-body-2 f-w-500 p-x-8 p-y-4 f-s-12 rounded-pill"
>
  {{ label }}
  <ng-content></ng-content>
</span>
