import { inject, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth-guard.service';
import { PrintLayoutComponent } from './components/print-layout/print-layout.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { PrintConsignmentComponent } from './components/print-consignment/print-consignment.component';
import { DiscountLabelPrintComponent } from './components/print/discount-label-print/discount-label-print.component';
import { FullComponent } from './layouts/full/full.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { SalePrintComponent } from './components/print/sale-print/sale-print.component';
import { PrintPreviewComponent } from './pages/print-preview/print-preview.component';
import { BusinessRegisterComponent } from './pages/business-register/business-register.component';
import { InitializeResolver } from './core/resolvers/initialize.resolver';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    resolve: { initialize: () => inject(InitializeResolver).resolve() },
    children: [
      {
        path: 'print-preview/:gift_card_ids',
        component: GiftCardComponent,
      },
      {
        path: '',
        redirectTo: 'sales',
        pathMatch: 'full',
        // canActivate: [AuthGuard],
        // loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'sales',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/sale/sale.module').then((m) => m.SaleModule),
      },
      {
        path: 'products',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'inventory',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/inventory/inventory.module').then((m) => m.InventoryModule),
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'support',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/messaging/messaging.module').then((m) => m.MessagingModule),
      },
      {
        path: 'assistant',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/assistant/assistant.module').then((m) => m.AssistantModule),
      },
      {
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
          { path: 'invoice/:invoice_ids', component: InvoiceComponent },
          { path: 'giftcard/:gift_card_ids', component: GiftCardComponent },
          { path: 'consignment/:id', component: PrintConsignmentComponent },
          { path: 'barcode', component: DiscountLabelPrintComponent },
          { path: 'orders/:sale_id', component: SalePrintComponent },
        ],
      },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'sign-in',
        component: SignInComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'business-register',
        component: BusinessRegisterComponent,
      },
      {
        path: 'print-preview',
        component: PrintPreviewComponent,
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
