export {};

declare global {
  interface String {
    _last(): string;
  }
}

if (!Array.prototype._last) {
  Array.prototype._last = function (): string {
    return this[this.length - 1];
  };
}
