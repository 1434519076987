import { Directive, HostListener } from '@angular/core';
import { BarcodeService } from '@app/core';

@Directive({
  selector: '[barcode]',
})
export class BarcodeDirective {
  barcode: string;
  delay = 100;
  lastPressedDate = 0;

  constructor(private barcodeService: BarcodeService) {}

  @HostListener('document:keydown', ['$event'])
  keydown(event: KeyboardEvent): void {
    const currentTime = new Date().getTime();

    if (currentTime - this.lastPressedDate > this.delay) {
      this.barcode = '';
    }

    this.lastPressedDate = currentTime;

    if (event.key == 'Shift' || event.key == 'Control' || event.key == 'Alt' || event.key == 'Meta') {
      return;
    }

    if (event.key == 'Enter') {
      if (this.barcode.length > 5) {
        this.barcodeService.setBarcode(this.barcode);
      }
      this.barcode = '';
    } else {
      this.barcode += event.key;
    }
  }
}
