export enum StockAdjustment {
  DAMAGE = 'damage',
  EXPIRATION = 'expiration',
  INTERNAL_USE = 'internal_use',
  THEFT = 'theft',
  DONATION = 'donation',
}

export const REASONS = [
  { value: StockAdjustment.DAMAGE, label: 'Daño' },
  { value: StockAdjustment.EXPIRATION, label: 'Caducidad' },
  { value: StockAdjustment.INTERNAL_USE, label: 'Uso interno' },
  { value: StockAdjustment.THEFT, label: 'Robo' },
  { value: StockAdjustment.DONATION, label: 'Donación' },
];
