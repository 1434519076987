import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { defaultIfEmpty, from, map, Observable, tap } from 'rxjs';
import { PaymentMethod } from '../models/payment-method';
import { Response } from '../interfaces/response';
import { AppDatabase } from '../data/app-database';
import { liveQuery } from 'dexie';
import { PaymentMethodEntity } from '../data/entities/payment-method.entity';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  private readonly index = environment.api + '/v1/payment_methods';

  paymentMethods$ = from(liveQuery(() => this.database.paymentMethods.toArray())).pipe(
    map((items) => items.map((i) => new PaymentMethodEntity(i).model()))
  );

  constructor(protected api: ApiService, private database: AppDatabase) {}

  /**
   * Get all payment methods from local database
   * @returns
   */
  allFromLocal(): Observable<PaymentMethod[]> {
    return from(this.database.paymentMethods.toArray()).pipe(
      map((items) => items.map((i) => new PaymentMethodEntity(i).model()))
    );
  }

  /**
   * Sync data from server to local database
   */
  sync(): void {
    this.api.get<Response<PaymentMethod[]>>(PaymentMethod, this.index).subscribe((response) => {
      if (response.ok) {
        const entities = response.data.map((i) => i.entity());
        this.database.paymentMethods.clear();
        this.database.paymentMethods.bulkPut(entities);
      }
    });
  }

  get(id: string): Observable<Response<PaymentMethod | null>> {
    return this.api.get<Response<PaymentMethod | null>>(PaymentMethod, `${this.index}/${id}`).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.paymentMethods.put(response.data!.entity());
        }
      })
    );
  }

  all(): Observable<Response<PaymentMethod[]>> {
    return this.api.get<Response<PaymentMethod[]>>(PaymentMethod, this.index).pipe(
      tap((response) => {
        if (response.ok) {
          const entities = response.data.map((i) => i.entity());
          this.database.paymentMethods.clear();
          this.database.paymentMethods.bulkPut(entities);
        }
      })
    );
  }

  create(paymentMethod: PaymentMethod): Observable<Response<PaymentMethod>> {
    return this.api.post<Response<PaymentMethod>>(PaymentMethod, this.index, paymentMethod).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.paymentMethods.put(response.data.entity());
        }
      })
    );
  }

  update(paymentMethod: PaymentMethod): Observable<Response<PaymentMethod>> {
    return this.api
      .put<Response<PaymentMethod>>(PaymentMethod, `${this.index}/${paymentMethod.id}`, paymentMethod)
      .pipe(
        tap((response) => {
          if (response.ok) {
            this.database.paymentMethods.put(response.data.entity());
          }
        })
      );
  }

  delete(paymentMethod: PaymentMethod): Observable<Response<PaymentMethod>> {
    return this.api.delete<Response<PaymentMethod>>(PaymentMethod, `${this.index}/${paymentMethod.id}`).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.paymentMethods.delete(paymentMethod.id);
        }
      })
    );
  }
}
