export {};

declare global {
  interface Array<T> {
    _remove(item: T): void;
    _removeByIndex(index: number): void;
    _first(): T;
    _last(): T;
    _shuffled(): Array<T>;
    _take(n: number): Array<T>;
  }
}

if (!Array.prototype._remove) {
  Array.prototype._remove = function <T>(item: T) {
    const index = this.indexOf(item);
    if (index != -1) {
      this.splice(index, 1);
    }
  };
}

if (!Array.prototype._removeByIndex) {
  Array.prototype._removeByIndex = function <T>(index: number) {
    this.splice(index, 1);
  };
}

if (!Array.prototype._first) {
  Array.prototype._first = function <T>(): T {
    return this[0];
  };
}

if (!Array.prototype._last) {
  Array.prototype._last = function <T>(): T {
    return this[this.length - 1];
  };
  //   Object.defineProperty(Array.prototype, '_last', {
  //     enumerable: false,
  //     writable: false,
  //     configurable: false,
  //     value: function <T>(): T {
  //       return this[this.length - 1];
  //     },
  //   });
}

if (!Array.prototype._shuffled) {
  Array.prototype._shuffled = function <T>(): Array<T> {
    for (let i = this.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this[i], this[j]] = [this[j], this[i]];
    }
    return this;
  };
}

if (!Array.prototype._take) {
  Array.prototype._take = function <T>(n: number): Array<T> {
    return this.slice(0, n);
  };
}
