export {};

// WARNING: Do not use this extension, it is not recommended to extend the Object prototype. Error in Dexie.js library. Use the mapObject function instead.
// declare global {
//   interface Object {
//     __map(i: Function): Object;
//   }
// }

// if (!Object.prototype.__map) {
//   Object.prototype.__map = function (callback) {
//     return callback(this);
//   };
// }

export function mapObject<T>(obj: T, callback: (value: T) => any): any {
  if (obj === undefined || obj === null) {
    return obj;
  }
  return callback(obj);
}
