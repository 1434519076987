export enum LotReviewType {
  EXPIRATION = 'expiration',
  DONATION = 'donation',
  STOCKOUT = 'stockout',
  INTERNAL_USE = 'internal_use',
  DISCOUNT = 'discount',
}

export const LOT_REVIEW_TYPES = [
  // { id: null, name: 'No seleccionado' },
  { id: LotReviewType.STOCKOUT, name: 'Agotado' },
  { id: LotReviewType.EXPIRATION, name: 'Expirado' },
  { id: LotReviewType.DONATION, name: 'Donación' },
  { id: LotReviewType.INTERNAL_USE, name: 'Use interno' },
  { id: LotReviewType.DISCOUNT, name: 'Descuento' },
];
