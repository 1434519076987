import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, OnDestroy, ViewContainerRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { environment } from 'environments/environment';
import { AppSettings } from '../../app.config';
import { NavItem, menu, menuFooter } from '../../components/sidebar/menu';
import {
  AppService,
  AuthService,
  Barcode,
  BarcodeService,
  Chat,
  Business,
  BusinessService,
  EventService,
  RegisterLite,
  SidenavService,
  Store,
  StoreService,
  CountryService,
  TranslationService,
} from '../../core';
import { CoreService } from '../../core/services/core.service';
import { NavigationService } from '../../core/services/navigation.service';
import { GiftCardViewComponent } from '../../modules/product/pages/gift-card/gift-card-view/gift-card-view.component';
import { Observable } from 'rxjs';
import { UserConfig } from '../../core/services/user-config.service';
import { ChatDialogComponent } from '../../components/chat-dialog/chat-dialog.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StoreEntity } from '@app/core/data/entities/store.entity';
import { Router, RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TablerIconsModule } from 'angular-tabler-icons';
import { SharedModule } from '@app/shared/shared.module';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'main',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss', './rail.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterModule,
    SharedModule,
    // AppNavItemComponent,
    MaterialModule,
    CommonModule,
    // SidebarComponent
    NgScrollbarModule,
    TablerIconsModule,
    // AppHorizontalHeaderComponent,
    // AppHorizontalSidebarComponent,
    // AppBreadcrumbComponent,
    // CustomizerComponent,
  ],
})
export class FullComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  private htmlElement!: HTMLHtmlElement;

  activeItem: NavItem;

  //get options from service
  options = this.settings.getOptions();

  items = menu;
  itemsFooter = menuFooter;
  buildVersion = environment.buildVersion;
  buildDate = environment.buildDate;
  account: any;
  accountPhotoURL: string;

  // isLoading = true;
  business$ = this.businessService.business$; //: Observable<Business[]>;
  locals$ = this.storeService.locals$; // Observable<Store[]>;
  selectedBusiness: Business | null;
  selectedLocal: Store | null;
  selectedRegister: RegisterLite | null;
  languages = this.countryService.getLanguages();
  defaultLang = this.translationService.defaultLang;

  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  @ViewChild('sidenavRight') sidenavContainerRight: MatSidenav;
  @ViewChild('dynamicContent', { read: ViewContainerRef }) dynamicContentContainer: ViewContainerRef;

  constructor(
    private countryService: CountryService,
    private settings: CoreService,
    private navigation: NavigationService,
    private observer: BreakpointObserver,
    private barcodeSerivce: BarcodeService,
    private dialog: MatDialog,
    private authService: AuthService,
    private app: AppService,
    private eventService: EventService,
    firebaseAuth: AngularFireAuth,
    public config: UserConfig,
    private storeService: StoreService,
    private businessService: BusinessService,
    private sidenavService: SidenavService,
    private translationService: TranslationService
  ) {
    firebaseAuth.authState.subscribe((firebaseUser) => {
      this.account = firebaseUser;
      this.accountPhotoURL = firebaseUser?.photoURL ?? '';

      if (firebaseUser) {
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'qkicwl2m',
          user_id: firebaseUser.uid,
          name: firebaseUser.displayName,
          email: firebaseUser.email,
          business_id: this.config.currentBusinessId,
          // created_at: firebaseUser.createdAt, // Signup date as a Unix timestamp
        });
        window.Intercom('update', {
          hide_default_launcher: true,
        });
      }
    });

    // call to Intercom('showSpace', 'tickets'); after 5 seconds
    // setTimeout(() => {
    //   // window.Intercom('showSpace', 'tickets');
    //   // window.Intercom('update', {
    //   //   hide_default_launcher: true,
    //   // });
    //   window.Intercom('show');
    // }, 5000);

    this.config.business$.subscribe((business) => {
      this.selectedBusiness = business;
      // if (business) {
      //   this.isLoading = false;
      // }
    });

    this.config.local$.subscribe((local) => {
      this.selectedLocal = local;
    });

    // this.account = authService.account$;

    this.htmlElement = document.querySelector('html')!;

    // Initialize project theme with options
    this.receiveOptions(this.options);

    // listen barcode scan
    this.barcodeSerivce.barcode$.subscribe((barcode: Barcode) => {
      switch (barcode.type) {
        case 'giftcard':
          this.showGiftCard(barcode.giftCardId);
          break;

        case 'order':
          this.navigation.toOrderView(barcode.invoiceNumber);
          break;
      }
    });
  }

  switchLanguage(lang: string): void {
    this.defaultLang = lang;
    this.translationService.changeLang(lang);
  }

  onItemClick(item: NavItem): void {
    //this.activeItem = item;
    //this.navigation.to(item.url);

    console.log('item', item);

    if (item.id === 'help') {
      window.Intercom('show');
      // window.Intercom('showSpace', 'tickets');
      // window.Intercom('showTicket', '5');
    }
  }

  setMenuItemVisibility(id: string, visible: boolean): void {
    const item = this.getMenuById(id);
    if (item) {
      item.hidden = !visible;
    }
  }

  getMenuById(id: string): NavItem | undefined {
    for (const item of this.items) {
      if (item.id === id) {
        return item;
      } else if (item.children) {
        for (const child of item.children) {
          if (child.id === id) {
            return child;
          }
        }
      }
    }
    return undefined;
  }

  onBusinessSelect(business: Business): void {
    this.config.setBusiness(business);
    this.config.reloadPage();
  }

  onLocalSelect(local: Store): void {
    this.config.setStore(local);
    this.config.setRegister(null);
  }

  onRegisterSelect(local: Store, register: RegisterLite): void {
    // if (register.id == this.selectedRegister?.id) {
    //   this.config.setRegister(null);
    // } else {
    this.config.setStore(local);
    this.config.setRegister(register);
    // }
  }

  showChat(chat: Chat) {
    const dialogRef = this.dialog.open(ChatDialogComponent, {
      width: '700px',
      data: chat,
    });

    dialogRef.afterClosed().subscribe((url: string | undefined) => {
      if (url) {
      }
    });
  }

  logout(): void {
    this.authService.logout();
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenavContainerRight);
    this.sidenavService.setViewContainerRef(this.dynamicContentContainer);

    this.sidenavContainer.scrollable.elementScrolled().subscribe((e: Event) => {
      // Obtener la posición de desplazamiento actual
      const scrollTop = this.sidenavContainer.scrollable.getElementRef().nativeElement.scrollTop || 0;

      // Verificar si la posición de desplazamiento es mayor o igual a 10px
      if (scrollTop >= 10) {
        $('.page .header').addClass('sticky');
      } else {
        $('.page .header').removeClass('sticky');
      }
    });

    // NOTE: ExpressionChangedAfterItHasBeenCheckedError fixed using Promise.resolve().then()
    Promise.resolve(null).then(() => {
      this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
    });
  }

  ngOnInit(): void {
    moment().locale('es');

    this.config.local$.subscribe((store) => {
      if (store != null) {
        this.eventService.addListener(store?.id);
      }
    });

    this.config.register$.subscribe((register) => {
      this.selectedRegister = register;

      // this.setMenuItemVisibility('sales-register', !!register);
      // this.setMenuItemVisibility('sales-cash-management', !!register);
      // this.setMenuItemVisibility('sales-register-closure', !!register);

      // if (register) {
      // Set register name in menu
      const salesRegisterItem = this.getMenuById('sales-register');
      if (salesRegisterItem) {
        salesRegisterItem.icon = register?.isOpen ? 'lock-open' : 'lock';
        salesRegisterItem.name = register?.isOpen ? 'Cerrar caja' : 'Abrir caja';
      }
      // }
    });
  }

  ngOnDestroy(): void {
    this.eventService.removeListener();
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }

  showGiftCard(giftcardId: string): void {
    this.dialog.open(GiftCardViewComponent, {
      width: '940px',
      data: giftcardId,
    });
  }
}
