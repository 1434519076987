import { MediaType } from '../enums/media-type';

export class Media {
  private _type: MediaType;
  private _url: string;

  public get type(): MediaType {
    return this._type;
  }

  public set type(type: MediaType) {
    this._type = type;
  }

  public get url(): string {
    return this._url;
  }

  public set url(url: string) {
    this._url = url;
  }

  constructor(init?: Partial<Media>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      type: this.type,
      url: this.url,
    };
  }
}
