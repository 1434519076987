import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Brand } from '../models/brand';
import { Response } from '../interfaces/response';
import { BrandFilter } from '../filters/brand.filter';
import { OrderBy, SortBy } from '../enums/sorting';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private readonly index = environment.api + '/v1/brands';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Brand | null>> {
    return this.api.get<Response<Brand | null>>(Brand, this.index + '/' + id);
  }

  all(): Observable<Response<Brand[]>> {
    const p = new BrandFilter({ sort: SortBy.NAME, order: OrderBy.ASC, limit: 500 });
    return this.api.get<Response<Brand[]>>(Brand, this.index + (p?.getQuery() ?? ''));
  }

  create(brand: Brand): Observable<Response<Brand>> {
    return this.api.post<Response<Brand>>(Brand, this.index, brand);
  }

  createMany(categories: Brand[]): Observable<Response<Brand[]>> {
    return this.api.post<Response<Brand[]>>(Brand, `${this.index}/bulk`, categories);
  }

  update(brand: Brand): Observable<Response<Brand>> {
    return this.api.put<Response<Brand>>(Brand, this.index + '/' + brand.id, brand);
  }

  delete(brand: Brand): Observable<Response<Brand>> {
    return this.api.delete<Response<Brand>>(Brand, this.index + '/' + brand.id);
  }
}
