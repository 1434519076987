import { ProductCodeType } from '../enums/product-code-type';

export class ProductCode {
  id: string;
  type: ProductCodeType;
  code: string;

  constructor(init?: Partial<ProductCode>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      type: this.type,
      code: this.code,
    };
  }
}
