import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  // List of currencies and their codes (you can add more)
  private currencies = [
    // Latam currencies
    'PEN', // Perú - Nuevo Sol
    'ARS', // Argentina - Peso Argentino
    'BOB', // Bolivia - Boliviano
    'BRL', // Brasil - Real Brasileño
    'CLP', // Chile - Peso Chileno
    'COP', // Colombia - Peso Colombiano
    'CRC', // Costa Rica - Colón Costarricense
    'CUP', // Cuba - Peso Cubano
    'DOP', // República Dominicana - Peso Dominicano
    'GTQ', // Guatemala - Quetzal Guatemalteco
    'HNL', // Honduras - Lempira Hondureño
    'MXN', // México - Peso Mexicano
    'NIO', // Nicaragua - Córdoba Nicaragüense
    'PYG', // Paraguay - Guaraní Paraguayo
    'PEN', // Perú - Sol Peruano
    'UYU', // Uruguay - Peso Uruguayo
    'VES', // Venezuela - Bolívar Venezolano (Soberano)
    'USD', // Varios países usan USD como moneda oficial (Panamá, Ecuador, El Salvador)

    // Other currencies
    'AUD', // Australia - Dólar Australiano
    'CAD', // Canadá - Dólar Canadiense
    'CNY', // China - Yuan Chino
    'EUR', // Euro
    'GBP', // Reino Unido - Libra Esterlina
    'JPY', // Japón - Yen Japonés
    'KRW', // Corea del Sur - Won Surcoreano
    'RUB', // Rusia - Rublo Ruso
    'SAR', // Arabia Saudita - Riyal Saudí
    'ZAR', // Sudáfrica - Rand Sudafricano
  ];

  constructor() {}

  // Method to get the list of currencies in the format { label, value }
  getCurrencies(): Array<{ label: string; value: string }> {
    const currenciesList: Array<{ label: string; value: string }> = [];

    this.currencies.forEach((currencyCode) => {
      const label = this.getCurrencyDisplayName(currencyCode);
      if (label) {
        currenciesList.push({ label, value: currencyCode });
      }
    });

    return currenciesList;
  }

  // Get the display name of a currency given its code
  private getCurrencyDisplayName(currencyCode: string): string | null {
    try {
      // We use Intl.NumberFormat to get the currency name
      const currencyName = new Intl.NumberFormat('es-PE', {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'name',
      })
        .formatToParts()
        .find((part) => part.type === 'currency')?.value;

      // Capitalize the currency name (example: "Peruvian Soles")
      return currencyName ? this.capitalizeWords(currencyName) : null;
    } catch (error) {
      console.error(`Error getting the currency name for code ${currencyCode}: `, error);
      return null;
    }
  }

  // Helper function to capitalize the currency name
  private capitalizeWords(text: string): string {
    return text
      .split(' ')
      .map((word) => {
        // Capitalize the first letter and keep the rest as it is
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }
}
