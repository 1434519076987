import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'user-profile-picture',
  templateUrl: './user-profile-picture.component.html',
  styleUrls: ['./user-profile-picture.component.scss'],
  standalone: true,
  imports: [],
})
export class UserProfilePictureComponent implements OnInit, OnDestroy {
  @Input() size: string = '28px';
  account: firebase.User | null;
  photoURL: string = '';

  private subscribe: any;

  constructor(firebaseAuth: AngularFireAuth) {
    this.subscribe = firebaseAuth.user.subscribe((user) => {
      this.account = user;
      this.photoURL =
        user?.photoURL || 'https://ui-avatars.com/api/?background=103b63&color=fff&name=' + user?.displayName;
    });
  }

  ngOnInit(): void {}

  name(): string {
    return this.account?.displayName || '';
  }

  email(): string {
    return this.account?.email || this.account?.phoneNumber || '';
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
}
