export class User {
  public id: string;
  public pin: string;
  public name: string;
  public lastName: string;
  public displayName: string;
  public imageUrl: string;
  public authProvider: string;
  public email: string;
  public phone: string;
  public mobile: string;
  public idNumber: string;
  public address: string;
  public birthDate: string;
  public gender: string;
  public storeId: string;
  public storeIds: string[];
  public ordersCount: number;
  public isOwner: boolean;
  public role: string;
  public quickKeyId: string;
  public patternLock: string;
  public lastActivity: Date;
  public isAvailable: boolean;
  public isEnabled: boolean;
  public isActive: boolean;
  public isNotified: boolean;
  public createdBy: string;
  public deletedBy: string;
  public createdAt: Date;
  public updatedAt: Date;
  public deletedAt: Date;

  // Used for create or change password
  password: string | null = null;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      pin: this.pin,
      name: this.name,
      last_name: this.lastName,
      display_name: this.displayName,
      image_url: this.imageUrl,
      auth_provider: this.authProvider,
      email: this.email,
      password: this.password,
      phone: this.phone,
      mobile: this.mobile,
      id_number: this.idNumber,
      address: this.address,
      birth_date: this.birthDate,
      gender: this.gender,
      store_id: this.storeId,
      store_ids: this.storeIds,
      orders_count: this.ordersCount,
      role: this.role,
      quick_key_id: this.quickKeyId,
      pattern_lock: this.patternLock,
      last_activity: this.lastActivity,
      is_available: this.isAvailable,
      is_enabled: this.isEnabled,
      is_active: this.isActive,
      is_notified: this.isNotified,
      created_by: this.createdBy,
      deleted_by: this.deletedBy,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      deleted_at: this.deletedAt,
    };
  }

  /**
   * Guardar en camelcase para deserealizar correctamente
   */
  public toLocalStorage(): any {
    return {
      id: this.id,
      name: this.name,
      email: this.email ?? null,
      imageUrl: this.imageUrl ?? null,
    };
  }
}
