export enum ConsignmentStatus {
  OPEN = 'open',
  SENT = 'sent',
  DISPATCHED = 'dispatched',
  RECEIVED = 'received',
  CANCELED = 'canceled',
}

export const CONSINGMENT_STATUS_LABELS = {
  [ConsignmentStatus.OPEN]: 'Abierto',
  [ConsignmentStatus.SENT]: 'Enviado',
  [ConsignmentStatus.DISPATCHED]: 'Despachado',
  [ConsignmentStatus.RECEIVED]: 'Recibido',
  [ConsignmentStatus.CANCELED]: 'Cancelado',
};
