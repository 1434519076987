import { OrderBy, SortBy } from '../enums/sorting';
import { PriceBookType } from '../models/price-book';

export class PriceBookFilter {
  query?: string | null;
  storeId?: string | null;
  type?: PriceBookType | null;
  sort?: SortBy | null;
  order?: OrderBy | null;
  start?: number | null;
  limit?: number | null;

  // helper
  currentPage = 1;
  itemsPerPage = 20;

  constructor(init?: Partial<PriceBookFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let start = this.start;
    let limit = this.limit;

    if (start === undefined && limit === undefined) {
      start = (this.currentPage - 1) * this.itemsPerPage;
      limit = this.itemsPerPage;
    }

    let q = '';
    q += this.query ? '&q=' + this.query : '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q += this.type ? '&type=' + this.type : '';
    q += this.order ? '&order=' + this.order : '';
    q += start ? '&start=' + start : '';
    q += limit ? '&limit=' + limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
