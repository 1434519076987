export enum Role {
  ADMIN = 'admin',
  MANAGER = 'manager',
  CASHER = 'cashier',
}

export const ROLES = [
  { label: 'Administador', value: Role.ADMIN },
  { label: 'Gerente', value: Role.MANAGER },
  { label: 'Cajero', value: Role.CASHER },
];
