import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppService,
  Consignment,
  ConsignmentService,
  ConsignmentType,
  NotifyService,
  PrintService,
  Store,
} from '@app/core';

@Component({
  selector: 'ns-print-consignment',
  templateUrl: './print-consignment.component.html',
  styleUrls: ['./print-consignment.component.scss'],
})
export class PrintConsignmentComponent implements OnInit {
  consignmentId: string;

  model?: Consignment;
  store: Store | undefined;

  orderType: ConsignmentType = ConsignmentType.ORDER;
  // receptionType: ConsignmentType = ConsignmentType.RECEPTION;
  returnType: ConsignmentType = ConsignmentType.RETURN;
  transferType: ConsignmentType = ConsignmentType.TRANSFER;

  constructor(
    private app: AppService,
    route: ActivatedRoute,
    private notify: NotifyService,
    private consignmentService: ConsignmentService,
    private printService: PrintService
  ) {
    this.consignmentId = route.snapshot.params['id'];
  }

  ngOnInit() {
    this.consignmentService.get(this.consignmentId).subscribe((response) => {
      if (response.ok) {
        this.model = response.data!!;
        this.store = this.app.stores.find((s) => s.id == response.data?.storeId);
        setTimeout(() => {
          this.printService.onDataReady();
        }, 10);
      } else {
        this.notify.error(response);
      }
    });
  }
}
