export enum Error {
  UNAUTHORIZED = 'unauthorized',
  FORBIDDEN = 'forbidden',
  ERROR = 'error',
  NOT_FOUND = 'not_found',
  UNPROCESSABLE = 'unprocessable',
  VALIDATION = 'validation_error',
  ACCOUNT_NOT_EXIST = 'account_not_exist',
  ACCOUNT_ALREADY_EXISTS = 'account_already_exists',
  CONTENT_MODERATION = 'content_moderation',
  LIMIT_REACHED = 'limit_reached',
  BUSINESS_REQUIRED = 'business_required',
  DOCUMENT_TYPE_NOT_CONFIGURED = 'document_type_not_configured',
  INVOICING_DISABLED = 'invoicing_disabled',

  CONNECTION_REFUSED = 'ERR_CONNECTION_REFUSED',
  HTTP_NOT_FOUND = 'HTTP_NOT_FOUND',
}

const errorsMessage: Map<Error, string> = new Map([
  [Error.UNAUTHORIZED, 'No está autorizado para realizar esta operación.'],
  [Error.FORBIDDEN, 'La operación no está permitida.'],
  [Error.VALIDATION, 'Error de validación.'],
  [Error.UNPROCESSABLE, 'No se puede procesar esta solicitud.'],
  [Error.NOT_FOUND, 'El registro no existe.'],
  [Error.ACCOUNT_NOT_EXIST, 'La cuenta no existe.'],
  [Error.CONTENT_MODERATION, 'Algunas palabras están prohibidas.'],
  [Error.LIMIT_REACHED, 'Ha alcanzado el límite máximo de registros.'],
  [Error.HTTP_NOT_FOUND, 'No se puede procesar esta solicitud.'],
  [Error.DOCUMENT_TYPE_NOT_CONFIGURED, 'El tipo de documento no está configurado para emitir comprobantes.'],
  [Error.INVOICING_DISABLED, 'La facturación está deshabilitada.'],
]);

export function errorMessage<T>(response: Response<T>): string {
  if (response.message) {
    return response.message;
  } else if (errorsMessage.has(response.error!)) {
    return errorsMessage.get(response.error!)!;
  } else {
    return response.message ?? 'Lo sentimos, algo salió mal.';
  }
}

export class Response<T> {
  ok: boolean;
  error: Error | undefined;
  message: string | undefined;
  data: T;

  // constructor(init?: Partial<Response>) {
  //   Object.assign(this, init);
  // }

  /**
   * @todo not working...
   */
  public get success(): boolean {
    return this.ok == true && this.data != null;
  }
}

export function responseSuccess<T>(value: any): Response<T> {
  return { ok: true, error: undefined, message: undefined, data: value, success: true };
}

export function responseError<T>(error: any): Response<T> {
  return { ok: false, error: error, message: undefined, data: null as any, success: true };
}
