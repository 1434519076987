export enum CashMovementType {
  OPENING_FLOAT = 'opening_float',
  CASH_IN = 'cash_in',
  PETTY_CASH_IN = 'petty_cash_in',
  CASH_OUT = 'cash_out',
  PETTY_CASH_OUT = 'petty_cash_out',
}

export const CashMovementTypes = [
  { label: 'Apertura de caja', value: CashMovementType.OPENING_FLOAT },
  { label: 'Ingreso de efectivo', value: CashMovementType.CASH_IN },
  { label: 'Ingreso de efectivo', value: CashMovementType.PETTY_CASH_IN },
  { label: 'Salida de efectivo', value: CashMovementType.CASH_OUT },
  { label: 'Salida de efectivo', value: CashMovementType.PETTY_CASH_OUT },
];
