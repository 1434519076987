<!-- <app-header #header [activeItem]="activeItem" [items]="items" (toggleMobileNav)="sidenav.toggle()"></app-header> -->

<!-- <mat-toolbar>
  <dl-toolbar></dl-toolbar>
</mat-toolbar> -->

<!-- <div class="chat-button-container" (click)="sidenavRight.opened = !sidenavRight.opened"> -->
<!-- <button
    (click)="sidenavRight.opened = !sidenavRight.opened"
    mat-fab
    color="primary"
    aria-label="Example icon button with a delete icon"
  > -->
<!-- <mat-icon>
    <i-tabler *ngIf="!sidenavRight.opened" name="message-2" class="icon-18"></i-tabler>
    <i-tabler *ngIf="sidenavRight.opened" name="square-rounded-x" class="icon-18"></i-tabler>
  </mat-icon> -->
<!-- </button> -->
<!-- </div> -->

<mat-sidenav-container
  barcode
  class="noprint"
  fullscreen
  [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top',
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    cyan_theme: options.activeTheme == 'cyan_theme'
  }"
>
  <mat-sidenav class="sidenav" #sidenav mode="side" [opened]="true">
    <!-- <dl-toolbar></dl-toolbar> -->

    <!-- <div class="sidenav-items"> -->
    <!-- <dl-toolbar></dl-toolbar> -->

    <!-- rail -->
    <div class="left-nav-rail">
      <!-- menu modules -->
      <nav class="ns-sidenav nav-rail-content">
        <!-- logo -->
        <a *ngIf="false" routerLink="/" class="nav-rail-header">
          <div class="google-symbols">
            <svg
              style="width: 2.3rem; height: 2.3rem"
              width="180"
              height="180"
              viewBox="0 0 180 180"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M95.3726 42.9333C111.628 26.6777 137.984 26.6777 154.239 42.9333C170.495 59.1889 170.495 85.5444 154.239 101.8L119.237 136.802C102.982 153.057 76.6264 153.057 60.3708 136.802C44.1152 120.546 44.1152 94.1907 60.3708 77.9351L95.3726 42.9333Z"
                fill="url(#paint0_linear_919_557)"
              />
              <path
                d="M25.4958 101.8C9.24024 85.5444 9.24025 59.189 25.4958 42.9334C41.7514 26.6778 68.1069 26.6778 84.3625 42.9334L140.843 99.4135C145.236 103.807 145.236 110.93 140.843 115.323L119.364 136.802C103.109 153.057 76.7532 153.057 60.4976 136.802L25.4958 101.8Z"
                fill="url(#paint1_linear_919_557)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_919_557"
                  x1="154.239"
                  y1="42.9333"
                  x2="60.3708"
                  y2="136.802"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#3A7DFF" />
                  <stop offset="1" stop-color="#5379FF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_919_557"
                  x1="25.4958"
                  y1="42.9334"
                  x2="119.364"
                  y2="136.802"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#36FFF3" />
                  <stop offset="1" stop-color="#366EFF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <!-- <div class="label">{{ i.name }}</div> -->
        </a>

        <!-- logo -->
        <a routerLink="/" class="app-logo">
          <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M95.3726 42.9333C111.628 26.6777 137.984 26.6777 154.239 42.9333C170.495 59.1889 170.495 85.5444 154.239 101.8L119.237 136.802C102.982 153.057 76.6264 153.057 60.3708 136.802C44.1152 120.546 44.1152 94.1907 60.3708 77.9351L95.3726 42.9333Z"
              fill="url(#paint0_linear_919_557)"
            />
            <path
              d="M25.4958 101.8C9.24024 85.5444 9.24025 59.189 25.4958 42.9334C41.7514 26.6778 68.1069 26.6778 84.3625 42.9334L140.843 99.4135C145.236 103.807 145.236 110.93 140.843 115.323L119.364 136.802C103.109 153.057 76.7532 153.057 60.4976 136.802L25.4958 101.8Z"
              fill="url(#paint1_linear_919_557)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_919_557"
                x1="154.239"
                y1="42.9333"
                x2="60.3708"
                y2="136.802"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3A7DFF" />
                <stop offset="1" stop-color="#5379FF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_919_557"
                x1="25.4958"
                y1="42.9334"
                x2="119.364"
                y2="136.802"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#36FFF3" />
                <stop offset="1" stop-color="#366EFF" />
              </linearGradient>
            </defs>
          </svg>
          <!-- <div class="label">{{ i.name }}</div> -->
        </a>

        <!-- search -->
        <div class="nav-rail-search">
          <button
            matTooltip=""
            matTooltipPosition="right"
            (click)="header.openDialog()"
            mat-icon-button
            class="d-flex justify-content-center"
          >
            <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
          </button>
        </div>

        <!-- items -->
        <div class="nav-rail-items">
          <div>
            <ng-container *ngFor="let i of items; let idx = index">
              <a
                class="section-link"
                *ngIf="!i.hidden"
                (isActiveChange)="$event && (activeItem = i)"
                [routerLink]="i.route"
                routerLinkActive="is-active"
              >
                <div class="google-symbols" matTooltip="{{ i.name }}" matTooltipPosition="right">
                  <i-tabler name="{{ i.icon }}" class="d-flex"></i-tabler>
                </div>
                <!-- <div class="label">{{ i.name }}</div> -->
              </a>
            </ng-container>
          </div>
          <div>
            <ng-container *ngFor="let i of itemsFooter; let idx = index">
              @if(!i.hidden){
              <a
                *ngIf="i.route"
                class="section-link"
                (isActiveChange)="$event && (activeItem = i)"
                [routerLink]="i.route"
                routerLinkActive="is-active"
              >
                <div class="google-symbols"><i-tabler name="{{ i.icon }}" class="d-flex"></i-tabler></div>
                <div *ngIf="i.name" class="label">{{ i.name }}</div>
              </a>
              <a *ngIf="!i.route" class="section-link" (click)="onItemClick(i)" routerLinkActive="is-active">
                <div *ngIf="i.icon" class="google-symbols"><i-tabler [name]="i.icon" class="d-flex"></i-tabler></div>
                <div *ngIf="i.name" class="label">{{ i.name }}</div>
              </a>
              }
            </ng-container>
            <!-- test mode -->
            <!-- <a class="section-link" routerLink="settings/plans">
              <div class="google-symbols"><i-tabler name="test-pipe" class="d-flex"></i-tabler></div>
              <div class="label">Gratis</div>
            </a> -->
          </div>
        </div>

        <!-- nav rail footer -->
        <div class="nav-rail-footer account d-flex flex-column" style="cursor: pointer">
          <!-- icons -->
          <div class="hidden rail-icons">
            <a class="nav-rail-icon" routerLink="assistant/app" routerLinkActive="is-active">
              <div class="icon-bg">
                <i-tabler name="inner-shadow-bottom-filled" class="icon-18"></i-tabler>
                <!-- <i-tabler name="brand-bilibili" class="icon-18"></i-tabler> -->
              </div>
            </a>
            <a class="nav-rail-icon">
              <div class="icon-bg">
                <i-tabler name="bell" class="icon-18"></i-tabler>
              </div>
            </a>
            <a class="nav-rail-icon">
              <div class="icon-bg">
                <i-tabler name="help-hexagon" class="icon-18"></i-tabler>
              </div>
            </a>
          </div>

          <ng-container *ngIf="false">
            <div [matMenuTriggerFor]="menu" class="username-container position-relative d-flex flex-align-center">
              <!-- <img
                class="image"
                style="width: 2.3rem; height: 2.3rem; border-radius: 50%"
                src="{{ accountPhotoURL }}"
                alt=""
              /> -->
              <user-profile-picture></user-profile-picture>
              <!-- <div class="name">{{ (account)?.displayName }}</div> -->
              <i-tabler name="chevron-down" class="icon-12 m-t-4 d-flex flex-justify-center"></i-tabler>
            </div>

            <mat-menu #menu="matMenu">
              <div class="d-flex p-y-12 flex-col align-items-center">
                <!-- <img
                  class="image"
                  style="width: 4.6rem; height: 4.6rem; border-radius: 50%"
                  src="{{ accountPhotoURL }}"
                  alt=""
                /> -->
                <user-profile-picture size="4rem"></user-profile-picture>
                <div class="p-t-12 f-w-600">{{ account?.displayName }}</div>
                <div class="email">{{ account?.email }}</div>
              </div>

              <div (click)="onBusinessSelect(i)" mat-menu-item *ngFor="let i of business$ | async">
                <!-- <mat-icon>voicemail</mat-icon> -->
                <div class="d-flex flex-row align-items-center justify-content-between">
                  <span class="name">{{ i.name }}</span>
                  <i-tabler
                    style="margin-left: 0.5rem"
                    *ngIf="i.id == selectedBusiness?.id"
                    name="circle-check"
                    class="icon-18 d-flex flex-justify-center"
                  ></i-tabler>
                </div>
              </div>
              <!-- <div mat-menu-item class="d-flex flex-col align-items-center">
                <img
                  class="image"
                  style="width: 4.6rem; height: 4.6rem; border-radius: 50%"
                  src="{{ (account)?.photoURL }}"
                  alt=""
                />
                <div class="name">{{ (account)?.displayName }}</div>
                <div class="email">{{ (account)?.email }}</div>
              </div> -->
              <button routerLink="/business-register" mat-menu-item>
                <mat-icon>
                  <i-tabler name="plus" class="icon-20 d-flex flex-justify-center"></i-tabler>
                </mat-icon>
                <span>Crear nuevo negocio</span>
              </button>
              <button (click)="logout()" mat-menu-item>
                <mat-icon>
                  <i-tabler name="power" class="icon-20 d-flex flex-justify-center"></i-tabler>
                </mat-icon>
                <span> Cerrar sesión </span>
              </button>

              <!-- language -->
              <!-- <div>
                {{ 'HELLO' | translate }}
                <mat-chip-set aria-label="Language selection">
                  <mat-chip
                    (click)="switchLanguage(language.value)"
                    *ngFor="let language of languages"
                    [value]="language.value"
                  >
                    {{ language.label }}
                  </mat-chip>
                </mat-chip-set>
              </div> -->
            </mat-menu>
          </ng-container>

          <div class="version">
            <span>v{{ buildVersion }}</span>
            <!-- - <span>{{ buildDate }}</span> -->
          </div>
        </div>
      </nav>

      <!-- menu items -->
      <nav *ngIf="false" class="nav-rail-items-content">
        <!-- store -->
        <ng-container>
          <div class="local-container d-flex flex-col" [matMenuTriggerFor]="appsmenu">
            <!-- business -->
            <!-- <div>
              <div>
                <img
                  class="image"
                  style="width: 25px; border-radius: 50%"
                  src="{{ selectedBusiness?.logoUrl }}"
                  alt=""
                />
              </div>
              <div class="sidenav-toolbar-logo">
                <span>{{ selectedBusiness?.name ?? 'Negocio' }}</span> <br />
              </div>
            </div> -->

            <!-- <div class="local-icon">
              <i-tabler name="map-pin" class="icon-16 m-r-8"></i-tabler>
            </div> -->
            <div class="local-name">
              <!-- <div class="d-flex flex-row align-items-center justify-content-between">
                <span class="selected-business">{{ selectedBusiness?.name ?? 'Negocio' }}</span>
                <i-tabler name="chevron-down" class="icon-12 d-flex flex-justify-center"></i-tabler>
              </div>

              <br />
              <label for="Local">Local</label>
              <span class="selected-local">{{ selectedLocal?.local ?? 'Local' }}</span>

              <ng-container *ngIf="selectedRegister">
                <br />
                <label for="Local">Caja</label>
                <span class="selected-register">{{ selectedRegister?.name ?? 'Caja' }}</span>
              </ng-container>

              <br />
              <mat-divider></mat-divider> -->

              <div class="d-flex align-items-center justify-content-between">
                <span class="selected-local">{{ selectedLocal?.name ?? 'Local' }}</span>
                <i-tabler name="chevron-down" class="icon-14"></i-tabler>
              </div>
              @if(selectedRegister){
              <span class="selected-register">{{ selectedRegister?.name }}</span>
              }

              <!-- <ng-container *ngIf="!selectedRegister">
                <span class="selected-local">{{ selectedLocal?.local ?? 'Local' }}</span>
              </ng-container>
              <ng-container *ngIf="selectedRegister">
                <span class="selected-local">{{ selectedRegister?.name ?? 'Caja' }}</span>
                <span class="selected-register">{{ selectedLocal?.local ?? 'Local' }}</span>
              </ng-container> -->
            </div>
          </div>
          <!-- <button mat-button [matMenuTriggerFor]="appsmenu" aria-label="Local">
              <div class="d-flex align-items-center">
                <i-tabler name="map-pin" class="icon-20 m-r-8"></i-tabler>
                {{ selectedLocal?.local ?? 'Local' }}
                <i-tabler name="chevron-down" class="icon-16 m-l-4"></i-tabler>
              </div>
            </button> -->
          <mat-menu #appsmenu="matMenu" class="apps-dd cardWithShadow">
            <div class="p-24">
              <div class="row">
                <!-- stores -->
                <div class="col col-12" *ngFor="let local of locals$ | async">
                  <!-- store -->
                  <div class="row text-hover-primary">
                    <div class="col">
                      <a
                        (click)="onLocalSelect(local)"
                        class="cursor-pointer d-flex justify-content-between align-items-center text-decoration-none"
                      >
                        <!-- <span
                          class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center"
                        > -->
                        <!-- <img [src]="local.img" width="20" /> -->
                        <!-- </span> -->

                        <div>
                          <h5 class="f-s-14 f-w-600 m-0 textprimary _mat-body-1 hover-text">
                            {{ local.name }}
                          </h5>
                          <span class="_mat-body-1 f-s-12">{{ local.address }}</span>
                        </div>

                        <i-tabler
                          *ngIf="selectedLocal?.id === local.id && !selectedRegister"
                          name="circle-check"
                          class="icon-20 m-r-8"
                        ></i-tabler>
                      </a>
                    </div>
                  </div>

                  <!-- registers -->
                  <div class="row">
                    <div class="col">
                      <div class="registers">
                        <div
                          (click)="onRegisterSelect(local, register)"
                          class="register col cursor-pointer text-hover-primary"
                          [class.is-selected]="selectedRegister?.id === register.id"
                          *ngFor="let register of local.registers"
                        >
                          <a
                            class="d-flex _align-items-center w-100 align-items-center justify-content-between text-decoration-none"
                          >
                            <div class="m-l-10 m-r-10">
                              <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                                {{ register.name }}
                              </h5>
                              <span *ngIf="register.isOpen === true" class="mat-body-1 f-s-10">
                                Abierto el {{ register.openingTime | moment }}
                              </span>
                              <span *ngIf="register.isOpen === false && register.closingTime" class="mat-body-1 f-s-10">
                                Cerrado el {{ register.closingTime | moment }}
                              </span>
                            </div>

                            <i-tabler
                              *ngIf="selectedRegister?.id === register.id"
                              name="circle-check"
                              class="icon-20 m-r-8"
                            ></i-tabler>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div
                  class="b-t-1 p-24 d-none d-lg-flex align-items-center justify-content-between"
                >
                  <span
                    class="d-flex align-items-center mat-subtitle-1 f-s-16 f-w-500"
                  >
                    <i-tabler name="help" class="icon-20 m-r-8"></i-tabler>Frequently
                    Asked Questions
                  </span>
                  <a
                    [routerLink]="['/theme-pages/faq']"
                    mat-flat-button
                    color="primary"
                    >Check</a
                  >
                </div> -->
                <!-- <div class="col-sm-4">
                <div class="p-x-16 p-y-32">
                  <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
                  <div
                    class="text-hover-primary"
                    *ngFor="let quicklink of quicklinks"
                  >
                    <a
                      [routerLink]="[quicklink.link]"
                      class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8"
                      >{{ quicklink.title }}</a
                    >
                  </div>
                </div>
              </div> -->
              </div>
              <!-- footer -->
              <div class="hidden row">
                <div class="col">
                  <div class="b-t-1 p-t-20 d-none d-lg-flex align-items-center justify-content-between">
                    <!-- <a
                      [routerLink]="['/settings/stores']"
                      class="d-flex align-items-center f-w-600 m-0 textprimary mat-body-1 hover-text"
                    >
                      <i-tabler name="edit" class="icon-20 m-r-8"></i-tabler>
                      Editar locales
                    </a> -->
                    <!-- <a
                    [routerLink]="['/settings/businesses']"
                    mat-flat-button
                    color="primary"
                    >Check</a
                  > -->
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>
        </ng-container>

        <!-- top menu -->
        <ng-container *ngFor="let i of items; let idx = index">
          <ng-container *ngIf="!i.hidden && activeItem?.route === i.route">
            <!-- label -->
            <a class="section-link is-header">
              <div>{{ i.name }}</div>
            </a>

            <!-- nested items -->
            <ng-container *ngFor="let j of i.children; let idx = index">
              <a *ngIf="!j.hidden" routerLink="{{ j.route }}" routerLinkActive="is-active" class="section-link">
                <div class="label">{{ j.name }}</div>
              </a>
              <!-- <a
                mat-button
                *ngIf="!j.hidden"
                routerLink="{{ j.route }}"
                routerLinkActive="is-active"
                class="_section-link"
              >
                {{ j.name }}
              </a> -->
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- bottom menu -->
        <ng-container *ngFor="let i of itemsFooter; let idx = index">
          <ng-container *ngIf="!i.hidden && activeItem?.route === i.route">
            <!-- label -->
            <a class="section-link is-header">
              <div>{{ i.name }}</div>
            </a>

            <!-- nested items -->
            <ng-container *ngFor="let j of i.children; let idx = index">
              @if(!j.hidden){
              <a *ngIf="j.route" routerLink="{{ j.route }}" routerLinkActive="is-active" class="section-link">
                <div class="label">{{ j.name }}</div>
              </a>
              <a *ngIf="!j.route" (click)="onItemClick(j)" class="section-link">
                <div class="label">{{ j.name }}</div>
              </a>
              }
            </ng-container>
          </ng-container>
        </ng-container>
      </nav>
    </div>

    <mat-accordion multi class="hidden app-nav-accordion">
      <ng-container *ngFor="let i of items; let idx = index">
        <mat-expansion-panel
          expanded="true"
          *ngIf="!i.hidden"
          class="_expansion-item mat-elevation-z0"
          [class.is-active]="i.active"
        >
          <mat-expansion-panel-header (click)="$event.stopPropagation()" [class.is-active]="i.active">
            <div>
              <div class="item" [class.is-active]="i.active">
                <div class="icon">
                  <i-tabler name="{{ i.icon }}" class="icon-16 d-flex"></i-tabler>
                </div>
                <span class="name">
                  {{ i.name }}
                </span>
              </div>
            </div>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <ng-container *ngFor="let s of i.children">
              <a *ngIf="!s.hidden" routerLink="{{ s.route }}" routerLinkActive="is-active" class="nested" mat-list-item>
                <!-- <i-tabler *ngIf="s.icon" name="{{ s.icon }}" class="icon-16 d-flex"></i-tabler> -->
                <span>{{ s.name }}</span>
                <span *ngIf="s.isNew" class="new">nuevo</span>
                <span *ngIf="s.isAlpha" class="alpha">alpha</span>
              </a>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <!-- </div> -->

    <!-- <mat-toolbar style="flex-flow: 1;" color="primary">Footer</mat-toolbar> -->
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="dashboard">
      <!-- [showToggle]="!isOver" (toggleCollapsed)="toggleCollapsed()" -->

      <div class="toolbar-container">
        <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
          <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
          <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
        </button>
      </div>
      <!-- <dl-toolbar class="dashboard-header"></dl-toolbar> -->
      <app-header #header [activeItem]="activeItem" [items]="items" (toggleMobileNav)="sidenav.toggle()"></app-header>
      <div class="dashboard-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>

  <!-- <mat-sidenav #sidenav mode="over" position="end" style="min-width: 600px">
    <ng-container #dynamicContent></ng-container>
  </mat-sidenav> -->

  <mat-sidenav class="_sidenav" #sidenavRight mode="over" position="end" [opened]="false" style="min-width: 800px">
    <ng-container #dynamicContent></ng-container>
    <!-- (onItemClick)="chat = $event" -->
    <!-- <button mat-fab color="primary" aria-label="Example icon button with a delete icon"> -->

    <!-- </button> -->

    <!-- <ns-chat (onItemClick)="showChat($event)"></ns-chat> -->
  </mat-sidenav>
</mat-sidenav-container>

<router-outlet name="print"></router-outlet>

<!-- <div *ngIf="isLoading" class="loader-wrapper">
  <mat-progress-spinner diameter="30" mode="indeterminate"></mat-progress-spinner>
</div> -->
