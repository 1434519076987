export class IDNumber {
  static isDNI(dni: string): boolean {
    return dni.length === 8; // Use strict comparison for length check
  }

  static isRUC(ruc: string): boolean {
    const validPattern = /^(10|15|16|17|20)\d{9}$/; // Regular expression for RUC format
    if (!validPattern.test(ruc)) {
      return false;
    }

    const calculatedCheckDigit = this.calculateCheckDigit(ruc.slice(0, -1));
    const providedCheckDigit = Number(ruc.slice(-1));

    return calculatedCheckDigit === providedCheckDigit;
  }

  private static calculateCheckDigit(number: string): number {
    let sum = 0;
    let weight = 2;

    const reversedNumberString = number.split('').reverse().join('');

    for (const digit of reversedNumberString) {
      const digitInt = Number(digit);

      sum += digitInt * weight;
      weight++;

      weight = weight % 8 === 0 ? 2 : weight; // Use modulo operator for weight reset
    }

    const checkDigit = 11 - (sum % 11);
    return checkDigit % 10;
  }
}
