<div class="consignment">
  <div class="header">
    <container-element [ngSwitch]="model?.type">
      <h2 *ngSwitchCase="orderType">Orden de compra</h2>
      <!-- <h2 *ngSwitchCase="receptionType">Recepción de stock</h2> -->
      <h2 *ngSwitchCase="returnType">Devolución de stock</h2>
      <h2 *ngSwitchCase="transferType">Transferencia de stock</h2>
    </container-element>

    <div class="information">
      <span># {{ model?.number }}</span>
      <span>{{ store?.denomination }} {{ store?.idNumber }} Local: {{ store?.local }}</span>
      <span>Dirección: {{ store?.address }}</span>
    </div>
  </div>

  <!-- details -->
  <div class="details">
    <div class="form read-only">
      <div class="fields">
        <!-- supplier -->
        <div class="field">
          <label class="_bold">PROVEEDOR</label>
          <p>{{ model?.supplierName | void }}</p>
        </div>

        <!-- invoice -->
        <!-- <div class="field">
            <label>#Factura</label>
            <p>{{ model?.supplierInvoice | void }}</p>
          </div> -->

        <!-- due_at -->
        <div class="field">
          <label class="_bold">FECHA DE ENTREGA</label>
          <p>{{ model?.dueAt | moment | void }}</p>
        </div>

        <!-- created -->
        <div class="field">
          <label class="_bold">CREADO</label>
          <p>{{ model?.createdAt | moment }}</p>
        </div>

        <!-- note -->
        <!-- <div class="field">
            <label>Nota</label>
            <p>{{ model?.note | void }}</p>
          </div> -->
      </div>
    </div>
  </div>

  <!-- products -->
  <table class="_table-wrap">
    <thead>
      <tr class="border-top">
        <th>Producto</th>
        <th>Cantidad</th>
        <th>Recibido</th>
        <th>Fecha de exp.</th>
        <th>Costo</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-top border-bottom" *ngFor="let i of model?.products ?? []; let idx = index">
        <td class="product">
          <span class="name">{{ i.name }}</span>
          <span class="sku">{{ i.sku }}</span>
        </td>
        <td>{{ i.quantity }}</td>
        <td>{{ i.received }}</td>
        <td>
          <div class="lots">
            <span *ngFor="let lot of i.lots ?? []" class="lot">
              {{ lot.number ? '#' + lot.number + ' - ' : '' }} {{ lot.expiresAt | moment : 'll' }} <br />
            </span>
          </div>
        </td>
        <td>{{ i.cost | currency }}</td>
        <td class="align-right">{{ model?.subtotal(i) | currency }}</td>
      </tr>
      <tr>
        <td colspan="5">Total</td>
        <td class="align-right">
          <h3 class="bold">{{ model?.total() | currency }}</h3>
        </td>
      </tr>
    </tbody>
  </table>
</div>
