import { Component, OnInit, Inject } from '@angular/core';
import { Chat, NotifyService } from '@app/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MessageComponent } from '@app/modules/messaging/pages/message/message.component';

@Component({
  selector: 'ns-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, SharedModule, MaterialModule, FormsModule, ReactiveFormsModule, MessageComponent],
})
export class ChatDialogComponent implements OnInit {
  constructor(
    private notify: NotifyService,
    public dialogRef: MatDialogRef<ChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public chat: Chat,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
