import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentPipe } from './pipes/moment.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { VoidPipe } from './pipes/void.pipe';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';

import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Directives
import { Select20Directive } from './directives/select20.directive';
import { Select2Directive } from './directives/select2.directive';
import { InputAnimDirective } from './directives/input-anim.directive';
import { TransitionDirective } from './directives/transition.directive';
import { RequiredValidatorDirective } from './directives/required.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FileDragNDropDirective } from './directives/file-drag-drop';

// Components
import { LoaderComponent } from './components/loader/loader.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { Search2Component } from './components/search2/search2.component';
import { Search3Component } from './components/search3/search3.component';
import { ReorderPointComponent } from './components/reorder-point/reorder-point.component';
import { Badge0Component } from './components/badge/badge.component';
import { ImageComponent } from './components/image/image.component';
import { IconComponent } from './components/icon/icon.component';
import { LocationComponent } from './components/location/location.component';
import { SelectComponent } from './components/select/select.component';
import { SwitchComponent } from './components/switch/switch.component';
import { SelectProductComponent } from './components/select-product/select-product.component';
import { RouterModule } from '@angular/router';
import { SearchComponent } from './components/search/search.component';
import { Search0Component } from './components/search-0/search.component';
import { PopoverComponent } from './components/popover/popover.component';
import { DialogDeleteComponent } from './components/dialog-delete/dialog-delete.component';
import { DateComponent } from './components/date/date.component';
import { HideDirective } from './directives/hide.directive';
import { QrcodeComponent } from './components/qrcode/qrcode.component';
import { DelayDirective } from './directives/delay.directive';
import { DialogOptionComponent } from './components/dialog-option/dialog-option.component';
import { StickyDirective } from './directives/sticky.directive';
import { BackArrowDirective } from './directives/back-arrow.directive';
import { BackButtonDirective } from './directives/back-button.directive';
import { BarcodeDirective } from './directives/barcode.directive';
import { GiftCardViewComponent } from '@app/modules/product/pages/gift-card/gift-card-view/gift-card-view.component';
import { PageDirective } from './directives/page.directive';
import { PageBodyDirective } from './directives/page-body.directive';
import { PageHeaderDirective } from './directives/page-header.directive';
import { BarcodeComponent } from './components/barcode/barcode.component';
import { ScrollableDirective } from './directives/scrollable.directive';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MediaComponent } from './components/media/media.component';
import { InitialsPipe } from './pipes/initials.pipe';
import { BadgeComponent } from '@app/components/shared/badge/badge.component';
import { LisdtDragAndDropDirective } from './directives/list-drag-drop';
import { IconsModule } from '@app/core/modules/icon.module';
import { UserProfilePictureComponent } from '@app/components/shared/user-profile-picture/user-profile-picture.component';

@NgModule({
  declarations: [
    InputAnimDirective,
    TransitionDirective,
    RequiredValidatorDirective,
    AutofocusDirective,
    FileDragNDropDirective,
    LisdtDragAndDropDirective,
    StickyDirective,
    Select2Directive,
    Select20Directive,
    ScrollableDirective,
    MomentPipe,
    InitialsPipe,
    SafeHtmlPipe,
    VoidPipe,
    LinkifyPipe,
    LoaderComponent,
    DialogComponent,
    DialogOptionComponent,
    DialogDeleteComponent,
    GiftCardViewComponent,
    ReorderPointComponent,
    InputErrorComponent,
    EmptyStateComponent,
    ProductSearchComponent,
    Search2Component,
    Search3Component,
    Search0Component,
    SearchComponent,
    Badge0Component,
    ImageComponent,
    IconComponent,
    LocationComponent,
    MediaComponent,
    SelectComponent,
    SwitchComponent,
    SelectProductComponent,
    PopoverComponent,
    DateComponent,
    HideDirective,
    QrcodeComponent,
    BarcodeComponent,
    DelayDirective,
    BackArrowDirective,
    BackButtonDirective,
    BarcodeDirective,
    PageDirective,
    PageHeaderDirective,
    PageBodyDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OverlayModule,
    DragDropModule,
    RouterModule,
    NgScrollbarModule,
    BadgeComponent,
    IconsModule,
    UserProfilePictureComponent,
  ],
  exports: [
    OverlayModule,
    DragDropModule,
    InputAnimDirective,
    TransitionDirective,
    RequiredValidatorDirective,
    AutofocusDirective,
    FileDragNDropDirective,
    LisdtDragAndDropDirective,
    StickyDirective,
    Select2Directive,
    Select20Directive,
    ScrollableDirective,
    HideDirective,
    DelayDirective,
    BackArrowDirective,
    BackButtonDirective,
    BarcodeDirective,
    PageDirective,
    PageHeaderDirective,
    PageBodyDirective,
    MomentPipe,
    InitialsPipe,
    SafeHtmlPipe,
    VoidPipe,
    LinkifyPipe,
    LoaderComponent,
    InputErrorComponent,
    EmptyStateComponent,
    ProductSearchComponent,
    Search2Component,
    Search3Component,
    SearchComponent,
    Search0Component,
    Badge0Component,
    ImageComponent,
    IconComponent,
    LocationComponent,
    SelectComponent,
    SwitchComponent,
    SelectProductComponent,
    PopoverComponent,
    DateComponent,
    QrcodeComponent,
    BarcodeComponent,
    MediaComponent,
    NgScrollbarModule,
    IconsModule,
    UserProfilePictureComponent,
  ],
  providers: [],
})
export class SharedModule {}
