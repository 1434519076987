<form [formGroup]="searchForm">
  <div class="p-24 p-b-0">
    <div class="row">
      <div class="col-11">
        <!-- <mat-form-field appearance="outline" color="primary" class="hide-hint w-100"> -->
        <div class="site-nav-search__wrapper">
          <input matInput placeholder="Buscar" formControlName="searchText" class="site-nav-search__input" />
        </div>
        <!-- </mat-form-field> -->
      </div>
      <div class="col-1 d-flex justify-content-end align-items-center">
        <button mat-icon-button mat-dialog-close class="d-flex justify-content-center">
          <i-tabler name="x" class="icon-18 d-flex"></i-tabler>
        </button>
      </div>
    </div>
  </div>
</form>
<!-- <mat-divider></mat-divider> -->
<mat-dialog-content class="mat-typography search-dialog">
  <div class="search-dialog-items">
    <ng-content *ngFor="let item of filteredNavItems">
      <a
        [routerLink]="[item.route]"
        mat-dialog-close
        class="search-dialog-item p-y-12 text-decoration-none d-block"
        [class.is-header]="item.children && item.children.length > 0"
      >
        @if (item.children && item.children.length > 0){
        <div class="d-flex align-items-center">
          <!-- <i-tabler name="{{ item.icon }}" class="icon-20"></i-tabler> -->
          <h5 class="f-s-15 f-w-600 d-block mat-subtitle-1 m-0 m-l-6">
            {{ item.name }}
          </h5>
        </div>
        <!-- <span class="f-s-12 mat-body-2">{{ item.route }}</span> -->
        } @else{
        <div class="d-flex align-items-center">
          @if (item.icon){
          <i-tabler name="{{ item.icon }}" class="icon-18"></i-tabler>
          }
          <h5 class="f-s-14 f-w-500 d-block mat-subtitle-1 m-0 m-l-6">
            {{ item.name }}
          </h5>
        </div>
        <!-- <span class="f-s-12 mat-body-2">{{ item.route }}</span> -->
        }
      </a>
    </ng-content>
  </div>
</mat-dialog-content>
