export enum SerieType {
  SALE_NOTE = 'sale_note',
  BOLETA = 'boleta',
  FACTURA = 'factura',
}

export interface CartSerie {
  type?: SerieType;
  name: string;
}

export const SerieTypes: CartSerie[] = [
  // { name: 'Nota de venta', type: undefined },
  // { name: 'Boleta', type: SerieType.BOLETA },
  // { name: 'Factura', type: SerieType.FACTURA },
];
