import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { StocktakeItem } from '../models/stocktake-item';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class StocktakeItemService {
  private readonly index = environment.api + '/v1/stocktake_items';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<StocktakeItem | null>> {
    return this.api.get<Response<StocktakeItem | null>>(StocktakeItem, this.index + '/' + id);
  }

  all(stocktakeId: string, version: number = 0): Observable<Response<StocktakeItem[]>> {
    return this.api.get<Response<StocktakeItem[]>>(
      StocktakeItem,
      `${this.index}?stocktake_id=${stocktakeId}&version=${version}`
    );
  }

  create(stocktakeItem: StocktakeItem): Observable<Response<StocktakeItem>> {
    return this.api.post<Response<StocktakeItem>>(StocktakeItem, this.index, stocktakeItem);
  }

  update(stocktakeItem: StocktakeItem): Observable<Response<StocktakeItem>> {
    return this.api.put<Response<StocktakeItem>>(StocktakeItem, this.index + '/' + stocktakeItem.id, stocktakeItem);
  }

  delete(stocktakeItem: StocktakeItem): Observable<Response<StocktakeItem>> {
    return this.api.delete<Response<StocktakeItem>>(StocktakeItem, this.index + '/' + stocktakeItem.id);
  }
}
