import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Stocktake } from '../models/stocktake';
import { Response } from '../interfaces/response';
import { StocktakeFilter } from '../filters/stocktake-filter';

@Injectable({
  providedIn: 'root',
})
export class StocktakeService {
  private readonly index = environment.api + '/v1/stocktakes';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Stocktake | null>> {
    return this.api.get<Response<Stocktake | null>>(Stocktake, this.index + '/' + id);
  }

  all(p: StocktakeFilter | null = null): Observable<Response<Stocktake[]>> {
    return this.api.get<Response<Stocktake[]>>(Stocktake, this.index + (p ? p!.getQuery() : ''));
  }

  create(stocktake: Stocktake): Observable<Response<Stocktake>> {
    return this.api.post<Response<Stocktake>>(Stocktake, this.index, stocktake);
  }

  update(stocktake: Stocktake): Observable<Response<Stocktake>> {
    return this.api.put<Response<Stocktake>>(Stocktake, this.index + '/' + stocktake.id, stocktake);
  }

  cancel(stocktake: Stocktake): Observable<Response<Stocktake>> {
    return this.api.post<Response<Stocktake>>(Stocktake, `${this.index}/${stocktake.id}/cancel`, null);
  }

  complete(stocktake: Stocktake): Observable<Response<Stocktake>> {
    return this.api.post<Response<Stocktake>>(Stocktake, `${this.index}/${stocktake.id}/complete`, null);
  }
}
