import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '../models/store';
import { StoreService } from '../api/store.service';
import Utils from '../utils/utils';
import { User } from '../models/user';
import { UserService } from '../api/user.service';
import { DataService } from './data.service';
import { Business } from '../models/business';
import { AuthApi } from '../api/auth.service';
import { UserConfig } from './user-config.service';
import { Router } from '@angular/router';
import { NotifyService } from './notify.service';
import { Error } from '../interfaces/response';
import { BusinessService } from '../api/business.service';
import { AppDatabase } from '../data/app-database';
import { RegisterLite } from '../models/register-lite';
import { PaymentMethodService } from '../api/payment-method.service';

export const DEFAULT_USER_ID = 'default';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // Current user
  private _user = new BehaviorSubject<User | null>(null);
  public user$ = this._user.asObservable();
  public user: User | null = null;

  // Current store
  private _store = new BehaviorSubject<Store | null>(null);
  public store$ = this._store.asObservable();
  public store: Store | null = null;

  // Current business
  private _business = new BehaviorSubject<Business | null>(null);
  public business$ = this._business.asObservable();
  public business: Business | null = null;

  // Stores
  private _stores = new BehaviorSubject<Store[]>([]);
  public stores$ = this._stores.asObservable();
  public stores: Store[] = [];

  public get currentUserId(): string {
    return this.user?.id ?? 'null';
  }

  public get currentStoreId(): string {
    return this.config.local?.id ?? this.config.currentLocalId; // this.store?.id ?? 'null';
  }

  // public get currencySimbol(): string {
  //   return 'S/ ';
  // }

  constructor(
    private userService: UserService,
    private dataService: DataService,
    private database: AppDatabase,
    private storeService: StoreService,
    private authApi: AuthApi,
    private config: UserConfig,
    private notify: NotifyService,
    private router: Router,
    private businessService: BusinessService,
    private paymentMethodService: PaymentMethodService
  ) {}

  /**
   * Init application
   */
  // initApplication(): void {
  //   this.authApi.data().subscribe((response) => {
  //     if (response.ok) {
  //       // Get the store that belongs to the user
  //       let store = response.data.stores.find((item) => item.id === this.config.currentLocalId);
  //       if (store === undefined) {
  //         store = response.data.stores._first();
  //       }

  //       // get selected register from store
  //       let register = store.registers.find((item) => item.id === this.config.currentRegisterId);
  //       // Commented because the register is not required
  //       // if (register === undefined) {
  //       //   register = store.registers._first();
  //       // }

  //       this.config.setInitialData(
  //         new Business(response.data.business),
  //         new User(response.data.user),
  //         new Store(store),
  //         register ? new RegisterLite(register) : undefined
  //       );

  //       // this.config.setBusiness(new Business(response.data.business));
  //       // this.config.setStore(new Store(response.data.stores._first()));
  //       // this.config.setUser(new User(response.data.user));

  //       this.loadBusinessData();
  //     } else if (response.error === Error.BUSINESS_REQUIRED) {
  //       this.router.navigate(['/business-register']); // TODO: create a unique create business page
  //     } else {
  //       this.notify.error(response);
  //     }
  //   });
  // }

  loadBusinessData() {
    // Remove all data from database
    this.database.removeData();

    // Load business data
    this.loadBusinesses();
    this.loadLocals();
  }

  // clearBusinessData() {
  //   this.database.locals.clear();
  // }

  private loadBusinesses(): void {
    // request and save business in local storage
    this.businessService.list().subscribe((businesses) => {});
  }

  private loadLocals(): void {
    this.storeService.all().subscribe((response) => {
      if (response.ok) {
        const locals = response.data;
        // Verificar si local almacenado localmente nos pertenece
        const exist = locals.find((item) => item.id === this.config.currentLocalId);
        if (!exist) {
          this.config.setStore(locals[0]);
        }
      }
    });
  }

  // loadUserConfig(): void {
  //   // this.loadUser();
  //   this.loadStores();
  // }

  /**
   * @param user
   */
  setUser(user: User): void {
    if (!Utils.deepEqual(user, this.user)) {
      // Establecer valor y notificar
      this.user = user;
      this.dataService.setUser(user);

      // Nofificar cambio
      this._user.next(user);
    }
  }

  /**
   * @param business
   */
  setBusiness(business: Business): void {
    if (!Utils.deepEqual(business, this.business)) {
      // Establecer valor y notificar
      this.business = business;
      this.dataService.setBusiness(business);

      // Nofificar cambio
      this._business.next(business);
    }
  }

  /**
   * @param store
   */
  private setStore(store: Store): void {
    // if (!Utils.deepEqual(store, this.store)) { // @todo user, store serialization in dataService
    if (store.id !== this.store?.id || store.name !== this.store?.name || store.address !== this.store?.address) {
      // Establecer valor y notificar
      this.store = store;
      this.dataService.setStore(store);

      // Nofificar cambio
      this._store.next(store);
    }
  }

  private loadBusiness(): void {
    const savedBusiness = this.dataService.getBusiness();
    if (savedBusiness) {
      this.setBusiness(savedBusiness);
    }

    this.userService.get(this.currentUserId).subscribe((response) => {
      if (response.ok) {
        let user = response.data!;
        this.setUser(user);
      }
    });
  }

  // @deprecated or upgrade: implement getData()
  private loadUser(): void {
    const savedUser = this.dataService.getUser();
    if (savedUser) {
      this.setUser(savedUser);
    }

    this.userService.get(this.currentUserId).subscribe((response) => {
      if (response.ok) {
        let user = response.data!;
        this.setUser(user);
      }
    });
  }

  private loadStores(): void {
    const savedStore = this.dataService.getStore();
    if (savedStore) {
      this.setStore(savedStore);
    }

    this.storeService.all({ start: 0, limit: 50 }).subscribe((response) => {
      if (response.ok) {
        this.stores = response.data;
        this._stores.next(this.stores);

        if (this.stores.length > 0) {
          if (this.store) {
            // Verificar si tienda almacenado localmente nos pertenece
            const exist = this.stores.find((item) => item.id === this.store?.id);

            // actualizar si existe
            if (exist) {
              this.setStore(exist);
            }
            // o establecer primer tienda encontrado por defecto
            else {
              this.setStore(this.stores[0]);
            }
          } else {
            this.setStore(this.stores[0]);
          }
        }
      }
    });
  }
}
