export enum PackingType {
  AEROSOL = 'aerosol',
  APLICADOR = 'aplicador',
  BARRA = 'barra',
  BIDON = 'bidon',
  BOLSA = 'bolsa',
  BOTELLA = 'botella',
  CAJA = 'caja',
  DISPENSADOR = 'dispensador',
  DISPLAY = 'display',
  DOYPACK = 'doypack',
  EMPAQUE = 'empaque',
  ESTUCHE = 'estuche',
  FRASCO = 'frasco',
  GALON = 'galon',
  GALONERA = 'galonera',
  LATA = 'lata',
  PAQUETE = 'paquete',
  POTE = 'pote',
  ROLLO = 'rollo',
  SACHET = 'sachet',
  SIXPACK = 'sixpack',
  SOBRE = 'sobre',
  TAPER = 'taper',
  VASO = 'vaso',
}

export const packingTypes = [
  PackingType.AEROSOL,
  PackingType.APLICADOR,
  PackingType.BARRA,
  PackingType.BIDON,
  PackingType.BOLSA,
  PackingType.BOTELLA,
  PackingType.CAJA,
  PackingType.DISPENSADOR,
  PackingType.DISPLAY,
  PackingType.DOYPACK,
  PackingType.EMPAQUE,
  PackingType.ESTUCHE,
  PackingType.FRASCO,
  PackingType.GALON,
  PackingType.GALONERA,
  PackingType.LATA,
  PackingType.PAQUETE,
  PackingType.POTE,
  PackingType.ROLLO,
  PackingType.SACHET,
  PackingType.SIXPACK,
  PackingType.SOBRE,
  PackingType.TAPER,
  PackingType.VASO,
];
