import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Account } from '../models/account';
import { User } from '../models/user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { first } from 'rxjs/operators';
import { SignIn } from '../models/sign-in';
import { DataService } from './data.service';
import { Store } from '../models/store';
import { AppDatabase } from '../data/app-database';
import { GoogleAuthProvider } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  ROUTE_SIGN_IN = '/sign-in';
  ROUTE_DASHBOARD = '/';
  BUSINESS_REGISTER = '/business-register';

  // private firebaseUser: Observable<firebase.User | null>;

  private roles: string[] = [];

  private _account = new BehaviorSubject<null | Account>(null);
  public account$ = this._account.asObservable();
  public account: null | Account = null;

  private _user = new BehaviorSubject<undefined | User>(undefined);
  public user$ = this._user.asObservable();
  public user: null | User = null;

  redirectUrl: string = '';

  constructor(
    private router: Router,
    private dataService: DataService,
    private firebaseAuth: AngularFireAuth,
    private jwtHelper: JwtHelperService,
    private database: AppDatabase
  ) {
    // this.firebaseUser = firebaseAuth.authState;
    // this.firebaseUser.subscribe((account) => {
    //   // console.log('account', account);
    //   if (account) {
    //     this.setAccount(account);
    //     this.loadTokenInformation();
    //   }
    // });
  }

  setUserConfig(signIn: SignIn): void {
    // Save in local storage
    this.dataService.setUser(new User(signIn.user));
    this.dataService.setStore(new Store(signIn.store));

    // Get payload
    this.loadTokenInformation();
  }

  /**
   * Sign in with email and password
   * @param callback
   */
  signInWithPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  // Register with email and password and send email verification
  registerWithEmailAndPassword(
    email: string,
    password: string,
    displayName: string
  ): Promise<firebase.auth.UserCredential> {
    return this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then((credentials) => {
        if (credentials.user) {
          return credentials.user.updateProfile({ displayName }).then(
            (_) => credentials,
            (error) => {
              console.log('error updateProfile', error);
              return credentials;
            }
          );
        }
        return credentials;
      })
      .then((credentials) => {
        if (credentials.user) {
          // Crear la URL de redirección (por ejemplo, página de confirmación)
          const currentDomain = window.location.origin; // Obtiene el dominio actual (ej. "http://localhost:4200" o "https://miaplicacion.com")
          const verificationUrl = `${currentDomain}/verify-email`; // Concatenar la ruta "/verify-email"

          const actionCodeSettings = {
            // Aquí defines la URL a la que el usuario será redirigido
            url: verificationUrl, // Cambia esto por la URL que prefieras
            handleCodeInApp: true, // Esto indica que el enlace se manejará dentro de tu app
          };
          return credentials.user.sendEmailVerification(actionCodeSettings).then(
            (_) => credentials,
            (error) => {
              console.log('error sendEmailVerification', error);
              return credentials;
            }
          );
        }
        return credentials;
      });
  }

  signInAndGetFirebaseToken(callback: (token: null | string, error: any) => void): void {
    this.firebaseAuth
      .signInWithPopup(new GoogleAuthProvider())
      .then((credentials) => {
        if (credentials.user == null) {
          callback(null, null);
        } else {
          credentials.user
            .getIdToken()
            .then((token) => {
              callback(token, null);
            })
            .catch((reason) => {
              this.logoutWithoutRedirect();
              callback(null, reason);
            });
        }
      })
      .catch((reason) => {
        console.log('fail auth provider', reason);
        this.logoutWithoutRedirect(); // @todo verificar si es necesario
        callback(null, reason);
      });
  }

  getToken(force: boolean = false): Observable<string | null> {
    return new Observable((observer) => {
      this.firebaseAuth.user.subscribe(
        (user) => {
          if (!user) {
            observer.error();
          } else {
            user
              .getIdToken(force)
              .then((idToken) => {
                observer.next(idToken);
                observer.complete();
              })
              .catch((reason) => observer.error(reason));
          }
        },
        (err) => observer.error(err)
      );
    });
  }

  /**
   * Remover sesión sin redireccionar
   */
  logoutWithoutRedirect(): void {
    this.clearLocalData();
    this.firebaseAuth.signOut();
  }

  /**
   * Cerrar sesión y eliminar información de cuenta
   */
  logout(): void {
    this.firebaseAuth.signOut().then(
      (_) => {
        this.clearLocalData();
        this.router.navigateByUrl(this.ROUTE_SIGN_IN);
      },
      (_) => {
        this.clearLocalData();
        this.router.navigateByUrl(this.ROUTE_SIGN_IN);
      }
    );
  }

  clearLocalData() {
    this.database.removeData();
    this.dataService.cleanData();
  }

  /**
   * Comprueba si existe un accesToken con formato válido
   */
  // public isJWTExist(): boolean {
  //   const token: string = this.jwtHelper.tokenGetter();
  //   if (!token || token.trim().length === 0) {
  //     return false;
  //   }

  //   try {
  //     this.jwtHelper.decodeToken(token);
  //     // El token se autorefresca si obtiene un 401. No verificar expiración
  //     // if (this.jwtHelper.isTokenExpired(token)) {
  //     //   return false;
  //     // }
  //   } catch (error) {
  //     return false;
  //   }

  //   return true;
  // }

  isLoggedIn(): Observable<any> {
    return this.firebaseAuth.authState.pipe(first());
  }

  /**
   * Verifica si tiene permisos
   * con "|" comprueba si tiene cualquiera de los roles
   * con "," debe tener todos los permisos @todo: implementar
   * @param inputRoles roles
   */
  hasRoles(inputRoles: string | string[], allRequired: boolean): boolean {
    if (this.roles === undefined) {
      return false;
    }

    if (Array.isArray(inputRoles)) {
      // AND else OR
      if (allRequired) {
        return inputRoles.every((permission) => {
          return this.roles.indexOf(permission) !== -1;
        });
      } else {
        for (const input of inputRoles) {
          return (
            this.roles.find((role) => {
              return role === input;
            }) !== undefined
          );
        }
      }
    } else {
      return false;
      // const arrayRoles = roles.split('|');
    }

    return false;
  }

  /**
   * Load business
   * @todo: verificar propiedad, el valor puede ser corrupto
   */
  loadTokenInformation(): void {
    try {
      from(this.jwtHelper.tokenGetter()).subscribe(
        (token: string) => {
          if (token) {
            // console.log('token', token);

            const payload = this.jwtHelper.decodeToken(token);
            // console.log('payload', payload);

            // Cargar roles antes que usuario permite escuchar a usuario para verificar roles
            if (payload.roles) {
              this.roles = payload.roles;
            }

            // Cargar información de usuario. @todo: obtener información de api
            if (payload.sub) {
              const user = new User();
              user.id = payload.sub;
              this.setUser(user);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {}
  }

  private setUser(user: User): void {
    this.user = user;
    this._user.next(user);
  }

  private setAccount(firebaseUser: firebase.User): void {
    const account = new Account();
    account.id = firebaseUser.uid;
    account.name = firebaseUser.displayName ?? '';
    account.email = firebaseUser.email ?? '';
    account.mobile = firebaseUser.phoneNumber ?? '';
    account.provider = firebaseUser.providerId;
    account.imageUrl = firebaseUser.photoURL ?? '';

    this.account = account;
    this._account.next(account);
  }
}
