// import { provideLottieOptions, AnimationLoader, provideCacheableAnimationLoader } from 'ngx-lottie';
// import player from 'lottie-web';
// import { ApplicationConfig } from '@angular/core';

export interface AppSettings {
  dir: 'ltr' | 'rtl';
  theme: string;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  boxed: boolean;
  horizontal: boolean;
  activeTheme: string;
  language: string;
  cardBorder: boolean;
  navPos: 'side' | 'top';
}

export const defaults: AppSettings = {
  dir: 'ltr',
  theme: 'light',
  sidenavOpened: false,
  sidenavCollapsed: false,
  boxed: true,
  horizontal: false,
  cardBorder: false,
  activeTheme: 'blue_theme',
  language: 'es-pe',
  navPos: 'side',
};

// export const appConfig: ApplicationConfig = {
//   providers: [
//     provideLottieOptions({
//       player: () => player,
//     }),
//     AnimationLoader,
//     provideCacheableAnimationLoader(),
//   ],
// };
