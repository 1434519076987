import { GiftCardTransactionType } from '../enums/gift-card-transaction-type';

export class GiftCard {
  private _id: string;
  private _groupId: string;
  private _groupName: string;
  private _name: string;
  private _mobile: string;
  private _email: string;
  private _number: string;
  private _totalSold: number;
  private _totalRedeemed: number;
  private _balance: number;
  private _note: string;
  private _transactions: GiftCardTransaction[];
  private _isEnabled: boolean;
  private _expiresAt: Date;
  private _notifiedAt: Date;
  private _createdAt: Date;
  private _updatedAt: Date;
  private _deletedAt: Date;

  // UI Helpers
  loading: boolean = false;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get groupId(): string {
    return this._groupId;
  }

  public set groupId(value: string) {
    this._groupId = value;
  }

  public get groupName(): string {
    return this._groupName;
  }

  public set groupName(value: string) {
    this._groupName = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(mobile: string) {
    this._mobile = mobile;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get number(): string {
    return this._number;
  }

  public set number(number: string) {
    this._number = number;
  }

  public get totalSold(): number {
    return this._totalSold;
  }

  public set totalSold(totalSold: number) {
    this._totalSold = totalSold;
  }

  public get totalRedeemed(): number {
    return this._totalRedeemed;
  }

  public set totalRedeemed(totalRedeemed: number) {
    this._totalRedeemed = totalRedeemed;
  }

  public get balance(): number {
    return this._balance;
  }

  public set balance(balance: number) {
    this._balance = balance;
  }

  public get note(): string {
    return this._note;
  }

  public set note(note: string) {
    this._note = note;
  }

  public get transactions(): GiftCardTransaction[] {
    return this._transactions;
  }

  public set transactions(transactions: GiftCardTransaction[]) {
    this._transactions = transactions;
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public set isEnabled(value: boolean) {
    this._isEnabled = value;
  }

  public get expiresAt(): Date {
    return this._expiresAt;
  }

  public set expiresAt(expiresAt: Date) {
    this._expiresAt = expiresAt;
  }

  public get notifiedAt(): Date {
    return this._notifiedAt;
  }

  public set notifiedAt(value: Date) {
    this._notifiedAt = value;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  public get deletedAt(): Date {
    return this._deletedAt;
  }

  public set deletedAt(deletedAt: Date) {
    this._deletedAt = deletedAt;
  }

  constructor(init?: Partial<GiftCard>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      group_id: this.groupId,
      group_name: this.groupName,
      name: this.name,
      mobile: this.mobile,
      email: this.email,
      number: this.number,
      // total_sold: this.totalSold,
      // total_redeemed: this.totalRedeemed,
      balance: this.balance,
      note: this.note,
      // transactions: this.transactions,
      is_enabled: this.isEnabled,
      expires_at: this.expiresAt,
      // created_at: this.createdAt,
      // updated_at: this.updatedAt,
      // deleted_at: this.deletedAt,
    };
  }
}

export class GiftCardTransaction {
  public id: string;
  public orderId: string;
  public amount: number;
  public type: GiftCardTransactionType;
  public userId: string;
  public userName: string;
  public customerId: string;
  public customerName: string;
  public createdAt: Date;

  constructor(init?: Partial<GiftCard>) {
    Object.assign(this, init);
  }
}
