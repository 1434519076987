export class Account {
  private _id: string;
  private _name: string;
  private _email: string;
  private _mobile: string;
  private _provider: string;
  private _imageUrl: string;

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(value: string) {
    this._mobile = value;
  }

  public get provider(): string {
    return this._provider;
  }

  public set provider(value: string) {
    this._provider = value;
  }

  public get imageUrl(): string {
    return this._imageUrl;
  }

  public set imageUrl(value: string) {
    this._imageUrl = value;
  }

  constructor(init?: Partial<Account>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      name: this.name ?? null,
      email: this.email ?? null,
      mobile: this.mobile ?? null,
      provider: this.provider ?? null,
      imageUrl: this.imageUrl ?? null,
    };
  }
}
