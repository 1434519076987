import { OrderBy, SortBy } from '../enums/sorting';

export class SaleFilter {
  registerClosureId?: string | null;
  query?: string | null;
  invoiceNumber?: string | null;
  sortBy?: SortBy | null = SortBy.CREATED_AT;
  orderBy?: OrderBy | null = OrderBy.DESC;
  start?: number | null;
  limit?: number | null;

  // helper
  currentPage = 1;
  itemsPerPage = 20;

  constructor(init?: Partial<SaleFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let start = this.start;
    let limit = this.limit;

    if (start == undefined && limit == undefined) {
      start = (this.currentPage - 1) * this.itemsPerPage;
      limit = this.itemsPerPage;
    }

    let q = '';
    q += this.registerClosureId ? '&register_closure_id=' + this.registerClosureId : '';
    q += this.query ? '&q=' + this.query : '';
    q += this.invoiceNumber ? '&invoice_number=' + this.invoiceNumber : '';
    q += this.sortBy ? '&sort=' + this.sortBy : '';
    q += this.orderBy ? '&order=' + this.orderBy : '';
    q += start ? '&start=' + start : '';
    q += limit ? '&limit=' + limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
