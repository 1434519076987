import { DiscountType } from '../enums/discount-type';

export class Discount {
  public id: string;
  public storeId: string;
  public name: string;
  public type: DiscountType;
  public value: number;
  public safetyAmount: number;
  public quantity: number;
  public start: Date;
  public end: Date;
  public isEnabled: boolean;
  public deletedAt: Date;

  constructor(init?: Partial<Discount>) {
    Object.assign(this, init);
  }

  discountFmt(): string {
    if (this.type == DiscountType.PERCENTAGE) {
      return `Desct: ${this.value} %`;
    } else {
      return `Desct: S/ ${this.value?.toFixed(2)}`;
    }
  }

  public getDiscountRate(price: number): number {
    switch (this.type) {
      case DiscountType.PERCENTAGE:
        return this.value;
      case DiscountType.CURRENCY:
        let percent = 0;
        if (price >= this.safetyAmount) {
          percent = (this.value * 100) / price;
        }
        return percent;
    }
  }

  newPrice(price: number): number {
    switch (this.type) {
      case DiscountType.PERCENTAGE:
        return price - (price * this.value) / 100;
      case DiscountType.CURRENCY:
        if (price >= this.safetyAmount) {
          return price - this.value;
        }
    }
    return price;
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      name: this.name,
      type: this.type,
      value: this.value,
      safety_amount: this.safetyAmount,
      quantity: this.quantity,
      start: this.start,
      end: this.end,
      is_enabled: this.isEnabled ?? false,
      deleted_at: this.deletedAt,
    };
  }
}
