import { ExciseType } from '@app/core/enums/excise-type';
import { ProductSaleType } from '@app/core/enums/product-sale-type';

export class SaleCreateRequest {
  store_id: string;
  register_id: string | undefined;
  invoice_type: string | undefined;
  user_id: string;
  customer: { id: string | undefined; name: string; id_number: string; document_type: string } | undefined;
  global_discount: number;
  global_discount_exempt: number;
  global_charge: number;
  global_charge_exempt: number;
  total: number;
  //total_tax: number; // @deprecated
  note: string;
  type: string;
  return_for: string | undefined;
  payments: {
    payment_id: string;
    amount: number;
    payment_date: Date;
    created_at: Date;
  }[];
  items: SaleItemRequest[];
}

export interface SaleItemRequest {
  product_id?: string;
  name: string;
  tax_id?: string;
  tax_code: string;
  tax_rate: number;
  discount_id: string;
  quantity: number;

  unit_value: number;
  unit_discount: number;
  unit_charge: number;
  sale_type: ProductSaleType;
  unit_type: string;
  excise_type?: ExciseType;
  excise_value?: number;
  bag_unit_tax?: number;
  note: string;

  created_at: Date;
  updated_at: Date;
}
