import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Printer } from '../models/printer';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  private readonly index = environment.api + '/v1/printers';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Printer | null>> {
    return this.api.get<Response<Printer | null>>(Printer, this.index + '/' + id);
  }

  all(): Observable<Response<Printer[]>> {
    return this.api.get<Response<Printer[]>>(Printer, this.index);
  }

  create(printer: Printer): Observable<Response<Printer>> {
    return this.api.post<Response<Printer>>(Printer, this.index, printer);
  }

  update(printer: Printer): Observable<Response<Printer>> {
    return this.api.put<Response<Printer>>(Printer, this.index + '/' + printer.id, printer);
  }

  delete(printer: Printer): Observable<Response<Printer>> {
    return this.api.delete<Response<Printer>>(Printer, this.index + '/' + printer.id);
  }
}
