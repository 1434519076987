<div *ngFor="let group of giftCards; let idx = index">
  <div class="main-page">
    <div class="gifcard-page">
      <div class="gift-card-container" *ngFor="let giftcard of group; let i = index">
        <div class="giftcard-bg">
          <!-- <img src="/assets/images/giftcard-bg.svg" alt="" /> -->
          <div class="gift-card">
            <div class="giftcard-header">
              <!-- <div class="title">Nishi</div> -->
              <!-- <div class="title">Nishi <span class="badge">gift card</span></div> -->
            </div>
            <div class="giftcard-content">
              <!-- qrcode -->
              <div class="qrcode-container">
                <!-- <span class="gc-info">A order del pordador</span> -->

                <div class="gcqrcode">
                  <ns-qrcode [size]="300" [value]="giftcard.number"></ns-qrcode>
                </div>

                <div class="space" style="height: 0.02cm"></div>
                <span>Expira: {{ giftcard.expiresAt | moment : 'l' }}</span>

                <!-- balance -->
                <!-- <label>Monto</label> -->
                <div class="amount">{{ giftcard.balance | currency }}</div>

                <!-- <div class="space" style="height: 0.2cm"></div> -->
              </div>
            </div>
            <!-- footer message -->
            <div class="giftcard-footer">
              <!-- <p class="note">
              Válido para compras en la app o en tiendas Nishi.
              <span
                >Expira el <span class="date">{{ giftcard.expiresAt | moment : 'll' }}</span></span
              >
            </p> -->

              <!-- <div class="flex-between">
              <span>www.nishi.app</span>
              <span>expira: {{ giftcard.expiresAt | moment : 'l' }}</span>
            </div> -->
            </div>
            <!-- <p class="footer">Descarga la App Nishi desde nishi.pe y haz tus compras por delivery. Válido para tiendas Nishi.</p> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <h2 class="page-break">-----------</h2> -->
  </div>
</div>
