export class FastCashPayment {
  static readonly LIMIT: number = 5;

  private static readonly coins: number[] = [0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200];

  private static readonly coins1: number[] = [
    // 0.1,
    // 0.2,
    0.5, 1, 2, 5, 10, 20, 50, 100, 200,
  ];

  private static readonly coins10: number[] = [
    // 0.1,
    // 0.2,
    // 0.5,
    1, 2, 5, 10, 20, 50, 100, 200,
  ];

  private static readonly coins50: number[] = [
    // 0.1,
    // 0.2,
    // 0.5,
    // 1,
    // 2,
    5, 10, 20, 50, 100, 200,
  ];

  private static readonly coinsGreater: number[] = [
    // 0.1,
    // 0.2,
    // 0.5,
    // 1,
    // 2,
    // 5,
    10, 20, 50, 100, 200,
  ];

  static isBaseCoin(amount: number): boolean {
    return FastCashPayment.coins.some((i) => i == amount);
  }

  // round() prevent 0.50 & 0.5 duplicates
  static generate(value: number): number[] {
    let coins: number[];
    if (value < 1) coins = FastCashPayment.coins1;
    else if (value < 10) coins = FastCashPayment.coins10;
    else if (value < 50) coins = FastCashPayment.coins50;
    else {
      // get left base
      const base = FastCashPayment.coinsGreater.sort((a, b) => b - a).find((it) => value < it) ?? 0;
      console.log('base', base, value, FastCashPayment.coinsGreater);

      // sum base to all coins
      const sumBase = FastCashPayment.coinsGreater
        .filter((it) => it <= base)
        .map((it) => it + base)
        .filter((it) => it >= value);
      coins = FastCashPayment.coinsGreater.concat(sumBase);
    }

    console.log('coins', coins);

    // let rounded = coins.map((it) => Math.round(it * 100) / 100);
    // let rounded = coins.map((it) => {
    //   const division = value / it;
    //   return Math.round(it * division * 100) / 100;
    // });

    let rounded = coins.map((coin) => {
      const divided = value / coin;
      const roundedUp = Math.ceil(divided);
      return parseFloat((coin * roundedUp).toFixed(2));
    });

    // let rounded = coins.map((it) => parseFloat((it * (value / it)).toFixed(2)));

    console.log('rounded', rounded);

    // Remove zeros by RoundingMode CEILING in large numbers. Exam: value=0.86, currency=200
    rounded = rounded.filter((it) => it !== 0);

    console.log('rounded 2', rounded);

    // console.log('result concat', [value, ...rounded]);
    // console.log('result filter', [value, ...rounded].filter((value, index, self) => self.indexOf(value) === index));
    // console.log('result sort', [value, ...rounded].filter((value, index, self) => self.indexOf(value) === index).sort());

    const result = [value, ...rounded]
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a - b)
      .slice(0, FastCashPayment.LIMIT);

    console.log('result', result);

    return result;
  }
}
