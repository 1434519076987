export {};

declare global {
  interface Date {
    _addDays(days: number): Date;
    _isToday(): boolean;
  }
}

if (!Date.prototype._addDays) {
  Date.prototype._addDays = function (days: number): Date {
    const ms = days * 24 * 60 * 60 * 1000;
    return new Date(this.getTime() + ms);
  };
}

if (!Date.prototype._isToday) {
  Date.prototype._isToday = function (): boolean {
    const today = new Date();
    return (
      this.getDate() == today.getDate() &&
      this.getMonth() == today.getMonth() &&
      this.getFullYear() == today.getFullYear()
    );
  };
}
