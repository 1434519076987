export class ProductPackage {
  private _id: string;
  private _quantity: number;
  private _productId: string;
  private _productName: string;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(quantity: number) {
    this._quantity = quantity;
  }

  public get productId(): string {
    return this._productId;
  }

  public set productId(productId: string) {
    this._productId = productId;
  }

  public get productName(): string {
    return this._productName;
  }

  public set productName(productName: string) {
    this._productName = productName;
  }

  constructor(init?: Partial<ProductPackage>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      quantity: this.quantity,
      product_id: this.productId,
      product_name: this.productName,
    };
  }
}
