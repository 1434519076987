import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Utils from '../../core/utils/utils';

declare var require: any;

const linkify = require('linkify-it')();

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(str: string, ...args: any[]): unknown {
    if (Utils.isEmpty(str)) {
      return '';
    }

    // + '<youtube-player videoId="dQw4w9WgXcQ"></youtube-player>'
    const html = this.getLinkifiedContent(str);
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  escape(str: string): string {
    return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  getLinkifiedContent(content: string) {
    let out = this.escape(content);
    const matches = linkify.match(content);
    const result = [];
    let last: number;

    if (matches) {
      last = 0;
      matches.forEach((match: any) => {
        if (last < match.index) {
          result.push(this.escape(content.slice(last, match.index)).replace(/\r?\n/g, '<br>'));
        }
        result.push('<a target="_blank" rel="noopener noreferrer" href="');
        result.push(this.escape(match.url));
        result.push('">');
        result.push(this.escape(match.text));
        result.push('</a>');
        last = match.lastIndex;
      });

      if (last < content.length) {
        result.push(this.escape(content.slice(last)).replace(/\r?\n/g, '<br>'));
      }
      out = result.join('');
    }

    return out;
  }
}
