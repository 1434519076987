import { PaymentType } from '../enums/payment-type';
import { CartItem } from './cart-item';
import { CartPayment } from './cart-payment';
import { TaxLite } from './tax-lite';
import { Customer } from './customer';
import { Sale } from './sale';
import { PaymentMethod } from './payment-method';
import { Store } from './store';
import { CartSerie } from '../enums/serie-type';
import { mapObject } from '../extensions/object-extension';
import { TaxType } from '../enums/tax-type';
import { isTaxCodeFree, TaxCode } from '../enums/tax-code';

function zero(value: number | null | undefined): number {
  return value ?? 0;
}

export class Cart {
  id: string;
  name: string;
  customer: Customer | undefined;
  invoiceType: string;
  amountPaid: number;
  note: string;

  bagUnitTax?: number;
  bagCount?: number;
  vatRate: number;
  itemCount: number;
  totalQuantity: number;
  totalItemsDiscount: number;
  totalItemsCharge: number;
  totalCost: number;
  subtotal: number;
  globalDiscount: number;
  globalDiscountExempt: number;
  globalCharge: number;
  globalChargeExempt: number;
  totalDiscount: number;
  totalCharge: number;
  totalPrice: number;
  totalTaxable: number;
  totalExempt: number;
  totalNonTaxable: number;
  totalFree: number;
  totalExport: number;
  totalVat: number;
  totalExcise?: number;
  totalBagTax?: number;
  totalTax: number;
  totalSale: number;

  items: CartItem[] = [];
  payments: CartPayment[] = [];

  // itemsDiscountAmount: number;
  // itemsDiscountRate: number;
  // globalDiscountAmount: number;
  // globalDiscountPercentage: number;
  // taxed: number;
  // unaffected: number;
  // exonerated: number;
  qrCode: string;
  taxRate: number;
  taxBase: number;
  taxName: string;
  taxType: string;
  returnFor: string | undefined;

  // totalTax: number;

  discountIsPercentage: boolean;
  discountRate: number;
  discountAmount: number;
  status: string; // DocumentStatus.Completed
  createdAt: Date;
  updatedAt: Date;

  // builds
  canPay: boolean;
  itemsSize: number;
  //itemsQuantity: number;
  subtotalFmt: number;
  // total: number; // @deprecated
  remainingPayment: number;
  paymentCompleted: boolean;
  saleCompleted: boolean;
  sale: Sale;

  /////////////////////////////////////////////////////////
  selectedSerie: CartSerie | undefined;
  paymentMethods: PaymentMethod[] = [];
  isTaxInclusive: boolean;
  local: Store;
  tax: TaxLite;

  isReturning(): boolean {
    return this.returnFor ? true : false;
  }

  loadSale(sale: Sale) {
    // set sale properties
    this.id = sale.id;
    this.name = sale.number;
    this.note = sale.note;
    this.returnFor = sale.returnFor;
    this.status = sale.status;
    this.createdAt = sale.createdAt;
    this.updatedAt = sale.updatedAt;

    // set serie
    this.selectedSerie = mapObject(sale.serie, (i) => {
      return {
        name: i.name,
        type: i.type,
      } as CartSerie;
    });

    // set customer
    this.customer = mapObject(
      sale.customer,
      (i) =>
        new Customer({
          id: i.id,
          name: i.name,
          documentNumber: i.idNumber,
          documentType: i.documentType,
          email: i.email,
          mobile: i.mobile,
        })
    );

    // set items
    this.items = sale.items.map((i) => CartItem.fromOrderItem(this, i));

    // set payments
    this.payments = sale.payments.map((i) => CartPayment.fromOrderPayment(i));
  }

  setStore(local: Store) {
    this.paymentMethods = local.paymentMethods;
    this.isTaxInclusive = local.isTaxInclusive;
    this.tax = local.tax;
    this.local = local;
  }

  getItemsSize(): string {
    switch (this.itemsSize) {
      case 1:
        return '1 item';
      default:
        return this.itemsSize + ' items';
    }
  }

  addItem(cartItem: CartItem) {
    this.items.push(cartItem);
  }

  getItemByProductId(productId: String): CartItem | undefined {
    return this.items.find((i) => i.productId === productId);
  }

  getItemById(itemId: String): CartItem | undefined {
    return this.items.find((i) => i.id == itemId);
  }

  removeItem(cartItem: CartItem) {
    const found = this.items.find((i) => i.id == cartItem.id);
    if (found) {
      this.items._remove(found);
    }
  }

  addPayment(item: CartPayment) {
    this.payments.push(item);
  }

  removePayment(item: CartPayment) {
    const index = this.payments.findIndex((i) => i.id == item.id);
    if (index != -1) {
      this.payments._removeByIndex(index);
    }
  }

  // TotalPrice returns the total price of the document
  _totalPrice() {
    // Calculate the total price of the document
    const totalAffected = this.items
      .filter((i) => i.taxType == TaxType.TAXABLE || i.taxType == TaxType.UNDEFINED)
      .reduce((a, b) => a + b.totalPrice, 0)
      ._sub(this.globalDiscount)
      ._add(this.globalCharge);

    const totalNonAffected = this.items
      .filter((i) => i.taxType == TaxType.EXEMPT || i.taxType == TaxType.NON_TAXABLE || i.taxType == TaxType.EXPORT)
      .reduce((a, b) => a + b.totalPrice, 0)
      ._sub(this.globalDiscountExempt)
      ._add(this.globalChargeExempt);

    return totalAffected + totalNonAffected;
  }

  _totalTaxable(): number {
    return this.items
      .filter((i) => i.taxType == TaxType.TAXABLE || i.taxType == TaxType.UNDEFINED)
      .reduce((a, b) => a + b.totalPrice, 0)
      ._sub(this.globalDiscount)
      ._add(this.globalCharge);
  }

  _totalExempt(): number {
    return this.items.filter((i) => i.taxType == TaxType.EXEMPT).reduce((a, b) => a + b.subtotal, 0);
  }

  _totalNonTaxable(): number {
    return this.items.filter((i) => i.taxType == TaxType.NON_TAXABLE).reduce((a, b) => a + b.subtotal, 0);
  }

  _totalExport(): number {
    return this.items.filter((i) => i.taxType == TaxType.EXPORT).reduce((a, b) => a + b.subtotal, 0); // @review
  }

  _totalFree(): number {
    return this.items.filter((i) => isTaxCodeFree(i.taxCode)).reduce((a, b) => a + b.priceExcludingTax * b.quantity, 0);
  }

  _totalVAT() {
    // Calculate the total of the taxes
    const totalItemsTaxable = this.items
      .filter((i) => i.taxType == TaxType.TAXABLE || i.taxType == TaxType.UNDEFINED)
      .reduce((a, b) => a + b.totalPrice, 0);

    const totalItemsVAT = this.items
      .filter((i) => i.taxType == TaxType.TAXABLE || i.taxType == TaxType.UNDEFINED)
      .reduce((a, b) => a + b.totalTax, 0);

    if (totalItemsTaxable == 0 || totalItemsVAT == 0) {
      return totalItemsVAT;
    }

    let totalTax = totalItemsVAT;

    // Subtract the global discount tax
    if (this.globalDiscount != 0) {
      const discountPercentage = this.globalDiscount / totalItemsTaxable;
      totalTax = totalTax - totalItemsVAT * discountPercentage;
    }

    // Add the global charge tax
    if (this.globalCharge != 0) {
      const chargePercentage = this.globalCharge / totalItemsTaxable;
      totalTax = totalTax + totalItemsVAT * chargePercentage;
    }

    return totalTax;
  }

  build() {
    // Build cart items
    this.items.forEach((i) => {
      // display values
      i.totalFmt = i.priceInput * i.quantity;
      i.subtotalFmt = i.basePrice * i.quantity;

      // calculate values
      i.exciseType = undefined;
      i.exciseValue = undefined;
      i.bagUnitTax = 0;
      i.unitCost = 0;
      i.unitValue = i.priceExcludingTax;
      i.unitDiscount = i.priceExcludingTax._getAmountFrom(zero(i.discountPercentageInput))._round();
      i.unitCharge = i.priceExcludingTax._getAmountFrom(zero(i.chargePercentageInput))._round();
      i.unitPrice = i.unitValue - i.unitDiscount + i.unitCharge;
      i.unitTax = i.taxType == TaxType.TAXABLE ? i.unitPrice._getAmountFrom(zero(i.tax.rate)) : 0;
      i.totalTax = i.unitTax * i.quantity;
      i.subtotal = i.unitValue * i.quantity;
      i.totalCost = i.unitCost * i.quantity;
      i.totalDiscount = i.unitDiscount * i.quantity;
      i.totalCharge = i.unitCharge * i.quantity;
      i.totalPrice = i.unitPrice * i.quantity;
      i.totalVat = i.taxType == TaxType.TAXABLE ? i.unitTax * i.quantity : 0;
      i.totalExcise = undefined;
      i.totalBagTax = i.bagUnitTax ? i.bagUnitTax * i.quantity : undefined;
      i.totalTax = i.totalVat + zero(i.totalExcise) + zero(i.totalBagTax);
      i.totalSale = i.totalPrice + i.totalTax;
    });

    this.bagUnitTax = this.items.find((i) => i.bagUnitTax)?.bagUnitTax;
    this.bagCount = this.bagUnitTax ? this.items.filter((i) => i.bagUnitTax).reduce((a, b) => a + b.quantity, 0) : 0;
    this.itemCount = this.items.length;
    this.totalQuantity = this.items.reduce((a, b) => a + b.quantity, 0);
    this.totalItemsDiscount = this.items.reduce((a, b) => a + b.totalDiscount, 0);
    this.totalItemsCharge = this.items.reduce((a, b) => a + b.totalCharge, 0);
    this.totalCost = this.items.reduce((a, b) => a + b.totalCost, 0);
    this.subtotal = this.items.reduce((a, b) => a + b.totalPrice, 0);
    this.globalDiscount = 0;
    this.globalDiscountExempt = 0;
    this.globalCharge = 0;
    this.globalChargeExempt = 0;
    this.totalDiscount = this.totalItemsDiscount + this.globalDiscount + this.globalDiscountExempt;
    this.totalCharge = this.totalItemsCharge + this.globalCharge + this.globalChargeExempt;
    this.totalPrice = this._totalPrice();
    this.totalTaxable = this._totalTaxable();
    this.totalExempt = this._totalExempt();
    this.totalNonTaxable = this._totalNonTaxable();
    this.totalExport = this._totalExport();
    this.totalFree = this._totalFree();
    this.totalVat = this._totalVAT();
    this.totalExcise = undefined;
    this.totalBagTax = this.bagUnitTax ? this.bagUnitTax * this.bagCount : undefined;
    this.totalTax = zero(this.totalVat) + zero(this.totalExcise) + zero(this.totalBagTax);
    this.totalSale = (this.totalPrice + this.totalTax)._round();

    // format values
    this.subtotalFmt = this.totalPrice;

    // Build cart

    this.itemsSize = this.items.length;
    // this.itemsQuantity = this.items.reduce((a, b) => a + b.quantity, 0);
    this.canPay = this.items.length > 0 && this.totalQuantity > 0;

    //////////// PAYMENT ///////////////////////////////////////

    // reset cash changes: negative amounts are changes
    this.payments = this.payments.filter((i) => i.isChange == false);

    // get amount paid
    this.amountPaid = this.getTotalPayment();

    // get remaining payment
    this.remainingPayment = this.totalSale - this.amountPaid;

    if (this.isReturning()) {
      // check payment complete
      if (this.remainingPayment >= 0) {
        this.paymentCompleted = true;

        // check change
        if (this.remainingPayment > 0) {
          // get first cash payment
          const cashPayment = this.paymentMethods.find((i) => i.type == PaymentType.CASH);
          if (cashPayment) {
            const item = CartPayment.from(cashPayment, this.remainingPayment);
            item.isChange = true;
            this.addPayment(item);
          }
        }
      } else {
        this.paymentCompleted = false;
      }
    } else {
      // check payment complete
      if (this.remainingPayment <= 0) {
        this.paymentCompleted = true;

        // check change
        if (this.remainingPayment < 0) {
          // get first cash payment
          const cashPayment = this.paymentMethods.find((i) => i.type == PaymentType.CASH);
          if (cashPayment) {
            const item = CartPayment.from(cashPayment, this.remainingPayment);
            item.isChange = true;
            this.addPayment(item);
          }
        }
      } else {
        this.paymentCompleted = false;
      }
    }
  }

  build0() {
    // Build cart items
    this.items.forEach((it) => {
      it.build();
    });

    // Build cart

    this.itemsSize = this.items.length;
    //this.itemsQuantity = this.items.reduce((a, b) => a + b.quantity, 0);
    this.canPay = this.items.length > 0; //  && this.itemsQuantity > 0;

    const itemsTotal = this.items.reduce((a, b) => a + b.totalSale, 0);

    // tax_amount
    this.totalTax = this.items.reduce((a, b) => {
      // apply global discount
      // @todo @breakpoint review
      return a + b.totalTax; // ._applyDiscount(this.globalDiscountPercentage);
    }, 0);

    // -------------- TAX INCLUSIVE ---------------
    if (this.isTaxInclusive) {
      this.totalSale = itemsTotal._round(); //.round() // round() is required for exact math with payments
      this.subtotalFmt = this.totalSale - this.totalTax;
    } else {
      // -------------- TAX EXCLUSIVE ---------------
      this.subtotalFmt = itemsTotal;
      this.totalSale = (this.subtotalFmt + this.totalTax)._round(); //.round()
    }

    // get items discount
    // this.itemsDiscountAmount = this.items.reduce((a, b) => a + b.totalDiscount, 0);

    //////////// PAYMENT ///////////////////////////////////////

    // reset cash changes: negative amounts are changes
    this.payments = this.payments.filter((i) => i.isChange == false);

    // get amount paid
    this.amountPaid = this.getTotalPayment();

    // get remaining payment
    this.remainingPayment = this.totalSale - this.amountPaid;

    if (this.isReturning()) {
      // check payment complete
      if (this.remainingPayment >= 0) {
        this.paymentCompleted = true;

        // check change
        if (this.remainingPayment > 0) {
          // get first cash payment
          const cashPayment = this.paymentMethods.find((i) => i.type == PaymentType.CASH);
          if (cashPayment) {
            const item = CartPayment.from(cashPayment, this.remainingPayment);
            item.isChange = true;
            this.addPayment(item);
          }
        }
      } else {
        this.paymentCompleted = false;
      }
    } else {
      // check payment complete
      if (this.remainingPayment <= 0) {
        this.paymentCompleted = true;

        // check change
        if (this.remainingPayment < 0) {
          // get first cash payment
          const cashPayment = this.paymentMethods.find((i) => i.type == PaymentType.CASH);
          if (cashPayment) {
            const item = CartPayment.from(cashPayment, this.remainingPayment);
            item.isChange = true;
            this.addPayment(item);
          }
        }
      } else {
        this.paymentCompleted = false;
      }
    }
  }

  private getTotalPayment(): number {
    return this.payments.reduce((value, item) => value + item.amount, 0);
  }
}
