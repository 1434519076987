// Enum representing the possible product code types
export enum ProductCodeType {
  CUSTOM = 'custom',
  EAN = 'ean',
  ISBN = 'isbn',
  ITF = 'itf',
  JAN = 'jan',
}

// Array of valid product code types
export const productCodeTypes = [
  { value: ProductCodeType.CUSTOM, label: 'CUSTOM' },
  { value: ProductCodeType.EAN, label: 'EAN' },
  { value: ProductCodeType.ISBN, label: 'ISBN' },
  { value: ProductCodeType.ITF, label: 'ITF' },
  { value: ProductCodeType.JAN, label: 'JAN' },
];
