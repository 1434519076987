import { DeliveryType } from '../enums/delivery-type';
import { SaleStatus } from '../enums/sale-status';
import { SaleType } from '../enums/sale-type';
import { PaymentType } from '../enums/payment-type';
import { PreferenceType } from '../enums/preference-type';
import { ProductSaleType } from '../enums/product-sale-type';
import { TaxType } from '../enums/tax-type';
import { Tax } from './tax';
import { SerieType } from '../enums/serie-type';
import { ProductCode } from './product-code';

export class Sale {
  id: string;
  businessId: string;
  store: SaleStore;
  register: SaleRegister;
  user: SaleUser;
  serie: SaleSerie;
  customer: SaleCustomer;
  rider: SaleRider;
  invoice: SaleInvoice;
  deliveryMethod: SaleDeliveryMethod;
  tax: Tax;
  items: SaleItem[];
  payments: SalePayment[];
  issueDate: Date;

  bagUnitTax: number;
  bagCount: number;
  vatRate: number;
  itemCount: number;
  totalQuantity: number;
  totalItemsDiscount: number;
  totalItemsCharge: number;
  totalCost: number;
  subtotal: number;
  globalDiscount: number;
  globalDiscountExempt: number;
  globalCharge: number;
  globalChargeExempt: number;
  totalDiscount: number;
  totalCharge: number;
  totalPrice: number;
  totalTaxable: number;
  totalExempt: number;
  totalNonTaxable: number;
  totalFree: number;
  totalExport: number;
  totalVat: number;
  totalExcise: number;
  totalBagTax: number;
  totalTax: number;
  totalSale: number;

  finals?: SaleFinals;
  number: string;
  address: string;
  reference: string;
  direction: any;
  location: string;
  status: SaleStatus;
  type: SaleType;
  note: string;
  returnFor: string;
  currencyCode: string;
  search: string;
  paymentDate: Date;
  receiptNumber: string;
  receiptEmail: string;
  shippingDate: Date;
  deliveryDate: Date;
  isReassigned: boolean;
  reassignedId: string;
  reassignedName: string;
  observation: string;
  cancelationReason: string;
  cancelationSrc: string;
  canceledBy: string;
  canceledAt: Date;
  history: any[];
  events: any[];
  version: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  isReturn(): boolean {
    return this.returnFor ? true : false;
  }

  get itemsSize(): number {
    return this.items?.length ?? 0;
  }

  get hasNote(): boolean {
    return (this.note ?? '').length > 0;
  }

  get statusColor(): string {
    switch (this.status) {
      case SaleStatus.CANCELED:
        return 'warn';
      case SaleStatus.COMPLETED:
        return 'success';
      default:
        return 'info';
    }
  }

  get statusName(): string {
    switch (this.status) {
      case SaleStatus.CANCELED:
        return 'Cancelado';
      case SaleStatus.COMPLETED:
        return 'Completado';
      case SaleStatus.PENDING:
        return 'Pendiente';
      case SaleStatus.PREPARING:
        return 'Preparando';
      case SaleStatus.READY:
        return 'Listo';
      default:
        return 'Desconocido';
    }
  }

  get itemsCount(): number {
    return this.items?.length ?? 0;
  }

  isInvoiceProcesing(): boolean {
    return this.invoice && (this.invoice.status === 'creating' || this.invoice.status === 'canceling');
  }

  isOrder() {
    return this.deliveryMethod ? true : false;
  }

  isDelivery() {
    return this.deliveryMethod?.type !== DeliveryType.PICKUP;
  }

  canDeliver(): boolean {
    return this.deliveryMethod?.type == DeliveryType.PICKUP && this.status == SaleStatus.READY;
  }

  canAccept(): boolean {
    return this.status == SaleStatus.PENDING;
  }

  canCancel(): boolean {
    return this.type == SaleType.APP && this.status != SaleStatus.CANCELED;
  }

  invoiceNumber(): string {
    return this.invoice?.code;
    //  const padded = (this.invoiceNumber + '').padStart(6, '0');
    //  return this.invoiceSerie + '-' + padded;
  }

  constructor(init?: Partial<Sale>) {
    Object.assign(this, init);
    this.rider = init?.rider ? new SaleRider(init.rider) : undefined!;
    this.user = init?.user ? new SaleUser(init.user) : undefined!;
    this.serie = init?.serie ? new SaleSerie(init.serie) : undefined!;
    this.register = init?.register ? new SaleRegister(init.register) : undefined!;
    this.customer = init?.customer ? new SaleCustomer(init.customer) : undefined!;
    this.store = init?.store ? new SaleStore(init.store) : undefined!;
    this.tax = init?.tax ? new Tax(init.tax) : undefined!;
    this.deliveryMethod = init?.deliveryMethod ? new SaleDeliveryMethod(init.deliveryMethod) : undefined!;
    this.payments = init?.payments?.map((i) => new SalePayment(i)) ?? [];
    this.items = init?.items?.map((i) => new SaleItem(i)) ?? [];
    this.finals = init?.finals ? new SaleFinals(init.finals) : undefined;
  }

  public toJSON(): any {
    return {};
  }
}

export class SaleStore {
  id: string;
  name: string;
  idNumber: string;
  denomination: string;
  local: string;
  logoUrl: string;
  location: string;
  hasRiders: boolean;
  address: string;
  currencyCode: string;
  countryCode: string;
  locale: string;
  language: string;

  constructor(init?: Partial<SaleStore>) {
    Object.assign(this, init);
  }
}

export class SaleSerie {
  id: string;
  name: string;
  type: SerieType;
  number: string;

  constructor(init?: Partial<SaleSerie>) {
    Object.assign(this, init);
  }
}

export class SaleRegister {
  id: string;
  name: string;
  closureID: string;

  constructor(init?: Partial<SaleRegister>) {
    Object.assign(this, init);
  }
}

export class SaleUser {
  id: string;
  name: string;

  constructor(init?: Partial<SaleUser>) {
    Object.assign(this, init);
  }
}

export class SaleCustomer {
  id: string;
  name: string;
  idNumber: string;
  documentType: string;
  mobile: string;
  email: string;

  constructor(init?: Partial<SaleCustomer>) {
    Object.assign(this, init);
  }
}

export class SaleInvoice {
  id: string;
  serie: string; // F001
  number: string; // 1
  code: string; // F001-00000001
  type: string;
  pdfUrl: string;
  status: string;

  constructor(init?: Partial<SaleInvoice>) {
    Object.assign(this, init);
  }
}

export class SaleRider {
  id: string;
  name: string;

  constructor(init?: Partial<SaleRider>) {
    Object.assign(this, init);
  }
}

export class SaleDeliveryMethod {
  id: string;
  name: string;
  type: DeliveryType;
  price: number;
  eta: number[];
  start: Date;
  end: Date;
  scheduleId: string;

  constructor(init?: Partial<SaleDeliveryMethod>) {
    Object.assign(this, init);
  }
}

export class SalePayment {
  id: string;
  paymentId: string; // payment method id
  type: PaymentType;
  name: string;
  amount: number;
  giftCardId: string;
  operationNumber: string;
  origin: string;
  createdAt: Date;

  constructor(init?: Partial<SalePayment>) {
    Object.assign(this, init);
  }
}

export class SaleItem {
  id: string;
  businessId: string;
  storeId: string;
  saleId: string;
  productId: string;
  image: string;
  sku: string;
  codes: ProductCode[];
  brandId: string;
  brandName: string;
  categoryId: string;
  categoryName: string;
  saleType: ProductSaleType;
  units: number;
  location: string;
  trackInventory: boolean;
  isStockout: boolean;
  isChecked: boolean;
  preference: PreferenceType;
  discountId: string;
  taxId: string;
  taxName: string;

  name: string;
  taxType: TaxType;
  taxRate: number;
  taxCode: string;
  exciseType: string;
  exciseValue: number;
  bagUnitTax: number;
  quantity: number;
  price: number;
  priceInc: number;
  unitCost: number;
  unitValue: number;
  unitDiscount: number;
  unitCharge: number;
  unitPrice: number;
  unitTax: number;
  unitType: string;
  subtotal: number;
  totalCost: number;
  totalDiscount: number;
  totalCharge: number;
  totalPrice: number;
  totalVat: number;
  totalExcise: number;
  totalBagTax: number;
  totalTax: number;
  totalSale: number;
  globalCode: string;
  note: string;

  quantityOrigin: number;
  finals: ItemFinals;
  discount: number;
  isReturn: boolean;
  substitute: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  public get hasNote(): boolean {
    return (this.note ?? '').length > 0;
  }

  discountPercentage(): number {
    if (this.unitDiscount === 0) return 0;
    return this.unitValue / this.unitDiscount;
  }

  get hasDiscount(): boolean {
    return (this.unitDiscount ?? 0) > 0;
  }

  constructor(init?: Partial<SaleItem>) {
    Object.assign(this, init);
    this.codes = init?.codes?.map((i) => new ProductCode(i)) ?? [];
    this.finals = init?.finals ? new ItemFinals(init.finals) : undefined!;
  }
}

class SaleFinals {
  subtotal: number;
  totalTax: number;
  totalDiscount: number;
  total: number;

  constructor(init?: Partial<SaleFinals>) {
    Object.assign(this, init);
  }
}

class ItemFinals {
  quantity: number;
  unitPrice: number;
  unitBasePrice: number;
  unitDiscount: number;
  unitCharge: number;
  discountPercentage: number;
  chargePercentage: number;
  totalDiscount: number;
  totalCharge: number;
  totalPrice: number;
  totalBasePrice: number;

  constructor(init?: Partial<ItemFinals>) {
    Object.assign(this, init);
  }
}
