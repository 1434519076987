import { AppEvent } from '../enums/event';
import { OrderBy, SortBy } from '../enums/sorting';

export class InventoryMovementFilter {
  storeId?: string | null;
  productId?: string | null;
  eventType: AppEvent;
  from?: string; //moment.Moment | null;
  to?: string; //moment.Moment | null;
  sort?: SortBy | null; // product_name|expired_at|created_at
  order?: OrderBy | null; // asc, desc
  start?: number | null;
  limit?: number | null;

  constructor(init?: Partial<InventoryMovementFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let q = '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q += this.productId ? '&product_id=' + this.productId : '';
    q += this.eventType ? '&event_type=' + this.eventType : '';
    q += this.from ? '&from=' + this.from : ''; // this.from.format()
    q += this.to ? '&to=' + this.to : ''; // this.to.format()
    q += this.sort ? '&sort=' + this.sort : '';
    q += this.order ? '&order=' + this.order : '';
    q += this.start ? '&start=' + this.start : '';
    q += this.limit ? '&limit=' + this.limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
