import { AbstractControl, ValidationErrors } from '@angular/forms';
import { IDNumber } from '../common/idNumber';

export function rucValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  if (!IDNumber.isRUC(value)) {
    return {
      invalidRUC: {
        message: 'El RUC no tiene el formato correcto.',
      },
    };
  }

  return null;
}
