export enum SaleStatus {
  REJECTED = 'rejected',
  REASSIGNED = 'reassigned',
  UPDATED = 'updated',
  CANCELED = 'canceled',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  PREPARING = 'preparing',
  READY = 'ready',
  PICKING_UP = 'picking_up',
  PICKED_UP = 'picked_up',
  MEETING = 'meeting',
  COMPLETED = 'completed',
}
