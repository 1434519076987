export enum SortBy {
  ID = 'id',
  NAME = 'name',
  INDEX = 'index',
  PRODUCT_NAME = 'product_name',
  PRODUCT_CATEGORY = 'product_category',
  EXPIRES_AT = 'expires_at',
  LOT_EXPIRES_AT = 'lot_expires_at',
  CREATED_AT = 'created_at',
  TOTAL_QUANTITY = 'total_quantity',
  SEARCH_COUNT = 'search_count',
}

export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc',
}
