<div class="blank-layout-container transition-in justify-content-center align-items-center">
  <div class="position-relative row w-100 h-100 _bg-gredient justify-content-center">
    <div class="col-lg-4 d-flex align-items-center">
      <mat-card class="cardWithShadow boxed-auth">
        <mat-card-content class="p-32">
          <div class="sp__1"></div>

          <div class="text-center">
            <a [routerLink]="['/dashboards/dashboard1']" *ngIf="options.theme === 'light'">
              <img src="./assets/images/logos/logo.svg" class="img-logo align-middle m-2" alt="logo" />
            </a>
            <a [routerLink]="['/dashboards/dashboard1']" *ngIf="options.theme === 'dark'">
              <img src="./assets/images/logos/logo.svg" class="img-logo align-middle m-2" alt="logo" />
            </a>
          </div>

          <div class="sp__1"></div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%">
            <h1 style="font-size: 24px">Inicia sesión en Nishi</h1>
            <p style="text-align: center">Accede a tu cuenta y empieza a gestionar tu negocio.</p>
          </div>

          <!-- <div class="sp__1"></div> -->

          <div class="row m-t-24 custom-row">
            <div class="col-12 _col-sm-6" style="display: flex; justify-content: center">
              <button (click)="singInWithGoogle()" mat-stroked-button class="_w-100">
                <div class="d-flex align-items-center">
                  <img
                    style="width: 20px"
                    src="/assets/images/svgs/google-icon.svg"
                    alt="google"
                    width="16"
                    class="m-r-8"
                  />
                  Iniciar sesión con Google
                </div>
              </button>
            </div>
            <!-- <div class="col-12 col-sm-6">
              <button mat-stroked-button class="w-100 d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <img
                    style="width: 30px"
                    src="/assets/images/svgs/facebook-icon.svg"
                    alt="facebook"
                    width="40"
                    class="m-r-4"
                  />
                  Iniciar sesión con Facebook
                </div>
              </button>
            </div> -->
          </div>

          <div class="or-border m-t-30">o iniciar sesión con</div>

          <form class="m-t-30" [formGroup]="form" (ngSubmit)="signInWithEmail()">
            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block">Email</mat-label>
            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input type="email" matInput formControlName="email" />
              <mat-hint *ngIf="f['email'].touched && f['email'].invalid" class="m-b-16 error-msg">
                <div class="text-error" *ngIf="f['email'].errors && f['email'].errors['required']">
                  El email es obligatorio.
                </div>
                <div class="text-error" *ngIf="f['email'].errors && f['email'].errors['minlength']">
                  El nombre debe tener 6 caracteres.
                </div>
              </mat-hint>
            </mat-form-field>
            <!-- password -->
            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block">Contraseña</mat-label>
            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input matInput type="password" formControlName="password" />
              <mat-hint *ngIf="f['password'].touched && f['password'].invalid" class="m-b-16 error-msg">
                <div class="text-error" *ngIf="f['password'].errors && f['password'].errors['required']">
                  La contraseña es obligatoria.
                </div>
                <div class="text-error" *ngIf="f['password'].errors && f['password'].errors['minlength']">
                  La contraseña debe tener 6 caracteres como mínimo.
                </div>
              </mat-hint>
            </mat-form-field>

            <div *ngIf="false" class="d-flex align-items-center m-b-12">
              <!-- <mat-checkbox color="primary">Recordar este dispositivo</mat-checkbox> -->
              <a [routerLink]="['/forgot-pwd']" class="text-primary f-w-600 text-decoration-none m-l-auto f-s-14"
                >¿Olvidaste tu contraseña?</a
              >
            </div>
            <button mat-flat-button style="min-height: 45px" color="primary" class="w-100" [disabled]="!form.valid">
              Iniciar sesión
            </button>
            <!-- input -->
          </form>
          <span class="d-block f-w-500 text-center m-t-24"
            >¿Nuevo en Nishi?
            <a [routerLink]="['/sign-up']" class="text-decoration-none text-primary f-w-500 f-s-14">
              Crear una cuenta</a
            >
          </span>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<dl-loader [visible]="isLoading"></dl-loader>
