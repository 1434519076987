import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Preference } from '../models/preference';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  private readonly index = environment.api + '/v1/preferences';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Preference | null>> {
    return this.api.get<Response<Preference | null>>(Preference, this.index + '/' + id);
  }

  update(preference: Preference): Observable<Response<Preference>> {
    return this.api.put<Response<Preference>>(Preference, this.index + '/' + preference.id, preference);
  }
}
