import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '.body',
})
export class PageBodyDirective {
  constructor(public elementRef: ElementRef) {}

  @HostBinding('class')
  elementClass = '_scrollable';
}
