export class PackageTreeFilter {
  storeId?: string | null;
  productId?: string | null; // helper to single product. This use productIds as query
  productIds?: string[] | null;
  attachLot?: boolean | null;

  constructor(init?: Partial<PackageTreeFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let q = '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q += this.productId ? '&product_ids=' + this.productId : '';
    q += this.productIds && this.productIds.length > 0 ? '&product_ids=' + this.productIds.join(',') : '';
    q += this.attachLot ? '&attach_lot=' + this.attachLot : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
