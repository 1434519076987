export const ACTIVE_STATUS = [
  { id: 'all', text: 'All' },
  { id: 'enabled', text: 'Enabled' },
  { id: 'disabled', text: 'Disabled' },
];

export const PUBLIC_STATUS = [
  { id: 'all', text: 'All' },
  { id: 'enabled', text: 'Public' },
  { id: 'disabled', text: 'Private' },
];

export const AVAILABLE_STATUS = [
  { id: 'all', text: 'All' },
  { id: 'enabled', text: 'Available' },
  { id: 'disabled', text: 'Not Available' },
];

export const OPTIONS_TO_BOOL = [
  { id: 'all', value: null },
  { id: 'enabled', value: true },
  { id: 'disabled', value: false },
];
