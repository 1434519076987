import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { GiftCardService, Response, NotifyService, GiftCard } from '@app/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '@app/shared/components/dialog/dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ns-gift-card-view',
  templateUrl: './gift-card-view.component.html',
  styleUrls: ['./gift-card-view.component.scss'],
})
export class GiftCardViewComponent implements OnInit {
  model: GiftCard = new GiftCard();

  isLoading: boolean = false;

  subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<GiftCardViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public notify: NotifyService,
    private giftCardService: GiftCardService,
    private router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute
  ) {
    this.load(data);
  }

  ngOnInit(): void {}

  private load(id: string): void {
    this.isLoading = true;
    this.giftCardService.get(id).subscribe((response: Response<GiftCard | null>) => {
      this.isLoading = false;
      if (response.ok) {
        this.model = response.data!;
      } else {
        this.notify.error(response);
      }
    });
  }

  toEdit() {
    this.dialogRef.close();
    // http://localhost:4200/products/gift-cards/items/638515550784d1ddf0a10d89/edit
    this.router.navigate([`products/gift-cards/items/${this.model.id}/edit`]);
  }

  delete(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '480px',
      data: {
        title: 'Title of dialog',
        message: 'Estás seguro que deseas eliminar este registro de forma permanente',
      },
    });

    dialogRef.afterClosed().subscribe((ok: boolean) => {
      if (ok) {
        this.onDelete();
      }
    });
  }

  private onDelete(): void {
    this.isLoading = true;

    this.giftCardService.delete(this.model).subscribe((response: Response<GiftCard>) => {
      this.isLoading = false;
      if (response.ok) {
        this.dialogRef.close();
      } else {
        this.notify.error(response);
      }
    });
  }
}
