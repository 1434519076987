import { StockOption } from '../enums/stock-option';

export class ProductFilter {
  storeId?: string | null;
  productIds?: string[] | null;
  query?: string | null;
  barcode?: string | null;
  discountId?: string | null;
  active?: boolean | null;
  public?: boolean | null;
  available?: boolean | null;
  stock?: StockOption | null;
  supplierId?: string | null;
  brandId?: string | null;
  categoryId?: string | null;
  start?: number | null;
  limit?: number | null;

  // helper
  currentPage = 1;
  itemsPerPage = 30;

  constructor(init?: Partial<ProductFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let start = this.start;
    let limit = this.limit;

    if (start == undefined && limit == undefined) {
      start = (this.currentPage - 1) * this.itemsPerPage;
      limit = this.itemsPerPage;
    }

    let q = '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q += this.productIds && this.productIds.length > 0 ? '&product_ids=' + this.productIds.join(',') : '';
    q += this.query ? '&search=' + this.query : '';
    q += this.barcode ? '&barcode=' + this.barcode : '';
    q += this.discountId != null ? '&discount_id=' + this.discountId : '';
    q += this.active != null ? '&active=' + this.active : '';
    q += this.public != null ? '&public=' + this.public : '';
    q += this.available != null ? '&available=' + this.available : '';
    q += this.stock && this.stock !== 'null' ? '&stock=' + this.stock : '';
    q += this.supplierId && this.supplierId !== 'null' ? '&supplier_id=' + this.supplierId : '';
    q += this.brandId && this.brandId !== 'null' ? '&brand_id=' + this.brandId : '';
    q += this.categoryId && this.categoryId !== 'null' ? '&category_id=' + this.categoryId : '';
    q += start ? '&start=' + start : '';
    q += limit ? '&limit=' + limit : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
