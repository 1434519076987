import { Item } from './item';

export class StocktakeCount {
  id: string;
  storeId: string;
  stocktakeId: string;
  name: string;
  sku: string;
  order: number;
  productId: string;
  quantity: number;
  version: Date;
  createdAt: Date;

  // UI helpers
  product: Item;
  isLoading: boolean = false;
  isFailed: boolean = false;
  style: string = '';

  constructor(init?: Partial<StocktakeCount>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      stocktake_id: this.stocktakeId,
      name: this.name,
      sku: this.sku,
      order: this.order,
      product_id: this.productId,
      quantity: this.quantity,
      created_at: this.createdAt,
    };
  }
}
