import { EVENTS } from '../enums/event';
import { LocationType } from '../enums/location-type';
import { StockAdjustment } from '../enums/stock-adjustment';

export class InventoryMovement {
  private _id: string;
  private _storeId: string;
  private _productId: string;
  private _productName: string;
  private _productSku: string;
  private _sourceLocationId: string;
  private _sourceLocationType: LocationType;
  private _sourceLocationName: string;
  private _sourceQuantityAfter: number;
  private _destLocationId: string;
  private _destLocationType: LocationType;
  private _destLocationName: string;
  private _destQuantityAfter: number;
  private _eventType: string;
  private _eventId: string;
  private _quantity: number;
  private _occurredAt: Date;
  private _createdAt: Date;
  private _updatedAt: Date;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get storeId(): string {
    return this._storeId;
  }

  public set storeId(storeId: string) {
    this._storeId = storeId;
  }

  public get productId(): string {
    return this._productId;
  }

  public set productId(productId: string) {
    this._productId = productId;
  }

  public get productName(): string {
    return this._productName;
  }

  public set productName(value: string) {
    this._productName = value;
  }

  public get productSku(): string {
    return this._productSku;
  }

  public set productSku(value: string) {
    this._productSku = value;
  }

  public get sourceLocationId(): string {
    return this._sourceLocationId;
  }

  public set sourceLocationId(sourceLocationId: string) {
    this._sourceLocationId = sourceLocationId;
  }

  public get sourceLocationType(): LocationType {
    return this._sourceLocationType;
  }

  public set sourceLocationType(sourceLocationType: LocationType) {
    this._sourceLocationType = sourceLocationType;
  }

  public get sourceLocationName(): string {
    return this._sourceLocationName;
  }

  public set sourceLocationName(value: string) {
    this._sourceLocationName = value;
  }

  public get sourceQuantityAfter(): number {
    return this._sourceQuantityAfter;
  }

  public set sourceQuantityAfter(sourceQuantityAfter: number) {
    this._sourceQuantityAfter = sourceQuantityAfter;
  }

  public get destLocationId(): string {
    return this._destLocationId;
  }

  public set destLocationId(destLocationId: string) {
    this._destLocationId = destLocationId;
  }

  public get destLocationType(): LocationType {
    return this._destLocationType;
  }

  public set destLocationType(destLocationType: LocationType) {
    this._destLocationType = destLocationType;
  }

  public get destLocationName(): string {
    return this._destLocationName;
  }

  public set destLocationName(value: string) {
    this._destLocationName = value;
  }

  public get destQuantityAfter(): number {
    return this._destQuantityAfter;
  }

  public set destQuantityAfter(destQuantityAfter: number) {
    this._destQuantityAfter = destQuantityAfter;
  }

  public get eventType(): string {
    return this._eventType;
  }

  public set eventType(eventType: string) {
    this._eventType = eventType;
  }

  public get eventId(): string {
    return this._eventId;
  }

  public set eventId(eventId: string) {
    this._eventId = eventId;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(quantity: number) {
    this._quantity = quantity;
  }

  public get occurredAt(): Date {
    return this._occurredAt;
  }

  public set occurredAt(occurredAt: Date) {
    this._occurredAt = occurredAt;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  constructor(init?: Partial<InventoryMovement>) {
    Object.assign(this, init);
  }

  public eventName(): string | undefined {
    return EVENTS.find((i) => i.id == this.eventType)?.name;
  }

  sourceQuantity(): string {
    if (this.sourceLocationType == LocationType.STORE) {
      return `${this.sourceQuantityAfter - this.quantity} ➜ ${this.sourceQuantityAfter}`;
    }
    return '';
  }

  destQuantity(): string {
    if (this.destLocationType == LocationType.STORE) {
      return `${this.destQuantityAfter - this.quantity} ➜ ${this.destQuantityAfter}`;
    }
    return '';
  }

  public eventIdFtm(): string | undefined {
    if (Object.values(StockAdjustment).includes(this.eventId as StockAdjustment)) {
      return this.eventId;
    } else {
      return '';
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      product_id: this.productId,
      product_name: this.productName,
      product_sku: this.productSku,
      source_location_id: this.sourceLocationId,
      source_location_type: this.sourceLocationType,
      source_location_name: this.sourceLocationName,
      source_quantity_after: this.sourceQuantityAfter,
      dest_location_id: this.destLocationId,
      dest_location_type: this.destLocationType,
      dest_location_name: this.destLocationName,
      dest_quantity_after: this.destQuantityAfter,
      event_type: this.eventType,
      event_id: this.eventId,
      quantity: this.quantity,
      occurred_at: this.occurredAt,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
