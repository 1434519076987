import { StoreEntity, RegisterLiteEntity } from '../data/entities/store.entity';
import { PaymentMethod } from '../models/payment-method';
import { TaxLite } from './tax-lite';
import { DeliveryMethod } from './delivery-method';
import { RegisterLite } from './register-lite';

export class Store {
  public id: string;
  public username: string;
  public name: string;
  public denomination: string;
  public local: string;
  public idNumber: string;
  public title: string;
  public description: string;
  public address: string;
  public department: string;
  public province: string;
  public district: string;
  public city: string;
  public ubigeo: string;
  public email: string;
  public mobile: string;
  public phone: string;
  public imageUrl: string;
  public logoUrl: string;
  public invoicingEnabled: boolean;
  public currencyCode: string;
  public timeZone: string;
  public country: string;
  public location: number[];
  public taxId: string; // used to update tax
  public tax: TaxLite;
  public area: any;
  public areas: any[];
  public baseRate: number;
  public kmCovered: number;
  public kmCost: number;
  public paymentMethods: PaymentMethod[];
  public deliveryMethods: DeliveryMethod[];
  public sellBefore: number;
  public rating: number;
  public reviews: number;
  public schedule: any;
  public receipt: StoreReceipt;
  public isPublic: boolean;
  public isAvailable: boolean;
  public availableAt: Date;
  public isTaxInclusive: boolean;
  public isActive: boolean;
  public isVerified: boolean;
  public unavailableAt: Date;
  public deliveryTime: number[];
  public minPurchase: number;
  public isAmazonExempt: boolean;
  public createdAt: Date;
  public updatedAt: Date;

  registers: RegisterLite[] = [];

  constructor(init?: Partial<Store>) {
    Object.assign(this, init);

    this.tax = init?.tax ? new TaxLite(init?.tax) : undefined!;

    // Start with empty receipt if not exists
    this.receipt = init?.receipt ? new StoreReceipt(init?.receipt) : new StoreReceipt();

    this.paymentMethods = init?.paymentMethods?.map((i) => new PaymentMethod(i)) ?? [];
    this.deliveryMethods = init?.deliveryMethods?.map((i) => new DeliveryMethod(i)) ?? [];
    this.registers = init?.registers?.map((i) => new RegisterLite(i)) ?? [];
  }

  public entity(): StoreEntity {
    return new StoreEntity({
      id: this.id,
      name: this.name,
      local: this.local,
      logoUrl: this.logoUrl,
      denomination: this.denomination,
      idNumber: this.idNumber,
      phone: this.phone,
      mobile: this.mobile,
      email: this.email,
      address: this.address,
      currencyCode: this.currencyCode,
      country: this.country,
      city: this.city,
      department: this.department,
      district: this.district,
      timeZone: this.timeZone,
      tax: this.tax,
      isTaxInclusive: this.isTaxInclusive,
      updatedAt: this.updatedAt,
      paymentMethods: this.paymentMethods?.map((i) => i.entity()),
      registers: this.registers?.map((i) => i.entity()),
    });
  }

  public toJSON(): any {
    // Prevent receipt empty object
    let mreceipt = undefined;
    if (this.receipt) {
      mreceipt = Object.keys(this.receipt).length === 0 ? undefined : this.receipt?.toJSON();
    }

    return {
      id: this.id,
      // username: this.username,
      name: this.name,
      // denomination: this.denomination,
      local: this.local,
      // idNumber: this.idNumber,
      title: this.title,
      description: this.description,
      address: this.address,
      department: this.department,
      province: this.province,
      district: this.district,
      city: this.city,
      ubigeo: this.ubigeo,
      email: this.email,
      mobile: this.mobile,
      phone: this.phone,
      image_url: this.imageUrl,
      logo_url: this.logoUrl,
      currency_code: this.currencyCode,
      time_zone: this.timeZone,
      country: this.country,
      location: this.location,
      tax_id: this.taxId,
      // tax: this.tax?.toJSON(),
      area: this.area,
      areas: this.areas,
      base_rate: this.baseRate,
      km_covered: this.kmCovered,
      km_cost: this.kmCost,
      // do not edit payments mehods hire, use payment methods endpoint
      // payment_methods: this.paymentMethods?.map((i) => new PaymentMethod(i).toJSON()),
      delivery_methods: this.deliveryMethods?.map((i) => new DeliveryMethod(i).toJSON()) ?? undefined,
      sell_before: this.sellBefore,
      rating: this.rating,
      reviews: this.reviews,
      schedule: this.schedule,
      receipt: mreceipt,
      is_public: this.isPublic,
      is_available: this.isAvailable,
      available_at: this.availableAt,
      is_active: this.isActive,
      is_verified: this.isVerified,
      unavailable_at: this.unavailableAt,
      delivery_time: this.deliveryTime,
      min_purchase: this.minPurchase,
      is_amazon_exempt: this.isAmazonExempt,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }

  /**
   * Guardar en camelcase para deserealizar correctamente
   */
  public toLocalStorage(): any {
    return {
      id: this.id,
      name: this.name,
      description: this.description ?? null,
      email: this.email ?? null,
      imageUrl: this.imageUrl ?? null,
      logoUrl: this.logoUrl ?? null,
      address: this.address ?? null,
      isAvailable: this.isAvailable ?? null,
    };
  }
}

class StoreReceipt {
  private _header: string;
  private _legend: string;
  private _footer: string;

  public get header(): string {
    return this._header;
  }

  public set header(header: string) {
    this._header = header;
  }

  public get legend(): string {
    return this._legend;
  }

  public set legend(legend: string) {
    this._legend = legend;
  }

  public get footer(): string {
    return this._footer;
  }

  public set footer(footer: string) {
    this._footer = footer;
  }

  constructor(init?: Partial<StoreReceipt>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      header: this.header,
      legend: this.legend,
      footer: this.footer,
    };
  }
}
