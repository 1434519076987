<div class="chat-container scrollable">
  <!-- <div class="chat-header">
    <h2>Conversaciones</h2>
  </div> -->
  <ul>
    <li (click)="itemClick(item)" class="chat-item" *ngFor="let item of item$ | async">
      <div class="chat-item-header">
        <div class="user-image">
          <img draggable="false" src="{{ item.photo ?? 'assets/images/unknown.jpg' }}" alt="photo" />
        </div>
      </div>

      <div class="chat-content">
        <div class="flex-between flex-column">
          <div class="username">
            {{ item.name }}
          </div>
          <!-- <div class="chat-date">
            {{ item.date | moment : 'relative' }}
          </div> -->
        </div>
        <div class="flex-between" style="align-items: center">
          <div class="last-message">
            <div [ngSwitch]="item.m_type">
              <ng-template ngSwitchCase="text">
                {{ item.m_text }}
              </ng-template>
              <ng-template ngSwitchCase="image">[image]</ng-template>
              <ng-template ngSwitchCase="audio">[audio]</ng-template>
              <ng-template ngSwitchCase="video">[video]</ng-template>
              <ng-template ngSwitchCase="file">[file]</ng-template>
              <ng-template ngSwitchDefault>[attachment]</ng-template>
            </div>
          </div>
          <span *ngIf="item.unread" class="chat-unread">{{ item.unread }}</span>
        </div>

        <div class="chat-date">
          {{ item.date | moment : 'relative' }}
        </div>
      </div>
    </li>
  </ul>

  <dl-loader [visible]="loading"></dl-loader>

  <div *ngIf="!loading && (item$ | async)?.length == 0" class="emtpy-list">
    <h2>No tienes conversaciones</h2>
    <p>
      <!-- No tienes conversaciones. <br /> -->
      El historial de conversaciones se mostrará aquí.
    </p>
    <img draggable="false" src="assets/illustrations/ill_chats.jpg" />
  </div>
</div>
