import { Component, Output, EventEmitter, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '@app/material.module';
import { CoreService } from '@app/core/services/core.service';
import { MenuItemComponent } from '@app/components/commons/menu-item/menu-item.component';

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
    MenuItemComponent,
    BadgeComponent,
    UserProfilePictureComponent,
  ],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() items: any[] = [];
  @Input() activeItem: any;

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];

  business$ = this.businessService.business$; //: Observable<Business[]>;
  locals$ = this.storeService.locals$; // Observable<Store[]>;
  selectedBusiness: Business | null;
  selectedLocal: Store | null;
  selectedRegister: RegisterLite | null;

  constructor(
    private vsidenav: CoreService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private businessService: BusinessService,
    private storeService: StoreService,
    private config: UserConfig,
    private authService: AuthService
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    // listen business
    this.config.business$.subscribe((business) => {
      this.selectedBusiness = business;
    });

    // listen local
    this.config.local$.subscribe((local) => {
      this.selectedLocal = local;
    });

    // Lister register
    this.config.register$.subscribe((register) => {
      this.selectedRegister = register;
    });
  }

  logout(): void {
    this.authService.logout();
  }

  onBusinessSelect(business: Business): void {
    this.config.setBusiness(business);
    this.config.reloadPage();
  }

  onLocalSelect(local: Store): void {
    this.config.setStore(local);
    this.config.setRegister(null);
  }

  onRegisterSelect(local: Store, register: RegisterLite): void {
    // if (register.id == this.selectedRegister?.id) {
    //   this.config.setRegister(null);
    // } else {
    this.config.setStore(local);
    this.config.setRegister(register);
    // }
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent, {
      panelClass: 'app-search-dialog',
      minHeight: '400px', // Altura mínima
      // maxHeight: '600px', // Altura máxima
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  notifications: notifications[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-1.jpg',
      title: 'Roman Joined thes Team!',
      subtitle: 'Congratulate him',
    },
    {
      id: 2,
      img: '/assets/images/profile/user-2.jpg',
      title: 'New message received',
      subtitle: 'Salma sent you new message',
    },
    {
      id: 3,
      img: '/assets/images/profile/user-3.jpg',
      title: 'New Payment received',
      subtitle: 'Check your earnings',
    },
    {
      id: 4,
      img: '/assets/images/profile/user-4.jpg',
      title: 'Jolly completed tasks',
      subtitle: 'Assign her new tasks',
    },
    {
      id: 5,
      img: '/assets/images/profile/user-5.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulatse him',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-inbox.svg',
      title: 'My Inbox',
      subtitle: 'Messages & Email',
      link: '/apps/email/inbox',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-tasks.svg',
      title: 'My Tasks',
      subtitle: 'To-do and Daily Tasks',
      link: '/apps/taskboard',
    },
  ];

  apps: apps[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-dd-chat.svg',
      title: 'Chat Application',
      subtitle: 'Messages & Emails',
      link: '/apps/chat',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'Todo App',
      subtitle: 'Completed task',
      link: '/apps/todo',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-dd-invoice.svg',
      title: 'Invoice App',
      subtitle: 'Get latest invoice',
      link: '/apps/invoice',
    },
    {
      id: 4,
      img: '/assets/images/svgs/icon-dd-date.svg',
      title: 'Calendar App',
      subtitle: 'Get Dates',
      link: '/apps/calendar',
    },
    {
      id: 5,
      img: '/assets/images/svgs/icon-dd-mobile.svg',
      title: 'Contact Application',
      subtitle: '2 Unsaved Contacts',
      link: '/apps/contacts',
    },
    {
      id: 6,
      img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
      title: 'Tickets App',
      subtitle: 'Create new ticket',
      link: '/apps/tickets',
    },
    {
      id: 7,
      img: '/assets/images/svgs/icon-dd-message-box.svg',
      title: 'Email App',
      subtitle: 'Get new emails',
      link: '/apps/email/inbox',
    },
    {
      id: 8,
      img: '/assets/images/svgs/icon-dd-application.svg',
      title: 'Courses',
      subtitle: 'Create new course',
      link: '/apps/courses',
    },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/login',
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register',
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error',
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes',
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee',
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo',
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview',
    },
  ];
}

import Fuse from 'fuse.js'; // Importa Fuse.js
import { menu, NavItem } from '@app/components/sidebar/menu';
import { AuthService, Business, BusinessService, RegisterLite, Store, StoreService, UserConfig } from '@app/core';
import { BadgeComponent } from '@app/components/shared/badge/badge.component';
import { UserProfilePictureComponent } from '@app/components/shared/user-profile-picture/user-profile-picture.component';

@Component({
  selector: 'app-search-dialog',
  standalone: true,
  imports: [CommonModule, RouterModule, MaterialModule, TablerIconsModule, ReactiveFormsModule],
  templateUrl: 'search-dialog.component.html',
  styleUrls: ['search-dialog.component.scss'],
})
export class AppSearchDialogComponent implements OnInit {
  searchForm: FormGroup;
  navItemsData: NavItem[];
  filteredNavItems: NavItem[];

  private fuse: Fuse<any>; // Agrega Fuse.js como una propiedad

  constructor(private formBuilder: FormBuilder) {}

  // filterNavItems: menu item and children item
  filterNavItems(items: NavItem[]): NavItem[] {
    const filteredNavItems: NavItem[] = [];
    items.forEach((item) => {
      if (!item.hidden) {
        filteredNavItems.push(item);
        if (item.children) {
          for (const child of item.children) {
            if (!child.hidden) {
              filteredNavItems.push(child);
            }
          }
        }
      }
    });
    return filteredNavItems;
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchText: [''],
    });

    this.navItemsData = this.filterNavItems(menu);
    this.filteredNavItems = this.navItemsData;

    // Configura Fuse.js
    // Configura Fuse.js para buscar tanto en displayName como en route
    const options = {
      keys: ['name', 'route'],
      threshold: 0.4,
      distance: 100,
      includeScore: true,
      ignoreLocation: true,
      minMatchCharLength: 2,
      shouldSort: true,
      useExtendedSearch: true,
      findAllMatches: true,
    };

    this.fuse = new Fuse(this.navItemsData, options);

    this.searchForm.get('searchText')!.valueChanges.subscribe((value) => {
      if (!value) {
        this.filteredNavItems = this.navItemsData;
      } else {
        this.filteredNavItems = this.fuse.search(value).map((result) => result.item);
      }
    });
  }
}
