export class ProductGeneratedRequest {
  ok: boolean;
  message: string;
  product: {
    name?: string;
    description?: string;
    price?: number;
    imageSearchTerm?: string;
    keywords?: string;
    categoryName?: string;
    brandName?: string;
    tags?: string[];
    alcoholicBeverage?: boolean;
    saleType?: string;
    packing?: string;
    quantity?: number;
    presentation?: string;
    unitType?: string;
    inStock?: number;
    reorderPoint?: number;
    reorderQuantity?: number;
  };

  constructor(init?: Partial<ProductGeneratedRequest>) {
    Object.assign(this, init);
  }

  public toRequest(): any {
    return {
      name: this.product.name,
      description: this.product.description,
      price: this.product.price,
      image_search_term: this.product.imageSearchTerm,
      keywords: this.product.keywords,
      category_name: this.product.categoryName,
      brand_name: this.product.brandName,
      tags: this.product.tags,
      alcoholic_beverage: this.product.alcoholicBeverage,
      sale_type: this.product.saleType,
      packing: this.product.packing,
      quantity: this.product.quantity,
      presentation: this.product.presentation,
      unit_type: this.product.unitType,
      in_stok: this.product.inStock,
      reorder_point: this.product.reorderPoint,
      reorder_quantity: this.product.reorderQuantity,
    };
  }
}
