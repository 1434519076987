import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppService, Chat, DEFAULT_USER_ID, Messaging } from '@app/core';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { Observable } from 'rxjs';

@Component({
  selector: 'ns-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class ChatComponent implements OnInit, OnDestroy {
  item$: Observable<Chat[]>;

  @Output() onItemClick = new EventEmitter<Chat>();

  loading: boolean;

  selectedItem: Chat | undefined;

  constructor(private app: AppService, private messaging: Messaging) {
    messaging.loading$.subscribe((loading) => {
      if (loading) {
        this.loading = false;
      }
    });

    this.item$ = messaging.chats$;
    //this.item$ = of(this.getChatTest());
  }

  ngOnInit(): void {
    this.loading = true;
    this.messaging.chatSubscribe(DEFAULT_USER_ID);
  }

  itemClick(chat: Chat) {
    if (this.selectedItem?.id != chat.id) {
      // show messages
      this.onItemClick.emit(chat);

      // mark as seen conversation
      if (chat.seen != true) {
        this.messaging.seenConversation(DEFAULT_USER_ID, chat.id);
      }
    }
  }

  ngOnDestroy(): void {
    this.messaging.removeChatListener();
  }

  getChatTest(): Chat[] {
    const chats: Chat[] = [];
    for (let i = 0; i < 60; i++) {
      chats.push(
        new Chat({
          id: 'default_63dd7e352efd0ef313bbcc83',
          name: `John doe ${i}`,
          photo: i % 2 == 0 ? undefined : 'https://thumbs.dreamstime.com/b/selfie-teen-girl-taking-beach-44978938.jpg',
          m_text: 'Hola, necesito ayuda con mis pedidos. Aún no llega y a pasado más de una hora. Donde esta!!',
          m_type: 'text',
          seen: false,
          unread: i % 2 == 0 ? 2 : undefined,
          date: new Date()._addDays(-i),
        })
      );
    }
    return chats;
  }
}
