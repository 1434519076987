export class LotFilter2 {
  id: string; // uuid v4
  name: string;
  from?: number;
  to?: number;
  isNotifiable: boolean;
  createdAt: Date;

  constructor(init?: Partial<LotFilter2>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      from: this.from,
      to: this.to,
      isNotifiable: this.isNotifiable,
      createdAt: this.createdAt,
    };
  }
}
