<div mat-dialog-title>
  {{ model?.groupName ?? model?.name | void }}
</div>

<div mat-dialog-content class="transition-in">
  <div class="header-wrap">
    <div class="header">
      <p>View and manage gift cards.</p>
    </div>
    <div class="btn-list">
      <!-- <a (click)="delete()" class="btn btn-danger">Delete</a> -->
    </div>
  </div>

  <!-- content -->
  <div class="row">
    <div class="col transition-in transition-delay-nth-1">
      <div class="form read-only">
        <label>Balance</label>
        <p>{{ model?.balance | currency }}</p>

        <label>Expiration date</label>
        <p>{{ model?.expiresAt | moment }}</p>
      </div>
    </div>
  </div>

  <div class="sp__1"></div>
  <h2>Transactions</h2>

  <!-- transactions -->
  <table class="table-wrap">
    <thead>
      <tr>
        <th>Date</th>
        <th>Customer</th>
        <th>Sold by</th>
        <th>Type</th>
        <th class="align-right">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of model.transactions; let idx = index">
        <td>{{ i.createdAt | moment }}</td>
        <td>{{ i.customerName | void }}</td>
        <td>{{ i.userName | void }}</td>
        <td>{{ i.type }}</td>
        <td class="align-right">{{ i.amount | currency }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="model.id && toEdit()">Editar</button>
</div>

<dl-loader [visible]="isLoading"></dl-loader>
