import { Directive, ElementRef, OnInit } from '@angular/core';

declare var $: any;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '.transition-in',
})
export class TransitionDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      $(this.el.nativeElement).addClass('is-active');
    }, 10);
  }
}
