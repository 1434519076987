import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { Business } from '../models/business';
import { AppDatabase } from '../data/app-database';
import { liveQuery } from 'dexie';
import { Store } from '../models/store';
import { DataService, PreferenceVars } from './data.service';
import { StoreEntity } from '../data/entities/store.entity';
import { BusinessEntity } from '../data/entities/business.entity';
import { User } from '../models/user';
import { RegisterLite } from '../models/register-lite';
import { Router } from '@angular/router';

class Preference {
  quickKeyId?: string;
  quickSaleTaxId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserConfig {
  // Current business
  private _business = new BehaviorSubject<Business | null>(null);
  public business$ = this._business.asObservable();
  public business: Business | null = null;

  // Current local
  private _local = new BehaviorSubject<Store | null>(null);
  public local$ = this._local.asObservable();
  public local: Store | null = null;

  // Current register
  private _register = new BehaviorSubject<RegisterLite | null>(null);
  public register$ = this._register.asObservable();
  public register: RegisterLite | null = null;

  // Current user
  private _user = new BehaviorSubject<User | null>(null);
  public user$ = this._user.asObservable();
  public user: User | null = null;

  private loadedPreference = false;

  // Preference properties
  public quickKeyId: string | undefined;
  public quickSaleTaxId: string | undefined;

  private loadPreference() {
    if (this.loadedPreference) {
      console.log('preference already loaded');
      return;
    }

    this.loadedPreference = true;

    const value = this.dataService.get(PreferenceVars.USER_PREF);
    const preference = value ? (JSON.parse(value) as Preference) : new Preference();
    console.log('load preference', preference);

    // Set properties
    this.quickKeyId = preference?.quickKeyId;
    this.quickSaleTaxId = preference?.quickSaleTaxId;
  }

  save() {
    const preference = new Preference();

    // Set properties
    preference.quickKeyId = this.quickKeyId;
    preference.quickSaleTaxId = this.quickSaleTaxId;

    // Save preference
    const value = JSON.stringify(preference);
    this.dataService.set(PreferenceVars.USER_PREF, value);
  }

  public get businessIdPreference(): string {
    return this.dataService.businessIdPreference;
  }

  public get currentBusinessId(): string {
    return this.business?.id ?? this.dataService.businessIdPreference;
  }

  public get currentLocalId(): string {
    return this.local?.id ?? this.dataService.localIdPreference;
  }

  public get currentRegisterId(): string {
    return this.register?.id ?? this.dataService.registerIdPreference;
  }

  public get currentUserId(): string {
    return this.user?.id ?? this.dataService.userIdPreference;
  }

  // Preferences
  PREF_LOT_REVIEW_DAYS = 'PREF_LOT_REVIEW_DAYS';

  public get lotReviewDays(): number {
    return this.dataService.getNumber(this.PREF_LOT_REVIEW_DAYS) ?? 15;
  }

  public set lotReviewDays(value: number) {
    this.dataService.set(this.PREF_LOT_REVIEW_DAYS, value);
  }

  constructor(private router: Router, private dataService: DataService, private database: AppDatabase) {
    this.loadPreference();

    // Listen business changes
    liveQuery(() => this.database.businesses.toArray()).subscribe((response) => {
      const item = response.find((i) => this.business?.id == i.id);
      if (item) {
        this.setCurrentBusiness(new BusinessEntity(item).model());
      }
    });

    // Listen local changes
    liveQuery(() => this.database.locals.toArray()).subscribe((response) => {
      const item = response.find((i) => this.local?.id == i.id);
      if (item) {
        const store = new StoreEntity(item).model();
        const register = store.registers?.find((r) => r.id === this.currentRegisterId);
        this.setCurrentRegister(register ?? null);
        this.setCurrentLocal(store);
      }
    });
  }

  private setCurrentBusiness(business: Business, notified = true): void {
    // if (this.business?.id !== business.id) {
    if (this.dataService.businessIdPreference !== business.id) {
      console.log('business change', business.name);
      this.dataService.businessIdPreference = business.id;
      this.business = business;
      if (notified) {
        this._business.next(this.business);
      }
    } else {
      this.business = business; // set properties changes
      console.log('business nochange');
    }
  }

  private setCurrentLocal(local: Store, notified = true): void {
    if (this.local?.id !== local.id) {
      console.log('local change', local.name);
      this.dataService.localIdPreference = local.id;
      this.local = local;
      if (notified) {
        this._local.next(this.local);
      }
    } else {
      this.local = local; // set properties changes
      console.log('local nochange');
    }
  }

  private setCurrentRegister(register: RegisterLite | null): void {
    // if (this.register?.id !== register?.id) {
    //   console.log('register change', register?.name);
    //   this.dataService.registerIdPreference = register?.id ?? null;
    //   this.register = register;
    //   this._register.next(this.register);
    // } else {
    //   this.register = register;
    //   console.log('register nochange');
    // }

    console.log('register change', register?.name);
    this.dataService.registerIdPreference = register?.id ?? null;
    this.register = register;
    this._register.next(this.register);
  }

  private setCurrentUser(user: User, notified = true): void {
    if (this.user?.id !== user.id) {
      console.log('user change', user.name);
      this.dataService.userIdPreference = user.id;
      this.user = user;
      if (notified) {
        this._user.next(this.user);
      }
    } else {
      this.user = user; // set properties changes
      console.log('user nochange');
    }
  }

  setInitialData(business: Business, user: User, store: Store, register: RegisterLite | null): void {
    // Set initial data without notify
    this.setCurrentBusiness(business, false);
    this.setCurrentUser(user, false);
    this.setCurrentLocal(store, false);

    if (register) {
      this.setCurrentRegister(register);
    }

    // Notify changes
    this._business.next(this.business!);
    this._user.next(this.user!);
    this._local.next(this.local!);
    if (this.register) {
      this._register.next(this.register!);
    }
  }

  /**
   * @param business
   */
  setBusiness(business: Business, notified = true): void {
    this.setCurrentBusiness(business, notified);
  }

  reloadPage() {
    location.reload();
    // const currentRoute = this.router.url;
    // this.router.navigateByUrl(currentRoute);
  }

  /**
   * @param local
   */
  setStore(local: Store): void {
    this.setCurrentLocal(local);
  }

  /**
   * @param register
   */
  setRegister(register: RegisterLite | null): void {
    this.setCurrentRegister(register);
  }

  /**
   * @param user
   */
  setUser(user: User): void {
    this.setCurrentUser(user);
  }

  clear(): void {
    this.dataService.cleanData();
  }
}
