import { Pipe, PipeTransform } from '@angular/core';

declare var moment: any;

const FORMAT = "YYYY-MM-DD'T'HH:mm:ss'Z'";

@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
  transform(value: Date | undefined, format: string | null = null): any {
    if (!value) {
      return value;
    }

    if (format === 'calendar') {
      return moment().utc(value, FORMAT).local().calendar();
    }

    if (format === 'relative') {
      return moment.utc(value, FORMAT).local().fromNow();
    }

    return moment
      .utc(value, FORMAT)
      .local()
      .format(format ? format : 'lll');
  }
}
