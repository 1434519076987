import { TaxType } from '../enums/tax-type';
import { Cart } from './cart';
import { TaxLite } from './tax-lite';
import { SaleItem } from './sale';
import { Product } from './product';
import { Tax } from './tax';
import { ProductSaleType } from '../enums/product-sale-type';
import { TaxCode } from '../enums/tax-code';

const { v4: uuidv4 } = require('uuid');

function zero(value: number | null | undefined): number {
  return value ?? 0;
}

export class QuickSale {
  storeId: string;
  name: string;
  tax: Tax;
  price: number;
  quantity: number;
}

export class CartItem {
  id: string;
  storeId: string;
  productId?: string;
  sku: string;
  image: string;
  description: string;
  saleType: ProductSaleType;
  tax: TaxLite;

  // Inputs values. This values can including taxes or not
  basePrice: number; // Price Including or excluding taxes
  priceInput: number; // Price including or excluding taxes
  discountPercentageInput: number; // Discount percentage
  chargePercentageInput: number; // Charge percentage

  // Display values
  totalFmt: number;
  subtotalFmt: number;

  name: string;
  taxType: TaxType; // @unused
  taxRate: number; // @unused
  taxCode: string; // @unused
  exciseType?: string;
  exciseValue?: number;
  bagUnitTax: number;
  quantity: number;
  priceExcludingTax: number; // price priceFmt: number;
  priceIncludingTax: number; // priceInc
  unitCost: number;
  unitValue: number;
  unitDiscount: number;
  unitCharge: number;
  unitPrice: number;
  unitTax: number;
  unitType: string;
  subtotal: number;
  totalCost: number;
  totalDiscount: number;
  totalCharge: number;
  totalPrice: number;
  totalVat: number;
  totalExcise?: number;
  totalBagTax?: number;
  totalTax: number;
  totalSale: number;
  globalCode: string;
  note: string;

  totalRealPrice: number;
  discount: string; // Discount
  discountId: string;
  discountRate: number;
  // discountRateFmt: number;
  // subtotal: number;
  // taxRate: number;
  // taxType: TaxType;
  // totalSale: number;
  // totalCost: number;
  item: Product;
  createdAt: Date;
  updatedAt: Date;

  /////////////////////////////////////////////////////
  cart: Cart;

  public get hasDiscount(): boolean {
    return (this.discountPercentageInput ?? 0) > 0;
  }

  public get hasNote(): boolean {
    return (this.note ?? '').length > 0;
  }

  constructor(cart: Cart, init?: Partial<CartItem>) {
    Object.assign(this, init);
    this.cart = cart;
  }

  build() {
    // display values
    this.totalFmt = this.priceInput * this.quantity;
    this.subtotalFmt = this.basePrice * this.quantity;

    // calculate values
    this.unitValue = this.priceExcludingTax;
    this.unitDiscount = this.priceExcludingTax._getAmountFrom(zero(this.discountPercentageInput))._round();
    this.unitCharge = this.priceExcludingTax._getAmountFrom(zero(this.chargePercentageInput))._round();
    this.unitPrice = this.unitValue - this.unitDiscount + this.unitCharge;
    this.unitTax = this.taxType == TaxType.TAXABLE ? this.unitPrice._getAmountFrom(this.tax.rate) : 0;
    this.totalTax = this.unitTax * this.quantity;
    this.subtotal = this.unitValue * this.quantity;
    this.totalCost = this.unitCost * this.quantity;
    this.totalDiscount = this.unitDiscount * this.quantity;
    this.totalCharge = this.unitCharge * this.quantity;
    this.totalPrice = this.unitPrice * this.quantity;
    this.totalVat = this.taxType == TaxType.TAXABLE ? this.unitTax * this.quantity : 0;
    this.totalExcise = undefined;
    this.totalBagTax = this.bagUnitTax ? this.bagUnitTax * this.quantity : undefined;
    this.totalTax = this.totalVat + zero(this.totalExcise) + zero(this.totalBagTax);
    this.totalSale = this.totalPrice + this.totalTax;
  }

  build2() {
    /////////////////////////////////////////////////////////////////////

    // display values
    this.totalFmt = this.priceInput * this.quantity;
    this.subtotalFmt = this.basePrice * this.quantity;

    // unit_price: excluding_tax with disc.
    this.unitPrice = this.priceExcludingTax._applyDiscount(this.discountPercentageInput);

    // set tax values
    this.unitTax = this.unitPrice._getAmountFrom(this.tax.rate);
    this.totalTax = this.unitTax * this.quantity;

    /////////////////////////////////////////////////////////////////////

    // Obtener monto de descuento solo si el nuevo precio es menor
    if (this.priceInput < this.basePrice) {
      this.unitDiscount = this.priceExcludingTax._getAmountFrom(this.discountPercentageInput);
      // this.unitDiscount = this.basePrice - this.price;
    } else {
      this.unitDiscount = 0;
    }

    this.totalDiscount = this.unitDiscount * this.quantity;
    this.totalSale = this.unitPrice * this.quantity + this.totalTax;
  }

  static fromQuickSale(cart: Cart, quickSale: QuickSale): CartItem {
    // Get base price
    const qprice = quickSale.price;
    const qtax = quickSale.tax.rate;

    let priceExcludingTax = 0;
    let priceIncludingTax = 0;

    // Price including tax
    if (cart.isTaxInclusive) {
      priceExcludingTax = qprice._priceExcludingTax(qtax);
      priceIncludingTax = qprice;
    } else {
      priceExcludingTax = qprice;
      priceIncludingTax = qprice._priceIncludingTax(qtax);
    }

    const price = cart.isTaxInclusive ? priceIncludingTax : priceExcludingTax;
    const priceWithDsct = price; // no discount

    const tax = quickSale.tax.toLite();

    return new CartItem(cart, {
      id: uuidv4(),
      storeId: quickSale.storeId,
      // productId: product.productId,
      name: quickSale.name,
      // image: product.image,
      // description: product.description,
      quantity: quickSale.quantity,
      basePrice: price,
      priceInput: priceWithDsct._round(),
      //priceFmt: priceWithDsct._round(),

      unitDiscount: 0,
      unitPrice: priceExcludingTax,
      unitTax: 0,

      priceExcludingTax: priceExcludingTax,
      priceIncludingTax: priceIncludingTax,
      //discount : product.discount,
      // discountId: product.discount?.id,
      // discountRate: product.discount?.getDiscountRate(price),
      // discountRateFmt: product.discount?.getDiscountRate(price)._round(),
      tax: tax,
      taxRate: tax.rate ?? 0,
      //totalTax: 0,
      taxType: tax.type ?? TaxType.TAXABLE,
      taxCode: tax.code ?? TaxCode.TaxCodeUnknown,
      //subtotal: 0,
      // item: product,
      createdAt: new Date(),
    });
  }

  static fromProduct(cart: Cart, product: Product, quantity: number = 1): CartItem {
    // Get base price
    const price = (cart.isTaxInclusive ? product.priceIncludingTax : product.priceExcludingTax) ?? 0;

    const priceWithDsct = product.discount ? product.discount?.newPrice(price) : price;

    return new CartItem(cart, {
      id: uuidv4(),
      storeId: product.storeId!!,
      productId: product.productId,
      sku: product.sku,
      name: product.name,
      image: product.image,
      description: product.description,
      quantity: quantity,
      basePrice: price,
      priceInput: priceWithDsct._round(),
      //priceFmt: priceWithDsct._round(),

      unitDiscount: 0,
      unitPrice: product.priceExcludingTax,
      unitTax: 0,

      priceExcludingTax: product.priceExcludingTax ?? 0,
      priceIncludingTax: product.priceIncludingTax ?? 0,
      //discount : product.discount,
      discountId: product.discount?.id,
      discountPercentageInput: product.discount?.getDiscountRate(price)._round(),
      //discountRateFmt: product.discount?.getDiscountRate(price)._round(),
      tax: product.tax,
      taxRate: product.tax?.rate ?? 0,
      totalTax: 0,
      taxType: product.tax?.type ?? TaxType.TAXABLE,
      taxCode: product.tax?.code ?? TaxCode.TaxCodeUnknown,
      subtotal: 0,
      item: product,
      createdAt: new Date(),
    });
  }

  static fromOrderItem(cart: Cart, orderItem: SaleItem): CartItem {
    // Get base price
    const price = (cart.isTaxInclusive ? orderItem.priceInc : orderItem.price) ?? 0;

    const priceWithDsct = price._applyDiscount(orderItem.discountPercentage());

    return new CartItem(cart, {
      id: uuidv4(),
      storeId: orderItem.storeId!!,
      productId: orderItem.productId,
      sku: orderItem.sku,
      name: orderItem.name,
      image: orderItem.image,
      note: orderItem.note,
      quantity: orderItem.quantity,
      // description: product.description,
      basePrice: price,
      priceInput: priceWithDsct._round(),
      //priceFmt: priceWithDsct._round(),

      unitDiscount: 0,
      unitPrice: orderItem.price,
      unitTax: 0,

      priceExcludingTax: orderItem.price ?? 0,
      priceIncludingTax: orderItem.priceInc ?? 0,
      //discount : product.discount,
      discountId: orderItem.discountId,
      discountPercentageInput: orderItem.discountPercentage()._round(),
      //discountRateFmt: orderItem.discountPercentage()._round(),
      tax: new TaxLite({
        id: orderItem.taxId,
        name: orderItem.taxName,
        type: orderItem.taxType,
        rate: orderItem.taxRate,
        code: orderItem.taxCode,
      }),
      //totalTax: orderItem.totalTax,
      taxRate: orderItem.taxRate ?? 0,
      taxType: orderItem.taxType,
      taxCode: orderItem.taxCode,
      // subtotal: 0,
      // item: orderItem,
      createdAt: new Date(),
    });
  }
}
