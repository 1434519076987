import { DiscountType } from '../enums/discount-type';
import { LotReviewType } from '../enums/lot-review-type';
import { Discount } from './discount';

export class LotReview {
  private _id: string;
  private _storeId: string;
  private _productId: string;
  private _productName: string;
  private _productSku: string;
  private _lotId: string;
  private _lotNumber: string;
  private _lotExpiresAt: Date;
  private _userId: string;
  private _userName: string;
  private _type: LotReviewType;
  private _quantity: number;
  private _discountType: DiscountType;
  private _discountValue: number;
  private _discounts: Discount[];
  private _isReviewed: boolean;
  private _isActive: boolean;
  private _isReversed: boolean;
  private _reviewedAt: Date;
  private _createdAt: Date;
  private _updatedAt: Date;

  loading: boolean;

  get isCurrency(): boolean {
    return this.discountType == DiscountType.CURRENCY;
  }

  set isCurrency(isCurrency: boolean) {
    this._discountType = isCurrency ? DiscountType.CURRENCY : DiscountType.PERCENTAGE;
  }

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get storeId(): string {
    return this._storeId;
  }

  public set storeId(value: string) {
    this._storeId = value;
  }

  public get productId(): string {
    return this._productId;
  }

  public set productId(productId: string) {
    this._productId = productId;
  }

  public get productName(): string {
    return this._productName;
  }

  public set productName(productName: string) {
    this._productName = productName;
  }

  public get productSku(): string {
    return this._productSku;
  }

  public set productSku(productSku: string) {
    this._productSku = productSku;
  }

  public get lotId(): string {
    return this._lotId;
  }

  public set lotId(lotId: string) {
    this._lotId = lotId;
  }

  public get lotNumber(): string {
    return this._lotNumber;
  }

  public set lotNumber(lotNumber: string) {
    this._lotNumber = lotNumber;
  }

  public get lotExpiresAt(): Date {
    return this._lotExpiresAt;
  }

  public set lotExpiresAt(lotExpiresAt: Date) {
    this._lotExpiresAt = lotExpiresAt;
  }

  public get userId(): string {
    return this._userId;
  }

  public set userId(userId: string) {
    this._userId = userId;
  }

  public get userName(): string {
    return this._userName;
  }

  public set userName(userName: string) {
    this._userName = userName;
  }

  public get type(): LotReviewType {
    return this._type;
  }

  public set type(type: LotReviewType) {
    this._type = type;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(quantity: number) {
    this._quantity = quantity;
  }

  public get discountType(): DiscountType {
    if (this.type == LotReviewType.DISCOUNT && !this._discountType) {
      this._discountType = DiscountType.PERCENTAGE;
    }
    return this._discountType;
  }

  public set discountType(value: DiscountType) {
    this._discountType = value;
  }

  public get discountValue(): number {
    return this._discountValue;
  }

  public set discountValue(value: number) {
    this._discountValue = value;
  }

  public get discounts(): Discount[] {
    return this._discounts;
  }

  public set discounts(value: Discount[]) {
    this._discounts = value;
  }

  public get isReviewed(): boolean {
    return this._isReviewed;
  }

  public set isReviewed(isReviewed: boolean) {
    this._isReviewed = isReviewed;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(value: boolean) {
    this._isActive = value;
  }

  public get isReversed(): boolean {
    return this._isReversed;
  }

  public set isReversed(isReversed: boolean) {
    this._isReversed = isReversed;
  }

  public get reviewedAt(): Date {
    return this._reviewedAt;
  }

  public set reviewedAt(reviewedAt: Date) {
    this._reviewedAt = reviewedAt;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  constructor(init?: Partial<LotReview>) {
    Object.assign(this, init);
    this.discounts = init?.discounts?.map((i) => new Discount(i)) ?? [];
    // this.discounts = [
    //   new Discount({ value: 5, type: DiscountType.CURRENCY }),
    //   new Discount({ value: 5, type: DiscountType.PERCENTAGE }),
    // ];
  }

  isNearToExpiry(): boolean {
    return this.lotExpiresAt && this.lotExpiresAt.getTime() - new Date().getTime() <= this.daysToMs(5);
  }

  isExpired(): boolean {
    return this.lotExpiresAt && this.lotExpiresAt.getTime() - new Date().getTime() <= 0;
  }

  daysToMs(days: number) {
    // 👇️        hour  min  sec  ms
    return days * 24 * 60 * 60 * 1000;
  }

  canReverse(): boolean {
    return !this.isActive && this.createdAt && this.createdAt.getTime() - new Date().getTime() > -this.daysToMs(1);
  }

  canApplyDiscount(): boolean {
    return this.lotExpiresAt && this.lotExpiresAt.getTime() - new Date().getTime() > this.daysToMs(5);
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      product_id: this.productId,
      product_name: this.productName,
      product_sku: this.productSku,
      lot_id: this.lotId,
      lot_number: this.lotNumber,
      lot_expires_at: this.lotExpiresAt,
      user_id: this.userId,
      user_name: this.userName,
      type: this.type,
      quantity: this.quantity,
      discount_type: this.discountType,
      discount_value: this.discountValue,
      //discounts: this.discounts,
      is_reviewed: this.isReviewed,
      is_active: this.isActive,
      is_reversed: this.isReversed,
      reviewed_at: this.reviewedAt,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
