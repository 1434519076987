import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GiftCard, GiftCardFilter, GiftCardService, NotifyService, OrderBy, PrintService, SortBy } from '@app/core';

//declare var QRCode: any;

const chunks = (a: GiftCard[], size: number) =>
  Array.from(new Array(Math.ceil(a.length / size)), (_, i) => a.slice(i * size, i * size + size));

@Component({
  selector: 'ns-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss'],
})
export class GiftCardComponent implements OnInit {
  giftCards: GiftCard[][] = [];

  giftCardIds: string[];
  invoiceDetails: Promise<any>[];

  //@ViewChild('canvas', { static: true })
  //canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;

  constructor(
    route: ActivatedRoute,
    private notify: NotifyService,
    private giftCardService: GiftCardService,
    private printService: PrintService
  ) {
    this.giftCardIds = route.snapshot.params['gift_card_ids'].split(',');
  }

  ngOnInit() {
    //this.ctx = this.canvas.nativeElement.getContext('2d')!!;

    const filter = new GiftCardFilter();

    filter.ids = this.giftCardIds;
    filter.sort = SortBy.ID;
    filter.order = OrderBy.ASC;

    this.giftCardService.all(filter).subscribe((response) => {
      if (response.ok) {
        this.giftCards = chunks(response.data, 36);
        this.printService.onDataReady();
      } else {
        this.notify.error(response);
      }
    });
  }
}
