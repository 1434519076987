export class RiderFilter {
  query?: string | null;
  storeId?: string | null;

  constructor(init?: Partial<RiderFilter>) {
    Object.assign(this, init);
  }

  getQuery(): string {
    let q = '';
    q += this.query ? '&q=' + this.query : '';
    q += this.storeId ? '&store_id=' + this.storeId : '';
    q = q ? '?' + q.substring(1) : '';
    return q;
  }
}
