export enum RoundingMode {
  ROUND_MID_DOWN = 'round_mid_down',
  ROUND_MID_UP = 'round_mid_up',
  ROUND_DOWN_ALWAYS = 'round_down_always',
  ROUND_UP_ALWAYS = 'round_up_always',
}

export const ROUNDING_VALUES = [
  { value: 0.01, label: '0.01' },
  { value: 0.05, label: '0.05' },
  { value: 0.1, label: '0.10' },
  { value: 0.5, label: '0.50' },
  { value: 1, label: '1.00' },
];

export const ROUNDING_MODES = [
  {
    value: RoundingMode.ROUND_MID_DOWN,
    label: 'Redondeo Medio Abajo: Ej. redondear a 0.10: 2.45 -> 2.4, 2.55 -> 2.5',
  },
  {
    value: RoundingMode.ROUND_MID_UP,
    label: 'Redondeo Medio Arriba: Ej. redondear a 0.10: 2.45 -> 2.5, 2.55 -> 2.6',
  },
  {
    value: RoundingMode.ROUND_DOWN_ALWAYS,
    label: 'Redondeo Siempre Abajo: Ej. redondear a 0.10: 2.49 -> 2.4, 2.79 -> 2.7',
  },
  {
    value: RoundingMode.ROUND_UP_ALWAYS,
    label: 'Redondeo Siempre Arriba: Ej. redondear a 0.10: 2.41 -> 2.5, 2.71 -> 2.8',
  },
];
