import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dl-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() visible: boolean | undefined;

  @Input() background = '#fbfbfb52';

  constructor() {}

  ngOnInit(): void {}
}
