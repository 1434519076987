export {};

declare global {
  interface Number {
    _round(): number;
    _zero(): number;
    _applyDiscount(percentage: number): number;
    _getAmountFrom(percentage: number): number;
    _extractTax(percentage: number): number;
    _getPercentageFrom(amount: number): number;
    _priceExcludingTax(tax: number): number;
    _priceIncludingTax(tax: number): number;
    _sub(value: number): number;
    _add(value: number): number;
  }
}

if (!Number.prototype._priceIncludingTax) {
  Number.prototype._priceIncludingTax = function (_tax: number): number {
    const value: number = +this;
    const percentage = _tax ?? 0;
    if (!value) return 0;
    const tax = (value * percentage) / 100;
    return value + tax;
  };
}

if (!Number.prototype._priceExcludingTax) {
  Number.prototype._priceExcludingTax = function (_tax: number): number {
    const value: number = +this;
    const percentage = _tax ?? 0;
    if (!value) return 0;
    return value / (1 + percentage / 100);
  };
}

if (!Number.prototype._round) {
  Number.prototype._round = function (): number {
    if (!this) return 0;
    return +this.toFixed(2);
  };
}

if (!Number.prototype._zero) {
  Number.prototype._zero = function (): number {
    if (!this) return 0;
    return this as number;
  };
}

if (!Number.prototype._applyDiscount) {
  Number.prototype._applyDiscount = function (percentage: number): number {
    const value: number = +this;
    if (!value) return 0;
    return value - (value * (percentage ?? 0)) / 100;
  };
}

if (!Number.prototype._getAmountFrom) {
  Number.prototype._getAmountFrom = function (_percentage: number): number {
    const value: number = +this;
    const percentage = _percentage ?? 0;
    if (!value) return 0;
    return (value * percentage) / 100;
  };
}

if (!Number.prototype._extractTax) {
  Number.prototype._extractTax = function (_percentage: number): number {
    const value: number = +this;
    const percentage = _percentage ?? 0;
    if (!value) return 0;
    return (value * percentage) / (100 + percentage);
  };
}

if (!Number.prototype._getPercentageFrom) {
  Number.prototype._getPercentageFrom = function (amount: number): number {
    const value: number = +this;
    if (!value) return 0;
    return (value * (amount ?? 0)) / 100;
  };
}

if (!Number.prototype._sub) {
  Number.prototype._sub = function (value: number): number {
    const _value: number = +this;
    if (!_value) return 0;
    return _value - (value ?? 0);
  };
}

if (!Number.prototype._add) {
  Number.prototype._add = function (value: number): number {
    const _value: number = +this;
    if (!_value) return 0;
    return _value + (value ?? 0);
  };
}
