import { DeliveryType } from '../enums/delivery-type';
import { TaxLite } from './tax-lite';

export class DeliveryMethod {
  id: string;
  name: string;
  description: string;
  storeId: string;
  type: DeliveryType;
  isEnabled: boolean;
  tax: TaxLite;
  baseRate: number;
  kmCovered: number;
  kmCost: number;
  eta: number[];
  // areas: any[];
  schedule: DeliverySchedule[];
  minPurchase: number;
  maxOrders: number;
  disableBefore: number;
  version: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(init?: Partial<DeliveryMethod>) {
    Object.assign(this, init);
    this.tax = init?.tax ? new TaxLite(init?.tax) : undefined!;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      store_id: this.storeId,
      type: this.type,
      is_enabled: this.isEnabled,
      tax_id: this.tax?.id,
      base_rate: this.baseRate,
      km_covered: this.kmCovered,
      km_cost: this.kmCost,
      eta: this.eta,
      // areas: this.areas,
      min_purchase: this.minPurchase,
      max_orders: this.maxOrders,
      disable_before: this.disableBefore,
    };
  }
}

export class DeliverySchedule {
  id: string;
  name: string;
  price: number;
  day: number;
  start: number;
  end: number;
  availableAt: Date;
  isEnabled: boolean;

  constructor(init?: Partial<DeliverySchedule>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      price: this.price,
      day: this.day,
      start: this.start,
      end: this.end,
      available_at: this.availableAt,
      is_enabled: this.isEnabled,
    };
  }
}
