import { Media } from './media';

export class Advertisement {
  id: string;
  storeId: string;
  name: string;
  title: string;
  description: string;
  action: string;
  media: Media;
  type: string;
  productIds: string[];
  collectionId: string;
  isEnabled: boolean;
  index: number;
  version: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  featuredPost: boolean;

  constructor(init?: Partial<Advertisement>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      name: this.name,
      title: this.title,
      description: this.description,
      action: this.action,
      media: this.media,
      type: this.type,
      product_ids: this.productIds,
      collection_id: this.collectionId,
      is_enabled: this.isEnabled,
      // index: this.index,
      // version: this.version,
      // created_at: this.createdAt,
      // updated_at: this.updatedAt,
      // deleted_at: this.deletedAt,
    };
  }
}
