import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { DeliveryMethod } from '../models/delivery-method';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class DeliveryMethodService {
  private readonly index = environment.api + '/v1/delivery_methods';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<DeliveryMethod | null>> {
    return this.api.get<Response<DeliveryMethod | null>>(DeliveryMethod, `${this.index}/${id}`);
  }

  all(): Observable<Response<DeliveryMethod[]>> {
    return this.api.get<Response<DeliveryMethod[]>>(DeliveryMethod, this.index);
  }

  create(deliveryMethod: DeliveryMethod): Observable<Response<DeliveryMethod>> {
    return this.api.post<Response<DeliveryMethod>>(DeliveryMethod, this.index, deliveryMethod);
  }

  update(deliveryMethod: DeliveryMethod): Observable<Response<DeliveryMethod>> {
    return this.api.put<Response<DeliveryMethod>>(DeliveryMethod, `${this.index}/${deliveryMethod.id}`, deliveryMethod);
  }

  delete(deliveryMethod: DeliveryMethod): Observable<Response<DeliveryMethod>> {
    return this.api.delete<Response<DeliveryMethod>>(DeliveryMethod, `${this.index}/${deliveryMethod.id}`);
  }
}
