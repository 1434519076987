<div mat-dialog-title>{{ data.title }}</div>

<div mat-dialog-content class="dialog-wrap">
  <div class="dialog-content">
    <p>{{ data.message }}</p>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()">{{ data.no ?? 'Cancelar' }}</button>
  <!-- [mat-dialog-close]="true" -->

  <button mat-button color="primary" *ngIf="style == 'default'" (click)="ok()" cdkFocusInitial>
    {{ data.yes ?? 'Aceptar' }}
  </button>

  <button mat-button color="warn" *ngIf="style == 'danger'" (click)="ok()" cdkFocusInitial>
    {{ data.yes ?? 'Eliminar' }}
  </button>
</div>
