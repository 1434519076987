import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router) {}

  printOrder(orderId: string) {
    this.isPrinting = true;
    this.router.navigate([
      {
        outlets: {
          print: ['print', 'orders', orderId],
        },
      },
    ]);
  }

  printDocument(documentName: string, ids: string[]) {
    this.isPrinting = true;
    this.router.navigate([
      '/',
      {
        outlets: {
          print: ['print', documentName, ids.join(',')],
        },
      },
    ]);
  }

  printBarcode(document: string, barcode: string, quantity: number) {
    this.isPrinting = true;
    this.router.navigate(
      [
        {
          outlets: {
            print: ['print', document],
          },
        },
      ],
      {
        queryParams: { barcode: barcode, quantity: quantity },
      }
    );
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    }, 10);
  }
}
