export class Printer {
  private _id: string;
  private _storeId: string;
  private _name: string;
  private _model: string;
  private _connection: string;
  private _address: string;
  private _createdAt: Date;
  private _updatedAt: Date;

  public get id(): string {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get storeId(): string {
    return this._storeId;
  }

  public set storeId(storeId: string) {
    this._storeId = storeId;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get model(): string {
    return this._model;
  }

  public set model(model: string) {
    this._model = model;
  }

  public get connection(): string {
    return this._connection;
  }

  public set connection(connection: string) {
    this._connection = connection;
  }

  public get address(): string {
    return this._address;
  }

  public set address(address: string) {
    this._address = address;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public set createdAt(createdAt: Date) {
    this._createdAt = createdAt;
  }

  public get updatedAt(): Date {
    return this._updatedAt;
  }

  public set updatedAt(updatedAt: Date) {
    this._updatedAt = updatedAt;
  }

  constructor(init?: Partial<Printer>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      name: this.name,
      model: this.model,
      connection: this.connection,
      address: this.address,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
