import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestCache {
  cache = new Map();

  get(url: string): any | undefined {
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const expired = Date.now() > cached.expire;

    if (expired) {
      this.cache.delete(url);
      return undefined;
    }

    return cached.response;
  }

  put(url: string, response: any, expire: number): void {
    const entry = { url, response, expire: Date.now() + expire };
    this.cache.set(url, entry);

    this.cache.forEach((item) => {
      if (Date.now() > item.expire) {
        this.cache.delete(item.url);
      }
    });
  }
}
