import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { RegisterClosure } from '../models/register-closure';
import { Response } from '../interfaces/response';
import { RegisterClosureFilter } from '../filters/register-closure.filter';

@Injectable({
  providedIn: 'root',
})
export class RegisterClosureService {
  private readonly index = environment.api + '/v1/register_closures';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<RegisterClosure | null>> {
    return this.api.get<Response<RegisterClosure | null>>(RegisterClosure, this.index + '/' + id);
  }

  all(p?: Partial<RegisterClosureFilter>): Observable<Response<RegisterClosure[]>> {
    return this.api.get<Response<RegisterClosure[]>>(
      RegisterClosure,
      this.index + (p ? new RegisterClosureFilter(p).getQuery() : '')
    );
  }

  create(registerClosure: RegisterClosure): Observable<Response<RegisterClosure>> {
    return this.api.post<Response<RegisterClosure>>(RegisterClosure, this.index, registerClosure);
  }

  update(registerClosure: RegisterClosure): Observable<Response<RegisterClosure>> {
    return this.api.put<Response<RegisterClosure>>(
      RegisterClosure,
      this.index + '/' + registerClosure.id,
      registerClosure
    );
  }

  delete(registerClosure: RegisterClosure): Observable<Response<RegisterClosure>> {
    return this.api.delete<Response<RegisterClosure>>(RegisterClosure, this.index + '/' + registerClosure.id);
  }
}
