// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: process.env['ENV'],
  production: process.env['PRODUCTION'] === 'true',
  api: process.env['API']!,
  search: process.env['SEARCH_API']!,
  aiServicesURL: process.env['AI_SERVICES_URL']!,
  import: process.env['IMPORT_API']!,
  // order: 'http://localhost:8089',
  order: process.env['ORDER_API']!,
  sentry: {
    dsn: process.env['SENTRY_DSN']!,
  },
  buildVersion: '0.1.17',
  buildDate: '02-07-2024',
  firebase: {
    apiKey: process.env['FB_API_KEY']!,
    databaseURL: process.env['FB_DATABASE_URL']!,
    authDomain: process.env['FB_AUTH_DOMAIN']!,
    projectId: process.env['FB_PROJECT_ID']!,
    storageBucket: process.env['FB_STORAGE_BUCKET']!,
    messagingSenderId: process.env['FB_MESSAGING_SENDER_ID']!,
    appId: process.env['FB_APP_ID']!,
    measurementId: process.env['FB_MEASUREMENT_ID']!,
  },
  allowedDomains: [
    'localhost:4200',
    'localhost:8082',
    'localhost:8089',

    // New
    'sl-ai-services-773178244480.southamerica-west1.run.app', // AI Services develop
    'https://mp-backend-87745123885.southamerica-east1.run.app', // API
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
